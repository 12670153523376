import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import { push } from 'react-router-redux'

import QuestionnaireGeneralInformationForm from '../../components/Questionnaires/QuestionnaireGeneralInformationForm'

import { createQuestionnaire } from '../../actions/questionnaires'
import { _fetchCustomer } from '../../actions/customers'
import { getFullName } from '../../selectors/contacts'

export function QuestionnaireGeneralInformation (props) {
  const {
    questionnaire,
    accessToken,
    createQuestionnaire,
    fetchQuestionnaire,
    updateQuestionnaire,
    changeForm,
    formValue,
    push,
    me
  } = props

  console.log(questionnaire);

  const { customer: nowCustomer } = formValue

  useEffect(() => {
    if (me.data.role.includes('Customer')) {
      push('/questionnaires')
    }
  }, [me]) // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line
    async function fetchData () {
      const customerData = await _fetchCustomer(questionnaire.customer.id, accessToken)
      customerData.default_contact = {
        ...questionnaire.detail.main_contact,
        name: getFullName(questionnaire.detail.main_contact)
      }
      customerData.default_location = {
        ...questionnaire.detail.main_location
      }
      changeForm('customer', {
        label: customerData.name,
        value: customerData
      })
    }
    if (questionnaire) {
      fetchData()
    }
  }, [questionnaire]) // eslint-disable-line

  function prepareInitialValues (e) {
    let data = {
      id: questionnaire.id || null,
      customer: {
        label: e.customer.name,
        value: e.customer
      },
      contact: {
        label: e.detail.main_contact.name,
        value: e.detail.main_contact
      },
      location: {
        label: e.detail.main_location.name,
        value: e.detail.main_location
      },
      advance: e.detail.general_information_complete
    }
    return data
  }

  function handleSubmit (e) {
    let target
    const request = {
      id: questionnaire.id || null,
      customer_id: e.customer.value.id,
      detail: {
        main_contact: {
          id: e.contact.value.id,
          ...e.main_contact
        },
        main_location: {
          id: e.location.value.id,
          ...e.main_location
        },
        general_information_complete: true,
        copy: { ...e.copy }
      }
    }

    if (e.id) {
      return updateQuestionnaire(request, accessToken, target).then((x) => {
        fetchQuestionnaire(e.id, accessToken)
        push(`/questionnaires/${e.id}/certification-information`)
      })
    } else {
      return createQuestionnaire(request, accessToken)
    }
  }

  return (
    <div className='content'>
      <div className='main-header'>
        <h2>Create Questionnaire </h2>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <QuestionnaireGeneralInformationForm
            onSubmit={handleSubmit}
            changeForm={changeForm}
            initialValues={prepareInitialValues(questionnaire)}
            customerAPI={{
              url: process.env.REACT_APP_BASE_API + '/customers',
              accessToken: accessToken
            }}
            contactAPI={{
              url: process.env.REACT_APP_BASE_API + '/customers/' + (nowCustomer ? nowCustomer.value.id : -1) + '/contacts',
              accessToken: accessToken
            }}
            locationAPI={{
              url: process.env.REACT_APP_BASE_API + '/customers/' + (nowCustomer ? nowCustomer.value.id : -1) + '/locations',
              accessToken: accessToken
            }}
            fileAPI={{
              url: process.env.REACT_APP_BASE_API,
              accessToken: accessToken
            }}
            push={push}
          />
        </div>
        <div className='col-md-4' />
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    me: state.auth.user,
    formValue: getFormValues('QuestionnaireGeneralInformationForm')(state) || {}
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createQuestionnaire: (data, key) => dispatch(createQuestionnaire(data, key)),
    changeForm: (field, data) => dispatch(change('QuestionnaireGeneralInformationForm', field, data)),
    push: to => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireGeneralInformation)
