function makeCertificationAction (props) {
  // console.log(1000, props)
  const actions = [
    {
      status: 'Created',
      todo: 'Submit Order Prerequisite Completeness',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Complete order prerequisite',
        action: props.onCompleteQuestionnaire,
        alternative: 'Please wait for order prerequisite completion by customer'
      },
      secondary: [
        {
          role: ['Super Admin', 'Scheduler'],
          text: 'Create audit schedule',
          action: props.onManageSchedule
        }
      ]
    },
    {
      status: 'Prerequisite Not Complete',
      todo: 'Resubmit Order Prerequisite Completeness',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Complete order prerequisite',
        action: props.onCompleteQuestionnaire,
        alternative: 'Please wait for order prerequisite completion by customer'
      },
      secondary: [
        {
          role: ['Super Admin', 'Scheduler'],
          text: 'Create audit schedule',
          action: props.onManageSchedule
        }
      ]
    },
    {
      status: 'Prerequisite Submitted',
      todo: 'Review Order Prerequisite Documents',
      primary: {
        role: ['Admin Support'],
        text: 'Review order prerequisite',
        action: props.onReviewQuestionnaire,
        alternative: 'Please wait for order prerequisite review by Admin Support'
      },
      secondary: [
        {
          role: ['Super Admin', 'Scheduler'],
          text: 'Create audit schedule',
          action: props.onManageSchedule
        }
      ]
    },
    {
      status: 'Prerequisite Completed',
      todo: 'Audit Scheduling Stage 1',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'A Team Stage 1 Need to Revise',
      todo: 'Update audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'Audit Schedule Stage 1 Created',
      todo: 'Upload A-Team Stage 1',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Upload A-Team',
        action: props.onUpload,
        alternative: 'Please wait for A Team by Audit Plan Operator'
      }
    },
    {
      status: 'A Team Stage 1 Proposed',
      todo: 'Review A-Team',
      primary: {
        role: ['Head of CB'],
        text: 'Review Decision',
        action: props.onReviewATeamStage1,
        alternative: 'Please wait for audit schedule review by Head of CB'
      }
    },
    {
      status: 'A Team Stage 1 Approved',
      todo: 'Prepare Audit Plan Stage 1',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Create Audit Plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan Stage 1 Drafted',
      todo: 'Update & Edit Audit Plan Stage 1',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan Stage 1 Need to Revise',
      todo: 'Update & Edit Audit Plan Stage 1',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan Stage 1 Created',
      todo: 'Review Audit Plan Stage 1',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by customer'
      }
    },
    {
      status: 'Audit Plan Stage 1 Approved',
      todo: 'Waiting for audit stage 1',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start audit stage 1',
        action: props.onAudit1Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      },
      secondary: [
        // {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Create Area of Concern / NC',
        //   action: props.onCreateNc
        // }, {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Audit report',
        //   action: props.onCreateReport
        // }
      ]
    },
    {
      status: 'Audit Stage 1 On Process',
      todo: 'Audit Stage 1 in Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Area of Concern / NC',
        action: props.onCreateNc,
        alternative: 'Audit Stage 1 in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit stage 1 report',
          action: props.onCreateReport
        }
      ]
    },
    // start
    {
      status: 'VA Stage 1 Required',
      todo: 'Wait for Quotation Amendment'
    },
    {
      status: 'VA Stage 1 Approved',
      todo: 'Audit Scheduling Stage 1 Verification',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'A Team VA Stage 1 Need to Revise',
      todo: 'Update audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'VA Stage 1 Schedule Created',
      todo: 'Upload A-Team VA Stage 1',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Upload A-Team',
        action: props.onUpload,
        alternative: 'Please wait for A Team by Audit Plan Operator'
      }
    },
    {
      status: 'A Team VA Stage 1 Proposed',
      todo: 'Review A-Team',
      primary: {
        role: ['Head of CB'],
        text: 'Review Decision',
        action: props.onReviewATeamVAStage1,
        alternative: 'Please wait for audit schedule review by Head of CB'
      }
    },
    {
      status: 'A Team VA Stage 1 Approved',
      todo: 'Prepare Audit Plan VA Stage 1',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Create Audit Plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan VA Stage 1 Drafted',
      todo: 'Update & Edit Audit Plan VA Stage 1',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan VA Stage 1 Need to Revise',
      todo: 'Update & Edit Audit Plan VA Stage 1',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan VA Stage 1 Created',
      todo: 'Review Audit Plan VA Stage 1',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by customer'
      }
    },
    {
      status: 'Audit Plan VA Stage 1 Approved',
      todo: 'Waiting for Verification Audit stage 1',
      primary: {
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start Verification Audit Stage 1',
        action: props.onAuditVA1Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      }
    },
    {
      status: 'VA Stage 1 On Process',
      todo: 'VA Stage 1 On Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Area of Concern / NC',
        action: props.onCreateNc,
        alternative: 'Audit VA Stage 1 in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit stage 1 report',
          action: props.onCreateReport
        }
      ]
    },
    // end
    {
      status: 'Audit Stage 1 Done',
      todo: 'Waiting for audit stage 2',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'A Team Stage 2 Need to Revise',
      todo: 'Update audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'Audit Schedule Stage 2 Created',
      todo: 'Upload A-Team Stage 2',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Upload A-Team',
        action: props.onUpload,
        alternative: 'Please wait for A Team by Audit Plan Operator'
      }
    },
    {
      status: 'A Team Stage 2 Proposed',
      todo: 'Review A-Team',
      primary: {
        role: ['Head of CB'],
        text: 'Review Decision',
        action: props.onReviewATeamStage2,
        alternative: 'Please wait for audit schedule review by Head of CB'
      }
    },
    {
      status: 'A Team Stage 2 Approved',
      todo: 'Prepare Audit Plan Stage 2',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Create Audit Plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan Stage 2 Drafted',
      todo: 'Update & Edit Audit Plan Stage 2',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan Stage 2 Need to Revise',
      todo: 'Update & Edit Audit Plan Stage 2',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan Stage 2 Created',
      todo: 'Review Audit Plan Stage 2',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by customer'
      }
    },
    {
      status: 'Audit Plan Stage 2 Approved',
      todo: 'Waiting for audit stage 2',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start audit stage 2',
        action: props.onAudit2Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      },
      secondary: [
        // {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Create Area of Concern / NC',
        //   action: props.onCreateNc
        // }, {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Audit report',
        //   action: props.onCreateReport
        // }
      ]
    },
    {
      status: 'Audit Stage 2 On Process',
      todo: 'Audit Stage 2 in Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Non Conformity',
        action: props.onCreateNc,
        alternative: 'Audit Stage 2 in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit stage 2 report',
          action: props.onCreateReport
        }
      ]
    },
    // start
    {
      status: 'VA Stage 2 Required',
      todo: 'Wait for Quotation Amendment'
    },
    {
      status: 'VA Stage 2 Approved',
      todo: 'Audit Scheduling Stage 2 Verification',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'A Team VA Stage 2 Need to Revise',
      todo: 'Update audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'VA Stage 2 Schedule Created',
      todo: 'Upload A-Team VA Stage 2',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Upload A-Team',
        action: props.onUpload,
        alternative: 'Please wait for A Team by Audit Plan Operator'
      }
    },
    {
      status: 'A Team VA Stage 2 Proposed',
      todo: 'Review A-Team',
      primary: {
        role: ['Head of CB'],
        text: 'Review Decision',
        action: props.onReviewATeamVAStage2,
        alternative: 'Please wait for audit schedule review by Head of CB'
      }
    },
    {
      status: 'A Team VA Stage 2 Approved',
      todo: 'Prepare Audit Plan VA Stage 2',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Create Audit Plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan VA Stage 2 Drafted',
      todo: 'Update & Edit Audit Plan VA Stage 2',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan VA Stage 2 Need to Revise',
      todo: 'Update & Edit Audit Plan VA Stage 2',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Lead Auditor'
      }
    },
    {
      status: 'Audit Plan VA Stage 2 Created',
      todo: 'Review Audit Plan VA Stage 2',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by customer'
      }
    },
    {
      status: 'Audit Plan VA Stage 2 Approved',
      todo: 'Waiting for Verification Audit stage 2',
      primary: {
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start Verification Audit Stage 2',
        action: props.onAuditVA2Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      }
    },
    {
      status: 'VA Stage 2 On Process',
      todo: 'VA Stage 2 On Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Area of Concern / NC',
        action: props.onCreateNc,
        alternative: 'Audit VA Stage 2 in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit stage 2 report',
          action: props.onCreateReport
        }
      ]
    },
    // end
    {
      status: 'Audit Stage 2 Done',
      todo: 'Waiting for Audit Documents Completeness Cheking',
      primary: {
        role: ['Admin Support'],
        text: 'Confirm Completeness',
        action: props.onCompletenessChecking,
        alternative: 'Please wait for Audit Documents Completeness Cheking'
      }
    },
    {
      status: 'Audit Documents Need to Revise',
      todo: 'Waiting for Audit Documents Update',
      primary: {
        role: ['Admin Support'],
        users: props.userLeadAuditor,
        text: 'Update Documents',
        action: props.onReviseDocument,
        alternative: 'Please wait for Audit Documents Update'
      }
    },
    {
      status: 'Audit Documents Completed',
      todo: 'Veto Person Assignment',
      primary: {
        pre: props.preAssignVeto,
        role: ['Operational Manager'],
        text: 'Assign Veto Person',
        action: props.onToggleVeto,
        alternative: 'Please wait for NC closing and Veto Person Assignment by Operational Manager'
      }
    },
    {
      status: 'Veto Person Assigned',
      todo: 'Veto Review Audit Report',
      primary: {
        role: ['Super Admin'],
        users: props.userVeto,
        text: 'Veto Review Audit Report',
        action: props.onCreateReport,
        alternative: 'Please wait for Audit Report Review by Veto Person'
      }
    },
    {
      status: 'Veto Process Done',
      todo: 'Head of CB Approval',
      primary: {
        role: ['Head of CB', 'Deputy Head of CB'],
        usersNot: props.userAuditor,
        text: 'Head of CB Approval',
        action: props.onHCBApproval,
        alternative: 'Please wait for Head of CB Approval'
      }
    },
    {
      status: 'Certificate Granted',
      todo: 'Publish Certificate',
      primary: {
        pre: false,
        // role: ['Super Admin', 'Head of CB', 'Certificate Admin'],
        // text: 'Publish Certificate',
        // action: props.onPublishCertifate,
        alternative: 'Please wait for Release Certificate'
      },
    },
    {
      status: 'Certificate Published',
      todo: 'Order Finish'
      // primary: {
      //   pre: false
      //   role: ['Super Admin', 'Head of CB', 'Certificate Admin'],
      //   text: 'Publish Certificate',
      //   action: props.onPublishCertifate,
      //   alternative: 'Please wait for Release Certificate'
      // }
    }
  ]

  return actions
}

export default makeCertificationAction
