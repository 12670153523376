import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import indonesia from '../../const/indonesia.js' // TODO split here

import { TextField, TextareaField, OptionsField } from '../common/FormFields'

const validate = (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }
  if (!values.address) {
    errors.address = 'Required'
  }
  if (!values.phone) {
    errors.phone = 'Required'
  }
  if (!values.postal_code) {
    errors.postal_code = 'Required'
  }
  if (!values.country) {
    errors.country = 'Required'
  }
  if (!values.city) {
    errors.city = 'Required'
  }

  return errors
}

class CustomerLocationForm extends Component {
  getCountries () {
    return [
      'Indonesia',
      'Other'
    ]
  }

  getProvinces () {
    return indonesia.map(x => x.name)
  }

  inIndonesia (x) {
    return indonesia.find(y => y.name === x)
  }

  getRegencies () {
    if (this.props.selectedProvince) {
      const province = indonesia.find(x => x.name === this.props.selectedProvince)
      return province.regencies
    }
    return null
  }

  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>
          <Field name='name' type='text' component={TextField} label='Address Name' required />

          <Field name='address' type='text' component={TextareaField} label='Address' required />

          { this.props.selectedCountry === 'Indonesia'
            ? <Field name='country' type='select' component={OptionsField} label='Country' options={this.getCountries()} required />
            : <Field name='country' component={TextField} label='Country' required />
          }

          { this.props.selectedCountry === 'Indonesia'
            ? <Field name='state' type='select' component={OptionsField} label='State' options={['', ...this.getProvinces()]} />
            : <Field name='state' component={TextField} label='State' />
          }

          { this.props.selectedCountry === 'Indonesia' && this.inIndonesia(this.props.selectedProvince)
            ? <Field name='city' type='select' component={OptionsField} label='City' options={['', ...this.getRegencies()]} required />
            : <Field name='city' component={TextField} label='City' required />
          }

          <Field name='postal_code' type='text' component={TextField} label='Postal Code' required />
          <Field name='phone' type='text' component={TextField} label='Phone' required />
          <Field name='fax' type='text' component={TextField} label='Fax' />
          <Field name='default' type='checkbox' component={OptionsField} label='Default address' />
          <Field name='billing' type='checkbox' component={OptionsField} label='Billing address' />
          <Field name='delivery' type='checkbox' component={OptionsField} label='Certificate delivery address' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const createSelector = formValueSelector('customerLocationCreate')
const BaseCustomerLocationCreateForm = props => <CustomerLocationForm {...props} mode='create' />

let CustomerLocationCreateForm = reduxForm({
  enableReinitialize: true,
  form: 'customerLocationCreate',
  validate
})(BaseCustomerLocationCreateForm)

CustomerLocationCreateForm = connect(state => { // eslint-disable-line 
  return {
    selectedCountry: createSelector(state, 'country'),
    selectedProvince: createSelector(state, 'state')
  }
})(CustomerLocationCreateForm)

const updateSelector = formValueSelector('customerLocationUpdate')
const BaseCustomerLocationUpdateForm = props => <CustomerLocationForm {...props} mode='update' />

let CustomerLocationUpdateForm = reduxForm({
  enableReinitialize: true,
  form: 'customerLocationUpdate',
  validate
})(BaseCustomerLocationUpdateForm)

CustomerLocationUpdateForm = connect(state => { // eslint-disable-line 
  return {
    selectedCountry: updateSelector(state, 'country'),
    selectedProvince: updateSelector(state, 'state')
  }
})(CustomerLocationUpdateForm)

export default CustomerLocationForm
export { CustomerLocationCreateForm, CustomerLocationUpdateForm }
