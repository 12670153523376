import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

// import AutoCompleteField from '../common/AutoCompleteField'
import orderStatuses from '../../const/orderStatuses'
import orderPhases from '../../const/orderPhases'
import AutoCompleteField from '../common/AutoCompleteField'


class BaseOrderFilter extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          <div className='form-group'>
            <Field name='status' component='select' className='form-control'>
              <option value=''>All Status &nbsp;</option>
              {orderStatuses.map((x, i) => <option key={i} value={x.label}>{x.label} &nbsp;</option>)}
            </Field>
          </div>
          <div className='form-group'>
            <Field name='phase' component='select' className='form-control'>
              <option value=''>All Cycle &nbsp;</option>
              {orderPhases.map((x, i) => <option key={i} value={x}>{x} &nbsp;</option>)}
            </Field>
          </div>
          &nbsp;
          <div className='form-group' style={{width: 150}}>
            <Field
              name='customer_id'
              component={AutoCompleteField}
              label='Customers'
              api={this.props.customerAPI}
            />
          </div>
          {
          // &nbsp;
          // <div className='form-group' style={{width: 150}}>
          //   <Field
          //     name='standard'
          //     component={AutoCompleteField}
          //     label='Standard'
          //     api={this.props.standardAPI}
          //   />
          // </div>
          }
          &nbsp;
          <div className="form-group">
            <label className="sr-only" htmlFor="exampleInputEmail2">
              Order Number
            </label>
            <Field
              name="number"
              component="input"
              type="text"
              placeholder="Search for order number..."
              className="form-control"
            />
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/orders' className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let OrderFilter = reduxForm({
  form: 'orderFilter' //, validate, warn
})(BaseOrderFilter)

export { BaseOrderFilter }
export default OrderFilter
