import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form'

import { OptionsField, TextareaField, TextField } from '../common/FormFields'
import ConfirmButton from '../common/ConfirmButton'
import FileField from '../common/FileField'

import {
  FactorPicker,
  AuditCycleField
  // FeesField
} from '../Quotations/QuotationFields'

import allocateMandays from '../../const/allocateMandays'

function checkFactor (factor) {
  const factorTotal = factor.reduce((sum, x) => {
    return sum + x.value
  }, 0)
  if (factorTotal > 30) {
    return 'Sum should be less than or equal to 30 %'
  } else {
    return false
  }
}

function validate (values) {
  if (
    (values.addition_factor && checkFactor(values.addition_factor)) ||
    (values.reduction_factor && checkFactor(values.reduction_factor)) ||
    (values.enhancement_factor && checkFactor(values.enhancement_factor)) ||
    (values.audit_cycle.length === 0)
  ) {
    throw new SubmissionError({
      addition_factor: values.addition_factor && checkFactor(values.addition_factor) ? checkFactor(values.addition_factor) : null,
      reduction_factor: values.reduction_factor && checkFactor(values.reduction_factor) ? checkFactor(values.reduction_factor) : null,
      enhancement_factor: values.enhancement_factor && checkFactor(values.enhancement_factor) ? checkFactor(values.enhancement_factor) : null,
      audit_cycle: values.audit_cycle.length === 0 ? 'Please select one or more phase' : null
    })
  } else {
    return this.props.onSubmit({ ...values, advance: true })
  }
}

function warn (values) {
  const errors = {}
  if (values.addition_factor && checkFactor(values.addition_factor)) {
    errors.addition_factor = checkFactor(values.addition_factor)
  }

  if (values.reduction_factor && checkFactor(values.reduction_factor)) {
    errors.reduction_factor = checkFactor(values.reduction_factor)
  }

  if (values.enhancement_factor && checkFactor(values.enhancement_factor)) {
    errors.enhancement_factor = checkFactor(values.enhancement_factor)
  }

  // console.log(values.audit_cycle)
  // if (values.audit_cycle && values.audit_cycle.length === 0) {
  //   errors.audit_cycle = 'Please select one or more phase'
  // }

  return errors
}

export class QuestionnaireMandaysCalculationForm extends Component {
  checkIncludes (cycle) {
    return cycle.reduce((sum, x, i) => {
      if (x.include) {
        return true
      }
      return sum
    }, false)
  }

  handleMandaysUpdate (e, val) {
    if (!this.checkIncludes(this.props.currentAuditCycle)) {
      const x = allocateMandays(this.props.phases, val)
      this.props.change('audit_cycle', x)
    }
  }

  render () {
    const {
      handleSubmit,
      submitting,
      integration,
      // services,
      interval,
      phases,
      mandaysAllocation,
      hasATeam,
      fileAPI
    } = this.props

    const intervalOptions = [
      // {
      //   value: 0,
      //   label: '...'
      // },
      {
        value: 6,
        label: '6 Months'
      },
      {
        value: 9,
        label: '9 Months'
      },
      {
        value: 12,
        label: '12 Months / Annual'
      }
    ]

    let filteredPhase = []
    filteredPhase = [ ...phases ]

    if (interval === '6') {
      filteredPhase = [ ...phases ]
    }

    if (interval === '9') {
      filteredPhase = phases.slice(0, 5)
    }

    if (interval === '12') {
      filteredPhase = phases.slice(0, 3)
    }

    const additionalServices = []
    // if (services.audit) {
    //   additionalServices.push('Gap Analysis / Pre Audit')
    // }
    // if (services.training) {
    //   additionalServices.push('Training')
    // }

    return (
      <form className='form-horizontal questionnaire-mandays-calculation-form' onSubmit={handleSubmit}>

        <fieldset>
          <legend>A Team</legend>

          <Field name='a_team' component={FileField} label='A Team Preliminary' api={{...fileAPI, path: 'questionnaire'}} />

          {
            // <Field name='mandays_by_a_team'
            //   component={OptionsField}
            //   type='selectOrInput'
            //   label='Perhitungan Formula Mandays sesuai A Team atau justifikasi lainnya'
            //   options={['Yes']}
            //   onChange={this.handleMandaysUpdate.bind(this)}
            // />
          }

          <Field name='mandays_by_a_team' component={TextField} label='Mandays from A Team or other justification' type='number' />

          <Field name='mandays_justification' component={TextareaField} label='Justifikasi penyesuaian mandays' />

        </fieldset>

        {!hasATeam && (

          <fieldset>

            <legend>Man Days Adjustment</legend>

            { !integration &&
              <Field name='reduction_factor'
                component={FactorPicker}
                label='Grounds for reduction (max 30%)'
                max={30}
                options={[
                  {
                    name: 'Product development',
                    max: 20
                  },
                  {
                    name: 'Knowledege of the site and management system available',
                    max: 10
                  },
                  {
                    name: 'Client preparedness for certification',
                    max: 10
                  },
                  {
                    name: 'Small site with high density of employees',
                    max: 10
                  },
                  {
                    name: 'Only one general process',
                    max: 10
                  },
                  {
                    name: 'Well developed management system',
                    max: 10
                  },
                  {
                    name: 'Many employees perform the same, simple task',
                    max: 10
                  },
                  {
                    name: 'Congeneric activities in all shifts (only with documented evidence of former internal / external audits)',
                    max: 10
                  },
                  {
                    name: 'High degree of automation',
                    max: 10
                  },
                  {
                    name: 'Some employees working in the field (off site)',
                    max: 10
                  }
                ]}
              />
            }

            { integration &&
              <Field name='addition_factor'
                component={FactorPicker}
                label='Additional Reduction for combined IMS Audits'
                max={30}
                options={[
                  {
                    name: 'Integrated manual, combined auditing of central elements',
                    max: 20
                  },
                  {
                    name: 'Using auditors with integrated qualification',
                    max: 10
                  },
                  {
                    name: 'Only for ISO 5001 for combined audits with ISO 14001 or ISO 9001',
                    max: 20
                  },
                  {
                    name: 'For combined audit SCC & OHSASS (only for OHSASS - when congeneric scope for OHSAS & SCC',
                    max: 5
                  },
                  {
                    name: 'Using auditors with integrated qualification (in the team), who auditing both management systems',
                    max: 10
                  }
                ]}
              />
            }

            <Field name='enhancement_factor'
              component={FactorPicker}
              label='Grounds for enhancement (max 30%)'
              max={30}
              options={[
                {
                  name: 'Difficult logistic with more than one site / building',
                  max: 5
                },
                {
                  name: 'Interpreter necessary',
                  max: 5
                },
                {
                  name: 'Large area with low density of employees',
                  max: 5
                },
                {
                  name: 'High diversity of regulations (food, medicines / pharmaceutical products, aviation / aerospace, nuclear power)',
                  max: 5
                },
                {
                  name: 'Indirect environment aspects',
                  max: 10
                },
                {
                  name: 'Processess with high complexity / many one-time jobs',
                  max: 10
                },
                {
                  name: 'Exceptional environment aspect / license requirement',
                  max: 10
                },
                {
                  name: 'Activities that require visiting temporary sites',
                  max: 10
                },
                {
                  name: 'Outsourced functions and processess',
                  max: 10
                },
                {
                  name: 'Higher sensitivity of receiving environment compared to typical location for the industru sector',
                  max: 10
                },
                {
                  name: 'The views of interested parties',
                  max: 10
                },
                {
                  name: 'Risk of ecological hazards and consequences, directly or indirectly, caused by the organization',
                  max: 10
                }

              ]}
            />

          </fieldset>
        )}


        <fieldset>
          <legend>Certification Process</legend>

          {additionalServices.length > 0 &&
            <Field
              name='additional_service'
              component={AuditCycleField}
              label='Additional Service'
              phases={additionalServices}
              scheme='load'
            />
          }

          <Field
            name='surveillance_interval'
            component={OptionsField}
            type='select'
            label='Certification Surveillance Interval'
            options={intervalOptions}
          />

          <Field
            name='audit_cycle'
            component={AuditCycleField}
            label='Audit Cycle'
            phases={filteredPhase}
            mandaysAllocation={mandaysAllocation}
            scheme='load'
            required
          />

          <Field name='include_recertification'
            component={OptionsField}
            type='select'
            label='Include recertification schedule'
            options={['Yes', 'No']}
          />
        </fieldset>
        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={handleSubmit} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton disabled={submitting} text={'Save Adjustment'}>
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={handleSubmit(validate.bind(this))} >Continue</button>
            </ConfirmButton>
          </div>
        </div>
      </form>
    )
  }
}

const selector = formValueSelector('questionnaireAdjustmentForm')

QuestionnaireMandaysCalculationForm = reduxForm({ // eslint-disable-line 
  form: 'questionnaireAdjustmentForm',
  enableReinitialize: true,
  warn
})(QuestionnaireMandaysCalculationForm)

QuestionnaireMandaysCalculationForm = connect(state => { // eslint-disable-line
  return {
    reductionFactor: selector(state, 'reduction_factor'),
    enhancementFactor: selector(state, 'enhancement_factor'),
    additionFactor: selector(state, 'addition_factor'),
    currentMandays: parseInt(selector(state, 'mandays'), 10),
    currentAuditCycle: selector(state, 'audit_cycle'),
    currentMandaysByATeam: parseInt(selector(state, 'mandays_by_a_team'), 10)
  }
})(QuestionnaireMandaysCalculationForm)

export default QuestionnaireMandaysCalculationForm
