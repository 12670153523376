import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Helmet } from 'react-helmet'

import Pagination from '../../components/common/Pagination'
import QuotationTable from '../../components/Quotations/QuotationTable'
import QuotationFilter from '../../components/Quotations/QuotationFilter'

import { fetchQuotations } from '../../actions/quotations'
import { asQueryObject, asQueryString } from '../../const/helper'

function mapStateToProps (state) {
  return {
    quotations: state.quotations.quotationList.data,
    pagination: state.quotations.quotationList.pagination,
    accessToken: state.auth.user.access.access_token,
    me: state.auth.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchQuotations: (params, key) => dispatch(fetchQuotations(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export class QuotationList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      return this.props.fetchQuotations(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    return this.props.fetchQuotations(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    if (filter.standard === null) {
      delete filter.standard
    }
    if (filter.standard) {
      filter.standard = filter.standard.value.id
    }
    if (filter.customer_id) {
      filter.customer_id = filter.customer_id.value.id
    }
    if (filter?.sales_id?.value?.id) {
      filter.sales_id = filter.sales_id.value.id
    }
    delete filter.page
    return this.props.push(`/quotations?${asQueryString(filter)}`)
  }

  render () {
    const { quotations, pagination, location, me } = this.props
    const role = me?.data?.role || [];

    return (
      <div className='content'>
        <Helmet>
          <title>Quotations</title>
        </Helmet>
        <div className='main-header'>
          <h2>Quotation List</h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          { quotations
            ? <div>
              <QuotationFilter
                onSubmit={this.handleFilter.bind(this)}
                initialValues={asQueryObject(location.search)}
                isShortVersion={role.includes('Customer')}
                standardAPI={{
                  url: process.env.REACT_APP_BASE_API + '/standards',
                  accessToken: this.props.accessToken,
                  label: 'name'
                }}
                customerAPI={{
                  url: process.env.REACT_APP_BASE_API + '/customers',
                  accessToken: this.props.accessToken,
                  label: 'name'
                }}
                salesAPI={{
                  url: process.env.REACT_APP_BASE_API + '/users?role=sales',
                  accessToken: this.props.accessToken,
                  label: 'data.display_name'
                }}
              />
              <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
              <QuotationTable quotations={quotations} />
            </div>
            : <div>loading</div>
          }
          <Pagination pagination={pagination} base='/quotations' params={asQueryObject(location.search)} />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationList)
