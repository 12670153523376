import React, { Fragment, useState, useEffect } from 'react'
import { Field } from 'redux-form'
import Required from '../common/Required'

const StandardScopeField = ({ options, index, input: { value, onChange, name }, onAdvance, onFetchScope }) => {
  const [standard, setStandard] = useState(value ? value?.[0]?.standard_id : 0)
  const [scopeArray, setScopeArray] = useState(value ? value.map(x => x.id) : [])
  const [step, setStep] = useState(value ? 2 : 0)
  const [scopeOption, setScopeOption] = useState([])
  const [showChild, setShowChild] = useState(false)
  const [scopeList, setScopeList] = useState([])
  const standarObject = options.find(x => x.value === parseInt(standard, 10))
  const scopeObjectArray = toScopeData(scopeArray)

  let standardName = ''
  if (standarObject) {
    standardName = standarObject.label
  }

  useEffect(() => {
    if (value) {
      onFetchScope(standard)
        .then(result => {
          setScopeOption(toScopeTree(result))
          setScopeList(result)
          setShowChild(false)
        })
    }
  }, [onFetchScope, value, standard])

  function toScopeTree (scopes = []) {
    const parents = scopes
      .filter(x => !x.detail.parent)
      .map(x => ({
        ...x,
        child: scopes.filter(xx => xx.detail.parent === x.id)
      }))
    return parents
  }

  function toScopeData (array) {
    return array.map(x => scopeList.find(xx => xx.id === x))
  }

  async function changeStandard (ev) {
    const standardId = ev.target.value
    const result = await onFetchScope(standardId)
    setScopeOption(toScopeTree(result))
    setScopeList(result)
    setStandard(standardId)
    setShowChild(false)
    if (result.length === 1) {
      const newValue = result.map(x => x.id)
      setScopeArray(newValue)
      onChange(result)
      setStep(2)
    } else if (result.length > 1) {
      setStep(1)
    }
  }

  function resetStandard () {
    setStep(0)
    onChange(null)
    setScopeArray([])
    setShowChild(false)
  }

  function toggleScopeSelection (id) {
    if (scopeArray.indexOf(id) >= 0) {
      setScopeArray(scopeArray.filter(xx => xx !== id))
    } else {
      setScopeArray([...scopeArray, id])
    }
  }

  function changeScope () {
    onChange(toScopeData(scopeArray))
    setStep(2)
    setShowChild(false)
  }

  async function resetScope () {
    const result = await onFetchScope(scopeObjectArray[0].standard_id)
    setScopeOption(toScopeTree(result))
    setScopeList(result)
    setStep(1)
    setShowChild(false)
  }

  return (
    <div>
      {step === 0 &&
        <Fragment>
          <label>Select standard</label>
          <select className='form-control' value={value} onChange={changeStandard}>
            { options.map((x, y) => {
              let option
              if (typeof x === 'object') {
                option = <option value={x.value} key={y} >{x.label}</option>
              } else {
                option = <option value={x} key={y} >{x}</option>
              }
              return option
            })}
          </select>
        </Fragment>
      }
      {step === 1 &&
        <Fragment>
          <div>
            <div className='modal fade in standard-scope-field' id='standard-scope-field' tabIndex='-1' role='dialog' style={{ display: 'block', paddingRight: '15px' }} >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <button onClick={resetStandard} type='button' className='close' data-dismiss='modal' aria-hidden='true'>×</button>
                    <h4 className='modal-title' id='myModalLabel'>Select Scope for {options.find(x => x.value === parseInt(standard, 10)).label}</h4>
                  </div>
                  <div className='modal-body'>
                    { scopeOption.length > 1 &&
                    <Fragment>
                      <div className='scope-selection'>
                        { scopeOption
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .sort((a, b) => parseInt(a.detail.number, 10) - parseInt(b.detail.number, 10))
                          .map((x, i) => {
                            return (
                              <div key={i} style={{ display: 'flex' }}>
                                <div style={{ marginRight: 10 }}>
                                  <input
                                    type='checkbox'
                                    defaultChecked={scopeArray.indexOf(x.id) >= 0}
                                    onChange={toggleScopeSelection.bind(this, x.id)}
                                  />{' '}
                                </div>
                                <div style={{ flex: 1 }}>
                                  <p>
                                    <strong>{x.detail.number} - {x.name}</strong>
                                    {x.child.length > 0 && showChild !== x.id &&
                                    <button className='btn btn-default btn-xs' onClick={() => setShowChild(x.id)}>Show sub scope</button>
                                    }
                                  </p>
                                  {x.detail.description &&
                                  <p>{x.detail.description}</p>
                                  }
                                  {x.child.length > 0 && showChild === x.id &&
                                  <div>
                                    {x.child.map((xx, ii) => (
                                      <div key={ii} style={{
                                        display: 'flex',
                                        borderBottom: '1px solid #aaa',
                                        marginBottom: 5
                                      }}>
                                        <div style={{ marginRight: 10 }}>
                                          <input
                                            type='checkbox'
                                            defaultChecked={scopeArray.indexOf(xx.id) >= 0}
                                            onChange={toggleScopeSelection.bind(this, xx.id)}
                                          />{' '}
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <p><strong>{xx.detail.number} - {xx.name}</strong></p>
                                          {
                                            // xx.detail.description && <p>{xx.detail.description}</p>
                                          }
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  }
                                </div>
                                <hr />
                              </div>
                            )
                          })
                        }
                      </div>
                      <button type='button' onClick={changeScope} className='btn-modal btn btn-default btn-block btn-sm'>Confirm scope selection</button>
                    </Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-backdrop fade in' onClick={() => 1 + 1} />
          </div>
        </Fragment>
      }
      { step === 2 &&
        <Fragment>
          <div className='well well-sm' style={{ borderBottom: 0, marginBottom: 0 }}>
            <strong>Standard : </strong>{standardName}
            <button className='btn btn-default pull-right btn-xs' onClick={resetStandard}>Change standard</button>
          </div>
          <div className='well well-sm'>
            {
              scopeObjectArray.length > 1 &&
              <button type='button' className='btn btn-default pull-right btn-xs' onClick={resetScope}>Change scopes</button>
            }
            {
              scopeObjectArray.map((x, i) => <p key={i}>{x && x.name}</p>)
            }
          </div>
        </Fragment>
      }
    </div>
  )
}

const StandardsField = ({ label, fields, standards, required, meta, meta: { error, warning, touched }, onFetchScope }) => {
  return (
    <div className='form-group standards-array'>

      <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
      <div className='col-sm-9'>

        {fields.map((member, index) => (
          <Fragment key={index}>
            <div className='row'>
              <div className='col-sm-12'>
                <div>
                  <Field
                    name={`${member}.scope`}
                    component={StandardScopeField}
                    options={standards}
                    index={index}
                    onFetchScope={onFetchScope}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 text-right'>
                <button
                  className='btn btn-sm'
                  type='button'
                  onClick={() => fields.remove(index)}
                >
                  Remove Standard
                </button>
              </div>
            </div>
          </Fragment>
        ))}
        <p>Select more than one standard for integration service.</p>
        <button className='btn btn-default btn-sm btn-add-new' type='button' onClick={() => fields.push({})}>Add Standard</button>
        {((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
      </div>
    </div>
  )
}

export default StandardsField
