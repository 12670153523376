import React, { Fragment } from 'react'

export default function DownloadLink (props) {
  if (!props.file) {
    return ''
  }
  let files
  if (typeof props.file === 'string') {
    files = JSON.parse(props.file);
  } else {
    files = props.file
  }

  return (
    <Fragment>
      {files.map(x => (
        <div key={x.id}>
          <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${props.accessToken}`}>
            <i className='fa fa-download' /> Download {x?.detail?.name}
          </a>
        </div>
      ))}
    </Fragment>
  )
}
