import React, { Fragment } from "react";

export default function AnnexWidget({ questionnaire: q, annex: a, ...props }) {
    let perSite = [];
    const scopes = q
        ? q.scopes.reduce((sum, x) => {
            if (sum.filter((y) => y.standard.id === x.standard.id).length === 0) {
                sum.push(x);
            }
            return sum;
        }, [])
        : [];

    return (
        <div className="widget">
            <div className="widget-header">
                <h3>
                    <i className="fa fa-tasks" /> Annex
                </h3>
            </div>
            <div className="widget-content">
                {scopes.map((x, i) => {
                    perSite = [];
                    return (
                        <div key={i}>
                            <h4>{x.standard.name}</h4>
                            <hr />
                            {x.detail ? (
                                <div>
                                    {(x.standard.annex || []).map((y, j) => {
                                        // eslint-disable-line
                                        if (!y.per_site) {
                                            return (
                                                <dl className="" key={j}>
                                                    <dt title={y.name}>
                                                        {" "}
                                                        {j + 1}.{" "}
                                                        <span
                                                            dangerouslySetInnerHTML={{ __html: y.name }}
                                                        />
                                                    </dt>
                                                    <dd>
                                                        {a[i].detail.annex[j] ? (
                                                            y.type !== "Matrix" ? (
                                                                a[i].detail.annex[j].answer
                                                            ) : (
                                                                <table className="table">
                                                                    <tbody>
                                                                        {a[i].detail.annex[j].answer
                                                                            .split("\n")
                                                                            .map((row, rowkey) => (
                                                                                <tr key={rowkey}>
                                                                                    {row.split(";").map((col, ckey) => (
                                                                                        <td key={ckey}>{col}</td>
                                                                                    ))}
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            )
                                                        ) : (
                                                            "n/a"
                                                        )}
                                                    </dd>
                                                    {a[i].detail.annex[j] && a[i].detail.annex[j].file && (
                                                        <dd>
                                                            {a[i].detail.annex[j].file.map((k) => (
                                                                <a
                                                                    key={k.id}
                                                                    href={`${process.env.REACT_APP_BASE_API}/download/${k.id}?token=${props.accessToken}`}
                                                                    className="btn btn-link btn-xs"
                                                                >
                                                                    <i className="fa fa-download" /> Download
                                                                </a>
                                                            ))}
                                                        </dd>
                                                    )}
                                                    {a[i].detail.annex[j] &&
                                                        a[i].detail.annex[j].explanation && (
                                                            <dd>
                                                                <p>{a[i].detail.annex[j].explanation}</p>
                                                            </dd>
                                                        )}
                                                </dl>
                                            );
                                        } else {
                                            return (
                                                <Fragment key={j}>
                                                    {q.locations.map((z, k) => {
                                                        return (
                                                            <Fragment key={k}>
                                                                <dl className="" key={j}>
                                                                    <dt title={y.name}>
                                                                        {" "}
                                                                        {j + 1}.{" "}
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: y.name,
                                                                            }}
                                                                        />
                                                                        <span>{z.location.name}</span>
                                                                    </dt>
                                                                    <dd>
                                                                        {a[i].detail.annex[j] ? (
                                                                            y.type !== "Matrix" ? (
                                                                                <p>
                                                                                {typeof a[i].detail.annex[j].answer === 'string' && a[i].detail.annex[j].answer}
                                                                                {Array.isArray(a[i].detail.annex[j].answer) && <span>{a[i].detail.annex[j].answer[k]}</span>}
                                                                                </p>
                                                                            ) : (
                                                                                <table className="table">
                                                                                    <tbody>
                                                                                        {a[i].detail.annex[j].answer
                                                                                            .split("\n")
                                                                                            .map((row, rowkey) => (
                                                                                                <tr key={rowkey}>
                                                                                                    {row
                                                                                                        .split(";")
                                                                                                        .map((col, ckey) => (
                                                                                                            <td key={ckey}>{col}</td>
                                                                                                        ))}
                                                                                                </tr>
                                                                                            ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            )
                                                                        ) : (
                                                                            "n/a"
                                                                        )}
                                                                    </dd>
                                                                    {a[i].detail.annex[j] &&
                                                                        a[i].detail.annex[j].file && (
                                                                            <dd>
                                                                                <a
                                                                                    href={`${process.env.REACT_APP_BASE_API}/download/${a[i].detail.annex[j].file.id}?token=${props.accessToken}`}
                                                                                    className="btn btn-link btn-xs"
                                                                                >
                                                                                    <i className="fa fa-download" />{" "}
                                                                                    Download
                                                                                </a>
                                                                            </dd>
                                                                        )}
                                                                    {a[i].detail.annex[j] &&
                                                                        a[i].detail.annex[j].explanation && (
                                                                            <dd>
                                                                                <p>
                                                                                    {a[i].detail.annex[j].explanation}
                                                                                </p>
                                                                            </dd>
                                                                        )}
                                                                </dl>
                                                            </Fragment>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                            // perSite.push(y)
                                        }
                                    })}

                                    {perSite.length > 0 &&
                                        q.locations &&
                                        q.locations.map((z, k) => {
                                            return (
                                                <div key={k}>
                                                    <h3>
                                                        {x.standard.name} for site {z.location.name}
                                                    </h3>
                                                    <hr />
                                                    {perSite.map((s, b) => {
                                                        // console.log(12, s);
                                                        return (
                                                            <dl className="dl-horizontal" key={b}>
                                                                <dt title={s.name}>{s.name}</dt>
                                                                <dd />
                                                                {
                                                                    // <dd>
                                                                    //   { a[i].detail.annex[b]
                                                                    //     ? a[i].detail.annex[b].answer
                                                                    //     : 'n/a'
                                                                    //   }
                                                                    // </dd>
                                                                    // { a[i].detail.annex[b] && a[i].detail.annex[b].file &&
                                                                    //   <dd>
                                                                    //     <a href={`${process.env.REACT_APP_BASE_API}/download/${a[i].detail.annex[b].file.id}?token=${props.accessToken}`} className='btn btn-link btn-xs'>
                                                                    //       <i className='fa fa-download' /> Download
                                                                    //     </a>
                                                                    //   </dd>
                                                                    // }
                                                                    // { a[i].detail.annex[b] && a[i].detail.annex[b].explanation &&
                                                                    //   <dd>
                                                                    //     <p>{a[i].detail.annex[b].explanation}</p>
                                                                    //   </dd>
                                                                    // }
                                                                }
                                                            </dl>
                                                        );
                                                        // return (
                                                        //   <div key={a.name}>
                                                        //     { a.type === 'textarea' &&
                                                        //       <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} component={TextareaField} label={a.name} help={a.description} />
                                                        //     }
                                                        //     { a.type === 'select' &&
                                                        //       <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} type='select' component={OptionsField} label={a.name} options={a.options.split(';')} help={a.description} />
                                                        //     }
                                                        //     { a.type === 'boolean' &&
                                                        //       <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} type='select' component={OptionsField} label={a.name} options={['Yes', 'No']} help={a.description} />
                                                        //     }
                                                        //     { a.type === 'checkbox' &&
                                                        //       <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} type='multiCheckbox' component={OptionsField} label={a.name} options={a.options.split(';')} help={a.description} />
                                                        //     }
                                                        //     { a.file &&
                                                        //       <Field name={`scopes[${i}].detail.annex[${b}][${k}].file`} component={FileField} api={props.fileAPI} label='Attacment'/>
                                                        //     }
                                                        //     { a.explanation &&
                                                        //       <Field name={`scopes[${i}].detail.annex[${b}][${k}].explanation`} component={TextareaField} api={props.fileAPI} label='Explanation'/>
                                                        //     }
                                                        //   </div>
                                                        // )
                                                    })}
                                                </div>
                                            );
                                        })}

                                    {!x.standard.annex ||
                                        (x.standard.annex.length === 0 && (
                                            <p>Annex question not available.</p>
                                        ))}
                                </div>
                            ) : (
                                <div> Not available</div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
