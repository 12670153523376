const questionnaireStatuses = [

  {
    label: 'Draft',
    color: 'warning'
  },
  {
    label: 'Created',
    color: 'primary'
  },
  {
    label: 'Assigned',
    color: 'info'
  },
  {
    label: 'Approved',
    color: 'info'
  },
  {
    label: 'Need to Revise',
    color: 'warning'
  },
  {
    label: 'Not Approved',
    color: 'warning'
  },
  {
    label: 'Quoted',
    color: 'success'
  },
  {
    label: 'Ordered',
    color: 'success'
  },
  {
    label: 'Replaced',
    color: 'info'
  }
]

export function getStatusColor (status) {
  return questionnaireStatuses.find(x => x.label === status) ? questionnaireStatuses.find(x => x.label === status).color : 'warning'
}
