import React, { Component } from 'react'
import AutoCompleteFilterField from '../common/AutoCompleteFilterField';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

const statuses = [
  'Closed',
  'Pending',
  'Open',
  'Potential Improvement',
  'Draft',
]

class BaseEventFilter extends Component {
  render () {
    const { handleSubmit, submitting, isCustomer } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          {!isCustomer && (
            <Field
              name="customer"
              component={AutoCompleteFilterField}
              label="Customer"
              api={this.props.customerAPI}
            />
          )}
           &nbsp;
          <div className='form-group'>
            <Field name='status' component='select' className='form-control'>
              <option value=''>All Status &nbsp;</option>
              {statuses.map((x, i) => <option key={i} value={x}>{x} &nbsp;</option>)}
            </Field>
          </div>
          &nbsp;
          <div className="form-group">
            <label className="sr-only" htmlFor="exampleInputEmail2">
              Order Number
            </label>
            <Field
              name="order_number"
              component="input"
              type="text"
              placeholder="Search for order number..."
              className="form-control"
            />
          </div>
          &nbsp;
          {
          // <div className='form-group'>
          //   <label className='sr-only' htmlFor='exampleInputEmail2'>Date</label>
          //   <Field
          //     name='date'
          //     component='input'
          //     type='text'
          //     placeholder='On Date'
          //     className='form-control'
          //   />
          // </div>
          }
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/events' className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let EventFilter = reduxForm({
  form: 'ncFilter' //, validate, warn
})(BaseEventFilter)

export { BaseEventFilter }
export default EventFilter
