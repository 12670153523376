export const initialState = {
  openSidebar: false,
  openSubMenu: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      window.scrollTo(0, 0);
      return { ...state };
    case "TOGGLE_SIDEBAR":
      return { ...state, openSidebar: !state.openSidebar };
    case "TOGGLE_SUBMENU":
      if (state.openSubMenu === action.payload) {
        return { ...state, openSubMenu: null };
      } else {
        return { ...state, openSubMenu: action.payload };
      }
    default:
      return state;
  }
};
