import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Helmet } from 'react-helmet'

import Pagination from '../../components/common/Pagination'
import QuestionnaireTable from '../../components/Questionnaires/QuestionnaireTable'
import QuestionnaireFilter from '../../components/Questionnaires/QuestionnaireFilter'

import { fetchQuestionnaires } from '../../actions/questionnaires'
import { fetchOptions } from '../../actions/options'

import { asQueryObject, asQueryString } from '../../const/helper'

export class QuestionnaireList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      return this.props.fetchQuestionnaires(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchQuestionnaires(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    if (filter.standard === null) {
      delete filter.standard
    }
    if (filter?.standard?.value?.id) {
      filter.standard = filter.standard.value.id
    }

    if (filter?.customer_id?.value?.id) {
      filter.customer_id = filter.customer_id.value.id
    }

    if (filter?.sales_id?.value?.id) {
      filter.sales_id = filter.sales_id.value.id
    }

    delete filter.page
    return this.props.push(`/questionnaires?${asQueryString(filter)}`)
  }

  render () {
    const { questionnaires, pagination, location, options, me } = this.props
    const role = me?.data?.role || [];
    const allowQuotationPreview = true;

    return (
      <div className='content'>
        <Helmet>
          <title>{`Questionnaires`}</title>
        </Helmet>
        <div className='main-header'>
          <h2>Questionnaire List</h2>
        </div>
        <div className='main-content'>
          { questionnaires
            ? <div>
              <QuestionnaireFilter
                onSubmit={this.handleFilter.bind(this)}
                initialValues={asQueryObject(location.search)}
                isShortVersion={role.includes('Customer')}
                standardAPI={{
                  url: process.env.REACT_APP_BASE_API + '/standards',
                  accessToken: this.props.accessToken,
                  label: 'name'
                }}
                customerAPI={{
                  url: process.env.REACT_APP_BASE_API + '/customers',
                  accessToken: this.props.accessToken,
                  label: 'name'
                }}
                salesAPI={{
                  url: process.env.REACT_APP_BASE_API + '/users?role=sales',
                  accessToken: this.props.accessToken,
                  label: 'data.display_name'
                }}
              />
              <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
              <QuestionnaireTable
                questionnaires={questionnaires}
                me={this.props.me}
                allowQuotationPreview={allowQuotationPreview}
              />
            </div>
            : <div>loading</div>
          }
          <Pagination pagination={pagination} base='/questionnaires' params={asQueryObject(location.search)} />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    questionnaires: state.questionnaires.questionnaireList.data,
    pagination: state.questionnaires.questionnaireList.pagination,
    options: state.options.optionList.data,
    accessToken: state.auth.user.access.access_token,
    me: state.auth.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchQuestionnaires: (params, key) => dispatch(fetchQuestionnaires(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireList)
