import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ItemLog from '../../components/common/ItemLog';
import Pagination from '../../components/common/Pagination';

import ReportTable from '../../components/Orders/ReportTable';
import ReportDetail from '../../components/Orders/ReportDetail';
import {
  ReportCreateForm,
  ReportUpdateForm,
} from '../../components/Orders/ReportForm';
import {
  VetoReviewCreateForm,
  VetoReviewUpdateForm,
} from '../../components/Orders/VetoReviewForm';

import { fetchOrder } from '../../actions/orders';
import {
  fetchReports,
  fetchReport,
  createReport,
  updateReport,
} from '../../actions/reports';
import { isVeto, isLeadAuditor, isAuditor } from '../../selectors/order';

export function OrderReportView(props) {
  const {
    accessToken,
    fetchOrder,
    fetchReport,
    fetchReports,
    createReport,
    updateReport,
    match,
    push,
    order,
    user,
    report,
    reports,
  } = props;

  const { orderId, id } = match.params;

  useEffect(() => {
    fetchOrder(orderId, accessToken);
  }, [orderId, accessToken, fetchOrder]);

  useEffect(() => {
    if (id !== 'new') {
      fetchReport(orderId, id, accessToken);
    } else if (!id) {
      fetchReports(orderId, accessToken);
    }
  }, [orderId, id, fetchReport, fetchReports, accessToken]);

  function handleSubmit(e) {
    const request = {
      ...e,
      order_id: orderId,
      status: e.advance ? 'Published' : 'Draft',
      log: {
        remarks: e.remarks,
      },
    };
    if (id === 'new') {
      return createReport(request, accessToken).then(() => {
        if (request.status === 'Published') {
          push(`/orders/${orderId}`);
        }
      });
    } else {
      return updateReport({ ...request, id }, accessToken).then(() => {
        if (request.status === 'Published') {
          push(`/orders/${orderId}`);
        }
      });
    }
  }

  if (!order) {
    return <p>loading</p>;
  }
  if (id && id !== 'new' && !report) {
    return <p>loading2</p>;
  }

  return (
    <div className="content">
      <Switch>
        <Route path="/orders/:orderId/reports/new">
          <div>
            <Helmet>
              <title>{`Order ${order.number} - Create report`}</title>
            </Helmet>
            <div className="main-header">
              <h2>
                {order.status === 'Veto Person Assigned'
                  ? 'Veto Review'
                  : 'New Report'}
              </h2>
              <em>
                For Order <Link to={`/orders/${order.id}`}>{orderId}</Link>
              </em>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="alert alert-info">
                  Current Order status is <strong>{order.status}</strong>
                </div>
                {['Audit Stage 1 On Process'].includes(order.status) &&
                  isLeadAuditor(user, order) && (
                    <ReportCreateForm
                      order={order}
                      initialValues={{ type: 'Stage 1' }}
                      onSubmit={handleSubmit}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken,
                      }}
                    />
                  )}
                {['Audit Stage 2 On Process'].includes(order.status) &&
                  isLeadAuditor(user, order) && (
                    <ReportCreateForm
                      order={order}
                      initialValues={{ type: 'Stage 2' }}
                      onSubmit={handleSubmit}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken,
                      }}
                    />
                  )}
                {['Audit On Process'].includes(order.status) &&
                  isLeadAuditor(user, order) && (
                    <ReportCreateForm
                      order={order}
                      initialValues={{ type: 'Audit' }}
                      onSubmit={handleSubmit}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken,
                      }}
                    />
                  )}

                {['Veto Person Assigned'].includes(order.status) && // status
                  isVeto(user, order) && ( // user
                    <VetoReviewCreateForm
                      order={order}
                      onSubmit={handleSubmit}
                      initialValues={{ type: 'Veto' }}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken,
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
        </Route>
        <Route path="/orders/:orderId/reports/:id">
          <div>
            <Helmet>
              <title>{`Order ${order.number} - Update report`}</title>
            </Helmet>
            <div className="main-header">
              {report && (
                <h2>{`Update ${
                  report.type === 'Veto' ? 'Veto Review' : 'Report'
                } ${report && report.id}`}</h2>
              )}
              <em>
                For Order <Link to={`/orders/${order.id}`}>{order.number}</Link>
              </em>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div>
                  {(isAuditor(user, order) ||
                    isLeadAuditor(user, order) ||
                    user.data.role.includes('Super Admin')) && (
                    <>
                      {
                      //   [
                      //   'Audit Documents Need to Revise',
                      //   'Audit Done',
                      // ].includes(order?.status) &&
                        ['Stage 1', 'Stage 2', 'Audit'].includes(
                          report?.type
                        ) &&
                        report?.status === 'Draft' && (
                          <ReportUpdateForm
                            order={order}
                            onSubmit={handleSubmit}
                            initialValues={report}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: accessToken,
                            }}
                          />
                        )}
                    </>
                  )}
                  {report?.status === 'Draft' &&
                    report?.type === 'Veto' &&
                    (isVeto(user, order) ||
                      user.data.role.includes('Super Admin')) && (
                      <VetoReviewUpdateForm
                        order={order}
                        onSubmit={handleSubmit}
                        initialValues={report}
                        fileAPI={{
                          url: process.env.REACT_APP_BASE_API,
                          accessToken: accessToken,
                        }}
                      />
                    )}
                  {report?.status === 'Published' &&
                    report?.decision === 'Veto Approval Pending' &&
                    report?.type === 'Veto' &&
                    (isVeto(user, order) ||
                      user.data.role.includes('Super Admin')) && (
                      <VetoReviewUpdateForm
                        order={order}
                        onSubmit={handleSubmit}
                        initialValues={report}
                        fileAPI={{
                          url: process.env.REACT_APP_BASE_API,
                          accessToken: accessToken,
                        }}
                      />
                    )}
                  {report?.status !== 'Draft' && (
                    <ReportDetail
                      order={order}
                      report={report}
                      accessToken={accessToken}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <ItemLog log={report && report.log} entity="Report" />
              </div>
            </div>
          </div>
        </Route>
        <Route path="/orders/:orderId/reports">
          <div>
            <div className="main-header">
              <h2>Reports</h2>
              {order && (
                <em>
                  For Order <Link to={`/orders/${order.id}`}>{order.id}</Link>
                </em>
              )}
            </div>
            {reports && <ReportTable reports={reports} />}
            <Pagination />
          </div>
        </Route>
      </Switch>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    order: state.orders.order.data,
    reports: state.reports.reportList.data,
    report: state.reports.report.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchReports: (id, accessToken) => dispatch(fetchReports(id, accessToken)),
    fetchReport: (orderId, id, accessToken) =>
      dispatch(fetchReport(orderId, id, accessToken)),
    createReport: (data, accessToken, target) =>
      dispatch(createReport(data, accessToken, target)),
    updateReport: (data, accessToken, target) =>
      dispatch(updateReport(data, accessToken, target)),
    push: to => dispatch(push(to)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
    // Implement map dispatch to props
  )(OrderReportView)
);
