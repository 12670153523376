import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./TopBar.css";

export default function TopBar({ onToggle, onLogout, me }) {
  const [profileDropdown, setProfileDropdown] = useState(false);

  return (
    <div className="top-bar navbar-fixed-top">
      <div className="container">
        <div className="clearfix">
          <button
            onClick={onToggle}
            className="pull-left toggle-sidebar-collapse"
          >
            <i className="fa fa-bars" />
          </button>
          <div className="pull-left left logo">
            <Link to="/">T&Uuml;V NORD Certification</Link>
            <h1 className="sr-only">T&Uuml;V NORD Certification</h1>
          </div>
          <div className="pull-right right">
            <div className="top-bar-right">
              <div className="notifications">
                <ul />
              </div>
              <div className="logged-user">
                <div
                  className={profileDropdown ? "btn-group open" : "btn-group"}
                >
                  <button
                    className="btn btn-link dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={() => setProfileDropdown(!profileDropdown)}
                  >
                    <span className="name">
                      {me?.data?.display_name || me?.username}
                    </span>{" "}
                    <span className="caret" />
                  </button>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/users/me">
                        <i className="fa fa-user" />
                        &nbsp;
                        <span className="text">Profile (ID : {me?.id})</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/users/me" title={me?.data?.role.join(" - ")}>
                        <i className="fa fa-user" />
                        &nbsp;
                        <span className="text">
                          {me?.data?.role?.[0]}
                          {me?.data?.role.length > 1 &&
                            ` +${me?.data?.role.length - 1} more`}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={(x) => {
                          x.preventDefault();
                          onLogout();
                        }}
                      >
                        <i className="fa fa-power-off" />
                        &nbsp;
                        <span className="text">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default class TopBar extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       profileDropdown: false,
//       messageDropdown: false,
//       notificationDropdown: false,
//     };
//   }

// setProfileDropdown(!)his.setState({
//       profileDropdown: !this.state.profileDropdown,
//     });
//   }

//   toggleMessage(ev) {
//     ev.preventDefault();
//     this.setState({
//       messageDropdown: !this.state.messageDropdown,
//     });
//   }

//   toggleNotification() {
//     this.setState({
//       notificationDropdown: !this.state.notificationDropdown,
//     });
//   }

//   render() {
//     return (
//       <div className="top-bar navbar-fixed-top">
//         <div className="container">
//           <div className="clearfix">
//             <button
//               onClick={this.props.onToggle}
//               className="pull-left toggle-sidebar-collapse"
//             >
//               <i className="fa fa-bars" />
//             </button>
//             <div className="pull-left left logo">
//               <Link to="/">T&Uuml;V NORD Certification</Link>
//               {/* <a href='index.html'><img src='../img/kingadmin-logo-white.png' alt='KingAdmin - Admin Dashboard' /></a> */}
//               <h1 className="sr-only">T&Uuml;V NORD Certification</h1>
//             </div>
//             <div className="pull-right right">
//               {/*
//                 <div className='searchbox'>
//                   <div id='tour-searchbox' className='input-group'>
//                     <input type='search' className='form-control' placeholder='enter keyword here...' />
//                     <span className='input-group-btn'>
//                       <button className='btn btn-default' type='button'><i className='fa fa-search' /></button>
//                     </span>
//                   </div>
//                 </div>

//               */}

//               <div className="top-bar-right">
//                 <div className="notifications">
//                   <ul>
//                     {
//                       // <li className='notification-item inbox'>
//                       //   <div className={this.state.messageDropdown ? 'btn-group open' : 'btn-group'}>
//                       //     <a className='dropdown-toggle' data-toggle='dropdown' onClick={this.toggleMessage.bind(this)} href='/'>
//                       //       <i className='fa fa-envelope' /><span className='count'>2</span>
//                       //       <span className='circle' />
//                       //     </a>
//                       //     <ul className='dropdown-menu' role='menu'>
//                       //       <li className='notification-header'>
//                       //         <em>You have 2 unread messages</em>
//                       //       </li>
//                       //       <li className='inbox-item clearfix'>
//                       //         <a href='/' onClick={(x) => x.preventDefault()}>
//                       //           <div className='media'>
//                       //             <div className='media-left'>
//                       //               <img className='media-object' src='https://api.fnkr.net/testimg/32x32/00CED1/FFF/?text=img+placeholder' alt='Antonio' />
//                       //             </div>
//                       //             <div className='media-body'>
//                       //               <h5 className='media-heading name'>Antonius</h5>
//                       //               <p className='text'>The problem just happened this morning. I can't see ...</p>
//                       //               <span className='timestamp'>4 minutes ago</span>
//                       //             </div>
//                       //           </div>
//                       //         </a>
//                       //       </li>
//                       //       <li className='inbox-item unread clearfix'>
//                       //         <a href='/' onClick={(x) => x.preventDefault()}>
//                       //           <div className='media'>
//                       //             <div className='media-left'>
//                       //               <img className='media-object' src='https://api.fnkr.net/testimg/32x32/00CED1/FFF/?text=img+placeholder' alt='Antonio' />
//                       //             </div>
//                       //             <div className='media-body'>
//                       //               <h5 className='media-heading name'>Michael</h5>
//                       //               <p className='text'>Hey dude, cool theme!</p>
//                       //               <span className='timestamp'>2 hours ago</span>
//                       //             </div>
//                       //           </div>
//                       //         </a>
//                       //       </li>
//                       //       <li className='inbox-item unread clearfix'>
//                       //         <a href='/' onClick={(x) => x.preventDefault()}>
//                       //           <div className='media'>
//                       //             <div className='media-left'>
//                       //               <img className='media-object' src='https://api.fnkr.net/testimg/32x32/00CED1/FFF/?text=img+placeholder' alt='Antonio' />
//                       //             </div>
//                       //             <div className='media-body'>
//                       //               <h5 className='media-heading name'>Stella</h5>
//                       //               <p className='text'>Ok now I can see the status for each item. Thanks! :D</p>
//                       //               <span className='timestamp'>Oct 6</span>
//                       //             </div>
//                       //           </div>
//                       //         </a>
//                       //       </li>
//                       //       <li className='inbox-item clearfix'>
//                       //         <a href='/' onClick={(x) => x.preventDefault()}>
//                       //           <div className='media'>
//                       //             <div className='media-left'>
//                       //               <img className='media-object' src='https://api.fnkr.net/testimg/32x32/00CED1/FFF/?text=img+placeholder' alt='Antonio' />
//                       //             </div>
//                       //             <div className='media-body'>
//                       //               <h5 className='media-heading name'>Jane Doe</h5>
//                       //               <p className='text'><i className='fa fa-reply' /> Please check the status of your ...</p>
//                       //               <span className='timestamp'>Oct 2</span>
//                       //             </div>
//                       //           </div>
//                       //         </a>
//                       //       </li>
//                       //       <li className='inbox-item clearfix'>
//                       //         <a href='/' onClick={(x) => x.preventDefault()}>
//                       //           <div className='media'>
//                       //             <div className='media-left'>
//                       //               <img className='media-object' src='https://api.fnkr.net/testimg/32x32/00CED1/FFF/?text=img+placeholder' alt='Antonio' />
//                       //             </div>
//                       //             <div className='media-body'>
//                       //               <h5 className='media-heading name'>John Simmons</h5>
//                       //               <p className='text'><i className='fa fa-reply' /> I've fixed the problem :)</p>
//                       //               <span className='timestamp'>Sep 12</span>
//                       //             </div>
//                       //           </div>
//                       //         </a>
//                       //       </li>
//                       //       <li className='notification-footer'>
//                       //         <a href='/' onClick={(x) => x.preventDefault()}>View All Messages</a>
//                       //       </li>
//                       //     </ul>
//                       //   </div>
//                       // </li>
//                     }
//                     {/*
//                       <li className='notification-item general'>
//                         <div className='btn-group'>
//                           <a className='dropdown-toggle' data-toggle='dropdown'>
//                             <i className='fa fa-bell' /><span className='count'>8</span>
//                             <span className='circle' />
//                           </a>
//                           <ul className='dropdown-menu' role='menu'>
//                             <li className='notification-header'>
//                               <em>You have 8 notifications</em>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-comment green-font' />
//                                 <span className='text'>New comment on the blog post</span>
//                                 <span className='timestamp'>1 minute ago</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-user green-font' />
//                                 <span className='text'>New registered user</span>
//                                 <span className='timestamp'>12 minutes ago</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-comment green-font' />
//                                 <span className='text'>New comment on the blog post</span>
//                                 <span className='timestamp'>18 minutes ago</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-shopping-cart red-font' />
//                                 <span className='text'>4 new sales order</span>
//                                 <span className='timestamp'>4 hours ago</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-edit yellow-font' />
//                                 <span className='text'>3 product reviews awaiting moderation</span>
//                                 <span className='timestamp'>1 day ago</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-comment green-font' />
//                                 <span className='text'>New comment on the blog post</span>
//                                 <span className='timestamp'>3 days ago</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-comment green-font' />
//                                 <span className='text'>New comment on the blog post</span>
//                                 <span className='timestamp'>Oct 15</span>
//                               </a>
//                             </li>
//                             <li>
//                               <a href='/' onClick={(x) => x.preventDefault()}>
//                                 <i className='fa fa-warning red-font' />
//                                 <span className='text red-font'>Low disk space!</span>
//                                 <span className='timestamp'>Oct 11</span>
//                               </a>
//                             </li>
//                             <li className='notification-footer'>
//                               <a href='/' onClick={(x) => x.preventDefault()}>View All Notifications</a>
//                             </li>
//                           </ul>
//                         </div>
//                       </li>
//                     */}
//                   </ul>
//                 </div>
//                 <div className="logged-user">
//                   <div
//                     className={
//                       this.state.profileDropdown
//                         ? "btn-group open"
//                         : "btn-group"
//                     }
//                   >
//                     <button
//                       className="btn btn-link dropdown-toggle"
//                       data-toggle="dropdown"
//                       onClick={this.toggleProfile.bind(this)}
//                     >
//                       {
//                         // <img src='https://api.fnkr.net/testimg/24x24/00CED1/FFF/?text=img+placeholder' alt='User Avatar' />{' '}
//                       }
//                       <span className="name">
//                         {me?.data?.display_name || me?.username}
//                       </span>{" "}
//                       <span className="caret" />
//                     </button>
//                     <ul className="dropdown-menu" role="menu">
//                       <li>
//                         <Link to="/users/me">
//                           <i className="fa fa-user" />
//                           &nbsp;
//                           <span className="text">Profile (ID : {me?.id})</span>
//                         </Link>
//                       </li>
//                       <li>
//                         <Link to="/users/me" title={me?.data?.role.join(" - ")}>
//                           <i className="fa fa-user" />
//                           &nbsp;
//                           <span className="text">
//                             {me?.data?.role?.[0]}
//                             {me?.data?.role.length > 1 &&
//                               ` +${me?.data?.role.length - 1} more`}
//                           </span>
//                         </Link>
//                       </li>
//                       <li>
//                         <a
//                           href="/"
//                           onClick={(x) => {
//                             x.preventDefault();
//                             this.props.onLogout();
//                           }}
//                         >
//                           <i className="fa fa-power-off" />
//                           &nbsp;
//                           <span className="text">Logout</span>
//                         </a>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
