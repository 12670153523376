import React from 'react'

import NumberFormat from 'react-number-format'
import { groupBy } from 'lodash'

import Required from '../common/Required'

export class FactorPicker extends React.Component {
  constructor (props) {
    super(props)
    // assign values to available optiobs
    const currentValues = groupBy(this.props.input.value, 'name')
    const optionsWithValues = this.props.options.map(x => {
      if (currentValues[x.name]) {
        x.value = currentValues[x.name][0].value
      }
      return x
    })

    // add user defined options to options
    const currentOptions = groupBy(this.props.options, 'name')
    const userOptions = this.props.input.value ? this.props.input.value.filter(x => !currentOptions[x.name]) : []
    const merged = [ ...optionsWithValues, ...userOptions ]

    const value = merged.map(x => {
      x.value = x.value || 0
      return x
    })
    this.state = { value }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.options && nextProps.options !== this.props.options) {
      const newOptions = this.state.value.map((x, i) => {
        return ({ ...x, max: nextProps.options[i] ? nextProps.options[i].max : 30 })
      })
      this.setState({
        value: newOptions
      })
    }
  }

  componentWillMount () {
    this.props.input.onChange(this.state.value.filter(x => x.value !== 0))
  }

  addNew (ev) {
    ev.preventDefault()
    const value = [ ...this.state.value, {
      name: this.refs.newName.value,
      max: 20,
      value: 0
    }]
    this.setState({
      value
    })
    this.refs.newName.value = null
    this.props.input.onChange(value.filter(x => x.value !== 0))
  }

  changeValue (index, e) {
    const current = this.state.value
    current[index].value = (e.target.value ? parseInt(e.target.value, 10) : 0)
    this.setState({
      value: current
    })
    this.props.input.onChange(current.filter(x => x.value !== 0))
  }

  getOptions (index) {
    const current = this.state.value
    const options = []
    const max = current[index].max

    for (let i = 0; i <= max; i += 5) {
      options.push(
        <option key={i} value={i}>{i} %</option>
      )
    }

    return options
  }

  render () {
    const { input, label, meta: { error, warning } } = this.props
    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <label htmlFor={label} className='col-sm-3 control-label'>{label}</label>
        <div className='col-sm-9'>
          {(error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>)}
          <table className='table table-condensed'>
            <thead>
              <tr>
                <th>Percentage</th>
                <th>Factor</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.value.map((x, i) => {
                return (
                  <tr key={i} style={{ opacity: (x.value !== 0 ? 1 : 0.5) }}>
                    <td width={30}>
                      {/* <input type='text' className='form-control input-sm' defaultValue={0} /> */}
                      <select name='' id='' value={x.value} className='form-control input-sm' onChange={this.changeValue.bind(this, i)}>
                        { this.getOptions(i) }
                      </select>
                    </td>
                    <td>{x.name}</td>
                    <td>
                      {x.value !== 0 &&
                        <button type='button' onClick={this.changeValue.bind(this, i)} className='btn btn-link btn-sm'><i className='fa fa-remove' /></button>
                      }
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={3}>
                  <div className='input-group' style={{ width: '100%' }}>
                    <input type='text' placeholder=' other ...' className='form-control input-sm' ref='newName' />
                    <div className='input-group-btn'>
                      <button type='button' className='btn btn-default btn-sm' onClick={this.addNew.bind(this)}>
                        <i className='fa fa-plus' />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export class AuditCycleField extends React.Component {
  constructor (props) {
    super(props)

    const phases = this.props.phases.map(x => {
      return {
        phase: x,
        include: false,
        day: 0,
        rate: 0
      }
    })

    // assign values to available options
    const currentValues = groupBy(this.props.input.value, 'phase')
    const phasesWithValues = phases.map(x => {
      if (currentValues[x.phase]) {
        return { ...currentValues[x.phase][0] }
      }
      return x
    })

    this.state = { value: phasesWithValues }
  }

  componentWillReceiveProps (nextProps) {
    // if (nextProps.phases && nextProps.phases.length !== this.props.phases.length) {
    //   const phases = nextProps.phases.map(x => {
    //     return {
    //       phase: x,
    //       include: false,
    //       day: 0,
    //       rate: 0
    //     }
    //   })

    //   // assign values to available options
    //   // const currentValues = groupBy(this.props.input.value, 'phase')
    //   // const phasesWithValues = phases.map(x => {
    //   //   if (currentValues[x.phase]) {
    //   //     return {...currentValues[x.phase][0]}
    //   //   }
    //   //   return x
    //   // })

    //   this.setState({
    //     value: phases
    //   })
    // }
  }

  componentWillMount () {
    this.props.input.onChange(this.state.value.filter(x => x.include))
  }

  togglePhase (i, j, e) {
    const value = this.state.value
    if (value[i].additional) {
      value.splice(i, 1)
    } else {
      if (!value[i].include) {
        value[i].include = true
        value[i].day = parseInt(j, 10)
      } else {
        value[i].include = false
        value[i].day = 0
      }
    }
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeManDay (i, e) {
    const value = this.state.value
    value[i].day = parseFloat(e.target.value)
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changePhase (i, e) {
    const value = this.state.value
    value[i].phase = e.target.value
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeRate (i, e, g) {
    const value = this.state.value
    value[i].rate = g.floatValue
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  handleAdd (ev) {
    ev.preventDefault()
    const value = this.state.value
    this.setState({
      value: [
        ...value,
        { ...value[0],
          day: 0,
          include: true,
          phase: this.props.newTitle || 'New Item',
          rate: 0,
          additional: true
        }
      ]
    })
  }

  toggleAll () {
    const setTo = this.state.value.filter(x => !x.include).length === this.state.value.length
    const value = this.props.mandaysAllocation
      ? this.props.mandaysAllocation.map(x => ({
        ...x,
        include: setTo,
        rate: 0
      }))
      : []
    // console.log(100, this.props.mandaysAllocation)
    this.setState({
      value
    })
    this.props.input.onChange(value.filter(x => x.include))
  }

  render () {
    const { input, label, mandaysAllocation, scheme, required, meta: { error, warning } } = this.props
    const { value } = this.state

    let changeDay
    let changeRate
    let changeInclude
    let allowAdd

    switch (scheme) {
      case 'load':
        changeDay = true
        changeRate = false
        changeInclude = true
        allowAdd = false
        break
      case 'financial':
        changeDay = false
        changeRate = true
        changeInclude = false
        allowAdd = false
        break
      case 'all':
        changeDay = true
        changeRate = true
        changeInclude = true
        allowAdd = true
        break
      default:
        changeDay = true
        changeRate = true
        changeInclude = true
        allowAdd = false
        break
    }

    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
        <div className='col-sm-9'>
          {(error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>)}
          <table className='table table-condensed'>
            <thead>
              <tr>
                { changeInclude &&
                  <th width={30}>
                    { this.props.mandaysAllocation &&
                      <input className='toggle-all' type='checkbox' onClick={this.toggleAll.bind(this)} checked={value.filter(x => x.include).length === value.length} />
                    }
                  </th>
                }
                <th width='40%'>Phase</th>
                <th>Man-Day</th>
                { changeRate &&
                  <th>Rate</th>
                }
              </tr>
            </thead>
            <tbody>
              { value.map((x, i) => {
                const dayAllocation = mandaysAllocation ? mandaysAllocation.find(y => x.phase === y.phase) : { day: 0 }
                return (
                  <tr key={i} style={{ opacity: (x.include ? 1 : 0.5) }}>
                    { changeInclude &&
                      <td>
                        <div className='checkbox'>
                          <label>
                            <input type='checkbox' onChange={this.togglePhase.bind(this, i, dayAllocation.day)} checked={x.include} />
                          </label>
                        </div>
                      </td>
                    }
                    { x.additional
                      ? <td>
                        <input
                          type='text'
                          className='form-control input-xs'
                          value={x.phase}
                          onChange={this.changePhase.bind(this, i)}
                        />
                      </td>
                      : <td style={{ verticalAlign: 'middle' }}>{x.phase}</td>
                    }
                    <td>
                      <div className='input-group'>
                        <input
                          type='number'
                          min='1'
                          step='0.5'
                          className='form-control input-xs'
                          disabled={!changeDay}
                          value={x.day ? x.day : dayAllocation.day}
                          onChange={this.changeManDay.bind(this, i)}
                        />
                        <div className='input-group-addon'>day(s)</div>
                      </div>
                    </td>
                    { changeRate &&
                      <td>
                        <div className='input-group'>
                          <div className='input-group-addon'>Rp</div>
                          <NumberFormat
                            className='form-control input-xs'
                            disabled={!changeRate}
                            value={x.rate}
                            onChange={this.changeRate.bind(this, i)}
                            thousandSeparator
                          />
                        </div>
                      </td>
                    }
                  </tr>
                )
              })}
            </tbody>
          </table>
          { allowAdd &&
            <div>
              <button type='button' className='btn btn-default btn-sm' onClick={this.handleAdd.bind(this)}>Add Item</button>
            </div>
          }
        </div>
      </div>
    )
  }
}

export class FeesField extends React.Component {
  constructor (props) {
    super(props)
    const options = this.props.options.map(x => {
      return {
        name: x,
        include: false,
        quantity: 0,
        rate: 0,
        note: ' ',
        payable: 'included'
      }
    })

    // assign values to available optiobs
    const currentValues = groupBy(this.props.input.value, 'name')

    const optionsWithValues = options.map(x => {
      if (currentValues[x.name]) {
        return { ...currentValues[x.name][0] }
      }
      return x
    })

    // add user defined options to options
    const userOptions = this.props.input.value ? this.props.input.value.filter(x => this.props.options.indexOf(x.name) < 0) : []
    const merged = [ ...optionsWithValues, ...userOptions ]

    this.state = { value: merged }
  }

  componentWillMount () {
    this.props.input.onChange(this.state.value)
  }

  toggleFee (i, e) {
    const value = this.state.value
    if (e.target.checked) {
      value[i].include = true
    } else {
      value[i].include = false
    }
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeQty (i, e) {
    const value = this.state.value
    value[i].quantity = parseFloat(e.target.value)
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeRate (i, e, g) {
    const value = this.state.value
    value[i].rate = g.floatValue
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeNote (i, e) {
    const value = this.state.value
    value[i].note = e.target.value
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changePayable (i, e) {
    const value = this.state.value
    value[i].payable = e.target.value
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  addNew () {
    const value = [ ...this.state.value, {
      name: this.refs.newName.value,
      include: true,
      quantity: 0,
      rate: 0,
      note: ' '
    }]
    this.setState({
      value
    })
    this.refs.newName.value = null
    this.props.input.onChange(value.filter(x => x.value !== 0))
  }

  handleChange (i, ev) {
    const { value } = this.state
    value[i].name = ev.target.value
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  render () {
    const { input, label, loadOnly, meta: { touched, error, warning } } = this.props
    const { value } = this.state
    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <label htmlFor={label} className='col-sm-3 control-label'>{label}</label>
        <div className='col-sm-9'>
          <table className='table table-condensed'>
            <thead>
              <tr>
                <th />
                <th>Fees</th>
                <th>Quantity</th>
                { !loadOnly &&
                  <th>Rate</th>
                }
                <th>Notes</th>
                <th>Payable</th>
              </tr>
            </thead>
            <tbody>
              {value.map((x, i) => {
                return (
                  <tr key={i} style={{ opacity: (x.include ? 1 : 0.5) }}>
                    <td width={30}>
                      <div className='checkbox'>
                        <label>
                          <input type='checkbox' onChange={this.toggleFee.bind(this, i)} checked={x.include} />
                        </label>
                      </div>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <input className='form-control input-xs' type='text' name='name' onChange={this.handleChange.bind(this, i)} value={x.name} />
                    </td>
                    <td>
                      <input
                        type='number'
                        min='0'
                        step='0.1'
                        className='form-control input-xs'
                        onChange={this.changeQty.bind(this, i)}
                        disabled={!x.include}
                        value={x.quantity}
                      />
                    </td>
                    { !loadOnly &&
                      <td>
                        <div className='input-group'>
                          <div className='input-group-addon'>Rp</div>
                          <NumberFormat
                            type='text'
                            className='form-control input-xs'
                            disabled={!x.include}
                            value={x.rate ? x.rate : 0}
                            onChange={this.changeRate.bind(this, i)}
                            thousandSeparator
                          />
                        </div>
                      </td>
                    }
                    <td>
                      <input
                        type='text'
                        className='form-control input-xs'
                        onChange={this.changeNote.bind(this, i)}
                        disabled={!x.include}
                        value={x.note}
                      />
                    </td>
                    <td>
                      <select
                        className='form-control input-xs'
                        onChange={this.changePayable.bind(this, i)}
                        disabled={!x.include}
                        value={x.payable}
                      >
                        <option value='include'>included</option>
                        <option value='excluded'>excluded</option>
                      </select>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={6}>
                  <div className='input-group' style={{ width: '100%' }}>
                    <input type='text' placeholder=' other ...' className='form-control input-sm' ref='newName' />
                    <div className='input-group-btn'>
                      <button type='button' className='btn btn-default btn-sm' onClick={this.addNew.bind(this)}>
                        <i className='fa fa-plus' />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {touched && ((error && <div><br /><span>{error}</span></div>) || (warning && <div><br /><span>{warning}</span></div>))}
        </div>
      </div>
    )
  }
}

export const ListComboField = ({ options, input, label, type, meta: { touched, error, warning } }) => (
  <div className={`form-group ${input.name.split('.').join('_')}`}>
    <label htmlFor={label} className='col-sm-3 control-label'>{label}</label>
    <div className='col-sm-9'>
      <table className='table table-condensed'>
        <tbody>
          {options.map((x, i) => {
            return (
              <tr>
                <td width='50%'>{x.label}</td>
                <td width='30'><input type='checkbox' className='form-control' /></td>
                <td><input type='text' className='form-control input-sm' /></td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {touched && ((error && <div><br /><span>{error}</span></div>) || (warning && <div><br /><span>{warning}</span></div>))}
    </div>
  </div>
)
