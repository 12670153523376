import NumberFormat from 'react-number-format';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import File from '../../components/common/File';
import DaTableShort from '../../components/Das/DaTableShort';
import DaTable from '../../components/Das/DaTable';
import DaFilter from '../../components/Das/DaFilter';
import { DaPaymentUpdateForm as DaPaymentForm } from '../../components/Das/DaPaymentForm';

import {
  fetchDas,
  fetchDaPayment,
  updateDaPayment,
  deleteDaPayment,
} from '../../actions/das';
import { asQueryObject, asQueryString } from '../../const/helper';

export function DaPaymentUpdate({
  accessToken,
  daPayment,
  das,
  deleteDaPayment,
  fetchDaPayment,
  fetchDas,
  location,
  match,
  push,
  role,
  updateDaPayment,
}) {
  useEffect(() => {
    fetchDaPayment(match.params.id, accessToken);
    // eslint-disable-next-line 
  }, []);

  const [dasStage, setDasStage] = useState({
    list: [],
    status: 'idle',
  });

  useEffect(() => {
    if (daPayment?.das) {
      setDasStage({
        list: daPayment.das,
        status: 'ready',
      });
    }
    // eslint-disable-next-line 
  }, [daPayment]);

  useEffect(() => {
    if (daPayment?.user?.id) {
      const query = asQueryObject(location.search);
      query.user = daPayment.user.id;
      query.status = ['Approved', 'Payment Prepared'];
      fetchDas('?' + asQueryString(query), accessToken);
    }
    // eslint-disable-next-line 
  }, [location.search, daPayment]);

  function handleRemove(e) {
    const newDas = dasStage.list.filter(x => x.id !== e.id);
    setDasStage({
      list: newDas,
      status: 'dirty',
    });
  }

  function handleDaPayment(e) {
    const req = {
      id: daPayment.id,
      detail: {
        ...e.detail,
        total: getTotal(dasStage.list),
      },
    };
    req.da = dasStage.list.map(x => x.id);
    if (e?.detail?.payment_receipt) {
      req.status = 'Done';
    }
    return updateDaPayment(req, accessToken).then(() => {
      setDasStage({
        list: dasStage.list,
        status: 'ready',
      });
    });
  }

  function handleSelect(e) {
    setDasStage({
      list: [...dasStage.list, e],
      status: 'dirty',
    });
  }

  function handleFilter(filter) {
    delete filter.page;
    push(`/daily-allowance-payments/${daPayment.id}?${asQueryString(filter)}`);
  }

  function getTotal(dap = []) {
    return dap.reduce(
      (sum, x, i) => {
        return {
          idr: sum.idr + (x?.detail?.total?.idr || 0),
          usd: sum.usd + (x?.detail?.total?.usd || 0),
        };
      },
      { idr: 0, usd: 0 }
    );
  }

  const auditor = daPayment?.user;
  const allowance = getTotal(dasStage.list);

  return (
    <div className="content">
      <div className="main-header">
        <h2>Daily Allowance Payment ID {daPayment && daPayment.number} </h2>
        <em className="">{auditor?.name}</em>
      </div>
      <div className="main-content">
        <div className="row">
          <div className="col-md-8">
            {daPayment ? (
              <div>
                <DaTableShort das={dasStage.list} onRemove={daPayment.status !== 'Done' && handleRemove} />
                {daPayment.status !== 'Done' && (
                  <>
                    <DaFilter
                      onSubmit={handleFilter}
                      initialValues={asQueryObject(location.search)}
                      resetUrl={`/daily-allowance-payments/${daPayment.id}`}
                    />
                    <DaTable
                      das={(das || []).filter((x) => x.status === 'Approved' || (x.status === 'Payment Prepared' && x.payment.id === daPayment.id))}
                      showUser
                      role={['Finance']}
                      onSelect={handleSelect}
                      selectedId={dasStage.list.map(x => x.id)}
                    />
                  </>
                )}
              </div>
            ) : (
              <div>loading</div>
            )}
          </div>
          <div className="col-md-4">
            <div className="well">
              <dl>
                <dt>
                  <strong>Total (IDR)</strong>
                </dt>
                <dd>
                  <NumberFormat
                    displayType="text"
                    value={allowance.idr}
                    thousandSeparator
                  />
                </dd>
                <dt>
                  <strong>Total (USD)</strong>
                </dt>
                <dd>
                  <NumberFormat
                    displayType="text"
                    value={allowance.usd}
                    thousandSeparator
                  />
                </dd>
              </dl>
              {daPayment && daPayment?.detail?.payment_receipt && (
                <File accessToken={accessToken} file={daPayment.detail.payment_receipt}></File>
              )}
              {
                (daPayment && dasStage.list.length > 0 && dasStage.status === 'ready' ? (
                  <a
                    href={`${process.env.REACT_APP_BASE_API}/pdf/daPayment?template=da-payment&id=${daPayment.id}&token=${accessToken}`}
                    className="btn btn-block btn-default"
                  >
                    Download PDF
                  </a>
                ) : (
                  <p>Please select DA and save to download PDF.</p>
                ))
              }
            </div>
            {daPayment && daPayment.status !== 'Done' && (
              <div className="widget">
                <div className="widget-header">
                  <h3>
                    <i className="fa fa-book" /> Update Daily Allowance Payment
                  </h3>
                </div>
                <div className="widget-content">
                  <DaPaymentForm
                    onSubmit={handleDaPayment}
                    status={'Draft'}
                    initialValues={daPayment}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: accessToken,
                    }}
                  />
                  {daPayment?.status === 'Draft' && (
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteDaPayment(daPayment.id)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    das: state.das.daList.data,
    daPayment: state.das.daPayment.data,
    pagination: state.das.daList.pagination,
    accessToken: state.auth.user.access.access_token,
    role: state.auth.user.data.role,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDas: (params, key) => dispatch(fetchDas(params, key)),
    fetchDaPayment: (params, key) => dispatch(fetchDaPayment(params, key)),
    updateDaPayment: (params, key) => dispatch(updateDaPayment(params, key)),
    deleteDaPayment: (params, key) => dispatch(deleteDaPayment(params, key)),
    push: to => dispatch(push(to)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DaPaymentUpdate);
