import React, { Component } from 'react'
import AutoCompleteFilterField from '../common/AutoCompleteFilterField';
import DateFilterField from '../common/DateFilterField';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

class BaseDaFilter extends Component {
  render () {
    const { handleSubmit, submitting, showUser, resetUrl } = this.props

    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          <div className='form-group'>
            <Field name='status' component='select' className='form-control'>
              <option value=''>All Status &nbsp;</option>
              {['Draft', 'Proposed', 'Need to Revise', 'Approved', 'Payment Prepared','Paid'].map((x, i) => <option key={i} value={x}>{x} &nbsp;</option>)}
            </Field>
          </div>
          &nbsp;
          {showUser && (
            <Field
              name="user"
              component={AutoCompleteFilterField}
              label="Auditor"
              api={this.props.userAPI}
            />
          )}
          &nbsp;
          <Field
            name="date_start"
            component={DateFilterField}
            label="From"
          />
          &nbsp;
          <Field
            name="date_end"
            component={DateFilterField}
            label="To"
          />
          &nbsp;
          <div className='form-group'>
            <label className='sr-only' htmlFor='exampleInputEmail2'>Order</label>
            <Field
              name='order_number'
              component='input'
              type='text'
              placeholder='Order Number ...'
              className='form-control'
            />
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to={resetUrl} className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let DaFilter = reduxForm({
  form: 'daFilter' //, validate, warn
})(BaseDaFilter)

export { BaseDaFilter }
export default DaFilter
