import React from 'react'
import moment from 'moment'
import intersection from 'lodash/intersection'

import questionnaireAction from '../../const/questionnaireAction'

export default function ActionWidget ({ questionnaire: q, user, ...props }) {
  const action = questionnaireAction(q, props)

  return (
    <div className='widget widget-reminder action-widget'>
      <div className='widget-content'>
        <div className='today-reminder'>
          <h4 className='reminder-title'>
            <span>Questionnaire {q.number} &nbsp; <span className='id small'>{q.id}</span></span>
            { q.due_date &&
              <span className='pull-right'>Due date : {moment.unix(q.due_date).add(2, 'days').format('dddd, DD MMMM YYYY')}</span>
            }
          </h4>
          <p className='reminder-place'>Current status: {q.status}</p>
          <p className='reminder-time'><i className='fa fa-clock-o' /> {action.todo}</p>
          <i className='fa fa-bell' />
          { (action.primary && action.primary.pre) || (action.primary && action.primary.pre === undefined)
            ? <div>
              {
                (intersection(action.primary.role, user.data.role).length > 0) ||
                (action.primary.users && action.primary.users.filter((x) => x.id === user.id).length > 0)
                  ? <p><button disabled={props.disabled} onClick={action.primary.action} className='btn-action btn btn-primary'>{action.primary.text}</button></p>
                  : <div className='alert alert-info'>{action.primary.alternative || 'Prerequisite not met.'}</div>
              }
            </div>
            : <div className='alert alert-info'>{(action.primary && action.primary.alternative) || 'Prerequisite not met.'}</div>
          }
          { action.secondary && action.secondary.map((x, i) => {
            return (
              <span key={i}>
                {
                  (
                    (intersection(x.role, user.data.role).length > 0) ||
                    (x.users && x.users.filter((x) => (x.user && x.user.id === user.id) || (x.id === user.id)).length > 0)
                  ) &&
                  (
                    (x.pre === undefined) ||
                    (x.pre)
                  )
                    ? <span><button onClick={x.action} className='btn btn-default btn-sm'>{x.text}</button> &nbsp;</span>
                    : <span />
                }
              </span>
            )
          })}
          {
            // props.download &&
            //   <a className='btn btn-default btn-sm' href={props.download} ><i className='fa fa-download' /> Download Questionnaire</a>
          }
          {
          // <em className='reminder-notes'>Bring weekly report summary</em>
          // <div className='btn-group btn-group-xs'>
          //   <button type='button' className='btn btn-warning'><i className='fa fa-cloud-upload' /> Sync</button>
          //   <div className='btn-group  btn-group-xs'>
          //     <button type='button' className='btn btn-warning dropdown-toggle' data-toggle='dropdown'>Remind Me <span className='caret' /></button>
          //     <ul className='dropdown-menu pull-right'>
          //       <li><a href='#'>15 minutes later</a></li>
          //       <li><a href='#'>30 minutes later</a></li>
          //       <li><a href='#'>1 hour later</a></li>
          //     </ul>
          //   </div>
          // </div>
          }
        </div>
      </div>
    </div>
  )
}
