import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextareaField, OptionsField } from '../common/FormFields'
import ConfirmButton from '../common/ConfirmButton'

const validate = (values, props) => {
  const errors = {}
  if (!values.decision) {
    errors.decision = 'Please make decision'
  }
  return errors
}

export class DecisionForm extends Component {
  render () {
    const { handleSubmit, submitting, results } = this.props

    return (
      <div>
        <form className='form-horizontal review-form' onSubmit={handleSubmit}>
          <Field
            name='decision'
            type='radio'
            component={OptionsField}
            label='Set as'
            options={results}
          />

          <Field name='log.detail.remarks' type='text' component={TextareaField} label='Remarks' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <ConfirmButton disabled={submitting} text={'Submit'}>
                <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit'>Submit Review</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

DecisionForm = reduxForm({ // eslint-disable-line 
  form: 'DecisionForm',
  validate
})(DecisionForm)

export default DecisionForm
