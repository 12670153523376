import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { intersection } from 'lodash'

import Pagination from '../../components/common/Pagination'
import DaPaymentTable from '../../components/Das/DaPaymentTable'
import DaFilter from '../../components/Das/DaFilter'
// import { DaCreateForm as DaForm } from '../../components/Das/DaForm'

import { fetchDaPayments } from '../../actions/das'
import { asQueryObject, asQueryString } from '../../const/helper'

class DaList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchDaPayments(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchDaPayments(this.props.location.search, this.props.accessToken)
  }

  handleDa (e) {
    // return this.props.createDa(e, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    if (filter.user) {
      filter.user = filter.user.value.id;
    }
    return this.props.push(`/daily-allowances?${asQueryString(filter)}`)
  }

  render () {
    const { das, pagination, location, role } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Daily Allowance Payments List</h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-12'>
              { das
                ? <div>
                  <DaFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                    showUser={intersection(['Finance', 'Operational Manager'], role).length >= 1}
                    resetUrl={`/daily-allowances`}
                    userAPI={{
                      url: process.env.REACT_APP_BASE_API + '/users',
                      accessToken: this.props.accessToken,
                      label: 'data.display_name'
                    }}
                  />
                  <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
                  <DaPaymentTable das={das}/>
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/daily-allowances' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              {
                // <div className='widget'>
                //   <div className='widget-header'>
                //     <h3><i className='fa fa-book' /> Create Das</h3>
                //   </div>
                //   <div className='widget-content'>
                //     <DaForm
                //       onSubmit={this.handleDa.bind(this)}
                //       initialValues={{
                //         detail: {
                //           bod_approval: true
                //         }
                //       }}
                //     />
                //   </div>
                // </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    das: state.das.daPaymentList.data,
    pagination: state.das.daPaymentList.pagination,
    accessToken: state.auth.user.access.access_token,
    role: state.auth.user.data.role
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDaPayments: (params, key) => dispatch(fetchDaPayments(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DaList)
