import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";

import { asOptions } from "../../const/helper";

import "./AutoCompleteFilterField.css";

export default function AutoCompleteFilterField({ input, api }) {
  const [value, setValue] = useState(input?.value);
  const headers = { Authorization: `Bearer ${api.accessToken}` };

  useEffect(() => {
    if (input?.value) {
      axios({
        method: "get",
        url: `${api.url}/${input.value}`,
        headers,
      }).then((x) => {
        setValue(asOptions([x.data], api.label)[0]);
      });
    }
    // eslint-disable-next-line 
  }, []);

  function handleChange(val) {
    setValue(val);
    input.onChange(val);
  }

  function handleGetOptions(input) {
    if (api) {
      return axios({
        method: "get",
        url:
          api.url.indexOf("?") > -1
            ? `${api.url}&q=${input}`
            : `${api.url}?q=${input}`,
        headers,
      }).then((x) => {
        return {
          options: asOptions(x.data, api.label),
        };
      });
    } else {
      return Promise.resolve([]);
    }
  }

  return (
    <div className="form-group auto-complete-filter-field">
      <Select.Async
        value={value}
        onChange={handleChange}
        loadOptions={handleGetOptions}
      ></Select.Async>
    </div>
  );
}
