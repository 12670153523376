import get from 'lodash/get';
import forEach from 'lodash/forEach';

export function asOptions(x, path, value) {
  return x.map(y => {
    const label = path ? get(y, path) : y.name;
    const optionValue = value ? get(y, value) : y;
    return {
      label,
      value: optionValue,
    };
  });
}

export function asQueryObject(x) {
  if (x !== '') {
    let string = x.slice(1, x.length);
    try {
      return JSON.parse(
        '{"' +
          decodeURI(string)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    } catch(e) {
      console.log(e);
    }
  }
  return {};
}

export function asQueryString(filter) {
  let string = '';
  forEach(filter, function (x, y) {
    if (x && x !== '' && x !== 'null') {
      string = string + '&' + y + '=' + x;
    }
  });
  return string.slice(1, string.length);
}

export function roundBy(value, limit) {
  const base = Math.floor(value);
  return base + (value % 1 >= limit ? 1 : 0);
}

export function hasAttachment(values, field) {
  const test = get(values, field);
  if (!test || test.length === 0) {
    return false;
  }
  return true;
}

export function toDateRange(yearInput, monthInput) {
  const now = new Date();
  const monthInputt = monthInput ? monthInput : 1;
  const month = parseInt(monthInputt, 10) < 10 ? `0${monthInputt}` : `${monthInputt}`;
  const monthDayStart = `${month}-01`;
  const yearStart = yearInput || now.getFullYear();
  const dateStart = `${yearStart}-${monthDayStart}`;

  let dateEnd
  if (!monthInput) {
    dateEnd = `${yearStart}-12-31`;
  } else {
    const endDate = new Date(yearStart, monthInput, 0);
    const endDateFormat = parseInt(endDate.getDate(), 10) < 10 ? `0${endDate.getDate()}` : `${endDate.getDate()}`;
    dateEnd = `${yearStart}-${month}-${endDateFormat}`;
  }

  return { dateStart, dateEnd }
}
