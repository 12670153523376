import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import { push } from 'react-router-redux'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import ItemLog from '../../components/common/ItemLog'
import Pagination from '../../components/common/Pagination'

import ChangeTable from '../../components/Orders/ChangeTable'
import ChangeDetail from '../../components/Orders/ChangeDetail'
import ChangeForm from '../../components/Orders/ChangeForm'
import ChangeDecisionForm from '../../components/Orders/ChangeDecisionForm'

import { fetchOrder } from '../../actions/orders'
import { fetchChanges, fetchChange, createChange, updateChange } from '../../actions/changes'

export function OrderChangeView (props) {
  const { fetchOrder, fetchChange, fetchChanges, createChange, updateChange, accessToken, order, change, changes, push, user } = props
  const id = get(props, 'match.params.id')
  const orderId = get(props, 'match.params.orderId')

  useEffect(() => {
    fetchOrder(orderId, accessToken)
  }, [fetchOrder, orderId, accessToken])

  useEffect(() => {
    if (id && id !== 'new') {
      fetchChange(orderId, id, accessToken)
    } else if (!id) {
      fetchChanges(orderId, accessToken)
    }
    // eslint-disable-next-line 
  }, [fetchOrder, orderId, id, accessToken])

  function handleSubmit (e) {
    const action = e.id && e.id !== 'new' ? updateChange : createChange
    return action({
      ...e,
      order_id: orderId,
      log: e.log ? e.log.detail : ''
    }, accessToken)
      .then(x => {
        if (e.status === 'Approved') {
          push(`/quotations`)
        } else {
          push(`/orders/${x.order.id}/changes/${x.id}`)
        }
      })
  }

  if (!order || (id && id !== 'new' && !change) || (!id && !changes)) {
    return <p>loading</p>
  }

  return (
    <div className='content'>
      <Switch>
        <Route path='/orders/:orderId/changes/new' exact>
          <div>
            <Helmet>
              <title>{`Order ${order.id} - Create change`}</title>
            </Helmet>
            <div className='main-header'>
              <h2>New Change</h2>
              <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <ChangeForm
                  onSubmit={handleSubmit}
                  extended={
                    user.data.role.includes('Operational Manager')
                      ? 2
                      : !user.data.role.includes('Sales')
                        ? 1
                        : false
                  }
                  initialValues={{
                    status: 'Draft'
                  }}
                />
              </div>
            </div>
          </div>
        </Route>
        <Route path='/orders/:orderId/changes/:id' exact>
          <div>
            <Helmet>
              <title>{`Order ${order.id} - Update change ${change && change.id}`}</title>
            </Helmet>
            <div className='main-header'>
              <h2>Update Change {change && change.id}</h2>
              <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                { user.data.role.includes('Operational Manager')
                  ? <ChangeDecisionForm
                    onSubmit={handleSubmit}
                    initialValues={{ ...change, status: 'Approved' }}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: accessToken
                    }}
                  />
                  : <div className='alert alert-info'>Please wait for approval by <strong>Operational Manager</strong></div>
                }
                <ChangeDetail change={change} />
              </div>
              <div className='col-md-4'>
                <ItemLog log={change && change.log} entity='Change' />
              </div>
            </div>
          </div>
        </Route>
        <Route path='/orders/:orderId/changes'>
          <div>
            <div className='main-header'>
              <h2>Change List</h2>
              <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <ChangeTable changes={changes} />
              </div>
              <Pagination />
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    changes: state.changes.changeList.data,
    change: state.changes.change.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchChanges: (id, accessToken) => dispatch(fetchChanges(id, accessToken)),
    fetchChange: (orderId, id, accessToken) => dispatch(fetchChange(orderId, id, accessToken)),
    createChange: (data, accessToken) => dispatch(createChange(data, accessToken)),
    updateChange: (data, accessToken) => dispatch(updateChange(data, accessToken)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderChangeView)
