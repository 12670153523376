const phases = [
  'Certification Audit',
  'Recertification Audit',
  'Surveillance Audit 1',
  'Surveillance Audit 2',
  'Surveillance Audit 3',
  'Surveillance Audit 4',
  'Surveillance Audit 5'
]

export default phases