import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import CertificateTable from '../../components/Certificates/CertificateTable'
import CertificateFilter from '../../components/Certificates/CertificateFilter'

import { fetchGlobalCertificates } from '../../actions/certificates'
import { asQueryObject, asQueryString } from '../../const/helper'

class CertificateList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchGlobalCertificates(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchGlobalCertificates(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    if (filter.customer) {
      filter.customer = filter.customer.value.id
    }
    return this.props.push(`/certificates?${asQueryString(filter)}`)
  }

  render () {
    const { certificates, pagination, location, isCustomer } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Certificates List </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { certificates
                ? <div>
                  <CertificateFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                    isCustomer={isCustomer}
                    customerAPI={{
                      url: process.env.REACT_APP_BASE_API + '/customers',
                      accessToken: this.props.accessToken,
                    }}
                  />
                  <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
                  <CertificateTable certificates={certificates} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/certificates' params={asQueryObject(location.search)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    certificates: state.certificates.globalCertificateList.data,
    pagination: state.certificates.globalCertificateList.pagination,
    accessToken: state.auth.user.access.access_token,
    isCustomer: state.auth.user.data.role.includes('Customer'),
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchGlobalCertificates: (params, key) => dispatch(fetchGlobalCertificates(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateList)
