import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  TextareaField,
  TextField,
  MultiCheckboxField,
  OptionsField
} from '../common/FormFields'

export class ChangeForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props

    const reasons = [
      'Legal Status',
      'Commercial Status (e.g. joint venture, sub-contracting with other organizations)',
      'Ownership status (e.g. mergers and acquisitions)',
      'Organization and management (e.g., key managerial, decision-making, or technical staff)',
      'Contact address or sites',
      'Scope of operations under the certified management system ',
      'Different number of employee with Questionnaire',
      'Major change to the management system and processes',
      'IATF OEM customer special status (applicable for IATF 16949)',
      'Transfer to a new IATF-recognized certificate body (applicable for IATF 16949)'
    ]

    const actions = [
      'Revised Quotation',
      'Reconfirmation or modification of the audit plan',
      'Reconfirmation or modification of the audit schedule',
      'Change the previous audit objectives or audit scope'
    ]

    return (
      <div>
        <form className='form-horizontal form-change' onSubmit={handleSubmit}>

          <Field name='detail.reason_list' component={MultiCheckboxField} label='Detail of changes' options={reasons} />
          <Field name='detail.reason_other' component={TextareaField} label='Other specified change' />

          { this.props.extended &&
            <Fragment>
              <Field name='detail.separated_audit_md' component={TextField} label='Special audit needed (Separated from the regular audit)' help='Enter mandays' />
              <Field name='detail.additional_audit_md' component={TextField} label='Special audit addition to regular audit (Special audit MD added to MD audit)' help='Enter mandays' />
              <Field name='detail.terminated' type='checkbox' component={OptionsField} label='  The audit process is terminated' />
            </Fragment>
          }
          { this.props.extended === 2 &&
            <Fragment>
              <Field name='detail.change' type='multiCheckbox' component={OptionsField} label=' Required changes' options={actions} />
            </Fragment>
          }

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-primary'>Save</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

ChangeForm = reduxForm({ // eslint-disable-line 
  form: 'ChangeForm'
})(ChangeForm)

export default ChangeForm
