import React from 'react'
import { Link } from 'react-router-dom'

export default function UserTable({users}) {
  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {users.length === 0 &&
            <tr colSpan={5}>
              <td>User not found</td>
            </tr>
          }
          {users.map((user) => (
            <tr key={user.id}>
              <td><Link to={`/users/${user.id}`}>{user.id}</Link></td>
              <td><Link to={`/users/${user.id}`}>{user.username}</Link></td>
              <td><Link to={`/users/${user.id}`}>{user.data && user.data.display_name}</Link></td>
              <td>{user.email}</td>
              <td>{user?.data?.role}</td>
              <td>{user?.customer?.name || 'Internal'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
