import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import InvoiceTable from '../../components/Invoices/InvoiceTable'
import InvoiceFilter from '../../components/Invoices/InvoiceFilter'

import { fetchGlobalInvoices } from '../../actions/invoices'
import { asQueryObject, asQueryString } from '../../const/helper'

class InvoiceList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchGlobalInvoices(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchGlobalInvoices(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    if (filter.customer) {
      filter.customer = filter.customer.value.id
    }
    return this.props.push(`/invoices?${asQueryString(filter)}`)
  }

  render () {
    const { invoices, pagination, location, isCustomer } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Invoices </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-12'>
              { invoices
                ? <div>
                  <InvoiceFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                    isCustomer={isCustomer}
                    customerAPI={{
                      url: process.env.REACT_APP_BASE_API + '/customers',
                      accessToken: this.props.accessToken,
                    }}
                  />
                  <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
                  <InvoiceTable invoices={invoices} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/invoices' params={asQueryObject(location.search)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    invoices: state.invoices.globalInvoiceList.data,
    pagination: state.invoices.globalInvoiceList.pagination,
    accessToken: state.auth.user.access.access_token,
    isCustomer: state.auth.user.data.role.includes('Customer'),
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchGlobalInvoices: (params, key) => dispatch(fetchGlobalInvoices(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceList)
