import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import FileField from '../common/FileField';
import DateField from '../common/DateField';

class DaPaymentForm extends Component {
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <Field
            name="detail.payment_date"
            component={DateField}
            label="Payment Date"
          />
          <Field
            name="detail.payment_receipt"
            component={FileField}
            label="Payment receipt"
            api={{...this.props.fileAPI, path: 'da'}}
          />
          <div className="form-group">
            <div className="col-sm-offset-3 col-sm-9">
              <button
                disabled={submitting}
                type="submit"
                className="btn btn-primary btn-block"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default DaPaymentForm;

const BaseDaPaymentUpdateForm = props => (
  <DaPaymentForm {...props} mode="update" />
);

export const DaPaymentUpdateForm = reduxForm({
  form: 'daPaymentUpdate', // validate, warn
  enableReinitialize: true,
})(BaseDaPaymentUpdateForm);

const BaseDaPaymentCreateForm = props => (
  <DaPaymentForm {...props} mode="create" />
);

export const DaPaymentCreateForm = reduxForm({
  form: 'daPaymentCreate', // validate, warn
  enableReinitialize: true,
})(BaseDaPaymentCreateForm);
