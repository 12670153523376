import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import {
    TextareaField,
    TextField,
    OptionsField
} from '../common/FormFields'
import DateField from '../common/DateField'
import FileField from '../common/FileField'
import RichTextField from '../common/RichTextField'

function CertificateForm(props) {
    const { handleSubmit, submitting, questionnaire, location, changeForm, fileAPI, mode, disabled } = props
    const standards = questionnaire.scopes.map(x => x.standard.name)
    const questionnairelocations = questionnaire.locations.map((x, i) => ({
        value: x.location.id,
        label: x.location.name
    }))
    const customerLocations = props.locations.map((x) => ({
        value: x.id,
        label: x.name
    }))

    useEffect(() => {
        if (typeof location !== 'undefined' && location) {
            if (questionnaire.locations[location]) {
                changeForm('detail.scope_wording', questionnaire.locations[location].detail.scope_of_business)
            }
        }
    }, [location, changeForm, mode, questionnaire.locations])

    return (
        <div>
            <form className='form-horizontal certificate-form' onSubmit={handleSubmit}>
                <Field name='detail.standard' type='select' component={OptionsField} label='Standard' options={['', ...standards]} disabled={disabled} />
                <Field name='detail.customer_name' component={RichTextField} label='Customer Name' required/>
                <Field name='detail.customer_name_size' component={TextField} label='Customer Name Font Size' disabled={disabled} help="Valid size is between 7 to 15" />
                <Field name='detail.customer_address' component={RichTextField} label='Customer Address' required/>
                <Field name='detail.customer_address_size' component={TextField} label='Customer Address Font Size' disabled={disabled} help="Valid size is between 7 to 15" />
                <Field name='detail.location' type='select' component={OptionsField} label='Main Location' options={['', ...questionnairelocations]} required disabled={disabled} />
                <Field name='detail.additional_location' type='select' component={OptionsField} label='Additional Location' options={['', ...customerLocations]} disabled={disabled} />
                <Field name='detail.logo' type='select' component={FileField} label='Logo' api={{...fileAPI, path: 'certificate'}} help="Use 200px by 100px (landscape) for best result " disabled={disabled} />
                <Field name='detail.scope_wording' component={RichTextField} label='Scope Wording' disabled={disabled} />
                <Field name='detail.scope_wording_alternate' component={RichTextField} label='Scope Wording in Bahasa Indonesia' disabled={disabled} />
                <Field name='detail.scope_wording_size' component={TextField} label='Scope Wording Font Size' disabled={disabled} help="Valid size is between 7 to 15" />
                <Field name='detail.airwaybill' component={TextField} label='Airway Bill' disabled={disabled} />
                <Field name='detail.initial_date' component={DateField} label='Initial Date' />
                <Field name='release_date' component={DateField} label='Release Date' disabled={disabled} />
                <Field name='detail.valid_from_date' component={DateField} label='Valid From' />
                <Field name='end_date' component={DateField} label='Valid Until' disabled={disabled} />
                <Field name='remarks' component={TextareaField} label='Remarks' disabled={disabled} />
                <Field name='detail.support_file' type='select' component={FileField} label='Support File' api={{...fileAPI, path: 'certificate'}} disabled={disabled} />
                <Field name='detail.signed_file' type='select' component={FileField} label='Scan Copy Signed Certificate' api={{...fileAPI, path: 'certificate'}} disabled={disabled} />
                <Field name='detail.annex_font_size' type='select' component={OptionsField} label='Annex Font Size' options={['', 'Small']} disabled={disabled} />
                <Field name='detail.location_page_break' type='select' component={TextField} label='Location Page Break' disabled={disabled} />
                {!disabled && (
                    <div className='form-group'>
                        <div className='col-sm-offset-3 col-sm-9'>
                            <button disabled={submitting} type='submit' className='btn btn-default btn-block' onClick={ev => {
                                ev.preventDefault()
                                changeForm('status', 'Draft')
                                setTimeout(handleSubmit, 100)
                            }}>Save as Draft</button>
                            <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                                ev.preventDefault()
                                changeForm('status', 'Published')
                                setTimeout(handleSubmit, 100)
                            }} >Publish</button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
}

export default CertificateForm

const BaseCertificateCreateForm = props => <CertificateForm {...props} mode='create' />
const selectorCreate = formValueSelector('certificateCreate')

let CertificateCreateFormX = reduxForm({
    form: 'certificateCreate',
    enableReinitialize: true
})(BaseCertificateCreateForm)

export const CertificateCreateForm = connect(state => { // eslint-disable-line
    return {
        location: selectorCreate(state, 'detail.location')
    }
})(CertificateCreateFormX)

const BaseCertificateUpdateForm = props => <CertificateForm {...props} mode='update' />
const selectorUpdate = formValueSelector('certificateUpdate')

let CertificateUpdateFormX = reduxForm({
    form: 'certificateUpdate',
    enableReinitialize: true
})(BaseCertificateUpdateForm)

export const CertificateUpdateForm = connect(state => { // eslint-disable-line
    return {
        location: selectorUpdate(state, 'detail.location')
    }
})(CertificateUpdateFormX)
