import React from 'react'
import File from '../common/File'

export default class ReviewWidget extends React.Component {
  render () {
    const q = this.props.questionnaire
    const r = q.review_detail

    if (!r) {
      return ''
    }

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Questionnaire Review Detail</h3>
        </div>
        <div className='widget-content'>
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>1</p>
            </div>
            <div className='col-md-3'>
              <p>Company Detail Complete?</p>
            </div>
            <div className='col-md-8'>
              <p><strong>{r.company_detail_complete}</strong></p>
              { r.company_detail_note && <div dangerouslySetInnerHTML={{__html: r.company_detail_note}} /> }
              { r.company_detail_file && <p><File accessToken={this.props.accessToken} file={r.company_detail_file} /></p> }
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>2</p>
            </div>
            <div className='col-md-3'>
              <p>Implementation and application of the management system sufficient?</p>
            </div>
            <div className='col-md-8'>
              <p><strong>{r.implementation_complete}</strong></p>
              { r.implementation_note && <div dangerouslySetInnerHTML={{__html: r.implementation_note}} /> }
              { r.implementation_file && <p><File accessToken={this.props.accessToken} file={r.implementation_file} /></p> }
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>3</p>
            </div>
            <div className='col-md-3'>
              <p>Number of sample site need to be audited</p>
            </div>
            <div className='col-md-8'>
              <p><strong>{r.number_of_site}</strong></p>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>4</p>
            </div>
            <div className='col-md-3'>
              <p>Is the scope of certificate and mandays audit appropriate?</p>
            </div>
            <div className='col-md-8'>
              <p><strong>{r.scope_mandays_appropriate}</strong></p>
              { r.scope_mandays_appropriate_note && <div dangerouslySetInnerHTML={{__html: r.scope_mandays_appropriate_note}} /> }
              { r.scope_mandays_appropriate_file && <p><File accessToken={this.props.accessToken} file={r.scope_mandays_appropriate_file} /></p> }
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>5</p>
            </div>
            <div className='col-md-3'>
              <p>CB has the competence personal (auditor or expert) to perform the audit activity?</p>
            </div>
            <div className='col-md-8'>
              <p><strong>{r.competence_personal}</strong></p>
              { r.competence_personal_note && <div dangerouslySetInnerHTML={{__html: r.competence_personal_note}} /> }
              { r.competence_personal_file && <p><File accessToken={this.props.accessToken} file={r.competence_personal_file} /></p> }
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>6</p>
            </div>
            <div className='col-md-3'>
              <p>Any  other points influencing the certification activity are taken into account? (language, safety conditions, threats to impartiality, etc.)</p>
            </div>
            <div className='col-md-8'>
              <p><strong>{r.other_influence}</strong></p>
              { r.other_influence_note && <div dangerouslySetInnerHTML={{__html: r.other_influence_note}} /> }
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-1'>
              <p className='text-right'>7</p>
            </div>
            <div className='col-md-3'>
              <p>Application Review Decision</p>
            </div>
            <div className='col-md-8'>
              <p>{r.review_result}</p>
              { r.review_result_note && <div dangerouslySetInnerHTML={{__html: r.review_result_note}} /> }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
