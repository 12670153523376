import React from 'react'
import moment from 'moment'
import { countBy, forEach, findIndex, cloneDeep } from 'lodash'

import ScheduleRow from './ScheduleRow'

import './AuditorScheduleTable.css'

export default function ScheduleCalendar (props) {
  const { auditorsSchedule, events, active, orderSchedule, onOpenModal } = props
  let start = moment(props.start)
  const end = moment(props.end)
  const numberOfDays = end.diff(start, 'days')

  // start count for months
  const months = [
    {
      value: start.format('YYYY-MM'),
      label: start.format('MMMM YYYY')
    }
  ]

  for (let i = 0; i < 23; i++) {
    start.add(1, 'months')
    months.push({
      value: start.format('YYYY-MM'),
      label: start.format('MMMM YYYY')
    })
  }

  // restart count for dates
  start = moment(props.start)
  const dates = [
    {
      fullDate: start.format('YYYY-MM-DD'),
      day: start.format('dddd'),
      date: start.format('DD'),
      month: start.format('MMMM')
    }
  ]

  for (let i = 0; i < numberOfDays; i++) {
    start.add(1, 'days')
    dates.push({
      fullDate: start.format('YYYY-MM-DD'),
      day: start.format('dddd'),
      date: start.format('DD'),
      month: start.format('MMMM')
    })
  }

  const monthHeader = []
  forEach(countBy(dates, 'month'), function (val, key) {
    monthHeader.push({
      name: key,
      count: val
    })
  })

  function sortByName(a, b) {
    return a?.data?.display_name.localeCompare(b?.data?.display_name);
  }

  const schedulesWithDates = auditorsSchedule ? auditorsSchedule.sort(sortByName).map(x => {
    x.dates = cloneDeep(dates)
    if (x.schedule.length > 0) {
      forEach(x.schedule, (y, j) => {
        if (y.start_date < props.start) {
          y.start_date = props.start
        }

        const dayIndex = findIndex(x.dates, function (o) { return o.fullDate === y.start_date })
        if (x.dates[dayIndex]) {
          x.dates[dayIndex].schedule = y
          x.dates.splice(dayIndex + 1, y.duration - 1)
        }
      })
    }
    return x
  }) : []

  console.debug(schedulesWithDates)

  return (
    <div className='auditor-schedule-table'>
      { auditorsSchedule && auditorsSchedule.length
        ? <div className='table-responsive'>
          <table className='table table-schedule table-condensed'>
            <thead>
              <tr>
                <th className='fixed-left' width={400}>Auditor</th>
                {monthHeader.map((x, i) => <th key={i} colSpan={x.count}>{x.name}</th>)}
              </tr>
              <tr className='date-row'>
                <th className='fixed-left'>&nbsp;</th>
                {dates.map((x, i) => {
                  let className = ['Sunday', 'Saturday'].indexOf(x.day) > -1 ? 'bg-info' : ''
                  const event = events ? events.find(yy => yy.date === x.fullDate) : null;
                  if (event) {
                    className = 'bg-success'
                  }
                  return (
                    <td key={i} className={className}>
                      {x.date}
                    </td>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {
                schedulesWithDates.map((x, i) => (
                  <ScheduleRow
                    onOpenModal={onOpenModal}
                    active={active && active.user_id.value === x.id ? active : null}
                    orderSchedule={orderSchedule ? orderSchedule.filter(xx => xx.user.id === x.id) : []}
                    key={i}
                    schedule={x}
                    events={events}
                    onOpenDate={props.onOpenDate}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
        : <div>not found</div>
      }
    </div>
  )
}
