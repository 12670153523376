const authInitialState = {
  user: null,
  error: null,
  loading: false,
};

export default (state = authInitialState, action) => {
  switch (action.type) {
    case "LOGIN_PENDING":
      return {
        user: null,
        error: null,
        loading: true,
      };
    case "LOGIN_FULFILLED":
      return {
        user: { ...action.payload },
        error: null,
        loading: false,
      };
    case "LOGIN_REJECTED":
      return {
        user: null,
        error: { ...action.payload },
        loading: false,
      };
    case "REGISTER_FULFILLED":
      return {
        user: { ...action.payload },
        error: null,
        loading: false,
      };
    case "REGISTER_PENDING":
      return {
        user: null,
        error: null,
        loading: true,
      };
    case "REGISTER_REJECTED":
      return {
        user: null,
        error: { ...action.payload },
        loading: false,
      };
    case "LOGOUT_FULFILLED":
      return { ...authInitialState };
    default:
      return state;
  }
};
