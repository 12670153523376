import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  fetchUser, deleteUser, updateUser,
  fetchSpecificationByUser, createSpecificationByUser, updateSpecification, deleteSpecification
} from '../../actions/users'

import { fetchStandards, fetchStandardScopes } from '../../actions/standards'

import { UserUpdateForm as UserForm } from '../../components/Users/UserForm'
import SpecificationTable from '../../components/Users/SpecificationTable'
import DeleteForm from '../../components/common/DeleteForm'

export class UserUpdate extends React.Component {
  componentWillMount () {
    if (this.props.match.params.id === 'me') {
      this.props.fetchUser(this.props.me.id, this.props.accessToken)
    } else {
      this.props.fetchUser(this.props.match.params.id, this.props.accessToken)
    }
    this.props.fetchSpecificationByUser(this.props.match.params.id, this.props.accessToken)
    this.props.fetchStandards(this.props.location.search, this.props.accessToken)
  }

  handleDelete () {
    return this.props.deleteUser(this.props.user.id, this.props.accessToken)
  }

  handleSubmit (e) {
    return this.props.updateUser({ ...e }, this.props.accessToken)
  }

  handleCreateSpecification (e) {
    return this.props.createSpecificationByUser(this.props.user.id, e, this.props.accessToken)
  }

  handleUpdateSpecification (e) {
    return this.props.updateSpecification(e, this.props.accessToken)
  }

  handleDeleteSpecification (e) {
    return this.props.deleteSpecification(e, this.props.accessToken)
  }

  handleStandardChange (id) {
    return this.props.fetchStandardScopes(id.target.value, this.props.accessToken)
  }

  render () {
    const { user, me } = this.props
    const role = me?.data?.role || [];

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Update {user && user.data && user.data.display_name}</h2>
          {role.includes('Super Admin') && (
            <em className='em'><Link to='/users'>back to user list</Link></em>
          )}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { user
                ? <div>
                  <UserForm
                    onSubmit={this.handleSubmit.bind(this)}
                    initialValues={user}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: this.props.accessToken
                    }}
                    full={this.props.role.includes('Super Admin')}
                  />
                  <DeleteForm onClick={this.handleDelete.bind(this)} />
                </div>
                : <div>loading</div>
              }
            </div>
            <div className='col-md-4' />
          </div>
        </div>

        { user && user.data && user.data.role.includes('Auditor') && this.props.standards &&
          <div>
            <div className='main-header'>
              <h2>Auditor Qualification</h2>
              <em>For User {user && user.data && user.data.display_name}</em>
            </div>
            <div className='main-content'>
              <div className='row'>
                <div className='col-md-12'>
                  {this.props.specifications
                    ? <SpecificationTable
                      user={user}
                      specifications={this.props.specifications}
                      standards={this.props.standards}
                      scopes={this.props.scopes}
                      onStandardChange={this.handleStandardChange.bind(this)}
                      onCreate={this.handleCreateSpecification.bind(this)}
                      onUpdate={this.handleUpdateSpecification.bind(this)}
                      onDelete={this.handleDeleteSpecification.bind(this)}
                    />
                    : <div>loading</div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.users.user.data,
    specifications: state.users.specificationList.data || [],
    standards: state.standards.standardList.data,
    scopes: state.standards.scopeList.data,
    accessToken: state.auth.user.access.access_token,
    role: state.auth.user.data.role,
    me: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUser: (id, key) => dispatch(fetchUser(id, key)),
    updateUser: (data, key) => dispatch(updateUser(data, key)),
    deleteUser: (id, key) => dispatch(deleteUser(id, key)),
    // specification
    fetchSpecificationByUser: (id, key) => dispatch(fetchSpecificationByUser(id, key)),
    createSpecificationByUser: (id, data, key) => dispatch(createSpecificationByUser(id, data, key)),
    updateSpecification: (data, key) => dispatch(updateSpecification(data, key)),
    deleteSpecification: (id, key) => dispatch(deleteSpecification(id, key)),
    // standards
    fetchStandards: (params, key) => dispatch(fetchStandards(params, key)),
    fetchStandardScopes: (id, key) => dispatch(fetchStandardScopes(id, key))

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUpdate)
