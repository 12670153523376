import { createSelector } from "reselect";

const globalSchedulesSelector = (state) =>
  state.schedules.scheduleList ? state.schedules.globalScheduleList.data : [];
const auditorsSelector = (state) =>
  state.users.auditorList ? state.users.auditorList.data : [];
const orderSelector = (state) =>
  state.orders.order ? state.orders.order.data : {};

export const auditorsScheduleSelector = createSelector(
  globalSchedulesSelector,
  auditorsSelector,
  (schedules, auditors) => {
    if (schedules && auditors) {
      return auditors.map((auditor) => {
        return {
          ...auditor,
          schedule: schedules.filter((x) => x.user.id === auditor.id),
        };
      });
    }
  }
);

export const otherAuditorsScheduleSelector = createSelector(
  globalSchedulesSelector,
  auditorsSelector,
  orderSelector,
  (schedules, auditors, order) => {
    if (schedules && auditors && order) {
      return auditors.map((auditor) => {
        return {
          ...auditor,
          schedule: schedules.filter(
            (x) => x.order.id !== order.id && x.user.id === auditor.id
          ),
        };
      });
    }
  }
);

export function getDateArrayStartEnd(data) {
  return data.reduce(
    (sum, item) => {
      return {
        start:
          sum.start === 0
            ? item.start_date
            : item.start_date < sum.start
            ? item.start_date
            : sum.start,
        end:
          sum.end === 0
            ? item.end_date
            : item.end_date > sum.end
            ? item.end_date
            : sum.end,
      };
    },
    {
      start: 0,
      end: 0,
    }
  );
}

export function getCurrentMainSchedule(order) {
  const caStage1Status = [
    "Created",
    "Prerequisite Not Complete",
    "Prerequisite Submitted",
    "Prerequisite Completed",
  ];
  let currentMainSchedule;

  if (order.phase === "Certification Audit") {
    if (caStage1Status.includes(order.status)) {
      currentMainSchedule = "Certification Audit Stage 1";
    } else {
      currentMainSchedule = "Certification Audit Stage 2";
    }
  } else {
    currentMainSchedule = order.phase;
  }

  return currentMainSchedule;
}

export function getScheduleSummary(schedules, order) {
  const currentMainSchedule = getCurrentMainSchedule(order);
  const xdaysByType = schedules.reduce((sum, x) => {
    if (x.detail.include_manday) {
      let dayCount = x.duration;
      if (x.detail.halfday_start) {
        dayCount -= 0.5;
      }
      if (x.detail.halfday_end) {
        dayCount -= 0.5;
      }
      if (x.detail.halfday) {
        dayCount -= 0.5;
      }
      if (sum[x.type]) {
        sum[x.type].dayCount += dayCount;
        if (x.role === "Lead Auditor") {
          sum[x.type].hasLead = true;
        }
        if (
          x.type === currentMainSchedule ||
          currentMainSchedule.includes(x.type)
        ) {
          sum[x.type].isMain = true;
        }
      } else {
        sum[x.type] = {
          dayCount,
          hasLead: x.role === "Lead Auditor",
          isMain:
            x.type === currentMainSchedule ||
            currentMainSchedule.includes(x.type),
        };
      }
    }
    return sum;
  }, {});
  return Object.entries(xdaysByType);
}

export function getScheduleError(schedules, order, quotation) {
  const auditCycle = quotation?.detail?.audit_cycle || [];
  const scheduleTypeSummary = getScheduleSummary(schedules, order);
  const mainScheduleSummary = scheduleTypeSummary.find(
    ([_key, value]) => value.isMain
  );
  const currentMainSchedule = getCurrentMainSchedule(order);
  const auditCycleItem = auditCycle.find(
    (x) => x.phase === currentMainSchedule
  );

  let scheduleError = [];
  if (auditCycleItem && !mainScheduleSummary) {
    scheduleError.push(`Pleae add ${auditCycleItem?.phase} schedule`);
  }
  if (
    auditCycleItem?.day &&
    mainScheduleSummary?.[1]?.dayCount &&
    mainScheduleSummary[1].dayCount !== auditCycleItem.day
  ) {
    scheduleError.push("Schedule day count should match requried man days");
  }
  if (
    mainScheduleSummary &&
    mainScheduleSummary[1] &&
    !mainScheduleSummary[1].hasLead
  ) {
    scheduleError.push(`${auditCycleItem.phase} should have Lead Auditor`);
  }
  return scheduleError;
}
