import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

const reductionUpdate = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.reduction_factor) {
    return formValue.detail.reduction_factor;
  } else {
    return [];
  }
};

const enhancementUpdate = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.enhancement_factor) {
    return formValue.detail.enhancement_factor;
  } else {
    return [];
  }
};

const additionUpdate = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.addition_factor) {
    return formValue.detail.addition_factor;
  } else {
    return [];
  }
};

export const mandDayUpdateSelector = createSelector(
  reductionUpdate,
  enhancementUpdate,
  additionUpdate,
  (reduction, enhancement, addition) => {
    const allReduction = reduction.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const allEnhancement = enhancement.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const allAddition = addition.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const baseValue = allEnhancement + allAddition - allReduction;

    if (baseValue > 30) {
      return 30;
    } else if (baseValue < -30) {
      return -30;
    } else {
      return baseValue;
    }
  }
);

const phases = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.audit_cycle) {
    return formValue.detail.audit_cycle;
  } else {
    return [];
  }
};

const additionals = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.additional_service) {
    return formValue.detail.additional_service;
  } else {
    return [];
  }
};

const fees = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.fees) {
    return formValue.detail.fees;
  } else {
    return [];
  }
};

export const totalFeesUpdateSelector = createSelector(
  phases,
  additionals,
  fees,
  (phases, additionals, fees) => {
    const allPhase = phases.reduce((x, y) => {
      return x + y.day * y.rate;
    }, 0);

    const allAdditional = additionals.reduce((x, y) => {
      return x + y.day * y.rate;
    }, 0);

    const allFees = fees.reduce((x, y) => {
      return x + y.quantity * y.rate;
    }, 0);

    return allPhase + allAdditional + allFees;
  }
);

const reductionCreate = (state) => {
  const formValue = getFormValues("quotationCreateForm")(state);
  if (formValue && formValue.detail && formValue.detail.reduction_factor) {
    return formValue.detail.reduction_factor;
  } else {
    return [];
  }
};

const enhancementCreate = (state) => {
  const formValue = getFormValues("quotationCreateForm")(state);
  if (formValue && formValue.detail && formValue.detail.enhancement_factor) {
    return formValue.detail.enhancement_factor;
  } else {
    return [];
  }
};

const additionCreate = (state) => {
  const formValue = getFormValues("quotationCreateForm")(state);
  if (formValue && formValue.detail && formValue.detail.addition_factor) {
    return formValue.detail.addition_factor;
  } else {
    return [];
  }
};

export const mandDayCreateSelector = createSelector(
  reductionCreate,
  enhancementCreate,
  additionCreate,
  (reduction, enhancement, addition) => {
    const allReduction = reduction.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const allEnhancement = enhancement.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const allAddition = addition.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const baseValue = allEnhancement + allAddition - allReduction;

    if (baseValue > 30) {
      return 30;
    } else if (baseValue < -30) {
      return -30;
    } else {
      return baseValue;
    }
  }
);

const createPhases = (state) => {
  const formValue = getFormValues("quotationCreateForm")(state);
  if (formValue && formValue.detail && formValue.detail.audit_cycle) {
    return formValue.detail.audit_cycle;
  } else {
    return [];
  }
};

const createAdditionals = (state) => {
  const formValue = getFormValues("quotationCreateForm")(state);
  if (formValue && formValue.detail && formValue.detail.additional_service) {
    return formValue.detail.additional_service;
  } else {
    return [];
  }
};

const createFees = (state) => {
  const formValue = getFormValues("quotationCreateForm")(state);
  if (formValue && formValue.detail && formValue.detail.fees) {
    return formValue.detail.fees;
  } else {
    return [];
  }
};

const createPhasesUpdate = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.audit_cycle) {
    return formValue.detail.audit_cycle;
  } else {
    return [];
  }
};

const createAdditionalsUpdate = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.additional_service) {
    return formValue.detail.additional_service;
  } else {
    return [];
  }
};

const createFeesUpdate = (state) => {
  const formValue = getFormValues("quotationUpdateForm")(state);
  if (formValue && formValue.detail && formValue.detail.fees) {
    return formValue.detail.fees;
  } else {
    return [];
  }
};

export const totalFirstYearFeesCreateSelector = createSelector(
  createPhases,
  createAdditionals,
  createFees,
  (phases, additionals, fees) => {
    const allPhase = phases.reduce((sum, x) => {
      if (x.phase.indexOf("Surveillance") < 0) {
        return sum + x.day * x.rate;
      }
      return sum;
    }, 0);

    const allAdditional = additionals.reduce((x, y) => {
      return x + y.day * y.rate;
    }, 0);

    const allFees = fees.reduce((x, y) => {
      return x + y.quantity * y.rate;
    }, 0);
    return allPhase + allAdditional + allFees;
  }
);

export const getFeesByYear = createSelector(
  createPhases,
  createAdditionals,
  createFees,
  (phases, additionals, fees) => {
    const y1 = phases.reduce((sum, x) => {
      if (x.phase.indexOf("Surveillance") < 0) {
        return sum + x.day * x.rate;
      }
      return sum;
    }, 0);

    const nextYears = phases.reduce((sum, x) => {
      if (x.phase.indexOf("Surveillance") === 0) {
        return [
          ...sum,
          {
            ...x,
            amount: x.day * x.rate,
          },
        ];
      }
      return sum;
    }, []);

    const allFees = fees.reduce((x, y) => {
      return x + y.quantity * y.rate;
    }, 0);

    const result = [
      {
        phase: "First Year",
        description: "CA1 + CA2 + Other fees",
        amount: y1 + allFees,
      },
      ...nextYears,
    ];

    return result;
  }
);

export const getFeesByYearUpdate = createSelector(
  createPhasesUpdate,
  createAdditionalsUpdate,
  createFeesUpdate,
  (phases, additionals, fees) => {
    const y1 = phases.reduce((sum, x) => {
      if (x.phase.indexOf("Surveillance") < 0) {
        return sum + x.day * x.rate;
      }
      return sum;
    }, 0);

    const nextYears = phases.reduce((sum, x) => {
      if (x.phase.indexOf("Surveillance") === 0) {
        return [
          ...sum,
          {
            ...x,
            amount: x.day * x.rate,
          },
        ];
      }
      return sum;
    }, []);

    const allFees = fees.reduce((x, y) => {
      return x + y.quantity * y.rate;
    }, 0);

    const result = [
      {
        phase: "First Year",
        description: "CA1 + CA2 + Other fees",
        amount: y1 + allFees,
      },
      ...nextYears,
    ];

    return result;
  }
);

const scopesSelector = (state) =>
  state.quotations.quotation.data
    ? state.quotations.quotation.data.questionnaire.scopes
    : [];

export const needBODSelector = createSelector(scopesSelector, (items) =>
  items.reduce((x, y) => {
    if (x === true) {
      return true;
    } else {
      if (y.standard.detail.bod_approval) {
        return true;
      } else {
        return false;
      }
    }
  }, false)
);

export function filterAuditCycle(cycles) {
  const surveillance = cycles.filter(
    (x) => x.phase.indexOf("Follow-up Audit / Special Audit") === -1
  );
  const finalCycles = [...surveillance];

  return finalCycles;
}

export function mergeAuditCycle(cycles) {
  const certificationAudit = {
    day: 0,
    include: false,
    phase: "Certification Audit",
    rate: 0,
    type: "certification",
  };
  const stage1 = cycles.find(
    (x) => x.phase === "Certification Audit Stage 1" && x.include
  );
  const stage2 = cycles.find(
    (x) => x.phase === "Certification Audit Stage 2" && x.include
  );
  const surveillance = cycles.filter(
    (x) => x.phase.indexOf("Certification Audit") === -1
  );
  const finalCycles = [...surveillance];

  if (stage1) {
    certificationAudit.day = certificationAudit.day + stage1.day;
    certificationAudit.include = true;
    certificationAudit.rate = stage1.rate;
  }
  if (stage2) {
    certificationAudit.day = certificationAudit.day + stage2.day;
    certificationAudit.include = true;
  }
  if (certificationAudit.include) {
    finalCycles.unshift(certificationAudit);
  }

  return finalCycles;
}
