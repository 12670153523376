import React from 'react'
import { Link } from 'react-router-dom'

export function LocalRow ({ customer, ...x }) {
  return (
    <tr>
      <td>
        <Link to={`/customers/${customer.id}/contacts/${x.id}`}>{x.title} {x.first_name} {x.middle_name} {x.surname}</Link>
      </td>
      <td>{x.job_title}</td>
      <td>{x.department}</td>
      <td>{x.mobile_phone} / {x.phone}</td>
      <td>{x.user && 'Yes'}</td>
      <td className='text-right'>
        <Link className='btn btn-xs btn-default' to={`/customers/${customer.id}/contacts/${x.id}`}><i className='fa fa-gear' /></Link>
      </td>
    </tr>
  )
}

export function BaseRow ({ customer, onSync, ...x }) {
  return (
    <tr>
      <td>
        {x.local
          ? <Link to={`/customers/${customer.id}/contacts/${x.local.id}`}>{x.contact_title} {x.contact_firstname} {x.contact_middlename} {x.contact_surname}</Link>
          : <button type='button' className='btn btn-link' onClick={() => onSync(x.contact_id)}>{x.contact_title} {x.contact_firstname} {x.contact_middlename} {x.contact_surname}</button>
        }
      </td>
      <td>{x.contact_jobtitle}</td>
      <td>{x.depth_name}</td>
      <td>{x.contact_mobilephone}</td>
      <td>{x.local && x.local.user && 'Yes'}</td>
      <td className='text-right'>
        <Link className='btn btn-xs btn-default' to={`/customers/${customer.id}/contacts/${x.id}`}><i className='fa fa-gear' /></Link>
      </td>
    </tr>
  )
}

export default class ContactTable extends React.Component {
  render () {
    const contacts = this.props.contacts || []
    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Job Title</th>
            <th>Department</th>
            <th>Phone</th>
            <th>Have User</th>
            <th className='text-center' width={80}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map(x => {
            if (!x.address_id) {
              return <LocalRow key={x.id} {...x} customer={this.props.customer} />
            } else {
              return <BaseRow key={x.address_id} {...x} customer={this.props.customer} onSync={this.props.onSync} />
            }
          })}
          {this.props.customer &&
            <tr className=' bg-grey'>
              <td className='customer-scope-container' colSpan={6}>
                <Link className='btn btn-block btn-default' to={`/customers/${this.props.customer.id}/contacts/new`}>Add Contact</Link>
              </td>
            </tr>
          }
        </tbody>
      </table>
    )
  }
}
