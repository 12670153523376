import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import ConfirmButton from '../common/ConfirmButton'
import FileField from '../common/FileField'

const validate = (values, props) => {
  const errors = {}
  if (props.required && !values.file) {
    errors.file = {
      file: 'Required'
    }
  }
  return errors
}

export class FileForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props

    return (

      <div>
        <form className='form-horizontal file-form' onSubmit={handleSubmit}>

          <Field name='file' component={FileField} label='File' api={{...this.props.fileAPI, path: 'common'}} />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <ConfirmButton disabled={submitting} text={'Submit'}>
                <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit'>Submit</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

FileForm = reduxForm({ // eslint-disable-line 
  enableReinitialize: true,
  form: 'FileForm',
  validate
})(FileForm)

export default FileForm
