import React, { Fragment } from 'react';

import scheduleTypes from '../../const/scheduleTypes';

export default function ScheduleRow({
    schedule,
    events,
    onOpenDate,
    active,
    orderSchedule,
    onOpenModal,
}) {
  function isInRange(date, start, end) {
    let xStart = start;
    let xEnd = end;
    if (start > end) {
      xStart = end;
      xEnd = start;
    }
    return date <= xEnd && date >= xStart;
  }

  function code(type) {
    return scheduleTypes.find(x => x.label === type)?.code || 'X';
  }

  return (
    <tr className={`auditor-line`}>
      <th className={`fixed-left ${schedule.weight > 0 ? 'match' : ''} ${schedule.isExpert ? 'expert' : ''}`}>
        <a onClick={() => onOpenModal(schedule)} href="#top">
          {schedule.data.display_name}
        </a>
      </th>

      {schedule.dates.map((y, j) => {
        let background = '';
        let selection = '';

        // change background if weekend or have event
        if (['Sunday', 'Saturday'].indexOf(y.day) > -1) {
          background = 'bg-info';
        }
        if (events && events.find(yy => yy.date === y.fullDate)) {
          background = 'bg-success';
        }

        // change background for other order schedule
        if (y.schedule) {
          background =
            y.schedule.status === 'Draft' ? 'bg-warning' : 'bg-danger';
        }

        // get schedule for this day
        const scheduled = orderSchedule
          ? orderSchedule.filter(
              z => y.fullDate >= z.start_date && y.fullDate <= z.end_date
            )
          : [];

        // put first day last last day first
        if (scheduled.length === 2) {
          if (
            (scheduled[0].detail.halfday_start &&
              y.fullDate === scheduled[0].start_date) ||
            (scheduled[1].detail.halfday_end &&
              y.fullDate === scheduled[1].end_date)
          ) {
            scheduled.push(scheduled[0]);
            scheduled.shift();
          }
        }

        // active selection
        if (
          active &&
          !active.id &&
          isInRange(y.fullDate, active.start_date, active.end_date)
        ) {
          selection = 'btn-selected';
        }

        return (
          <td
            colSpan={y.schedule ? y.schedule.duration : 1}
            key={j}
            className={`${background}`}
          >
            {y.schedule && (
              <button
                onClick={() => onOpenModal(y.schedule)}
                className="btn btn-link btn-xs"
                title={`${y.schedule.order.number}`}
              >
                {y.schedule.order.number}
              </button>
            )}
            {scheduled.length === 1 && (
              <Fragment>
                {scheduled[0].detail.halfday_start &&
                  scheduled[0].start_date === y.fullDate && (
                    <button
                      className={`${selection} btn-empty btn btn-link btn-xs`}
                      onClick={() => onOpenDate(schedule.id, y.fullDate)}
                    >
                      {y.date}
                    </button>
                  )}
                <button
                  className={`${
                    active && scheduled[0].id === active.id
                      ? 'btn-selected'
                      : ''
                  } btn-schedule btn btn-xs`}
                  onClick={() =>
                    onOpenDate(schedule.id, y.fullDate, scheduled[0].id)
                  }
                >
                  {code(scheduled[0].type)}
                </button>
                {scheduled[0].detail.halfday_end &&
                  scheduled[0].end_date === y.fullDate && (
                    <button
                      className={`${selection} btn-empty btn btn-link btn-xs`}
                      onClick={() => onOpenDate(schedule.id, y.fullDate)}
                    >
                      {y.date}
                    </button>
                  )}
                {scheduled[0].detail.halfday &&
                  scheduled[0].end_date === y.fullDate && (
                    <button
                      className={`${selection} btn-empty btn btn-link btn-xs`}
                      onClick={() => onOpenDate(schedule.id, y.fullDate)}
                    >
                      {y.date}
                    </button>
                  )}
              </Fragment>
            )}
            {scheduled.length === 2 && (
              <Fragment>
                {scheduled.map((yy, ii) => (
                  <button
                    key={ii}
                    className={`${
                      active && yy.id === active.id ? 'btn-selected' : ''
                    } btn-schedule btn btn-link btn-xs`}
                    onClick={() => onOpenDate(schedule.id, y.fullDate, yy.id)}
                  >
                    {code(yy.type)}
                  </button>
                ))}
              </Fragment>
            )}
            {!y.schedule && scheduled.length === 0 && (
              <button
                className={`${selection} btn-empty btn btn-link btn-xs`}
                onClick={() => onOpenDate && onOpenDate(schedule.id, y.fullDate)}
              >
                {y.date}
              </button>
            )}
          </td>
        );
      })}
    </tr>
  );
}
