import React, { useState } from 'react'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import intersection from 'lodash/intersection'

import CertificationInformationWidget from '../../components/Questionnaires/CertificationInformationWidget'
import AdditionalInformationWidget from '../../components/Questionnaires/AdditionalInformationWidget'
import MandaysCalculationWidget from '../../components/Questionnaires/MandaysCalculationWidget'
import AnnexWidget from '../../components/Questionnaires/AnnexWidget'
import ActionWidget from '../../components/Questionnaires/ActionWidget'
import ReviewWidget from '../../components/Questionnaires/ReviewWidget'
import RevisionWidget from '../../components/Questionnaires/RevisionWidget'

import CustomerWidget from '../../components/Customers/CustomerWidget'
import LocationWidget from '../../components/Customers/LocationWidget'
import ContactWidget from '../../components/Customers/ContactWidget'

import ItemLog from '../../components/common/ItemLog'
import LoadingModal from '../../components/common/LoadingModal'

import { annexWithValueSelector } from '../../selectors/questionnaires'
import { sendInvite } from '../../actions/questionnaires'

export function QuestionnaireSummary2 (props) {
  const { questionnaire, questionnaireAnnex, user, accessToken, push } = props
  const { role } = user.data

  const generalInformationComplete = get(questionnaire, 'detail.general_information_complete')
  const certificationInformationComplete = get(questionnaire, 'detail.certification_information_complete')
  const auditLocationComplete = get(questionnaire, 'detail.audit_location_complete')
  const additionalInformationComplete = get(questionnaire, 'detail.additional_information_complete')
  const annexComplete = get(questionnaire, 'detail.annex_complete')
  const mandaysCalculationComplete = get(questionnaire, 'detail.mandays_calculation_complete')
  const customer = get(questionnaire, 'customer')
  const mainContact = get(questionnaire, 'detail.main_contact')

  const [loading, setLoading] = useState(false)

  let nextDraft = 'general-information'
  if (generalInformationComplete) {
    nextDraft = 'certification-information'
  }
  if (certificationInformationComplete) {
    nextDraft = 'audit-location'
  }
  if (auditLocationComplete) {
    nextDraft = 'additional-information'
  }
  if (additionalInformationComplete) {
    nextDraft = 'annex'
  }
  if (annexComplete) {
    nextDraft = 'mandays-calculation'
  }

  const toQuotationLink = questionnaire.quotation
    ? `/quotations/${questionnaire.quotation.id}`
    : `/quotations/new/${questionnaire.id}`

  function handleInvite () {
    setLoading(true)
    const request = {
      id: questionnaire.id,
      contact_id: questionnaire.detail.main_contact.id,
      user_id: questionnaire.customer_user ? questionnaire.customer_user.id : null
    }
    props.sendInvite(request, props.accessToken).then((data) => {
      setLoading(false)
    })
  }

  return (
    <div className=''>
      <div className='row'>
        <div className='col-md-8'>
          <ActionWidget
            questionnaire={questionnaire}
            user={user}
            userReviewer={[questionnaire.reviewer]}
            toDraft={() => push(`/questionnaires/${questionnaire.id}/${nextDraft}`)}
            toAssignment={() => push(`/questionnaires/${questionnaire.id}/assignment`)}
            toReview={() => push(`/questionnaires/${questionnaire.id}/review`)}
            toQuotation={() => push(toQuotationLink)}
            toReplacement={() => push(`/questionnaires/${questionnaire.id}`)}
            allowTemporaryQuotation
            quotationApproved={questionnaire.quotation && ['Published to Customer', 'Manual Approval', 'Approved by Customer'].includes(questionnaire.quotation.status)}
            haveQuotation={questionnaire.quotation}
            allowDownload={questionnaire.status !== 'Draft'}
            download={() => {
              window.location = `${process.env.REACT_APP_BASE_API}/pdf/questionnaire?template=questionnaire-2&id=${questionnaire.id}&token=${accessToken}`
              return true
            }}
            sendInvite={handleInvite}
          />
          <div className='row'>
            <div className='col-md-6'>
              <CustomerWidget customer={customer} accessToken={accessToken} />
            </div>
            <div className='col-md-6'>
              <ContactWidget contact={mainContact} accessToken={accessToken} title='Main Contact' />
            </div>
          </div>
          <CertificationInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
          {questionnaire.locations.map((x, i) => (
            <LocationWidget
              key={i}
              location={x.location}
              detail={x.detail}
              accessToken={accessToken}
              title={`Audit Location : ${x.location.name}`}
            />
          ))}
          <AdditionalInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
          <AnnexWidget annex={questionnaireAnnex} questionnaire={questionnaire} accessToken={accessToken} />
          <MandaysCalculationWidget questionnaire={questionnaire} accessToken={accessToken} />
          { questionnaire.review_detail && user && !user.data.role.includes('Customer') &&
            <ReviewWidget questionnaire={questionnaire} accessToken={accessToken} />
          }
          { questionnaire.revision &&
            <RevisionWidget revision={questionnaire.revision} />
          }
        </div>
        <div className='col-md-4'>
          { ['Draft', 'Need to Revise'].includes(questionnaire.status) && intersection(['Sales', 'Super Admin'], role).length > 0 &&
            <div className='questionnaire-steps'>
              <Link to={`/questionnaires/${questionnaire.id}/general-information`}>
                { generalInformationComplete
                  ? <p><i className='fa fa-check' /> General information complete</p>
                  : <p>General information incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/certification-information`}>
                { certificationInformationComplete
                  ? <p><i className='fa fa-check' /> Certification information complete</p>
                  : <p>Certification information incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/audit-location`}>
                { auditLocationComplete
                  ? <p><i className='fa fa-check' /> Audit location complete</p>
                  : <p>Audit location incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/additional-information`}>
                { additionalInformationComplete
                  ? <p><i className='fa fa-check' /> Additional information complete</p>
                  : <p>Additional information incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/annex`}>
                { annexComplete
                  ? <p><i className='fa fa-check' /> Annex complete</p>
                  : <p>Annex incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/mandays-calculation`}>
                { mandaysCalculationComplete
                  ? <p><i className='fa fa-check' /> Mandays Calculation complete</p>
                  : <p>Mandays Calculation incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/assignment`}>
                { ['Assigned', 'Approved', 'Need to Revise', 'Not Approved', 'Quoted', 'Ordered', 'Replaced'].includes(questionnaire.status)
                  ? <p><i className='fa fa-check' /> Review assignment complete</p>
                  : <p>Review assignment incomplete </p>
                }
              </Link>
              <Link to={`/questionnaires/${questionnaire.id}/assignment`}>
                { ['Approved', 'Quoted', 'Ordered', 'Replaced'].includes(questionnaire.status)
                  ? <p><i className='fa fa-check' /> Review complete</p>
                  : <p>Review incomplete </p>
                }
              </Link>
            </div>
          }
          { questionnaire && !role.includes('Customer') &&
            <div>
              <ItemLog log={questionnaire.log} entity='Questionnaire' />
            </div>
          }
        </div>
      </div>
      <LoadingModal open={loading} message='Sending request' />
    </div>
  )
}

function mapStateToProps (state) {
  return {
    accessToken: state.auth.user.access.access_token,
    questionnaireAnnex: annexWithValueSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    sendInvite: (request, key) => dispatch(sendInvite(request, key))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireSummary2)
