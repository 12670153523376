import React from 'react'
import NumberFormat from 'react-number-format'
import {groupBy} from 'lodash'

export class AllowanceField extends React.Component {
  constructor (props) {
    super(props)
    const options = this.props.options.map(x => {
      return {
        name: x,
        include: false,
        quantity: 0,
        rate: 0,
        note: ' ',
        currency: 'IDR'
      }
    })

    // assign values to available optiobs
    const currentValues = groupBy(this.props.input.value, 'name')

    const optionsWithValues = options.map(x => {
      if (currentValues[x.name]) {
        return {...currentValues[x.name][0]}
      }
      return x
    })

    // add user defined options to options
    const userOptions = this.props.input.value ? this.props.input.value.filter(x => this.props.options.indexOf(x.name) < 0) : []
    const merged = [ ...optionsWithValues, ...userOptions ]

    this.state = { value: merged }
  }

  componentWillMount () {
    this.props.input.onChange(this.state.value)
  }

  toggleFee (i, e) {
    const value = this.state.value
    if (e.target.checked) {
      value[i].include = true
    } else {
      value[i].include = false
    }
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeQty (i, e) {
    const value = this.state.value
    value[i].quantity = parseInt(e.target.value, 10)
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeRate (i, e, g) {
    const value = this.state.value
    value[i].rate = g.floatValue
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeNote (i, e) {
    const value = this.state.value
    value[i].note = e.target.value
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  changeCurrency (i, e) {
    const value = this.state.value
    value[i].currency = e.target.IDRe
    this.setState({ value })
    this.props.input.onChange(value.filter(x => x.include))
  }

  addNew () {
    const value = [ ...this.state.value, {
      name: this.refs.newName.value,
      quantity: 0,
      currency: 'IDR',
      rate: 0,
      note: ' '
    }]
    this.setState({
      value
    })
    this.refs.newName.value = null
    this.props.input.onChange(value.filter(x => x.value !== 0))
  }

  removeOption (i, ev) {
    ev.preventDefault()

    const newValue = [...this.state.value]

    newValue.splice(i, 1)
    this.props.input.onChange(newValue)
    this.setState({
      value: newValue
    })
  }

  render () {
    const {options, input, label, meta: {touched, error, warning}} = this.props
    const {value} = this.state
    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <label htmlFor={label} className='col-sm-3 control-label'>{label}</label>
        <div className='col-sm-9'>
          <table className='table table-condensed'>
            <thead>
              <tr>
                <th />
                <th>Fees</th>
                <th>Quantity</th>
                <th>Currency</th>
                <th>Rate</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {value.map((x, i) => {
                return (
                  <tr key={i} style={{opacity: (x.include ? 1 : 0.5)}}>
                    <td width={30}>
                      { options.indexOf(x.name) >= 0
                        ? <div className='checkbox'>
                          <label>
                            <input type='checkbox' onChange={this.toggleFee.bind(this, i)} checked={x.include} disabled={this.props.disabled}/>
                          </label>
                        </div>
                        : <a href='/' onClick={this.removeOption.bind(this, i)}><i className='fa fa-remove' /></a>
                      }
                    </td>
                    <td style={{verticalAlign: 'middle'}}>{x.name}</td>
                    <td>
                      <input
                        type='number'
                        min='1'
                        className='form-control input-xs'
                        onChange={this.changeQty.bind(this, i)}
                        disabled={!x.include || this.props.disabled}
                        value={x.quantity}
                      />
                    </td>
                    <td>
                      <select
                        className='form-control input-xs'
                        onChange={this.changeCurrency.bind(this, i)}
                        disabled={!x.include || this.props.disabled}
                        value={x.currency}
                      >
                        <option value='IDR'>IDR</option>
                        <option value='USD'>USD</option>
                      </select>
                    </td>
                    <td>
                      <div className='input-group'>
                        <NumberFormat
                          type='text'
                          className='form-control input-xs'
                          disabled={!x.include || this.props.disabled}
                          value={x.rate ? x.rate : 0}
                          onChange={this.changeRate.bind(this, i)}
                          thousandSeparator
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control input-xs'
                        onChange={this.changeNote.bind(this, i)}
                        disabled={!x.include || this.props.disabled}
                        value={x.note}
                      />
                    </td>
                  </tr>
                )
              })}
              {!this.props.disabled && (
                <tr>
                  <td colSpan={6}>
                    <div className='input-group' style={{width: '100%'}}>
                      <input type='text' placeholder=' other ...' className='form-control input-sm' ref='newName' />
                      <div className='input-group-btn'>
                        <button className='btn btn-default btn-sm' onClick={this.addNew.bind(this)}>
                          <i className='fa fa-plus' />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {touched && ((error && <div><br /><span>{error}</span></div>) || (warning && <div><br /><span>{warning}</span></div>))}
        </div>
      </div>
    )
  }
}
