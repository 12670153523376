import moment from 'moment';
import NumberFormat from 'react-number-format';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import DaTableShort from '../../components/Das/DaTableShort';
import DaTable from '../../components/Das/DaTable';
import DaFilter from '../../components/Das/DaFilter';
import { DaPaymentCreateForm as DaPaymentForm } from '../../components/Das/DaPaymentForm';

import { fetchDas, createDaPayment } from '../../actions/das';
import { asQueryObject, asQueryString } from '../../const/helper';

class DaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      das: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const query = asQueryObject(nextProps.location.search);
      query.status = ['Approved', 'Payment Prepared'];

      this.props.fetchDas('?' + asQueryString(query), this.props.accessToken);
    }
  }

  componentWillMount() {
    this.props.fetchDas(this.props.location.search, this.props.accessToken);
  }

  handleFilter(filter) {
    delete filter.page;
    filter.user = this.props.das[0].user.id;
    return this.props.push(
      `/daily-allowance-payments/new?${asQueryString(filter)}`
    );
  }

  handleDa(e) {
    if (this.state.das.length === 0) {
      return false;
    }
    const req = {
      ...e,
      user_id: this.state.das[0].user.id,
      da: this.state.das.map(x => x.id),
      detail: {
        ...e.detail,
        total: this.getTotal(this.state.das),
      }
    };
    if (e?.detail?.payment_receipt) {
      req.status = 'Done';
    }
    return this.props.createDaPayment(req, this.props.accessToken);
  }

  handleSelect(e) {
    this.setState({
      das: [...this.state.das, e],
    });
  }

  handleRemove(e) {
    const das = this.state.das.filter((x) => x.id !== e.id);
    this.setState({ das });
  }

  getTotal(dap = []) {
    return dap.reduce(
      (sum, x, i) => {
        return {
          idr: sum.idr + (x?.detail?.total?.idr || 0),
          usd: sum.usd + (x?.detail?.total?.usd || 0),
        };
      },
      { idr: 0, usd: 0 }
    );
  }

  render() {
    const { das, location, accessToken } = this.props;
    const auditor = das?.[0]?.user;
    const search = asQueryObject(location.search);
    const userId = search.user;
    const month = moment(search?.month);

    const allowance = this.getTotal(this.state.das);

    return (
      <div className="content">
        <div className="main-header">
          <h2>Create Daily Allowance Payment </h2>
          <em className="">
            {auditor?.name}, {month.format('MMMM YYYY')}
          </em>
        </div>
        <div className="main-content">
          <div className="row">
            <div className="col-md-8">
              {das ? (
                <div>
                  {this.state.das.length === 0 && (
                    <div className='well well-sm well-info'>Please select Daily Allowance</div>
                  )}
                  <DaTableShort das={this.state.das} onRemove={this.handleRemove.bind(this)}/>
                  <hr />
                  <DaFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                    resetUrl={`/daily-allowance-payments/new?user=${userId}`}
                  />
                  <DaTable
                    das={das}
                    showUser
                    role={['Finance']}
                    onSelect={this.handleSelect.bind(this)}
                    selectedId={this.state.das.map((x) => x.id)}
                  />
                </div>
              ) : (
                <div>loading</div>
              )}
            </div>
            <div className="col-md-4">
              <div className="well">
                <dl>
                  <dt>
                    <strong>Total (IDR)</strong>
                  </dt>
                  <dd>
                    <NumberFormat
                      displayType="text"
                      value={allowance.idr}
                      thousandSeparator
                    />
                  </dd>
                  <dt>
                    <strong>Total (USD)</strong>
                  </dt>
                  <dd>
                    <NumberFormat
                      displayType="text"
                      value={allowance.usd}
                      thousandSeparator
                    />
                  </dd>
                </dl>
                <p>Please select DA and save to download PDF.</p>
              </div>
              <div className="widget">
                <div className="widget-header">
                  <h3>
                    <i className="fa fa-book" /> Create Daily Allowance Payment
                  </h3>
                </div>
                <div className="widget-content">
                  <DaPaymentForm
                    onSubmit={this.handleDa.bind(this)}
                    initialValues={{}}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: accessToken,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    das: state.das.daList.data,
    pagination: state.das.daList.pagination,
    accessToken: state.auth.user.access.access_token,
    role: state.auth.user.data.role,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDas: (params, key) => dispatch(fetchDas(params, key)),
    createDaPayment: (params, key) => dispatch(createDaPayment(params, key)),
    push: to => dispatch(push(to)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DaList);
