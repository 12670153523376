import React from 'react'

export default class ReportDetail extends React.Component {
  render () {
    const { report } = this.props
    let veto = null

    if (report?.detail?.veto) {
      veto = report?.detail?.veto
    }

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Report Detail</h3>
        </div>
        <div className='widget-content'>
          <div className='row'>
            { report.detail && report.detail.attendance_list &&
              <div className='col-md-6'>
                <dl>
                  <dt>Organization Structure</dt>
                  { report.detail.attendance_list.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.audit_report &&
              <div className='col-md-6'>
                <dl>
                  <dt>Audit Report</dt>
                  { report.detail.audit_report.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.document_review &&
              <div className='col-md-6'>
                <dl>
                  <dt>Document Review</dt>
                  { report.detail.document_review.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.environmental_aspect &&
              <div className='col-md-6'>
                <dl>
                  <dt>Environmental Aspect</dt>
                  { report.detail.environmental_aspect.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.handwritten_notes &&
              <div className='col-md-6'>
                <dl>
                  <dt>Handwritten Notes</dt>
                  { report.detail.handwritten_notes.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.declaration_of_independence &&
              <div className='col-md-6'>
                <dl>
                  <dt>Declaration of Independence</dt>
                  { report.detail.declaration_of_independence.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.attendance_list &&
              <div className='col-md-6'>
                <dl>
                  <dt>Attendance List</dt>
                  { report.detail.attendance_list.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.draft_ncr &&
              <div className='col-md-6'>
                <dl>
                  <dt>Draft NCR</dt>
                  { report.detail.draft_ncr.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.management_conformity &&
              <div className='col-md-6'>
                <dl>
                  <dt>Management of Conformity</dt>
                  { report.detail.management_conformity.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.evidence_ncr &&
              <div className='col-md-6'>
                <dl>
                  <dt>Evidence of NCR</dt>
                  { report.detail.evidence_ncr.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.audit_checklist &&
              <div className='col-md-6'>
                <dl>
                  <dt>Audit Checklist</dt>
                  { report.detail.audit_checklist.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }
            { report.detail && report.detail.draft_certification &&
              <div className='col-md-6'>
                <dl>
                  <dt>Draft of Certification</dt>
                  { report.detail.draft_certification.map((x, i) => (
                    <dd key={i}>
                      <strong>{x.detail.name}</strong> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a>
                    </dd>
                  ))}
                </dl>
              </div>
            }

            { report.detail && report.detail.audit_documentation_review &&
              <div className='col-md-6'>
                <dl>
                  <dt>Audit Documentation</dt>
                  <dd>{ report.detail.audit_documentation_review }</dd>
                </dl>
              </div>
            }
            { report.detail && report.detail.audit_duration_review &&
              <div className='col-md-6'>
                <dl>
                  <dt>Audit Duration</dt>
                  <dd>{ report.detail.audit_duration_review }</dd>
                </dl>
              </div>
            }
            { report.detail && report.detail.ea_code &&
              <div className='col-md-6'>
                <dl>
                  <dt>EA Code and Scope Wording Review</dt>
                  <dd>{ report.detail.ea_code }</dd>
                </dl>
              </div>
            }
            { report.detail && report.detail.technical_competence &&
              <div className='col-md-6'>
                <dl>
                  <dt>Technical Competence in Audit</dt>
                  <dd>{ report.detail.technical_competence }</dd>
                </dl>
              </div>
            }
            { report.detail && report.detail.corrective_action &&
              <div className='col-md-6'>
                <dl>
                  <dt>Corrective action resulted in last audit reviewed (Audit Stage 1 / RC / SA)</dt>
                  <dd>{ report.detail.corrective_action }</dd>
                </dl>
              </div>
            }
            { report.detail && report.detail.about_nc &&
              <div className='col-md-6'>
                <dl>
                  <dt>About nonconformities</dt>
                  <dd>{ report.detail.about_nc }</dd>
                </dl>
              </div>
            }
            { report.detail && report.detail.report_content &&
              <div className='col-md-6'>
                <dl>
                  <dt>Report content</dt>
                  <dd>{ report.detail.report_content }</dd>
                </dl>
              </div>
            }
            <div className='col-md-12'>
              <dl>
                <dt>Remarks</dt>
                <dd>{report.remarks}</dd>
              </dl>
            </div>
          </div>
        </div>
        { veto &&
          <div className='widget-content'>
            <h4>Veto Report</h4>
            <div className='row'>
              { veto.audit_documentation &&
                <div className='col-md-12'>
                  <h5>Audit Documentation Review</h5>
                  <p>{veto.audit_documentation}</p>
                </div>
              }
              { veto.audit_duration &&
                <div className='col-md-12'>
                  <h5>Audit Duration Review</h5>
                  <p>{veto.audit_duration}</p>
                </div>
              }
              { veto.ea_code_review &&
                <div className='col-md-12'>
                  <h5>EA Code Review</h5>
                  <p>{veto.ea_code_review}</p>
                </div>
              }
              { veto.technical_competence_in_audit &&
                <div className='col-md-12'>
                  <h5>Technical Competence in Audit Review</h5>
                  <p>{veto.technical_competence_in_audit}</p>
                </div>
              }
              { veto.corrective_action_resulted &&
                <div className='col-md-12'>
                  <h5>Corrective Action Resulted Review</h5>
                  <p>{veto.corrective_action_resulted}</p>
                </div>
              }
              { veto.about_conformities &&
                <div className='col-md-12'>
                  <h5>About Conformities Review</h5>
                  <p>{veto.about_conformities}</p>
                </div>
              }
              { veto.report_content &&
                <div className='col-md-12'>
                  <h5>Report Content Review</h5>
                  <p>{veto.report_content}</p>
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}
