import React from "react";

import "./Required.css";
export default function Required() {
  return (
    <span className="required">
      <span className="label label-info" title="Required">
        required
      </span>
    </span>
  );
}
