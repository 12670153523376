import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import orderBy from 'lodash/fp/orderBy'
import get from 'lodash/get'
import { change } from 'redux-form'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import BasicModal from '../../components/common/BasicModal'
import Affix from '../../components/common/Affix'

import PlanTable from '../../components/Orders/PlanTable'
import AuditEventForm from '../../components/Orders/AuditEventForm'
import EventForm from '../../components/Orders/EventForm'
import { PlanCreateForm } from '../../components/Orders/PlanForm'

import { fetchOrder } from '../../actions/orders'
import { fetchPlans, fetchPlan, createPlan, updatePlan } from '../../actions/plans'

import { auditDaysSelector } from '../../selectors/plans'
import { eventTemplate } from '../../const/eventTemplate'
import orderPlanTemplate from '../../const/orderPlanTemplate'

export function OrderPlanCreate (props) {
  const { order, fetchOrder, fetchPlan, accessToken, match, auditDays, change, plan, createPlan, updatePlan, push, user } = props
  const orderId = get(match, 'params.orderId')
  const id = get(match, 'params.id')

  const [openAuditEvent, setOpenAuditEvent] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [openEvent, setOpenEvent] = useState(false)
  const [activeEvent, setActiveEvent] = useState(null)
  const [mode, setMode] = useState(plan?.detail?.events?.length > 0 ?  'manual' : 'document')
  const [events, setEvents] = useState(get(props, 'plan.detail.events') || [])

  useEffect(() => {
    if (user.data.role.includes('Customer')) {
      push(`/orders/${orderId}/plans/${id}/review`)
    }
  }, [user, orderId, id, push])

  useEffect(() => {
    fetchOrder(orderId, accessToken)
  }, [orderId, fetchOrder, accessToken])

  useEffect(() => {
    if (id !== 'new') {
      fetchPlan(orderId, id, accessToken)
    }
    // eslint-disable-next-line 
  }, [orderId, id, accessToken])

  function getAuditor (x, i) {
    const auditor = order.schedules.find((y, j) => {
      return y.user.id === parseInt(x, 10)
    })
    return auditor.user
  }

  function handleOpenAuditEvent (e) {
    setActiveEvent(e)
    setOpenAuditEvent(x => !x)
  }

  function handleOpenEvent (e) {
    setActiveEvent(e)
    setOpenEvent(x => !x)
  }

  function handleSubmitAuditEvent (e) {
    const auditor = e.auditor || []
    const newEvent = {
      ...e,
      dateTime: e.day,
      auditor: auditor.map(getAuditor),
      type: 'auditEvent'
    }
    const reducedEvents = activeEvent === null
      ? [...events, newEvent]
      : [ ...events.slice(0, activeEvent), newEvent, ...events.slice(activeEvent + 1) ]

    setEvents(reducedEvents)
    setOpenAuditEvent(false)
    setActiveEvent(null)
  }

  function handleSubmitEvent (e) {
    const newEvent = {
      ...e,
      dateTime: e.day,
      type: 'event'
    }
    const reducedEvents = activeEvent === null
      ? [...events, newEvent]
      : [ ...events.slice(0, activeEvent), newEvent, ...events.slice(activeEvent + 1) ]

    setEvents(reducedEvents)
    setOpenEvent(false)
    setActiveEvent(null)
  }

  function handleDeleteEvent () {
    const reducedEvents = [ ...events.slice(0, activeEvent), ...events.slice(activeEvent + 1) ]

    setEvents(reducedEvents)
    setOpenAuditEvent(false)
    setOpenEvent(false)
    setActiveEvent(null)
  }

  function formatAuditForm (data) {
    const formatted = { ...data }
    if (data.auditor) {
      formatted.auditor = data.auditor.map((x) => (x.id))
    }
    return formatted
  }

  function applyTemplate (y) {
    const data = eventTemplate.find((x) => x.key === y.target.value)
    change('AuditEventForm', 'site', data.site)
    change('AuditEventForm', 'process', data.process)
    change('AuditEventForm', 'focus', data.focus)
  }

  function formatEvents (events) {
    const test = events.reduce((sum, x, i) => {
      const dayNumber = auditDays.reduce((sum2, y, j) => x.day === y.label ? j : sum2, null)

      if (sum[dayNumber]) {
        sum[dayNumber].event = orderBy(['time'])(['asc'])([ ...sum[dayNumber].event, { ...x, key: i } ])
      } else {
        sum[dayNumber] = {
          day: dayNumber,
          title: 'Day ' + (parseInt(dayNumber, 10) + 1) + ' : ' + auditDays[dayNumber].label,
          event: [{ ...x, key: i }]
        }
      }
      return sum
    }, [])
    return test
  }

  function handleSubmitPlan (e) {
    setSubmitting(true)
    const isLeadAuditor = order.users.find(x => x.user.id === user.id && x.role === 'Lead Auditor');
    const data = {
      status: isLeadAuditor ? 'Published' : 'Draft',
      detail: {
        ...e.detail,
        events: [...events]
      },
      remarks: e.remarks,
      order_id: orderId
    }

    if (id !== 'new') {
      return updatePlan({ ...data, id }, accessToken)
        .then(() => {
          setSubmitting(false)
          push(`/orders/${orderId}`)
        })
    } else if (id === 'new') {
      return createPlan(data, accessToken)
        .then(() => {
          setSubmitting(false)
          push(`/orders/${orderId}`)
        })
    }
  }

  function toggleMode (mode) {
    setMode(mode)
    if (events.length === 0 && mode === 'manual') {
      const day1 = order.schedules
        .sort((a, b) => a.start_date > b.start_date)[0]
        .start_date
      const day1Format = moment(day1).format('dddd, DD-MM-YYYY')
      const defaultTemplate = orderPlanTemplate.map(x => ({
        ...x,
        day: day1Format,
        dateTime: day1Format
      }))
      setEvents(defaultTemplate)
    }
  }

  if (!order) {
    return <p>loading</p>
  }


  return (
    <Fragment>
      <div className='content'>
        <Helmet>
          { id === 'new'
            ? <title>{`Order ${order.number} - Create plan`}</title>
            : <title>{`Order ${order.number} - Update plan`}</title>
          }
        </Helmet>
        <div className='main-header'>
          { id === 'new' ? <h2>Create Plan</h2> : <h2>Update Plan</h2>}
          <em>For Order <Link to={`/orders/${order.id}`}>{order.number}</Link></em>
        </div>
        { mode === 'document'
          ? <div className='row'>
            <div className='col-md-10'>
              <Fragment>
                <PlanCreateForm
                  order={order}
                  onSubmit={handleSubmitPlan}
                  initialValues={plan || {}}
                  fileAPI={{
                    url: process.env.REACT_APP_BASE_API,
                    accessToken: accessToken
                  }}
                />
              </Fragment>
            </div>
            <div className='col-md-2'>
              <p>
                <button className='btn btn-block btn-sm btn-default' onClick={() => toggleMode('manual')}>Create custom plan</button>
              </p>
            </div>
          </div>
          : <div className='row'>
            <div className='col-md-10'>
              { order && auditDays
                ? <PlanTable
                  events={formatEvents(events)}
                  onOpenAuditEvent={handleOpenAuditEvent}
                  onOpenEvent={handleOpenEvent}
                  editable
                />
                : <div>loading order</div>
              }
            </div>
            <div className='col-md-2'>
              <Affix>
                <div className='widget'>
                  <div className='widget-content'>
                    <button disabled={isSubmitting} className='btn btn-sm btn-block btn-default' onClick={() => toggleMode('document')}>Back</button>
                    <hr />
                    { order
                      ? <div>
                        <button disabled={isSubmitting} onClick={() => handleOpenAuditEvent(null)} className='btn btn-sm btn-default btn-block'>Add Audit Event</button>
                        <button disabled={isSubmitting} onClick={() => handleOpenEvent(null)} className='btn btn-sm btn-default btn-block'>Add Non Audit Event</button>
                        <hr />
                        <button disabled={isSubmitting} onClick={handleSubmitPlan} className='btn btn-link btn-block'>Save</button>
                      </div>
                      : <div>loading order</div>
                    }
                  </div>
                </div>
              </Affix>
            </div>
          </div>
        }
      </div>
      <BasicModal open={openAuditEvent} onClose={() => handleOpenAuditEvent(null)} title='Audit Event'>
        <AuditEventForm
          onSubmit={handleSubmitAuditEvent}
          onDelete={handleDeleteEvent}
          initialValues={activeEvent != null ? formatAuditForm(events[activeEvent]) : {
            day: auditDays[0].label,
            time: '07:00'
          }}
          dayOptions={auditDays}
          schedules={order.schedules}
          deletable={activeEvent !== null}
          template={eventTemplate}
          useTemplate={applyTemplate}
        />
      </BasicModal>
      <BasicModal open={openEvent} onClose={() => handleOpenEvent(null)} title='Non Audit Event'>
        <EventForm
          onSubmit={handleSubmitEvent}
          onDelete={handleDeleteEvent}
          initialValues={activeEvent != null ? events[activeEvent] : {
            day: auditDays[0].label,
            time: '07:00'
          }}
          dayOptions={auditDays}
          deletable={activeEvent !== null}
        />
      </BasicModal>
    </Fragment>
  )
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    plans: state.plans.planList.data,
    plan: state.plans.plan.data,
    auditDays: auditDaysSelector(state),
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    fetchPlans: (id, accessToken) => dispatch(fetchPlans(id, accessToken)),
    fetchPlan: (orderId, id, accessToken) => dispatch(fetchPlan(orderId, id, accessToken)),
    createPlan: (data, accessToken, target) => dispatch(createPlan(data, accessToken, target)),
    updatePlan: (data, accessToken, target) => dispatch(updatePlan(data, accessToken, target)),
    change: (a, b, c) => dispatch(change(a, b, c)),
    push: to => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderPlanCreate)
