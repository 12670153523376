import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getNcLabel } from '../../const/orders'

export default class AuditReportTable extends React.Component {
  render () {
    const { ncs } = this.props

    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>NC No.</th>
              <th>Audit Stage</th>
              <th>NC Category</th>
              <th>NC Description</th>
              <th>Related Clause</th>
              <th>Follow-up Due Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ncs
              .sort((a, b) => a.id < b.id)
              .map((x, i) => {
                return (
                  <tr key={i}>
                    <td><Link to={`/orders/${x.order.id}/ncs/${x.id}`}>NC.{x.order.number}.{x.counter}</Link></td>
                    <td><Link to={`/orders/${x.order.id}`}>{x.order.status}</Link></td>
                    <td>{x.kind}</td>
                    <td>{x.description}</td>
                    <td>{x.clausul}</td>
                    <td>{x.due_date ? moment.unix(x.due_date).format('DD MMMM YYYY') : '-'}</td>
                    <td>{getNcLabel(x.status)}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}
