import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import { getPlanLabel } from '../../const/orders'
import { getPhaseInitial } from '../../selectors/order'

export default class OrderPlan extends React.Component {
  render () {
    const { order, plans, accessToken } = this.props

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Audit Plan</h3>
          {
          // <div className='widget-header-toolbar'>
          //   <Link to={`/orders/${order.id}/plans/new`} className='btn btn-link btn-xs'><i className='fa fa-plus' />New</Link>{' '}
          // </div>
          }
        </div>
        <div className=''>
          <table className='table' style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <th>Audit Plan No.</th>
                <th>Download</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { plans.length === 0 &&
                <tr>
                  <td colSpan={3}>
                    <p>Not available</p>
                    {
                      // ['Super Admin', 'Audit Plan Operator'].indexOf(user.data.role) > -1
                      // ? <Link to={`/orders/${order.id}/plans/new`} className='btn btn-default'><i className='fa fa-plus' />Create Audit Plan</Link>
                      // : <p>Not available</p>
                    }
                  </td>
                </tr>
              }
              { plans.map((x, y) => {
                const planDoc = get(x, 'detail.plan_document[0]')
                return (
                  <tr key={y}>
                    <td><Link to={`/orders/${order.id}/plans/${x.id}`}>AP.OD.{x.order.quotation.sales.code}.{getPhaseInitial(x.order.phase)}.{x.order.quotation.counter}</Link></td>
                    <td>
                      { planDoc 
                        ? <a href={`${process.env.REACT_APP_BASE_API}/download/${planDoc.id}?token=${accessToken}`} className='btn btn-link btn-xs btn-400'><i className='fa fa-download' /> {planDoc.detail.name}</a>
                        : <span>-</span>
                      }
                    </td>
                    <td>{getPlanLabel(x.status)}</td>
                  </tr>
                )
              })}
              <tr />
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
