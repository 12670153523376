import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getStatusColor } from '../../const/questionnaires'
import { groupByStandard } from '../../selectors/standards'

function Label ({ status }) {
  return (
    <span className={`label label-${getStatusColor(status)}`}>{status.toUpperCase()}</span>
  )
}

export default class QuestionnaireTable extends React.Component {
  getLink (x, me) {
    const role = me.data.role
    let to
    switch (x.status) {
      case 'Published':
        to = `/questionnaires/${x.id}/assignment`
        break
      case 'Assigned':
        to = `/questionnaires/${x.id}`
        break
      default:
        to = `/questionnaires/${x.id}`
        break
    }
    if (role === 'Customer') {
      to = `/questionnaires/${x.id}`
    }
    return to
  }

  getServices (x) {
    const services = []
    if (x.indexOf('certification') > -1) { services.push('System Certification') }
    if (x.indexOf('audit') > -1) { services.push('General Audit') }
    if (x.indexOf('training') > -1) { services.push('Training') }
    return services.join(' - ')
  }

  allowQuotation (q, me, allowQuotationPreview) {
    // only allow admin and owner
    if (!(['Super Admin'].indexOf(me.data.role) > -1 || q.sales.id === me.id)) {
      return false
    }
    // allow preview on draft
    if (q.status === 'Draft' && allowQuotationPreview) {
      return true
    }
    // allow on approved
    if (q.status === 'Approved') {
      return true
    }
    // unallow for rest
    return false
  }

  render () {
    const {
      questionnaires,
      me
      // allowQuotationPreview
    } = this.props
    return (
      <div className='table-responsive questionnaire-table'>
        <table className='table colored-header datatable project-list'>
          <thead>
            <tr>
              <th>Questionaire No.</th>
              <th>Create Date</th>
              <th>Customer Name</th>
              <th>Standard</th>
              <th>Sales In Charge</th>
              <th>Reviewer</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {questionnaires.map((x) => (
              <tr className={`questionnaire-${x.id}`} key={x.id}>
                <td><Link to={this.getLink(x, me)}>{x.number}</Link></td>
                <td>{moment.unix(x.created_at).format('D MMMM YYYY')}</td>
                <td>{x.customer.name}</td>
                <td>{x.scopes.reduce(groupByStandard, []).map(x => x.name).join(' - ')}</td>
                <th>{x.sales.name}</th>
                <th>{x.reviewer && x.reviewer.name}</th>
                <td><Label status={x.status} /></td>
                <td>
                  {
                    // this.allowQuotation(x, me, allowQuotationPreview) &&
                    // <Link to={`/quotations/new/${x.id}`} className='btn btn-xs btn-primary btn-create-quotation'>Create Quotation</Link>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
