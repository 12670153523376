import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, OptionsField } from '../common/FormFields'
// import SelectOrInputField from '../common/SelectOrInputField'
import FileField from '../common/FileField'

// import entityType from '../../const/entityType'
import businessType from '../../const/businessType'
import RepeatTextField from '../common/RepeatTextField'

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  // } else if (values.name.length > 50) {
  //   errors.name = 'Must be 50 characters or less'
  }
  if (values.detail && values.detail.type === '') {
    errors.detail = {
      type: 'Required'
    }
  }
  return errors
}

class CustomerForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <form className='form-horizontal customer-form' onSubmit={handleSubmit}>
        {(this.props.basic || (!this.props.basic && !this.props.other)) && (
          <fieldset>
            {!this.props.basic && !this.props.other && (
              <legend>Customer Detail</legend>
            )}
            <Field
              name='name'
              type='text'
              component={TextField}
              label='Customer Legal Name'
              required
            />
            {
              // <Field name='detail.type' component={SelectOrInputField} label='Entity Type' options={entityType} required placeholder='Please insert here!' />
            }
            <Field
              name='business'
              type='select'
              component={OptionsField}
              label='Business'
              options={businessType}
              required
            />
            <Field
              name='npwp'
              type='text'
              component={TextField}
              label='NPWP'
              required
            />
            <Field
              name='email'
              type='text'
              component={TextField}
              label='Email'
              required
            />
            <Field
              name='website'
              type='text'
              component={TextField}
              label='Website'
            />
            <Field
              name='legal_document'
              component={FileField}
              label='Legal Document'
              api={{...this.props.fileAPI, path: 'customer'}}
            />
            <Field
              name='detail.unit'
              type='text'
              component={RepeatTextField}
              label='Unit/Department'
            />
          </fieldset>
        )}

        {(this.props.other || (!this.props.basic && !this.props.other)) && (
          <fieldset>
            {!this.props.basic && !this.props.other && (
              <legend>Bank Detail</legend>
            )}
            <Field
              name='bank_name'
              type='text'
              component={TextField}
              label='Bank Name'
            />
            <Field
              name='bank_branch'
              type='text'
              component={TextField}
              label='Bank Branch'
            />
            <Field
              name='account_name'
              type='text'
              component={TextField}
              label='Account Name'
            />
            <Field
              name='account_number'
              type='text'
              component={TextField}
              label='Account Number'
            />
          </fieldset>
        )}

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-9'>
            <button
              disabled={submitting}
              type='submit'
              className='btn btn-primary btn-block btn-submit'
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default CustomerForm

const BaseCustomerCreateForm = props => (
  <CustomerForm {...props} mode='create' />
)

export const CustomerCreateForm = reduxForm({
  enableReinitialize: true,
  form: 'customerCreate',
  validate
})(BaseCustomerCreateForm)

const BaseCustomerUpdateForm = props => (
  <CustomerForm {...props} mode='update' />
)

export const CustomerUpdateForm = reduxForm({
  enableReinitialize: true,
  form: 'customerUpdate',
  validate
})(BaseCustomerUpdateForm)
