import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'

import Required from './Required'

import { asOptions } from '../../const/helper'

export const TextField = ({ hidden, input, help, disabled, label, type, required, meta: { touched, error, warning }, stack }) => (
  <div className={`form-group ${input.name.split('.').join('_')} ${hidden ? 'hidden' : ''}`} >
    <label htmlFor={label} className={`${!stack ? 'col-sm-3' : ''} control-label`}>{label} {required && <Required />}</label>
    <div className={!stack ? 'col-sm-9' : ''}>
      <input {...input} disabled={disabled} type={type} className='form-control' id={label} placeholder={label} />
      { help && <p className='help-text'>{help}</p> }
      {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
    </div>
  </div>
)

export const TextareaField = ({ disabled, input, label, help, required, meta: { touched, error, warning }, stack }) => {
  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label htmlFor={label} className={`${!stack ? 'col-sm-3' : ''} control-label`}>{label} {required && <Required />}</label>
      <div className={!stack ? 'col-sm-9' : ''}>
        <textarea disabled={disabled} {...input} className='form-control' id={label} rows='5' cols='30' placeholder={label} />
        { help && <p className='help-text'>{help}</p> }
        {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
      </div>
    </div>
  )
}

export const AnnexTextareaField = ({ input, label, help, labelAlt, helpAlt, required, meta: { touched, error, warning }, stack }) => (
  <div className={`form-group ${input.name.split('.').join('_')}`}>
    <div htmlFor={label} className='col-sm-3 control-label'>
      <div dangerouslySetInnerHTML={{ __html: label }} /> {required && <Required />}
      <div dangerouslySetInnerHTML={{ __html: labelAlt }} /> {required && <Required />}
    </div>
    <div className='col-sm-9'>
      <textarea {...input} className='form-control' id={label} rows='5' cols='30' />
      { help && <div className='help-text' dangerouslySetInnerHTML={{ __html: help }} />}
      { help && <div className='help-text' dangerouslySetInnerHTML={{ __html: helpAlt }} />}
      {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
    </div>
  </div>
)

export const AnnexMatrixField = ({ disabled, options, input, label, help, labelAlt, helpAlt, required, meta: { touched, error, warning }, stack }) => {
  const [value, setValue] = useState(input?.value || options)
  useEffect(() => {
    if (input?.value) {
      setValue(input.value)
    }
  }, [input.value])

  function toGrid(x) {
    return x.split('\n').map((x) => x.split(';'));
  }
  function toString(arr) {
    return arr.map((x) => x.join(';')).join("\n");
  }

  function handleChange(x, xx, val){
    const newVal = JSON.parse(JSON.stringify(grid))
    newVal[x][xx] = val;
    const newValString = toString(newVal);
    setValue(newValString);
    input.onChange(newValString);

  }

  const grid = toGrid(value);
  return (
  <div className={`form-group ${input.name.split('.').join('_')}`}>
    <div htmlFor={label} className='col-sm-3 control-label'>
      <div dangerouslySetInnerHTML={{ __html: label }} /> {required && <Required />}
      <div dangerouslySetInnerHTML={{ __html: labelAlt }} /> {required && <Required />}
    </div>
    <div className='col-sm-9'>
      <table className="table">
        <tbody> {grid.map((x, i) => (
          <tr key={i}>
            {x.map((xx, ii) => (
              <td key={ii} style={{padding: 0}}>
                <input disabled={disabled} className='form-control' id={label} value={xx} onChange={(ev) => handleChange(i, ii, ev.target.value)} />
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
      { help && <div className='help-text' dangerouslySetInnerHTML={{ __html: help }} />}
      { help && <div className='help-text' dangerouslySetInnerHTML={{ __html: helpAlt }} />}
      {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
    </div>
  </div>
)}

export class OptionsField extends React.Component {
  componentWillMount () {
    this.setState({
      value: (this.props.input.value || ''),
      select: true,
      multiCheckbox: []
    })
  }

  onChangeToInput (ev) {
    this.setState({
      value: ev.target.value,
      select: (ev.target.value !== 'Other' ? this.state.select : !this.state.select)
    })
    this.props.input.onChange(ev.target.value)
  }

  onCheckBoxChange (ev) {
    const checked = ev.target.checked
    const option = ev.target.value
    let current = this.state.multiCheckbox
    if (checked && current.indexOf(option) === -1) {
      current = [ ...current, option ]
    } else {
      current.splice(current.indexOf(option), 1)
    }
    this.props.input.onChange(current)
    this.setState({
      multiCheckbox: current
    })
  }

  showSelect () {
    this.setState({
      value: this.props.options[0].value,
      select: true
    })
    this.props.input.onChange(this.props.options[0].value)
  }

  onRadioChange (ev) {
    // const checked = ev.target.checked
    const option = ev.target.value
    // let current = this.state.multiCheckbox.map(x => x + '')
    // if (checked) {
    //   current = [ ...current, option ]
    // } else {
    //   current.splice(current.indexOf(option), 1)
    // }
    this.props.input.onChange(option)
    // this.setState({
    //   multiCheckbox: current
    // })
  }

  getInput () {
    let { options, input, label, disabled } = this.props

    switch (this.props.type) {
      case 'select' :
        return (
          <select disabled={disabled} {...this.props.input} className='form-control'>
            { this.props.options.map((x, y) => {
              let option
              if (typeof x === 'object') {
                option = <option value={x.value} key={y} >{x.label}</option>
              } else {
                option = <option value={x} key={y} >{x}</option>
              }
              return option
            })}
          </select>
        )
      case 'selectOrInput' :
        if (!this.state.value || options.indexOf(this.state.value) > -1) {
          return (
            <select disabled={disabled} {...input} className='form-control' value={this.state.value} onChange={this.onChangeToInput.bind(this)} >
              { options.map((x, y) => {
                let option
                if (typeof x === 'object') {
                  option = <option value={x.value} key={x.value} >{x.label}</option>
                } else {
                  option = <option value={x} key={x} >{x}</option>
                }
                return option
              })}
              <option value={'Other'}>Other</option>
            </select>
          )
        } else {
          return (
            <div>
              <div className='input-group'>
                <input disabled={disabled} {...input} type='text' className='form-control' id={label} placeholder={label} />
                <span className='input-group-btn'>
                  <button onClick={this.showSelect.bind(this)}className='btn btn-default' type='button'><i className='fa fa-bars' /></button>
                </span>
              </div>
            </div>
          )
        }
      case 'checkbox' :
        return (
          <div>
            <div className='checkbox'>
              <label>
                <input disabled={disabled} {...input} type='checkbox' /> { this.props.label }
              </label>
            </div>
          </div>
        )
      case 'radio' :
        return (
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string') {
                result = <div className='radio' key={y}>
                  <label><input
                    disabled={disabled}
                    type='radio'
                    name={input.name}
                    value={x}
                    onChange={this.onRadioChange.bind(this)}
                    defaultChecked={input.value === x}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                // const values = input.value ? input.value.map((x) => x.value) : []
                result = <div className='radio' key={y}>
                  <label><input
                    disabled={disabled}
                    type='radio'
                    name={input.name}
                    value={x.value}
                    onChange={this.onRadioChange.bind(this)}
                    defaultChecked={input.value === x.value}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
        )
      case 'multiCheckbox' :
        return (
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string') {
                result = <div className='checkbox' key={y}>
                  <label><input
                    disabled={disabled}
                    type='checkbox'
                    name={y}
                    value={x}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x) > -1}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                // const values = input.value ? input.value.map((x) => x.value) : []
                result = <div className='checkbox' key={y}>
                  <label><input
                    disabled={disabled}
                    type='checkbox'
                    name={x.value}
                    value={x.value}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x.value) > -1}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
        )
      default :
        break
    }
  }

  render () {
    const { stack } = this.props
    return (
      <div className={`form-group ${this.props.input.name.split('.').join('_')}`}>
        <label htmlFor={this.props.label} className={`${!stack ? 'col-sm-3' : ''} control-label`}>
          { this.props.type !== 'checkbox' &&
            <span>{this.props.label}</span>
          }
          {this.props.required && <Required />}
        </label>
        <div className={!stack ? 'col-sm-9' : ''}>
          { this.getInput() }
          { this.props.help && <p className='help-text'>{this.props.help}</p> }
          {this.props.meta.touched && ((this.props.meta.error && <div className='alert alert-danger'>{this.props.meta.error}</div>))}
        </div>
      </div>
    )
  }
}

export class AnnexOptionsField extends React.Component {
  componentWillMount () {
    this.setState({
      value: (this.props.input.value || ''),
      select: true,
      multiCheckbox: []
    })
  }

  onChangeToInput (ev) {
    this.setState({
      value: ev.target.value,
      select: (ev.target.value !== 'Other' ? this.state.select : !this.state.select)
    })
    this.props.input.onChange(ev.target.value)
  }

  onCheckBoxChange (ev) {
    const checked = ev.target.checked
    const option = ev.target.value
    let current = this.state.multiCheckbox
    if (checked && current.indexOf(option) === -1) {
      current = [ ...current, option ]
    } else {
      current.splice(current.indexOf(option), 1)
    }
    this.props.input.onChange(current)
    this.setState({
      multiCheckbox: current
    })
  }

  showSelect () {
    this.setState({
      value: this.props.options[0].value,
      select: true
    })
    this.props.input.onChange(this.props.options[0].value)
  }

  onRadioChange (ev) {
    // const checked = ev.target.checked
    const option = ev.target.value
    // let current = this.state.multiCheckbox.map(x => x + '')
    // if (checked) {
    //   current = [ ...current, option ]
    // } else {
    //   current.splice(current.indexOf(option), 1)
    // }
    this.props.input.onChange(option)
    // this.setState({
    //   multiCheckbox: current
    // })
  }

  getInput () {
    let { options, input, label } = this.props

    switch (this.props.type) {
      case 'select' :
        return (
          <select {...this.props.input} className='form-control'>
            { this.props.options.map((x, y) => {
              let option
              if (typeof x === 'object') {
                option = <option value={x.value} key={y} >{x.label}</option>
              } else {
                option = <option value={x} key={y} >{x}</option>
              }
              return option
            })}
          </select>
        )
      case 'selectOrInput' :
        if (!this.state.value || options.indexOf(this.state.value) > -1) {
          return (
            <select {...input} className='form-control' value={this.state.value} onChange={this.onChangeToInput.bind(this)} >
              { options.map((x, y) => {
                let option
                if (typeof x === 'object') {
                  option = <option value={x.value} key={x.value} >{x.label}</option>
                } else {
                  option = <option value={x} key={x} >{x}</option>
                }
                return option
              })}
              <option value={false}>Other</option>
            </select>
          )
        } else {
          return (
            <div>
              <div className='input-group'>
                <input {...input} type='text' className='form-control' id={label} placeholder={label} />
                <span className='input-group-btn'>
                  <button onClick={this.showSelect.bind(this)}className='btn btn-default' type='button'><i className='fa fa-bars' /></button>
                </span>
              </div>
            </div>
          )
        }
      case 'checkbox' :
        return (
          <div>
            <div className='checkbox'>
              <label>
                <input {...input} type='checkbox' /> { this.props.label }
              </label>
            </div>
          </div>
        )
      case 'radio' :
        return (
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string') {
                result = <div className='radio' key={y}>
                  <label><input
                    type='radio'
                    name={input.name}
                    value={x}
                    onChange={this.onRadioChange.bind(this)}
                    defaultChecked={input.value === x}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                // const values = input.value ? input.value.map((x) => x.value) : []
                result = <div className='radio' key={y}>
                  <label><input
                    type='radio'
                    name={input.name}
                    value={x.value}
                    onChange={this.onRadioChange.bind(this)}
                    defaultChecked={input.value === x.value}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
        )
      case 'multiCheckbox' :
        return (
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string') {
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={y}
                    value={x}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x) > -1}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                // const values = input.value ? input.value.map((x) => x.value) : []
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={x.value}
                    value={x.value}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x.value) > -1}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
        )
      default :
        break
    }
  }

  render () {
    return (
      <div className={`form-group ${this.props.input.name.split('.').join('_')}`}>
        { this.props.type !== 'checkbox' &&
          <div htmlFor={this.props.label} className='col-sm-3 control-label'>
            <div dangerouslySetInnerHTML={{ __html: this.props.label }} /> {this.props.required && <Required />}
            <div dangerouslySetInnerHTML={{ __html: this.props.labelAlt }} /> {this.props.required && <Required />}
          </div>
        }
        <div className='col-sm-9'>
          { this.getInput() }
          { this.props.help && <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.help }} />}
          { this.props.help && <div className='help-text' dangerouslySetInnerHTML={{ __html: this.props.helpAlt }} />}
          {this.props.meta.touched && ((this.props.meta.error && <div className='alert alert-danger'>{this.props.meta.error}</div>))}
        </div>
      </div>
    )
  }
}

export class MultiCheckboxField extends React.Component {
  componentWillMount () {
    this.setState({
      multiCheckbox: this.props.input.value || []
    })
  }

  onCheckBoxChange (ev) {
    const checked = ev.target.checked
    const option = ev.target.value
    let current = this.state.multiCheckbox.map(x => x + '')
    if (checked) {
      current = [ ...current, option ]
    } else {
      current.splice(current.indexOf(option), 1)
    }
    this.props.input.onChange(current)
    this.setState({
      multiCheckbox: current
    })
  }

  render () {
    const { disabled, help, label, options, input, required, meta: { touched, warning, error }, stack } = this.props
    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <label htmlFor={label} className={`${!stack ? 'col-sm-3' : ''} control-label`}>{label} {required && <Required />}</label>
        <div className={!stack ? 'col-sm-9' : ''}>
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string' || typeof x === 'number') {
                result = <div className='checkbox' key={y}>
                  <label><input
                    disabled={disabled}
                    type='checkbox'
                    name={y}
                    value={x}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x) > -1}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                const values = input.value ? input.value : []
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={x.value}
                    value={x.value}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={values.indexOf(x.value) > -1}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
          { help && <p className='help-text'>{help}</p> }
          {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        </div>
      </div>
    )
  }
}

export class AnnexMultiCheckboxField extends React.Component {
  componentWillMount () {
    this.setState({
      multiCheckbox: this.props.input.value || []
    })
  }

  onCheckBoxChange (ev) {
    const checked = ev.target.checked
    const option = ev.target.value
    let current = this.state.multiCheckbox.map(x => x + '')
    if (checked) {
      current = [ ...current, option ]
    } else {
      current.splice(current.indexOf(option), 1)
    }
    this.props.input.onChange(current)
    this.setState({
      multiCheckbox: current
    })
  }

  render () {
    const { help, label, options, input, helpAlt, meta: { touched, warning, error } } = this.props
    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <div className='col-sm-3'>
          <div htmlFor={this.props.label} className='control-label'><div dangerouslySetInnerHTML={{ __html: this.props.label }} /> {this.props.required && <Required />}</div>
          <div htmlFor={this.props.label} className='control-label'><div dangerouslySetInnerHTML={{ __html: this.props.labelAlt }} /> {this.props.required && <Required />}</div>
        </div>
        <div className='col-sm-9'>
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string' || typeof x === 'number') {
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={y}
                    value={x}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x) > -1}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                const values = input.value ? input.value : []
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={x.value}
                    value={x.value}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={values.indexOf(x.value) > -1}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
          { help && <div className='help-text' dangerouslySetInnerHTML={{ __html: help }} />}
          { help && <div className='help-text' dangerouslySetInnerHTML={{ __html: helpAlt }} />}
          {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        </div>
      </div>
    )
  }
}

export class AutoCompleteField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.input.value,
      showInput: false,
      showModal: false
    }
  }

  onChange (value) {
    this.setState({
      value: value
    })
    this.props.input.onChange(value)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props) {
      this.setState({
        value: nextProps.input.value
      })
    }
  }

  getOptions (input, callback) {
    const { api } = this.props
    if (api) {
      return axios({
        method: 'get',
        url: api.url.indexOf('?') > -1 ? api.url + '&q=' + input : api.url + '?q=' + input,
        headers: {
          'Authorization': `Bearer ${api.accessToken}`
        }
      }).then((x) => {
        return {
          options: asOptions(x.data, api.label)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve([])
      })
    }
  }

  render () {
    const { disabled, label, input, required, meta: { touched, error, warning }, stack } = this.props
    return (
      <div className={`form-group ${input.name.split('.').join('_')} ${input.name.split('.').join('_').split('[').join('_').split(']').join('')}`}>
        <label htmlFor={label} className={`${!stack ? 'col-sm-3' : ''} control-label`}>
          <span>{label}</span>
          {required && <Required />}
        </label>
        <div className={!stack ? 'col-sm-9' : ''}>
          { this.state.showInput === false
            ? <Select.Async
              disabled={disabled}
              value={this.state.value}
              onChange={this.onChange.bind(this)}
              loadOptions={this.getOptions.bind(this)}
            />
            : <input {...input} type='text' className='form-control' id={label} placeholder={label} />
          }
          { this.props.openNew &&
            <div>
              <br />
              <button className='btn btn-default btn-sm btn-add-new' type='button' onClick={this.props.openNew}>Add New</button>
            </div>
          }
          {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        </div>
      </div>
    )
  }
}
