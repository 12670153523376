import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { push } from 'react-router-redux'

import Inside from './Inside'
import UserList from './Users/UserList'
import UserNew from './Users/UserNew'
import UserUpdate from './Users/UserUpdate'
import UserUpdateSpec from './Users/UserUpdateSpec'
import AuditorMatrix from './Users/AuditorMatrix'

function mapStateToProps (state) {
  return {
    me: state.auth.user
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    push: (to) => dispatch(push(to)),
  }
}

export class Users extends React.Component {
  render () {
    const { me, location } = this.props;

    if(me?.data?.role && me.data.role.includes('Customer') && location?.pathname !== '/users/me') {
      this.props.push(`/`)
    }

    return (
      <Inside>
        <Switch>
          <Route path='/users/auditor-experts' component={AuditorMatrix} />
          <Route path='/users/new' component={UserNew} />
          <Route path='/users/:id/spec' component={UserUpdateSpec} />
          <Route path='/users/:id' component={UserUpdate} />
          <Route exact path='/users' component={UserList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)
