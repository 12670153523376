const questionnaireStatuses = [
  {
    label: 'Draft',
    color: 'primary',
  },
  {
    label: 'Created',
    color: 'primary',
  },
  {
    label: 'Assigned',
    color: 'primary',
  },
  {
    label: 'Approved',
    color: 'primary',
  },
  {
    label: 'Need to Revise',
    color: 'primary',
  },
  {
    label: 'Not Approved',
    color: 'primary',
  },
  {
    label: 'Quoted',
    color: 'primary',
  },
  {
    label: 'Ordered',
    color: 'primary',
  },
  {
    label: 'Replaced',
    color: 'primary',
  },
  {
    label: 'Revision Draft',
    color: 'primary',
  },
  {
    label: 'Revision Created',
    color: 'primary',
  },
  {
    label: 'Revision Assigned',
    color: 'primary',
  },
  {
    label: 'Revision Approved',
    color: 'primary',
  },
  {
    label: 'Revision Need to Revise',
    color: 'primary',
  },
  {
    label: 'Revision Not Approved',
    color: 'primary',
  },
]

export default questionnaireStatuses
