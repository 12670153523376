export default function questionnaireAction (q, props) {
  const actions = [
    {
      status: 'Draft',
      todo: 'Complete questionnaire draft',
      primary: {
        role: ['Super Admin', 'Sales', 'Customer'],
        text: 'Complete questionnaire draft',
        action: props.toDraft,
        alternative: 'Please wait for questionnaire draft by sales'
      },
      secondary: [{
        pre: props.allowDownload,
        role: ['Super Admin', 'Sales'],
        text: 'Download Questionnaire',
        action: props.download
      }, {
        role: ['Super Admin', 'Sales'],
        text: 'Invite User',
        action: props.sendInvite
      }]
    },
    {
      status: 'Revision Draft',
      todo: 'Complete revision draft',
      primary: {
        role: ['Super Admin', 'Sales', 'Customer'],
        text: 'Complete revision draft',
        action: props.toDraft,
        alternative: 'Please wait for questionnaire draft by sales'
      },
      secondary: [{
        pre: props.allowDownload,
        role: ['Super Admin', 'Sales'],
        text: 'Download Questionnaire',
        action: props.download
      }]
    },
    {
      status: 'Created',
      todo: 'Review questionnaire',
      primary: {
        role: ['Super Admin', 'Application Reviewer Coordinator'],
        text: 'Review questionnaire',
        action: props.toReview,
        alternative: 'Please wait for questionnaire review by application review manager'
      },
      secondary: [
        {
          pre: props.allowTemporaryQuotation,
          role: ['Super Admin', 'Sales'],
          text: 'Create Temporary Quotation',
          action: props.toQuotation
        },
        {
          pre: props.haveQuotation,
          role: ['Super Admin', 'Sales'],
          text: 'Go to Quotation',
          action: props.toQuotation
        },
        {
          pre: props.allowDownload,
          role: ['Super Admin', 'Sales'],
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Revision Created',
      todo: 'Review questionnaire revision',
      primary: {
        role: ['Super Admin', 'Application Reviewer Coordinator'],
        text: 'Review questionnaire revision',
        action: props.toReview,
        alternative: 'Please wait for questionnaire review by application review manager'
      },
      secondary: [
        // {
        //   pre: props.allowTemporaryQuotation,
        //   role: ['Super Admin', 'Sales'],
        //   text: 'Create Temporary Quotation',
        //   action: props.toQuotation
        // },
        {
          pre: props.haveQuotation,
          role: ['Super Admin', 'Sales'],
          text: 'Go to Quotation',
          action: props.toQuotation
        },
        {
          pre: props.allowDownload,
          role: ['Super Admin', 'Sales'],
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Assigned',
      todo: 'Review questionnaire',
      primary: {
        role: ['Super Admin', 'Application Reviewer Coordinator'],
        users: props.userReviewer,
        text: 'Review questionnaire',
        action: props.toReview,
        alternative: 'Please wait for questionnaire review'
      },
      secondary: [
        {
          pre: props.allowTemporaryQuotation,
          role: ['Super Admin', 'Sales'],
          text: 'Create Temporary Quotation',
          action: props.toQuotation
        },
        {
          pre: props.allowTemporaryQuotation,
          role: ['Super Admin', 'Sales'],
          text: 'Create Temporary Quotation',
          action: props.toQuotation
        },
        {
          pre: props.allowDownload,
          role: ['Super Admin', 'Sales'],
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Revision Assigned',
      todo: 'Review questionnaire revision',
      primary: {
        role: ['Super Admin', 'Application Reviewer Coordinator'],
        users: props.userReviewer,
        text: 'Review questionnaire revision',
        action: props.toReview,
        alternative: 'Please wait for questionnaire revision review'
      },
      secondary: [
        {
          pre: props.allowDownload,
          role: ['Super Admin', 'Sales'],
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Approved',
      todo: 'Publish quotation',
      primary: {
        role: ['Super Admin', 'Sales'],
        text: 'Publish quotation',
        action: props.toQuotation,
        alternative: 'Please wait for quotation by sales'
      },
      secondary: [
        {
          role: ['Super Admin', 'Sales', 'Application Reviewer Coordinator', 'Sales Manager', 'Board of Director', 'Customer'],
          users: props.userReviewer,
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Revision Approved',
      todo: 'Publish quotation',
      primary: {
        role: ['Super Admin', 'Sales'],
        text: 'Publish quotation',
        action: props.toQuotation,
        alternative: 'Please wait for quotation by sales'
      },
      secondary: [
        {
          role: ['Super Admin', 'Sales', 'Application Reviewer Coordinator', 'Sales Manager', 'Board of Director', 'Customer'],
          users: props.userReviewer,
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Need to Revise',
      todo: 'Revise questionnaire',
      primary: {
        role: ['Super Admin', 'Sales'],
        text: 'Revise questionnaire',
        action: props.toDraft,
        alternative: 'Please wait for questionnaire revision by sales'
      }
    },
    {
      status: 'Revision Need to Revise',
      todo: 'Revise questionnaire revision',
      primary: {
        role: ['Super Admin', 'Sales'],
        text: 'Revise questionnaire revision',
        action: props.toDraft,
        alternative: 'Please wait for questionnaire revision by sales'
      }
    },
    {
      status: 'Not Approved'
      // todo: 'Complete questionnaire draft',
      // primary: {
      //   role: ['Super Admin', 'Sales'],
      //   text: 'Complete questionnaire draft',
      //   action: props.onCompleteQuestionnaire,
      //   alternative: 'Please wait for questionnaire draft by sales'
      // }
    },
    {
      status: 'Quoted',
      todo: 'Quotation created',
      primary: {
        role: ['Super Admin', 'Sales'],
        pre: props.quotationApproved,
        text: 'Go to Quotation',
        action: props.toQuotation,
        alternative: 'Quotation has not been reviewed'
      },
      secondary: [
        {
          role: ['Super Admin', 'Sales', 'Application Reviewer Coordinator', 'Sales Manager', 'Board of Director', 'Customer'],
          users: props.userReviewer,
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Ordered',
      todo: 'Download Questionnaire',
      primary: {
        role: ['Super Admin', 'Sales', 'Application Reviewer Coordinator', 'Sales Manager', 'Board of Director', 'Customer'],
        text: 'Download Questionnaire',
        action: props.download
      },
      secondary: [
        {
          role: ['Super Admin', 'Sales', 'Application Reviewer Coordinator', 'Sales Manager', 'Board of Director', 'Customer'],
          users: props.userReviewer,
          text: 'Download Questionnaire',
          action: props.download
        }
      ]
    },
    {
      status: 'Replaced',
      todo: 'Go to replacement quotation',
      primary: {
        role: ['Super Admin', 'Sales'],
        text: 'Go to replacement quotation',
        action: props.toReplacement,
        alternative: 'Please wait for questionnaire draft by sales'
      }
    }
  ]

  console.log(q.status);

  return actions.find(x => x.status === q.status) || {
    todo: 'Pending',
    primary: {
      role: ['Super Admin', 'Sales'],
      text: 'Go to replacement quotation',
      action: props.toReplacement,
      alternative: 'Please wait for questionnaire draft by sales'
    }
  }
}
