import React from 'react'

export default function File ({ file, accessToken, onDelete }) {
  return (
    <ul className='list-group'>
      {file?.length === 0 && (
        <p className="well well-sm">Not available</p>
      )}
      {file && file.map((x, i) => (
        <li key={i} className='list-group-item' >
          <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} ><i className='fa fa-download' /> {x.detail.name}</a>
          { onDelete &&
            <a href="#top" onClick={() => onDelete(x.id)} className='text-danger pull-right'><i className='fa fa-remove' href="#top"/> Delete</a>
          }
        </li>
      ))}
    </ul>
  )
}
