import React from 'react'
// import BasicModal from './BasicModal'

export default class ConfirmButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: props.open
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({
        open: nextProps.open
      })
    }
  }

  handleOpenConfirm (e) {
    if (this.props.onClick) {
      this.props.onClick(true)
    }
    this.setState({
      open: true
    })
  }

  handleCloseConfirm () {
    if (this.props.onClick) {
      this.props.onClick(false)
    }
    this.setState({
      open: false
    })
  }

  render () {
    return (
      <div>
        { this.state.open
          ? <div className={`animated fadeInUp`}>
            <div className='alert alert-info'>
              <p>{this.props.confirmMessage || 'Please make sure your input values'}</p>
            </div>
            {this.props.children}
          </div>
          : <button type='button' disabled={this.props.disabled} className={`btn-confirm btn ${this.props.className || 'btn-primary'} btn-block btn-submit`} onClick={this.handleOpenConfirm.bind(this)} >{this.props.text}</button>
        }
      </div>
    )
  }
}
