import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

import Required from './Required'

export default function DateField (props) {
  const { input, label, required, meta: { touched, error, warning }, disabled } = props
  const currentDate = moment()
  const [value, setValue] = useState(currentDate)

  useEffect(() => {
    if (input.value) {
      const value = typeof input.value === 'number' ? moment.unix(input.value) : moment(input.value);
      setValue(value)
      input.onChange(value.format('YYYY-MM-DD'))
    }
  }, [input])

  function handleChange (data) {
    setValue(data)
    input.onChange(data.format('YYYY-MM-DD'))
  }

  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label title={input.name} htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
      <div className='col-sm-9'>
        <DatePicker
          dateFormat='YYYY-MM-DD'
          selected={value}
          onChange={handleChange}
          className='form-control'
          disabled={disabled}
        />
        {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
      </div>
    </div>
  )
}

// export class XDateField extends React.Component {
//   constructor (props) {
//     super(props)
//     let value = moment()
//     if (this.props.input.value && typeof this.props.input.value === 'number') {
//       value = moment.unix(this.props.input.value)
//     }
//     if (this.props.input.value && typeof this.props.input.value === 'string') {
//       value = moment(this.props.input.value)
//     }
//     this.state = {
//       startDate: value
//     }
//   }

//   onChange (value) {
//     this.setState({
//       startDate: value
//     })
//     this.props.input.onChange(value.format('YYYY-MM-DD'))
//   }

//   render () {
//     const { label, required, meta: { touched, error, warning } } = this.props
//     return (

//       <div className={`form-group ${this.props.input.name.split('.').join('_')}`}>
//         <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
//         <div className='col-sm-9'>
//           <DatePicker
//             dateFormat='YYYY-MM-DD'
//             selected={this.state.startDate}
//             onChange={this.onChange.bind(this)}
//             className='form-control'
//           />
//           {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
//         </div>
//       </div>
//     )
//   }
// }
