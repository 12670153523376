import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Switch, Route } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';

import ItemLog from '../../components/common/ItemLog';
import Pagination from '../../components/common/Pagination';

import NcTable from '../../components/Ncs/NcTable';
import NcDetail from '../../components/Orders/NcDetail';
import { NcCreateForm, NcUpdateForm } from '../../components/Orders/NcForm';
import NcClosureForm from '../../components/Orders/NcClosureForm';
import NcAnswerForm from '../../components/Orders/NcAnswerForm';

import { fetchOrder } from '../../actions/orders';
import { fetchNcs, fetchNc, createNc, updateNc, deleteNc } from '../../actions/ncs';
import { asQueryObject } from '../../const/helper';
import { getStage } from '../../selectors/order';

export function OrderNcView(props) {
  const {
    fetchOrder,
    fetchNc,
    fetchNcs,
    createNc,
    updateNc,
    deleteNc,
    match,
    accessToken,
    location,
    order,
    nc,
    ncs,
    pagination,
    user,
  } = props;

  const { params } = match;

  useEffect(() => {
    fetchOrder(params.orderId, accessToken);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (params.id && params.id !== 'new') {
      fetchNc(match.params.orderId, match.params.id, accessToken);
    }
  }, [params]); // eslint-disable-line

  useEffect(() => {
    if (!params.id) {
      fetchNcs(params.orderId, accessToken, location.search);
    }
  }, [location, params]); // eslint-disable-line

  function handleSubmitNew(e) {
    const request = {
      ...e,
      order_id: params.orderId,
      due_date: moment(e.due_date).unix(),
      status: e.advance ? 'Open' : 'Draft',
      log: e.log ? e.log.detail : '',
    };

    if (request.kind === 'PI' && e.advance) {
      request.status = 'Potential Improvement';
    }
    return createNc(
      request,
      accessToken,
      request.status === 'Open' ? `/orders/${request.order_id}/ncs` : null
    );
  }

  function handleSubmitUpdate(e) {
    const request = {
      ...e,
      order_id: params.orderId,
      due_date: moment(e.due_date).unix(),
      status: e.advance ? 'Open' : 'Draft',
      log: e.log ? e.log.detail : '',
    };
    return updateNc(
      request,
      accessToken,
      request.status === 'Open' ? `/orders/${request.order_id}/ncs` : null
    );
  }

  function handleSubmitAnswer(e) {
    const request = {
      ...e,
      order_id: params.orderId,
      status: e.advance ? 'Pending' : 'Open',
      log: e.log ? e.log.detail : '',
    };
    return updateNc(
      request,
      accessToken,
      request.status === 'Pending' ? `/orders/${request.order_id}/ncs` : null
    );
  }

  function handleSubmitClosure(e) {
    const request = {
      ...e,
      // due_date: moment(e.due_date).unix(),
      due_date: null,
      order_id: params.orderId,
      log: e.log ? e.log.detail : '',
    };
    return updateNc(
      request,
      accessToken,
      ['Open', 'Closed'].indexOf(e.status) > -1
        ? `/orders/${request.order_id}/ncs`
        : null
    );
  }

  function handleDelete() {
    return deleteNc({
      order_id: params.orderId,
      id: nc.id
    },accessToken)
  }

  if (!order) {
    return <p>loading</p>;
  }

  const title = 'Area of Concern / NC';
  const stage = getStage(order);

  return (
    <div className="content">
      <Switch>
        <Route path="/orders/:orderId/ncs/new">
          <div>
            <div className="main-header">
              <h2>{title}</h2>
              <em>
                For Order <Link to={`/orders/${order.id}`}>{order.number}</Link>
              </em>
            </div>
            <div className="row">
              <div className="col-md-8">
                <NcCreateForm
                  onSubmit={handleSubmitNew.bind(this)}
                  order={order}
                  stage={stage}
                  initialValues={{
                    kind: stage === 'Stage 1' ? 'AoC': 'NC A',
                    stage: stage,
                    due_date: moment().add(1, 'M').format('YYYY-MM-DD'),
                  }}
                  fileAPI={{
                    url: process.env.REACT_APP_BASE_API,
                    accessToken: accessToken,
                  }}
                />
              </div>
            </div>
          </div>
        </Route>
        <Route path="/orders/:orderId/ncs/:id">
          <div>
            <div className="main-header">
              {nc && nc.auditor && (
                <h2>
                  NC.{nc.auditor.data && nc.auditor.data.auditor_code}.
                  {order.number}.{nc.stage}.{nc.counter}
                </h2>
              )}
              <em>
                For Order <Link to={`/orders/${order.id}`}>{order.number}</Link>
              </em>
            </div>
            <div className="row">
              <div className="col-md-8">
                {nc && user && nc.status === 'Pending' && (
                  <div className="well">
                    {order.users.find(
                      x =>
                        ['Auditor', 'Lead Auditor'].indexOf(x.role) >= 0 &&
                        x.user.id === user.id
                    ) || user.data.role === 'Super Admin' ? (
                      <NcClosureForm
                        onSubmit={handleSubmitClosure.bind(this)}
                        initialValues={nc}
                        fileAPI={{
                          url: process.env.REACT_APP_BASE_API,
                          accessToken: accessToken,
                        }}
                      />
                    ) : (
                      <div className="alert alert-info">
                        Waiting for auditor review.
                      </div>
                    )}
                  </div>
                )}
                {nc && user && nc.status === 'Open' && (
                  <div>
                    {order.users.find(
                      x =>
                        ['Customer'].indexOf(x.role) >= 0 &&
                        x.user.id === user.id
                    ) ? (
                      <NcAnswerForm
                        onSubmit={handleSubmitAnswer.bind(this)}
                        initialValues={{
                          ...nc,
                          detail: {
                            ...nc.detail,
                            correction_date:
                              get(nc, 'detail.correction_date') ||
                              moment().format('YYYY-MM-DD'),
                            implementation_deadline:
                              get(nc, 'detail.implementation_deadline') ||
                              moment().format('YYYY-MM-DD'),
                          },
                        }}
                        fileAPI={{
                          url: process.env.REACT_APP_BASE_API,
                          accessToken: accessToken,
                        }}
                      />
                    ) : (
                      <div className="alert alert-info">
                        Waiting for customer input.
                      </div>
                    )}
                  </div>
                )}
                {nc && user && nc.status === 'Draft' && (
                  <div>
                    {order.users.find(
                      x =>
                        ['Auditor', 'Lead Auditor'].indexOf(x.role) >= 0 &&
                        x.user.id === user.id
                    ) ? (
                      <NcUpdateForm
                        onSubmit={handleSubmitUpdate.bind(this)}
                        stage={stage}
                        initialValues={{
                          ...nc,
                          due_date: moment
                            .unix(nc.due_date)
                            .format('YYYY-MM-DD'),
                        }}
                        fileAPI={{
                          url: process.env.REACT_APP_BASE_API,
                          accessToken: accessToken,
                        }}
                      />
                    ) : (
                      <div className="alert alert-info">
                        Waiting for auditor submission.
                      </div>
                    )}
                  </div>
                )}
                {nc && nc.status !== 'Draft' && (
                  <NcDetail nc={nc} accessToken={accessToken} onDelete={!user?.data?.role?.includes('Customer') && handleDelete}/>
                )}
              </div>
              <div className="col-md-4">
                {nc ? (
                  <ItemLog log={nc.log} entity="Order NC" />
                ) : (
                  <div>loading</div>
                )}
              </div>
            </div>
          </div>
        </Route>
        <Route path="/orders/:orderId/ncs">
          <div>
            <div className="main-header">
              <h2>Non Comply List</h2>
              <em>
                For Order <Link to={`/orders/${order.id}`}>{order.number}</Link>
              </em>
            </div>
            <div className="row">
              <div className="col-md-8">
                {ncs && <NcTable ncs={ncs} order={order} />}
                {ncs && (
                  <Pagination
                    pagination={pagination}
                    base={`/orders/${order.id}/ncs`}
                    params={asQueryObject(location.search)}
                  />
                )}
              </div>
              <div className="col-md-4">
                {order.users.filter(
                  x =>
                    x.user.id === user.id &&
                    (x.role === 'Auditor' || x.role === 'Lead Auditor')
                ).length > 0 && (
                  <div className="widget">
                    <div className="widget-header">
                      <h3>
                        <i className="fa fa-table" /> Create New
                      </h3>
                    </div>
                    <div className="widget-content">
                      <NcCreateForm
                        onSubmit={handleSubmitNew.bind(this)}
                        initialValues={{
                          kind: 'NC A',
                          stage: stage,
                          due_date: moment().add(1, 'M').format('YYYY-MM-DD'),
                        }}
                        fileAPI={{
                          url: process.env.REACT_APP_BASE_API,
                          accessToken: accessToken,
                        }}
                        simple
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    order: state.orders.order.data,
    ncs: state.ncs.ncList.data,
    pagination: state.ncs.ncList.pagination,
    nc: state.ncs.nc.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchNcs: (id, accessToken, query) =>
      dispatch(fetchNcs(id, accessToken, query)),
    fetchNc: (orderId, id, accessToken) =>
      dispatch(fetchNc(orderId, id, accessToken)),
    createNc: (data, accessToken, target) =>
      dispatch(createNc(data, accessToken, target)),
    updateNc: (data, accessToken, target) =>
      dispatch(updateNc(data, accessToken, target)),
    deleteNc: (data, accessToken, target) =>
      dispatch(deleteNc(data, accessToken, target)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderNcView);
