import React from 'react';
import moment from 'moment';

export default function DaTableShort({ das, isFinance, onRemove }) {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>DA Date</th>
            <th>Order</th>
            <th>Cycle</th>
            <th>Mandays</th>
            <th>Total</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {das.map((da, id) => {
            return (
              <tr key={id}>
                <td>{moment(da.date).format('DD MMMM YYYY')}</td>
                <td>{da?.order?.number}</td>
                <td>{da?.order?.phase}</td>
                <td>{da?.orderSchedules?.length} schedule(s)</td>
                <td>
                  {da?.detail?.total?.usd
                    ? `USD ${da.detail.total.usd.toLocaleString()}`
                    : ''}{' '}
                  {da?.detail?.total?.idr
                    ? `IDR ${da.detail.total.idr.toLocaleString()}`
                    : ''}
                </td>
                <td>
                  <span className={`label label-default`}>
                    {da.status.toUpperCase()}
                  </span>
                </td>
                <td>
                  {da.status !== 'Paid' && onRemove && (
                    <button className="btn btn-xs btn-default" onClick={() => onRemove(da)}>Remove</button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
