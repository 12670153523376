import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  TextField,
  OptionsField
} from '../common/FormFields'

class ChangeDecisionForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props

    const actions = [
      'Revised Quotation',
      'Reconfirmation or modification of the audit plan',
      'Reconfirmation or modification of the audit schedule',
      'Change the previous audit objectives or audit scope'
    ]

    return (
      <div>
        <form className='form-horizontal form-change' onSubmit={handleSubmit}>
          <Field name='detail.separated_audit_md' component={TextField} label='Special audit needed (Separated from the regular audit)' help='Enter mandays' />
          <Field name='detail.additional_audit_md' component={TextField} label='Special audit addition to regular audit (Special audit MD added to MD audit)' help='Enter mandays' />
          <Field name='detail.terminated' type='checkbox' component={OptionsField} label='  The audit process is terminated' />

          <Field name='detail.change' type='multiCheckbox' component={OptionsField} label=' Required changes' options={actions} />
          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-primary'>Save</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

ChangeDecisionForm = reduxForm({ // eslint-disable-line 
  form: 'ChangeDecisionForm'
})(ChangeDecisionForm)

export default ChangeDecisionForm
