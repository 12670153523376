import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextareaField } from '../common/FormFields'
import ConfirmButton from '../common/ConfirmButton'
import DateField from '../common/DateField'
import FileField from '../common/FileField'

export class NcAnswerForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal nc-form' onSubmit={handleSubmit}>
          <Field name='detail.correction' component={TextareaField} label='Correction of nonconformity' />
          <Field name='detail.correction_date' component={DateField} label='Date of correction' />

          <Field name='detail.cause' component={TextareaField} label='Cause of nonconformity' />
          <Field name='detail.corrective_action' component={TextareaField} label='Corrective action planned' />
          <Field name='detail.implementation_deadline' component={DateField} label='Deadline for implementation' />

          <Field name='detail.correction_photo' component={FileField} label='Evidence of corrections / Corrective action' api={{...this.props.fileAPI, path: 'nc'}} />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-6'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
            </div>
            <div className='col-sm-3'>
              <ConfirmButton disabled={submitting} text={'Save Correction'}>
                <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, advance: true}))} >Submit</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

NcAnswerForm = reduxForm({ // eslint-disable-line 
  form: 'ncAnswerForm'
})(NcAnswerForm)

export default NcAnswerForm
