import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
// import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'

import AuditorScheduleTable from '../components/Orders/AuditorScheduleTable'
import BasicModal from '../components/common/BasicModal'

import { fetchSchedules, fetchGlobalSchedules } from '../actions/schedules'
import { fetchAuditors } from '../actions/users'

import { auditorsScheduleSelector } from '../selectors/schedules'

export class ScheduleMatrix extends React.Component {
    constructor(props) {
        super(props)
        const day1 = moment()
        const start = day1.format('YYYY-MM-01')
        const end = day1.add(59, 'days').endOf('month').format('YYYY-MM-DD')
        this.state = {
            start,
            end,
            openModal: null
        }
    }

    componentWillMount() {
        this.props.fetchGlobalSchedules(this.props.accessToken, {
            start: this.state.start,
            end: this.state.end,
            'per-page': 0
        })
        this.props.fetchAuditors(this.props.accessToken)
    }

    handleUpdateFilter(e) {
        const x = moment(e + '-01')
        const start = x.format('YYYY-MM-DD')
        x.add(59, 'days').endOf('month')
        const end = x.format('YYYY-MM-DD')
        if (start !== this.state.start) {
            this.setState({ start, end })
            this.props.fetchGlobalSchedules(this.props.accessToken, {
                start: start,
                end: end
            })
        }
    }

    render() {
        const { auditorsSchedule } = this.props
        return (
            <Inside>
                <div className='content'>
                    <div>
                        <div className='main-header'>
                            <h2>Schedule Matrix</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {auditorsSchedule
                                    ? <AuditorScheduleTable
                                        start={this.state.start}
                                        end={this.state.end}
                                        onUpdateFilter={this.handleUpdateFilter.bind(this)}
                                        onOpenModal={x => this.setState({ openModal: x })}
                                        auditorsSchedule={auditorsSchedule}
                                    />
                                    : <div>loading</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <BasicModal open={this.state.openModal} onClose={() => this.setState({ openModal: null })}>
                    {this.state.openModal?.email && (
                        <>
                            <dl className="dl-horizontal">
                                <dt>Auditor</dt>
                                <dd>{this.state.openModal?.data?.display_name}</dd>
                                <dt>Email</dt>
                                <dd>{this.state.openModal?.email}</dd>
                            </dl>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Standard</th>
                                        {['Lead Auditor', 'Auditor', 'Senior Auditor', 'Expert', 'Trainee'].map(x => <th key={x}>{x}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.openModal.specifications.map((x, i) => (
                                        <tr key={i}>
                                            <td>{x?.standard?.name} : {x?.scope?.name}</td>
                                            {['Lead Auditor', 'Auditor', 'Senior Auditor', 'Expert', 'Trainee'].map(xx => (
                                                <td key={xx}>{x?.capacity?.includes(xx) && 'x'}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                    {this.state.openModal?.start_date && (
                        <>
                            <dl className="dl-horizontal">
                                <dt>Order</dt>
                                <dd>{this.state.openModal.order.number}</dd>
                                <dt>Customer</dt>
                                <dd>{this.state.openModal.customer.name}</dd>
                                <dt>Standard</dt>
                                <dd>{this.state.openModal.order.scopes.map((x) => x.standard.name).join(', ')}</dd>
                            </dl>
                        </>
                    )}
                </BasicModal>
            </Inside>
        )
    }
}

function mapStateToProps(state) {
    return {
        auditors: state.users.auditorList.data,
        auditorsSchedule: auditorsScheduleSelector(state),
        accessToken: state.auth.user.access.access_token
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSchedules: (id, key, params) => dispatch(fetchSchedules(id, key, params)),
        fetchGlobalSchedules: (key, params) => dispatch(fetchGlobalSchedules(key, params)),
        fetchAuditors: (key) => dispatch(fetchAuditors(key))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleMatrix)
