function makeSurveillanceAction (props) {
  // console.log(1000, props)
  const actions = [
    {
      status: 'Created',
      todo: 'Submit Order Prerequisite Completeness',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Complete order prerequisite',
        action: props.onCompleteQuestionnaire,
        alternative: 'Please wait for order prerequisite completion by customer'
      },
      secondary: [
        {
          role: ['Super Admin', 'Scheduler'],
          text: 'Create audit schedule',
          action: props.onManageSchedule
        }
      ]
    },
    {
      status: 'Prerequisite Not Complete',
      todo: 'Resubmit Order Prerequisite Completeness',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Complete order prerequisite',
        action: props.onCompleteQuestionnaire,
        alternative: 'Please wait for order prerequisite completion by customer'
      },
      secondary: [
        {
          role: ['Super Admin', 'Scheduler'],
          text: 'Create audit schedule',
          action: props.onManageSchedule
        }
      ]
    },
    {
      status: 'Prerequisite Submitted',
      todo: 'Review Order Prerequisite Documents',
      primary: {
        role: ['Admin Support'],
        text: 'Review order prerequisite',
        action: props.onReviewQuestionnaire,
        alternative: 'Please wait for order prerequisite review by Admin Support'
      },
      secondary: [
        {
          role: ['Super Admin', 'Scheduler'],
          text: 'Create audit schedule',
          action: props.onManageSchedule
        }
      ]
    },
    {
      status: 'Prerequisite Completed',
      todo: 'Audit Scheduling',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'A Team Need to Revise',
      todo: 'Update audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'Audit Schedule Created',
      todo: 'Upload A-Team',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Upload A-Team',
        action: props.onUpload,
        alternative: 'Please wait for A Team by Audit Plan Operator'
      }
    },
    {
      status: 'A Team Proposed',
      todo: 'Review A-Team',
      primary: {
        role: ['Head of CB'],
        text: 'Review Decision',
        action: props.onReviewATeam,
        alternative: 'Please wait for audit schedule review by Head of CB'
      }
    },
    {
      status: 'A Team Approved',
      todo: 'Prepare Audit Plan',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Open audit plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan Drafted',
      todo: 'Update & Edit Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan Need to Revise',
      todo: 'Update & Edit Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan Created',
      todo: 'Review Audit Plan',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by customer'
      }
    },
    {
      status: 'Audit Plan Approved',
      todo: 'Waiting for audit',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start audit',
        action: props.onAuditStart,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      },
      secondary: [
        // {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Create Area of Concern / NC',
        //   action: props.onCreateNc
        // }, {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Audit report',
        //   action: props.onCreateReport
        // }
      ]
    },
    {
      status: 'Audit On Process',
      todo: 'Audit in Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Area of Concern / NC',
        action: props.onCreateNc,
        alternative: 'Audit in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit report',
          action: props.onCreateReport
        }
      ]
    },
    // start
    {
      status: 'VA Required',
      todo: 'Wait for Quotation Amendment'
    },
    {
      status: 'VA Approved',
      todo: 'Audit Scheduling Verification',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'A Team VA Need to Revise',
      todo: 'Update audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'VA Schedule Created',
      todo: 'Upload A-Team VA',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Upload A-Team',
        action: props.onUpload,
        alternative: 'Please wait for A Team by Audit Plan Operator'
      }
    },
    {
      status: 'A Team VA Proposed',
      todo: 'Review A-Team',
      primary: {
        role: ['Head of CB'],
        text: 'Review Decision',
        action: props.onReviewATeamVA,
        alternative: 'Please wait for audit schedule review by Head of CB'
      }
    },
    {
      status: 'A Team VA Approved',
      todo: 'Prepare Audit Plan VA',
      primary: {
        role: ['Audit Plan Operator'],
        text: 'Open audit schedule',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan VA Drafted',
      todo: 'Update & Edit Audit Plan VA',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan VA Need to Revise',
      todo: 'Update & Edit Audit Plan VA',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Audit Plan VA Created',
      todo: 'Review Audit Plan VA',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by customer'
      }
    },
    {
      status: 'Audit Plan VA Approved',
      todo: 'Waiting for Verification Audit',
      primary: {
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start Verification Audit',
        action: props.onAuditVA1Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      }
    },
    {
      status: 'VA On Process',
      todo: 'VA On Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Area of Concern / NC',
        action: props.onCreateNc,
        alternative: 'Audit VA in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit report',
          action: props.onCreateReport
        }
      ]
    },
    // end
    {
      status: 'Audit Done',
      todo: 'Waiting for Audit Documents Completeness Cheking',
      primary: {
        role: ['Admin Support'],
        text: 'Confirm Completeness',
        action: props.onCompletenessChecking,
        alternative: 'Please wait for Audit Documents Completeness Cheking'
      }
    },
    {
      status: 'Audit Documents Need to Revise',
      todo: 'Waiting for Audit Documents Update',
      primary: {
        role: ['Admin Support'],
        users: props.userLeadAuditor,
        text: 'Update Documents',
        action: props.onReviseDocument,
        alternative: 'Please wait for Audit Documents Update'
      }
    },
    {
      status: 'Audit Documents Completed',
      todo: 'Veto Person Assignment',
      primary: {
        pre: props.preAssignVeto,
        role: ['Operational Manager'],
        text: 'Assign Veto Person',
        action: props.onToggleVeto,
        alternative: 'Please wait for NC closing and Veto Person Assignment by Operational Manager'
      }
    },
    {
      status: 'Veto Person Assigned',
      todo: 'Veto Review Audit Report',
      primary: {
        role: ['Super Admin'],
        users: props.userVeto,
        text: 'Veto Review Audit Report',
        action: props.onCreateReport,
        alternative: 'Please wait for Audit Report Review by Veto Person'
      }
    },
    {
      status: 'Veto Process Done',
      todo: 'Head of CB Approval',
      primary: {
        role: ['Head of CB', 'Deputy Head of CB'],
        usersNot: props.userAuditor,
        text: 'Head of CB Approval',
        action: props.onHCBApproval,
        alternative: 'Please wait for Head of CB Approval'
      }
    },
    {
      status: 'Certificate Maintained',
      todo: 'Order Finish'
      // primary: {
      //   pre: false
      //   role: ['Super Admin', 'Head of CB', 'Certificate Admin'],
      //   text: 'Publish Certificate',
      //   action: props.onPublishCertifate,
      //   alternative: 'Please wait for Release Certificate'
      // }
    }
  ]

  return actions
}

export default makeSurveillanceAction
