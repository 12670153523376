import React from 'react'
import { Switch, Route } from 'react-router-dom'

import OrderPlanReview from './OrderPlanReview'
import OrderPlanCreate from './OrderPlanCreate'

export default class OrderPlanView extends React.Component {
  render () {
    return (
      <div>
        <div className='content'>
          <Switch>
            <Route path='/orders/:orderId/plans/:id/review' component={OrderPlanReview} />
            <Route path='/orders/:orderId/plans/:id' component={OrderPlanCreate} />
            <Route path='/orders/:orderId/plans/new' component={OrderPlanCreate} />
          </Switch>
        </div>
      </div>
    )
  }
}
