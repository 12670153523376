import React from 'react'
// import DownloadLink from '../../components/common/DownloadLink'

export default function LocationWidget ({ title, detail: d, location: l, accessToken, ...props }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-map-marker' /> {title || l.name}</h3>
      </div>
      <div className='widget-content'>

        <table className='table table-hover table-striped'>
          <tbody>
            <tr>
              <th> Name </th>
              <td> {l.name} </td>
            </tr>

            <tr>
              <th> Scope wording </th>
              <td> {d.scope_of_business ? <div dangerouslySetInnerHTML={{__html: d.scope_of_business}} /> : <span>n/a</span>} </td>
            </tr>

            <tr>
              <th> Address </th>
              <td> {l.address} </td>
            </tr>

            <tr>
              <th> City </th>
              <td> {l.city} </td>
            </tr>

            <tr>
              <th> State </th>
              <td> {l.state} </td>
            </tr>

            <tr>
              <th> Country </th>
              <td> {l.country} </td>
            </tr>

            <tr>
              <th> Postal Code </th>
              <td> {l.postal_code} </td>
            </tr>

            <tr>
              <th> Phone </th>
              <td> {l.phone} </td>
            </tr>

            <tr>
              <th> Fax </th>
              <td> {l.fax} </td>
            </tr>

            <tr className='info'>
              <th> Number of employees </th>
              <td> {d.number_of_employees ? <span>{d.number_of_employees}</span> : <span>n/a</span>} </td>
            </tr>

            <tr>
              <th> Number of employees : fulltime </th>
              <td> {d.number_of_employees_fulltime ? <span>{d.number_of_employees_fulltime}</span> : <span>n/a</span>} </td>
            </tr>

            <tr>
              <th> Number of employees : temporary </th>
              <td> {d.number_of_employees_temporary ? <span>{d.number_of_employees_temporary}</span> : <span>n/a</span>} </td>
            </tr>

            <tr>
              <th> Number of employees : sub contract </th>
              <td> {d.number_of_employees_sub_contract ? <span>{d.number_of_employees_sub_contract}</span> : <span>n/a</span>} </td>
            </tr>

            <tr>
              <th> Number of shift </th>
              {/* <td> {d.number_of_employees_shift ? <span>{d.number_of_employees_shift}</span> : <span>n/a</span>} </td> */}
              <td>1 Shift</td>
            </tr>

            <tr>
              <th> Outsourced process </th>
              <td> {d.outsourced_process ? <span>{d.outsourced_process}</span> : <span>n/a</span>} </td>
            </tr>

            <tr>
              <th> Temporary location </th>
              <td> {d.temporary_location ? <span>{d.temporary_location}</span> : <span>n/a</span>} </td>
            </tr>

          </tbody>
        </table>

       
      </div>
    </div>
  )
}
