const scheduleTypes = [
  {
    label: 'Certification Audit Stage 1',
    code: 'S1',
  },
  {
    label: 'Certification Audit Stage 2',
    code: 'S2',
  },
  {
    label: 'Surveillance Audit',
    code: 'SA',
  },
  {
    label: 'Recertification Audit',
    code: 'RC',
  },
  {
    label: 'Verification Audit',
    code: 'VA',
  },
  {
    label: 'Traveling',
    code: 'T',
  },
  {
    label: 'Gap Analysis',
    code: 'G',
  },
];

export default scheduleTypes;

export function getScheduleByType(phase, status = null) {
  switch (phase) {
    case 'Certification Audit':
      if (['Created', 'Prerequisite Not Complete', 'Prerequisite Submitted', 'Prerequisite Completed'].includes(status)) {
        return scheduleTypes.filter(x => !['S2', 'RC', 'SA'].includes(x.code));
      } else {
        return scheduleTypes.filter(x => !['S1', 'RC', 'SA'].includes(x.code));
      }
    case 'Recertification Audit':
      return scheduleTypes.filter(x => !['S1', 'S2', 'SA'].includes(x.code));
    default:
      return scheduleTypes.filter(x => !['S1', 'S2', 'RC'].includes(x.code));
  }
}

