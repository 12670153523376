import axios from 'axios'
import reduce from 'lodash/reduce'

import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchSchedules = (id, accessToken, query = '') => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SCHEDULES_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${id}/schedules?${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_SCHEDULES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_SCHEDULES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchGlobalSchedules = (accessToken, query = '') => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_GLOBAL_SCHEDULES_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/schedules?${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_GLOBAL_SCHEDULES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_GLOBAL_SCHEDULES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchSchedule = (orderId, id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SCHEDULE_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${orderId}/schedules/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_SCHEDULE_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_SCHEDULE_REJECTED',
        payload: e
      })
    })
  }
}

export const createSchedule = (data, accessToken, target) => {
  return (dispatch) => {
    // dispatch({
    //   type: 'CREATE_SCHEDULE_PENDING'
    // })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/schedules`
    }).then((x) => {
      dispatch({
        type: 'CREATE_SCHEDULE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Schedule created with the id ${x.data.id}`
      }))
      return x.data
      // if (target) {
      //   dispatch(change(target.form, target.field, {
      //     value: x.data.id,
      //     label: x.data.name
      //   }))
      // } else {
      //   dispatch(push(`/orders/${x.data.customer.id}/schedules/${x.data.id}`))
      // }
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      // dispatch({
      //   type: 'CREATE_SCHEDULE_REJECTED',
      //   payload: e
      // })
    })
  }
}

export const updateSchedule = (data, accessToken, target) => {
  return (dispatch) => {
    // dispatch({
    //   type: 'UPDATE_SCHEDULE_PENDING'
    // })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/schedules/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_SCHEDULE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Schedule with the id ${x.data.id} updated`
      }))
      return x.data
      // if (target) {
      //   dispatch(push(target))
      // }
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      // dispatch({
      //   type: 'UPDATE_SCHEDULE_REJECTED',
      //   payload: e
      // })
    })
  }
}

export const deleteSchedule = (data, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/schedules/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_SCHEDULE_FULFILLED',
        payload: data.id
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Schedule deleted`
      }))
      return data
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
    })
  }
}
