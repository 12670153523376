import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { Helmet } from "react-helmet";

import ItemLog from "../../components/common/ItemLog";
import FileForm from "../../components/common/FileForm";
import OrderAction from "../../components/Orders/OrderAction";
import OrderDocuments from "../../components/Orders/OrderDocuments";
import OrderInvoice from "../../components/Orders/OrderInvoice";
import OrderNc from "../../components/Orders/OrderNc";
import OrderCertificate from "../../components/Orders/OrderCertificate";
import OrderChange from "../../components/Orders/OrderChange";
import OrderReport from "../../components/Orders/OrderReport";
import OrderSchedule from "../../components/Orders/OrderSchedule";
import OrderUsers from "../../components/Orders/OrderUsers";
import OrderPlan from "../../components/Orders/OrderPlan";
import VetoForm from "../../components/Orders/VetoForm";
import DetailWidget from "../../components/Orders/DetailWidget";

import BasicModal from "../../components/common/BasicModal";
import DecisionForm from "../../components/common/DecisionForm";

import { fetchOrder, updateOrder } from "../../actions/orders";
import { fetchInvoices } from "../../actions/invoices";
import { fetchPlans } from "../../actions/plans";
import { fetchReports } from "../../actions/reports";
import { fetchNcs } from "../../actions/ncs";
import { fetchCertificates } from "../../actions/certificates";
import { fetchChanges } from "../../actions/changes";
import { fetchSchedules } from "../../actions/schedules";

import orderStatuses from "../../const/orderStatuses";

export class OrderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openVeto: false,
      openModal: false,
      submitting: false,
    };
  }

  componentWillMount() {
    this.props.fetchOrder(this.props.match.params.id, this.props.accessToken);
    this.props.fetchInvoices(
      this.props.match.params.id,
      this.props.accessToken
    );
    this.props.fetchPlans(this.props.match.params.id, this.props.accessToken);
    this.props.fetchReports(this.props.match.params.id, this.props.accessToken);
    this.props.fetchNcs(this.props.match.params.id, this.props.accessToken);
    this.props.fetchCertificates(
      this.props.match.params.id,
      this.props.accessToken
    );
    this.props.fetchChanges(this.props.match.params.id, this.props.accessToken);
    this.props.fetchSchedules(
      this.props.match.params.id,
      this.props.accessToken
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.order &&
      this.props.order &&
      nextProps.order.status !== this.props.order.status
    ) {
      this.props.fetchInvoices(
        this.props.match.params.id,
        this.props.accessToken
      );
    }
  }

  toggleOpenModal(auditForm) {
    this.setState({
      openModal: !this.state.openModal,
      auditForm,
    });
  }

  handleSubmitOrder(status) {
    this.setState({
      submitting: true,
    });
    return this.props
      .updateOrder(
        {
          id: this.props.order.id,
          status,
        },
        this.props.accessToken
      )
      .then(() => {
        this.setState({
          submitting: false,
        });
        this.toggleOpenModal();
      });
  }

  handleSubmitOrder2(data) {
    this.setState({
      submitting: true,
    });

    let request = {
      id: this.props.order.id,
      log: data.log ? data.log.detail : "",
      status: data.decision,
    };

    return this.props.updateOrder(request, this.props.accessToken).then(() => {
      this.setState({
        submitting: false,
      });
      this.toggleOpenModal();
    });
  }

  handleSubmitVeto(e) {
    this.setState({
      submitting: true,
    });
    return this.props
      .updateOrder(
        {
          id: this.props.order.id,
          veto: e.veto.map((x) => x.user_id.value.id),
          status: e.advance ? "Veto Person Assigned" : this.props.order.status,
        },
        this.props.accessToken
      )
      .then(() => {
        this.setState({
          submitting: false,
        });
        this.toggleOpenModal();
      });
  }

  handleSubmitFile(data) {
    this.setState({
      submitting: true,
    });
    const request = {
      id: this.props.order.id,
    };
    switch (this.props.order.status) {
      case "Audit Schedule Stage 1 Created":
        request.status = "A Team Stage 1 Proposed";
        request.detail = {
          a_team_stage_1: data.file,
        };
        break;
      case "Audit Schedule Stage 2 Created":
        request.status = "A Team Stage 2 Proposed";
        request.detail = {
          a_team_stage_2: data.file,
        };
        break;
      case "Audit Schedule Created":
        request.status = "A Team Proposed";
        request.detail = {
          a_team: data.file,
        };
        break;
      case "VA Stage 1 Schedule Created":
        request.status = "A Team VA Stage 1 Proposed";
        request.detail = {
          a_team_va_stage_1: data.file,
        };
        break;
      case "VA Stage 2 Schedule Created":
        request.status = "A Team VA Stage 2 Proposed";
        request.detail = {
          a_team_va_stage_2: data.file,
        };
        break;
      case "VA Schedule Created":
        request.status = "A Team VA Proposed";
        request.detail = {
          a_team_va: data.file,
        };
        break;
      default:
        // statements_def
        break;
    }
    return this.props.updateOrder(request, this.props.accessToken).then(() => {
      this.toggleOpenModal();
      this.setState({
        submitting: false,
      });
    });
  }

  getReportLink() {
    const { push, order, reports } = this.props;
    let reportType;
    switch (order.status) {
      case "Audit Stage 1 On Process":
        reportType = "Stage 1";
        break;
      case "Audit Stage 2 On Process":
        reportType = "Stage 2";
        break;
      case "Audit On Process":
        reportType = "Audit";
        break;

      case "VA Stage 1 On Process":
        reportType = "Stage 1";
        break;
      case "VA Stage 2 On Process":
        reportType = "Stage 2";
        break;
      case "VA On Process":
        reportType = "Stage 2";
        break;

      case "Veto Person Assigned":
        reportType = "Veto";
        break;
      default:
        reportType = "Stage 1";
        break;
    }
    const currentReport = reports.filter((x) => x.type === reportType);
    const url =
      currentReport.length > 0
        ? `/orders/${order.id}/reports/${currentReport[0].id}`
        : `/orders/${order.id}/reports/new`;

    return push.bind(this, url);
  }

  render() {
    const {
      order,
      invoices,
      reports,
      plans,
      ncs,
      certificates,
      changes,
      schedules,
      user,
      accessToken,
    } = this.props;

    const { submitting } = this.state;

    if (!order) {
      return <p>loading</p>;
    }

    const veto = order.users.filter((x) => x.role === "Veto Person");
    const vetoFormValue =
      veto.length > 0
        ? veto.map((x) => {
            return {
              user_id: {
                label: x.user.data.display_name,
                value: x.user,
              },
            };
          })
        : null;

    const doneChecking = order.log.find(
      (x) => x.action === "to:Audit Documents Completed"
    );
    const doneVetoProcess = order.log.find(
      (x) => x.action === "to:Veto Process Done"
    );
    const doneGranted = order.log.find(
      (x) => x.action === "to:Certificate Granted"
    );
    // const donePlanApproval =
    //   order.log.find((x) => x.action === "to:Audit Plan Stage 1 Approved") ||
    //   order.log.find((x) => x.action === "to:Audit Plan Approved") ||
    //   order.log.find((x) => x.action === "to:Audit Done") ||
    //   order.log.find((x) => x.action === "to:Certificate Granted");

    let hcbStatuses = [
      "Audit Documents Need to Revise",
      "Certificate Maintained",
    ];
    switch (order.phase) {
      case "Certification Audit":
        hcbStatuses = ["Audit Documents Need to Revise", "Certificate Granted"];
        break;
      case "Recertification Audit":
        hcbStatuses = ["Audit Documents Need to Revise", "Certificate Granted"];
        break;
        case "Transfer Audit":
          hcbStatuses = ["Audit Documents Need to Revise", "Certificate Granted"];
          break;
      default:
        hcbStatuses = [
          "Audit Documents Need to Revise",
          "Certificate Maintained",
        ];
    }

    return (
      <div>
        <Helmet>
          <title>{`Order ${order.number}`}</title>
        </Helmet>
        <div className="content">
          <div>
            <div className="main-header">
              <h2>{order.number} </h2>
              <em>
                Part of Quotation{" "}
                <Link to={`/quotations/${order.quotation.id}`}>
                  {order.quotation.number}
                </Link>
              </em>
            </div>
            <div className="row">
              <div className="col-md-8">
                {order && plans && reports && invoices ? (
                  <div>
                    {order.quotation.status !== "Replaced" &&
                    ["Ordered", "Approved"].includes(
                      order.questionnaire.status
                    ) ? (
                      <OrderAction
                        order={order}
                        user={user}
                        onCompleteQuestionnaire={this.props.push.bind(
                          this,
                          `/orders/${order.id}/prerequisite/${order.questionnaire.id}`
                        )}
                        onReviewQuestionnaire={this.toggleOpenModal.bind(
                          this,
                          "prerequisite-review"
                        )}
                        onUpload={this.toggleOpenModal.bind(
                          this,
                          "file-upload"
                        )}
                        onManageSchedule={this.props.push.bind(
                          this,
                          `/orders/${order.id}/schedules/manage`
                        )}
                        onReviewSchedule={this.props.push.bind(
                          this,
                          `/orders/${order.id}/schedules/review`
                        )}
                        onReviewATeamStage1={this.toggleOpenModal.bind(
                          this,
                          "stage-1-a-team-review"
                        )}
                        onReviewATeamStage2={this.toggleOpenModal.bind(
                          this,
                          "stage-2-a-team-review"
                        )}
                        onReviewATeam={this.toggleOpenModal.bind(
                          this,
                          "a-team-review"
                        )}
                        onReviewATeamVAStage1={this.toggleOpenModal.bind(
                          this,
                          "va-stage-1-a-team-review"
                        )}
                        onReviewATeamVAStage2={this.toggleOpenModal.bind(
                          this,
                          "va-stage-2-a-team-review"
                        )}
                        onReviewATeamVA={this.toggleOpenModal.bind(
                          this,
                          "va-a-team-review"
                        )}
                        onCreatePlan={this.props.push.bind(
                          this,
                          `/orders/${order.id}/plans/new`
                        )}
                        onUpdatePlan={
                          plans[0]
                            ? this.props.push.bind(
                                this,
                                `/orders/${order.id}/plans/${plans[0].id}${
                                  user.data.role.includes("Customer")
                                    ? "/review"
                                    : ""
                                }`
                              )
                            : null
                        }
                        onAudit1Start={this.toggleOpenModal.bind(
                          this,
                          "stage-1-start"
                        )}
                        onAudit2Start={this.toggleOpenModal.bind(
                          this,
                          "stage-2-start"
                        )}
                        onAuditStart={this.toggleOpenModal.bind(this, "start")}
                        onAuditVA1Start={this.toggleOpenModal.bind(
                          this,
                          "va-stage-1-start"
                        )}
                        onAuditVA2Start={this.toggleOpenModal.bind(
                          this,
                          "va-stage-2-start"
                        )}
                        onAuditVAStart={this.toggleOpenModal.bind(
                          this,
                          "va-start"
                        )}
                        onCreateNc={this.props.push.bind(
                          this,
                          `/orders/${order.id}/ncs/new`
                        )}
                        onCreateReport={this.getReportLink()}
                        onUpdateReport={
                          reports[0]
                            ? this.props.push.bind(
                                this,
                                `/orders/${order.id}/reports/${reports[0].id}`
                              )
                            : null
                        }
                        onCompletenessChecking={this.toggleOpenModal.bind(
                          this,
                          "confirm-completeness"
                        )}
                        onReviseDocument={this.toggleOpenModal.bind(
                          this,
                          "submit-completeness"
                        )}
                        onToggleVeto={this.toggleOpenModal.bind(
                          this,
                          "veto-assignment"
                        )}
                        onHCBApproval={this.toggleOpenModal.bind(
                          this,
                          "hcb-approval"
                        )}
                        onPublishCertifate={this.props.push.bind(
                          this,
                          `/orders/${order.id}/certificates`
                        )}
                        preAuditStart={
                          invoices.filter(
                            (x) => ["Draft", "Created"].indexOf(x.status) >= 0
                          ).length === 0
                        }
                        prePublishCertificate={
                          invoices.filter((x) => x.status !== "Paid").length ===
                          0
                        }
                        preAssignVeto={order.nc_status === "Closed"}
                        onAuditCancel={this.toggleOpenModal.bind(
                          this,
                          "cancel"
                        )}
                        onChangeOrder={this.toggleOpenModal.bind(
                          this,
                          "change-order"
                        )}
                        userAuditor={order.users.filter(
                          (x) =>
                            x.role === "Auditor" || x.role === "Lead Auditor"
                        )}
                        userLeadAuditor={order.users.filter(
                          (x) => x.role === "Lead Auditor"
                        )}
                        userCustomer={order.users.filter(
                          (x) => x.role === "Customer"
                        )}
                        userVeto={order.users.filter(
                          (x) => x.role === "Veto Person"
                        )}
                      />
                    ) : (
                      <div className="alert alert-info">
                        Current Quotation / Questionnaire is on amendment
                        process.
                      </div>
                    )}

                    <OrderDocuments
                      order={order}
                      reports={reports}
                      accessToken={accessToken}
                      internal={!user.data.role.includes("Customer")}
                    />
                  </div>
                ) : (
                  <div>loading order data</div>
                )}
                <Fragment>
                  {plans && orderStatuses.find ? (
                    <OrderPlan
                      order={order}
                      plans={plans}
                      user={user}
                      accessToken={accessToken}
                    />
                  ) : (
                    <div>loading plan</div>
                  )}
                </Fragment>

                <Fragment>
                  {reports ? (
                    <OrderReport order={order} reports={reports} user={user} />
                  ) : (
                    <div></div>
                  )}
                  {ncs ? (
                    <OrderNc order={order} ncs={ncs} user={user} />
                  ) : (
                    <div></div>
                  )}
                </Fragment>
                {invoices ? (
                  <OrderInvoice order={order} invoices={invoices} user={user} />
                ) : (
                  <div>loading invoice</div>
                )}
                {doneGranted && (
                  <Fragment>
                    {certificates ? (
                      <OrderCertificate
                        order={order}
                        certificates={certificates}
                        user={user}
                      />
                    ) : (
                      <div>loading certificate</div>
                    )}
                  </Fragment>
                )}
                {changes ? (
                  <OrderChange order={order} changes={changes} user={user} />
                ) : (
                  <div>loading change</div>
                )}
                {order ? (
                  <DetailWidget
                    order={order}
                    accessToken={this.props.accessToken}
                  />
                ) : (
                  <div>loading detail</div>
                )}
              </div>
              <div className="col-md-4">
                <OrderUsers order={order} />
                <Fragment>
                  {schedules && (
                    <OrderSchedule
                      order={order}
                      schedules={schedules}
                      user={user}
                    />
                  )}
                </Fragment>
                <ItemLog log={order.log} entity="Order" />
              </div>
            </div>
          </div>
        </div>
        <BasicModal
          open={this.state.openModal}
          onClose={this.toggleOpenModal.bind(this)}
        >
          {this.state.auditForm === "prerequisite-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm that the prerequisite is completed.
              </div>
              <DecisionForm
                results={[
                  "Prerequisite Not Complete",
                  "Prerequisite Completed",
                ]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
              {
                // <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Prerequisite Not Complete')}>Set order as : Prerequisite Not Complete</button>
                // <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Prerequisite Completed')}>Set order as : Prerequisite Completed</button>
              }
            </div>
          )}
          {this.state.auditForm === "file-upload" && (
            <FileForm
              onSubmit={this.handleSubmitFile.bind(this)}
              fileAPI={{
                url: process.env.REACT_APP_BASE_API,
                accessToken: accessToken,
              }}
            />
          )}

          {this.state.auditForm === "stage-1-a-team-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the A Team before advancing to next stage.
              </div>
              <DecisionForm
                results={[
                  "A Team Stage 1 Need to Revise",
                  "A Team Stage 1 Approved",
                ]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}
          {this.state.auditForm === "stage-2-a-team-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the A Team before advancing to next stage.
              </div>
              <DecisionForm
                results={[
                  "A Team Stage 2 Need to Revise",
                  "A Team Stage 2 Approved",
                ]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}
          {this.state.auditForm === "a-team-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the A Team before advancing to next stage.
              </div>
              <DecisionForm
                results={["A Team Need to Revise", "A Team Approved"]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}

          {this.state.auditForm === "va-stage-1-a-team-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the A Team before advancing to next stage.
              </div>
              <DecisionForm
                results={[
                  "A Team VA Stage 1 Need to Revise",
                  "A Team VA Stage 1 Approved",
                ]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}
          {this.state.auditForm === "va-stage-2-a-team-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the A Team before advancing to next stage.
              </div>
              <DecisionForm
                results={[
                  "A Team VA Stage 2 Need to Revise",
                  "A Team VA Stage 2 Approved",
                ]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}
          {this.state.auditForm === "va-a-team-review" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the A Team before advancing to next stage.
              </div>
              <DecisionForm
                results={["A Team VA Need to Revise", "A Team VA Approved"]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}

          {this.state.auditForm === "stage-1-start" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please make sure that audit schedules are confirmed and audit
                requirements are completed.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-primary"
                onClick={this.handleSubmitOrder.bind(
                  this,
                  "Audit Stage 1 On Process"
                )}
              >
                Start audit
              </button>
            </div>
          )}
          {this.state.auditForm === "stage-2-start" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please make sure that audit schedules are confirmed and audit
                requirements are completed.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-primary"
                onClick={this.handleSubmitOrder.bind(
                  this,
                  "Audit Stage 2 On Process"
                )}
              >
                Start audit
              </button>
            </div>
          )}
          {this.state.auditForm === "start" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please make sure that audit schedules are confirmed and audit
                requirements are completed.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-primary"
                onClick={this.handleSubmitOrder.bind(this, "Audit On Process")}
              >
                Start audit
              </button>
            </div>
          )}

          {this.state.auditForm === "va-stage-1-start" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please make sure that audit schedules are confirmed and audit
                requirements are completed.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-primary"
                onClick={this.handleSubmitOrder.bind(
                  this,
                  "VA Stage 1 On Process"
                )}
              >
                Start audit
              </button>
            </div>
          )}
          {this.state.auditForm === "va-stage-2-start" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please make sure that audit schedules are confirmed and audit
                requirements are completed.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-primary"
                onClick={this.handleSubmitOrder.bind(
                  this,
                  "VA Stage 2 On Process"
                )}
              >
                Start audit
              </button>
            </div>
          )}
          {this.state.auditForm === "va-start" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please make sure that audit schedules are confirmed and audit
                requirements are completed.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-primary"
                onClick={this.handleSubmitOrder.bind(this, "VA On Process")}
              >
                Start audit
              </button>
            </div>
          )}

          {this.state.auditForm === "confirm-completeness" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the Audit Documents Completeness.
              </div>
              <DecisionForm
                results={[
                  "Audit Documents Need to Revise",
                  "Audit Documents Completed",
                ]}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}
          {this.state.auditForm === "submit-completeness" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the Audit Documents Completeness.
              </div>
              {!doneChecking && (
                <button
                  disabled={submitting}
                  className="btn btn-block btn-primary"
                  onClick={this.handleSubmitOrder.bind(
                    this,
                    "Audit Stage 2 Done"
                  )}
                >
                  Audit Documents Completed.
                </button>
              )}
              {doneChecking && !doneVetoProcess && (
                <button
                  disabled={submitting}
                  className="btn btn-block btn-primary"
                  onClick={this.handleSubmitOrder.bind(
                    this,
                    "Veto Person Assigned"
                  )}
                >
                  Audit Documents Completed.
                </button>
              )}
              {doneVetoProcess && (
                <button
                  disabled={submitting}
                  className="btn btn-block btn-primary"
                  onClick={this.handleSubmitOrder.bind(
                    this,
                    "Veto Process Done"
                  )}
                >
                  Audit Documents Completed.
                </button>
              )}
            </div>
          )}
          {this.state.auditForm === "hcb-approval" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm the order is completed.
              </div>
              <DecisionForm
                results={hcbStatuses}
                onSubmit={this.handleSubmitOrder2.bind(this)}
              />
            </div>
          )}
          {this.state.auditForm === "cancel" && (
            <div>
              <div className="alert alert-info">
                {" "}
                Please confirm before canceling audit.
              </div>
              <button
                disabled={submitting}
                className="btn btn-block btn-danger"
                onClick={this.handleSubmitOrder.bind(this, "Canceled")}
              >
                Cancel audit
              </button>
            </div>
          )}
          {this.state.auditForm === "change-order" && (
            <div>
              <div className="alert alert-info"> Change order here.</div>
              <button
                disabled={submitting}
                className="btn btn-block btn-danger"
                onClick={this.handleSubmitOrder.bind(this, "Order Finished")}
              >
                Set order as finished
              </button>
            </div>
          )}
          {this.state.auditForm === "veto-assignment" && (
            <VetoForm
              userAPI={{
                url:
                  process.env.REACT_APP_BASE_API + "/users?auditors&per-page=0",
                label: "data.display_name",
                accessToken: this.props.accessToken,
              }}
              onSubmit={this.handleSubmitVeto.bind(this)}
              initialValues={{ veto: vetoFormValue }}
            />
          )}
        </BasicModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.orders.order.data,
    invoices: state.invoices.invoiceList.data,
    plans: state.plans.planList.data,
    ncs: state.ncs.ncList.data,
    certificates: state.certificates.certificateList.data,
    changes: state.changes.changeList.data,
    reports: state.reports.reportList.data,
    schedules: state.schedules.scheduleList.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    updateOrder: (data, key, target) =>
      dispatch(updateOrder(data, key, target)),
    fetchInvoices: (id, key) => dispatch(fetchInvoices(id, key)),
    fetchPlans: (id, key) => dispatch(fetchPlans(id, key)),
    fetchReports: (id, key) => dispatch(fetchReports(id, key)),
    fetchNcs: (id, key) => dispatch(fetchNcs(id, key)),
    fetchCertificates: (id, key) => dispatch(fetchCertificates(id, key)),
    fetchChanges: (id, key) => dispatch(fetchChanges(id, key)),
    fetchSchedules: (id, key) => dispatch(fetchSchedules(id, key)),
    push: (route) => dispatch(push(route)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderView);
