const initialState = {
  globalCounts: {
    data: null,
    error: null,
    loading: true,
  },
  auditCounts: {
    data: null,
    error: null,
    loading: true,
  },
  topCustomer: {
    data: null,
    error: null,
    loading: true,
  },
  topSales: {
    data: null,
    error: null,
    loading: true,
  },
  topStandard: {
    data: null,
    error: null,
    loading: true,
  },
  topScope: {
    data: null,
    error: null,
    loading: true,
  },
  mandaysExecution: {
    data: null,
    error: null,
    loading: true,
  },
  invoices: {
    data: null,
    error: null,
    loading: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GLOBAL_COUNTS_PENDING":
      return {
        ...state,
        globalCounts: {
          ...state.globalCounts,
          loading: true,
        },
      };
    case "GLOBAL_COUNTS_FULFILLED":
      return {
        ...state,
        globalCounts: {
          data: [...action.payload],
          error: null,
          loading: false,
        },
      };
    case "GLOBAL_COUNTS_REJECTED":
      return {
        ...state,
        globalCounts: {
          ...state.globalCounts,
          error: { ...action.payload },
          loading: false,
        },
      };

    case "AUDIT_COUNTS_PENDING":
      return {
        ...state,
        auditCounts: {
          ...state.auditCounts,
          loading: true,
        },
      };
    case "AUDIT_COUNTS_FULFILLED":
      return {
        ...state,
        auditCounts: {
          data: [...action.payload],
          error: null,
          loading: false,
        },
      };
    case "AUDIT_COUNTS_REJECTED":
      return {
        ...state,
        auditCounts: {
          ...state.auditCounts,
          error: { ...action.payload },
          loading: false,
        },
      };

    case "TOP_CUSTOMER_PENDING":
      return {
        ...state,
        topCustomer: {
          ...state.topCustomer,
          loading: true,
        },
      };
    case "TOP_CUSTOMER_FULFILLED":
      return {
        ...state,
        topCustomer: {
          data: { ...action.payload },
          error: null,
          loading: false,
        },
      };
    case "TOP_CUSTOMER_REJECTED":
      return {
        ...state,
        topCustomer: {
          ...state.topCustomer,
          error: { ...action.payload },
          loading: false,
        },
      };

    case "TOP_SALES_PENDING":
      return {
        ...state,
        topSales: {
          ...state.topSales,
          loading: true,
        },
      };
    case "TOP_SALES_FULFILLED":
      return {
        ...state,
        topSales: {
          data: { ...action.payload },
          error: null,
          loading: false,
        },
      };
    case "TOP_SALES_REJECTED":
      return {
        ...state,
        topSales: {
          ...state.topSales,
          error: { ...action.payload },
          loading: false,
        },
      };

    case "TOP_STANDARD_PENDING":
      return {
        ...state,
        topStandard: {
          ...state.topStandard,
          loading: true,
        },
      };
    case "TOP_STANDARD_FULFILLED":
      return {
        ...state,
        topStandard: {
          data: { ...action.payload },
          error: null,
          loading: false,
        },
      };
    case "TOP_STANDARD_REJECTED":
      return {
        ...state,
        topStandard: {
          ...state.topStandard,
          error: { ...action.payload },
          loading: false,
        },
      };

    case "TOP_SCOPE_PENDING":
      return {
        ...state,
        topScope: {
          ...state.topScope,
          loading: true,
        },
      };
    case "TOP_SCOPE_FULFILLED":
      return {
        ...state,
        topScope: {
          data: { ...action.payload },
          error: null,
          loading: false,
        },
      };
    case "TOP_SCOPE_REJECTED":
      return {
        ...state,
        topScope: {
          ...state.topScope,
          error: { ...action.payload },
          loading: false,
        },
      };

    case "WIDGET_SALES":
      return {
        ...state,
        widgetSales: {
          ...state.widgetSales,
          loading: true,
        },
      };
    case "WIDGET_STATS":
      return {
        ...state,
        widgetStats: {
          ...state.widgetStats,
          loading: false,
        },
      };
    case "CHARTS_ORDER":
      return {
        ...state,
        chartsOrder: {
          ...state.chartsOrder,
          loading: true,
        },
      };
    case "CHARTS_MANDAYS":
      return {
        ...state,
        chartsMandays: {
          ...state.chartsMandays,
          loading: false,
        },
      };
    case "CHARTS_TOP":
      return {
        ...state,
        chartsTop: {
          ...state.chartsTop,
          loading: false,
        },
      };
    case "SALESBY_AUDIT":
      return {
        ...state,
        salesbyAudit: {
          ...state.salesbyAudit,
          loading: false,
        },
      };
    case "SALESBY_SALES":
      return {
        ...state,
        salesbySales: {
          ...state.salesbySales,
          loading: false,
        },
      };
    case "MANDAYS_EXECUTION_RECAP_FULFILLED":
      return {
        ...state,
        mandaysExecution: {
          data: [...action.payload],
          error: null,
          loading: false,
        },
      };
    case "INVOICES_RECAP_FULFILLED":
      return {
        ...state,
        invoices: {
          data: [...action.payload],
          error: null,
          loading: false,
        },
      };
    case "LOGOUT_FULFILLED":
      return { ...initialState };

    default:
      return state;
  }
};
