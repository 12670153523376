import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function DaTable({ das, showUser, role, onSelect, selectedId }) {
  function canCreatePayment(da) {
    if (da.status !== 'Approved') {
      return false;
    }
    if (!role.includes('Finance')) {
      return false;
    }
    return true;
  }
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>DA ID</th>
            {showUser && <th>Auditor</th>}
            <th>DA Date</th>
            <th>Customer</th>
            <th>Order</th>
            <th>Cycle</th>
            <th>Mandays</th>
            <th>Total</th>
            <th>Status</th>
            <th>{!onSelect && 'Payment'}</th>
          </tr>
        </thead>
        <tbody>
          {das?.map((da, id) => {
            return (
              <tr key={id}>
                <td>
                  <Link to={`/daily-allowances/${da.id}`}>{da.number}</Link>
                </td>
                {showUser && (
                  <td>
                    <Link to={`/daily-allowances/${da.id}`}>
                      {da.user.name}
                    </Link>
                  </td>
                )}
                <td>{moment(da.date).format('DD MMMM YYYY')}</td>
                <td>{da?.customer?.name}</td>
                <td>{da?.order?.number}</td>
                <td>{da?.order?.phase}</td>
                <td>{da.mandays ? `${da.mandays} day(s)` : 'n/a'} </td>
                <td>
                  {da?.detail?.total?.usd
                    ? `USD ${da.detail.total.usd.toLocaleString()}`
                    : ''}{' '}
                  {da?.detail?.total?.idr
                    ? `IDR ${da.detail.total.idr.toLocaleString()}`
                    : ''}
                </td>
                <td>
                  <span className={`label label-default`}>
                    {da.status.toUpperCase()}
                  </span>
                </td>
                <td>
                  {!onSelect && canCreatePayment(da) && (
                    <Link
                      className="btn btn-primary btn-xs"
                      to={`/daily-allowance-payments/new/?user=${da.user.id}&status=Approved`}
                    >
                      Create payment
                    </Link>
                  )}
                  {!onSelect && da?.payment?.id && (
                    <Link to={`/daily-allowance-payments/${da.payment.id}`}>
                      {da.payment.number}
                    </Link>
                  )}
                  {onSelect && (
                    <>
                      {!selectedId.includes(da.id) ? (
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => onSelect(da)}
                        >
                          Add to payment list
                        </button>
                      ) : (
                        <>Selected</>
                      )}
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
