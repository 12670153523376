import React from 'react'
import { Link } from 'react-router-dom'

import { getCertificateLabel } from '../../const/orders'

export default class ChangeTable extends React.Component {
  render () {
    const { changes } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {changes.map((x, i) => {
              return (
                <tr key={i}>
                  <td><Link to={`/orders/${x.order.id}/changes/${x.id}`}>{x.id}</Link></td>
                  <td>{getCertificateLabel(x.status)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
