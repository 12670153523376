import React from "react";
import { connect } from "react-redux";

import { removeFlash } from "../../actions/flasher";
import "./Flasher.css";

function Flasher({ flasher, removeFlash }) {
  return (
    <div id="gritter-notice-wrapper">
      {flasher.map((x) => {
        return (
          <div
            key={x.id}
            className="gritter-item-wrapper "
            role="alert"
            onClick={() => removeFlash(x.id)}
          >
            <div className="gritter-item">
              <div className="gritter-without-image">
                <p dangerouslySetInnerHTML={{ __html: x.message }} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    flasher: state.flasher,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeFlash: (id) => dispatch(removeFlash(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flasher);
