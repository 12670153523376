const questionnaireStatuses = [
  {
    label: 'Temporary',
    color: 'primary',
  },
  {
    label: 'Draft',
    color: 'primary',
  },
  {
    label: 'Created',
    color: 'primary',
  },
  {
    label: 'Need to Revise',
    color: 'primary',
  },
  {
    label: 'Reviewed by SM',
    color: 'primary',
  },
  {
    label: 'Published to Customer',
    color: 'primary',
  },
  {
    label: 'Manual Approval',
    color: 'primary',
  },
  {
    label: 'Approved by Customer',
    color: 'primary',
  },
  {
    label: 'Replaced',
    color: 'primary',
  },
  {
    label: 'Amendment Draft',
    color: 'primary',
  },
  {
    label: 'Amendment Created',
    color: 'primary',
  },
  {
    label: 'Amendment Need to Revise',
    color: 'primary',
  },
  {
    label: 'Amendment Reviewed by SM',
    color: 'primary',
  },
  {
    label: 'Amendment Published to Customer',
    color: 'primary',
  },
  {
    label: 'Amendment Manual Approval',
    color: 'primary',
  },
  {
    label: 'Amendment Approved by Customer',
    color: 'primary',
  },
];

export default questionnaireStatuses;
