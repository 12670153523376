import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { getScheduleByType } from '../../const/scheduleTypes'

import {
  OptionsField,
  AutoCompleteField
} from '../common/FormFields'
import DateField from '../common/DateField'

const selector = formValueSelector('ScheduleForm')

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

const warn = (values) => {
  const warnings = {}
  if (values.username === 'test') {
    warnings.username = 'No test please'
  }
  return warnings
}

const roles = ['Lead Auditor', 'Auditor', 'Senior Auditor', 'Expert', 'Trainee']

export function ScheduleForm (props) {
  const { handleSubmit, submitting, userAPI, locations, start, end, change, type, order } = props
  useEffect(() => {
    if (start === end) {
      change('detail.halfday_end', null)
      change('detail.halfday_start', null)
    } else {
      change('detail.halfday', null)
    }

    if (start > end) {
      const previousStart = start
      change('start_date', end)
      change('end_date', previousStart)
    }
    // eslint-disable-next-line 
  }, [start, end])

  useEffect(() => {
    if (type === 'Traveling') {
      change('detail.include_manday', null)
    }
    // eslint-disable-next-line 
  }, [type])

  let filteredActivities = getScheduleByType(order.phase, order.status);

  return (
    <form className='form-horizontal schedule-form' onSubmit={handleSubmit}>
      <Field
        name='user_id'
        component={AutoCompleteField}
        label='Select User'
        api={userAPI}
        disabled
      />
      <Field name='type' type='select' component={OptionsField} label='Activity' options={filteredActivities} />
      <Field name='location' type='select' component={OptionsField} label='Location' options={locations} />
      <Field name='role' type='select' component={OptionsField} label='Role' options={roles} />
      <Field name='start_date' type='select' component={DateField} label='Start Date' />
      <Field name='end_date' type='select' component={DateField} label='End Date' />
      <Field name='detail.include_manday' type='checkbox' component={OptionsField} label='Include manday(s)' />
      {start !== end &&
        <Fragment>
          <Field name='detail.halfday_start' type='checkbox' component={OptionsField} label='Count first day as half day' />
          <Field name='detail.halfday_end' type='checkbox' component={OptionsField} label='Count last day as half day' />
        </Fragment>
      }
      {start === end &&
        <Fragment>
          <Field name='detail.halfday' type='checkbox' component={OptionsField} label='Count as half day' />
        </Fragment>
      }

      <div className='form-group'>
        <div className='col-sm-offset-3 col-sm-6'>
          {props.onDelete &&
            <button disabled={submitting} type='button' className='btn btn-danger' onClick={() => props.onDelete(props.initialValues.id)}>Delete</button>
          }
        </div>
        <div className='col-sm-3'>
          <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
        </div>
      </div>
    </form>
  )
}

ScheduleForm = reduxForm({ // eslint-disable-line 
  enableReinitialize: true,
  form: 'ScheduleForm',
  validate,
  warn
})(ScheduleForm)

ScheduleForm = connect(state => { // eslint-disable-line
  const start = selector(state, 'start_date')
  const end = selector(state, 'end_date')
  const type = selector(state, 'type')
  return {
    start, end, type
  }
})(ScheduleForm)

export default ScheduleForm
