import React from 'react'
import { Link } from 'react-router-dom'

import File from '../common/File'

export default class OrderDocuments extends React.Component {
  render () {
    const { order, accessToken, reports } = this.props
    const veto = reports.find(x => x.type === 'Veto')
    const doneVetoProcess = order.log.find(x => x.action === 'to:Veto Process Done')
    const doneHCBApproval = order.log.find(x => x.action === 'to:Certificate Granted')

    return (
      <div className='well well-sm'>
        <div className=''>
          <div className='row'>
            <div className='col-md-6'>
              <ul className='list-unstyled'>
                <li><Link className='btn btn-link btn-xs' to={`/quotations/${order.quotation.id}`}><i className='fa fa-arrow-left' /></Link>Quotation : {order.quotation.number}</li>
                <li><Link className='btn btn-link btn-xs' to={`/questionnaires/${order.questionnaire.id}`}><i className='fa fa-arrow-left' /></Link>Questionnaire : {order.questionnaire.number}</li>
                { doneVetoProcess &&
                  <li><a href={`${process.env.REACT_APP_BASE_API}/pdf/report?template=veto-review&id=${veto.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Veto and Reviewer Feedback</a></li>
                }
                {
                  doneHCBApproval &&
                  <li><a href={`${process.env.REACT_APP_BASE_API}/pdf/order?template=release-protocol&id=${order.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Release Protocol</a></li>
                }
              </ul>
            </div>
            { order.detail && this.props.internal &&
              <div className='col-md-6'>
                { order.detail.a_team_stage_1 &&
                  <div>
                    <strong>A Team Stage 1 : </strong>
                    <File file={order.detail.a_team_stage_1} accessToken={accessToken} />
                  </div>
                }
                { order.detail.a_team_stage_2 &&
                  <div>
                    <strong>A Team Stage 2 : </strong>
                    <File file={order.detail.a_team_stage_2} accessToken={accessToken} />
                  </div>
                }
                { order.detail.a_team_va_stage_1 &&
                  <div>
                    <strong>A Team VA Stage 1 : </strong>
                    <File file={order.detail.a_team_va_stage_1} accessToken={accessToken} />
                  </div>
                }
                { order.detail.a_team_va_stage_2 &&
                  <div>
                    <strong>A Team VA Stage 2 : </strong>
                    <File file={order.detail.a_team_va_stage_2} accessToken={accessToken} />
                  </div>
                }
                { order.detail.a_team &&
                  <div>
                    <strong>A Team: </strong>
                    <File file={order.detail.a_team} accessToken={accessToken} />
                  </div>
                }
                { order.detail.a_team_va &&
                  <div>
                    <strong>A Team VA : </strong>
                    <File file={order.detail.a_team_va} accessToken={accessToken} />
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
