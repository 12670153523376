import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Inside from '../Inside'
import DaPaymentList from './DaPaymentList'
import DaPaymentNew from './DaPaymentNew'
import DaPaymentUpdate from './DaPaymentUpdate'

export default class DaPayments extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/daily-allowance-payments/new' component={DaPaymentNew} />
          <Route path='/daily-allowance-payments/:id' component={DaPaymentUpdate} />
          <Route exact path='/daily-allowance-payments' component={DaPaymentList} />
        </Switch>
      </Inside>
    )
  }
}