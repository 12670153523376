function allocateMandays (phases, mandays, audit1 = 1) {
  // console.log(phases, mandays);
  const md = parseInt(mandays, 10)

  return phases.map((item) => {
    if (item === 'Certification Audit Stage 1') {
      return {
        phase: item,
        day: audit1
      }
    }
    if (item === 'Certification Audit Stage 2') {
      return {
        phase: item,
        day: md - audit1
      }
    }
    if (item === 'Recertification Audit') {
      return {
        phase: item,
        day: md
      }
    }
    return {
      phase: item,
      day: Math.ceil(md / 3)
    }
  })
}

export default allocateMandays
