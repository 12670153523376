import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, AnnexOptionsField, AnnexTextareaField, AnnexMatrixField, AnnexMultiCheckboxField } from '../common/FormFields'
import AnnexFileField from '../common/AnnexFileField'
import ConfirmButton from '../common/ConfirmButton'

export class QuestionnaireAnnexForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openTab: 0
    }
  }

  changeTab (target, ev) {
    ev.preventDefault()
    this.setState({
      openTab: target
    })
  }

  render () {
    const { handleSubmit, submitting, questionnaire } = this.props

    const scopes = questionnaire.scopes.reduce((sum, x) => {
      if (sum.filter(y => y.standard.id === x.standard.id).length === 0) {
        sum.push(x)
      }
      return sum
    }, [])

    return (
      <form className='form-horizontal questionnaire-annex-form' onSubmit={handleSubmit}>
        {scopes.length > 1 &&
          <div>
            <ul className='nav nav-tabs nav-tabs-custom-colored tabs-iconized'>
              {scopes.map((x, i) => (
                <li key={i} className={this.state.openTab === i ? 'active' : ''} >
                  <a href='/' onClick={this.changeTab.bind(this, i)} >{x.standard.name}</a>
                </li>
              ))}
            </ul>
            <br />
          </div>
        }

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='tab-content profile-page'>
          { scopes.map((x, i) => {
            // perSite = []
            return (
              <div key={i} className={this.state.openTab === i ? 'tab-pane profile active' : 'tab-pane profile'}>
                <fieldset key={i}>
                  <legend>Annex for <strong>{x.standard.name}</strong></legend>
                  {!x.standard.annex &&
                    <p>Annex not available. Please continue.</p>
                  }
                  {x.standard.annex && x.standard.annex.map((y, j) => {
                    console.log('bing', y);
                    // if (!y.per_site) {
                    return (
                      <div key={y.name}>
                        <p><strong>{j + 1}</strong></p>
                        { !y.per_site && (
                          <>
                            { y.type.toLowerCase() === 'textarea' &&
                              <Field name={`scopes[${i}].detail.annex[${j}].answer`} component={AnnexTextareaField} label={y.name} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} />
                            }
                            { y.type.toLowerCase() === 'select' &&
                              <Field name={`scopes[${i}].detail.annex[${j}].answer`} type='select' component={AnnexOptionsField} label={y.name} options={y.options.split(';')} help={y.description} />
                            }
                            { y.type.toLowerCase() === 'boolean' &&
                              <Field name={`scopes[${i}].detail.annex[${j}].answer`} type='select' component={AnnexOptionsField} label={y.name} options={['-', 'Yes', 'No']} help={y.description} />
                            }
                            { y.type.toLowerCase() === 'checkbox' &&
                              <Field name={`scopes[${i}].detail.annex[${j}].answer`} component={AnnexMultiCheckboxField} label={y.name} options={y.options.split(';')} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} />
                            }
                            { y.type.toLowerCase() === 'matrix' &&
                              <Field name={`scopes[${i}].detail.annex[${j}].answer`} component={AnnexMatrixField} label={y.name} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} options={y.options}/>
                            }
                            { y.file &&
                              <Field name={`scopes[${i}].detail.annex[${j}].file`} component={AnnexFileField} api={this.props.fileAPI} label='Attachment' />
                            }
                            { y.explanation &&
                              <Field name={`scopes[${i}].detail.annex[${j}].explanation`} component={AnnexTextareaField} api={this.props.fileAPI} label='Explanation' />
                            }
                          </>
                        )}
                        { y.per_site && (
                          <>
                            {questionnaire.locations.map((z, k) => {
                              return (
                                <Fragment key={k}>
                                  <p><strong>{z.location.name}</strong></p>
                                  { y.type.toLowerCase() === 'textarea' &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} component={AnnexTextareaField} label={y.name} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} />
                                  }
                                  { y.type.toLowerCase() === 'select' &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} type='select' component={AnnexOptionsField} label={y.name} options={y.options.split(';')} help={y.description} />
                                  }
                                  { y.type.toLowerCase() === 'boolean' &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} type='select' component={AnnexOptionsField} label={y.name} options={['-', 'Yes', 'No']} help={y.description} />
                                  }
                                  { y.type.toLowerCase() === 'checkbox' &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} component={AnnexMultiCheckboxField} label={y.name} options={y.options.split(';')} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} />
                                  }
                                  { y.type.toLowerCase() === 'matrix' &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} component={AnnexMatrixField} label={y.name} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} options={y.options}/>
                                  }
                                  { y.file &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].file[${k}]`} component={AnnexFileField} api={this.props.fileAPI} label='Attachment' />
                                  }
                                  { y.explanation &&
                                    <Field name={`scopes[${i}].detail.annex[${j}].explanation[${k}]`} component={AnnexTextareaField} api={this.props.fileAPI} label='Explanation' />
                                  }
                                </Fragment>
                              )
                            })}
                          </>
                        )}
                        <hr />
                      </div>
                    )
                  })}
                  { this.state.openTab === scopes.length - 1 &&
                    <div className='form-group'>
                      <div className='col-sm-offset-3 col-sm-6'>
                        <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
                          ev.preventDefault()
                          this.props.changeForm('advance', false)
                          setTimeout(handleSubmit, 100)
                        }} >Save Draft</button>
                      </div>
                      <div className='col-sm-3'>
                        <ConfirmButton
                          disabled={submitting}
                          text={'Save Annex'}
                        >
                          <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                            ev.preventDefault()
                            this.props.changeForm('advance', true)
                            setTimeout(handleSubmit, 100)
                          }} >Continue</button>
                        </ConfirmButton>
                      </div>
                    </div>
                  }
                  { this.state.openTab !== scopes.length - 1 &&
                    <div>
                      <button onClick={this.changeTab.bind(this, (i + 1))} className='btn btn-next-page btn-primary btn-block' type='button'>Next Page</button>
                    </div>
                  }
                </fieldset>
              </div>
            )
          })}
        </div>
      </form>
    )
  }
}

QuestionnaireAnnexForm = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireAnnexForm',
  enableReinitialize: true
})(QuestionnaireAnnexForm)

export default QuestionnaireAnnexForm
