import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { destroy } from 'redux-form'

import Pagination from '../../components/common/Pagination'
import UserTable from '../../components/Users/UserTable'
import { UserCreateForm as UserForm } from '../../components/Users/UserForm'
import UserFilter from '../../components/Users/UserFilter'

import { createUser, fetchUsers } from '../../actions/users'
import { asQueryObject, asQueryString } from '../../const/helper'

class UserList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      return this.props.fetchUsers(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    return this.props.fetchUsers(this.props.location.search, this.props.accessToken)
  }

  handleSubmit (e) {
    return this.props.submitUser({ ...e }, this.props.accessToken)
      .then(data => {
        if (data?.id) {
          this.props.push(`/users/${data.id}`)
        }
        // this.props.destroy('userCreate')
      })
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/users?${asQueryString(filter)}`)
  }

  render () {
    const { users, pagination, location, me } = this.props
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Users </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { users
                ? <div>
                  <UserFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
                  <UserTable users={users} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/users' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              { me.data.role.includes('Super Admin') &&
                <div className='widget'>
                  <div className='widget-header'>
                    <h3><i className='fa fa-group' /> Create User</h3> <em className='hidden'>- Summary of Tasks</em>
                    <div className='btn-group widget-header-toolbar hide'>
                      <button title='Focus' className='btn-borderless btn-focus'><i className='fa fa-eye' /></button>
                      <button title='Expand/Collapse' className='btn-borderless btn-toggle-expand'><i className='fa fa-chevron-up' /></button>
                      <button title='Remove' className='btn-borderless btn-remove'><i className='fa fa-times' /></button>
                    </div>
                  </div>
                  <div className='widget-content'>
                    <UserForm
                      onSubmit={this.handleSubmit.bind(this)}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: this.props.accessToken
                      }}
                      full
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    users: state.users.userList.data,
    pagination: state.users.userList.pagination,
    accessToken: state.auth.user.access.access_token,
    me: state.auth.user
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchUsers: (id, key) => dispatch(fetchUsers(id, key)),
    submitUser: (data, key) => dispatch(createUser(data, key)),
    push: (to) => dispatch(push(to)),
    destroy: (form) => dispatch(destroy(form))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList)
