import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Route, Switch } from 'react-router-dom'

import QuestionnaireAdditionalInformation from './QuestionnaireAdditionalInformation'
import QuestionnaireAnnex from './QuestionnaireAnnex'
import QuestionnaireAuditLocation from './QuestionnaireAuditLocation'
import QuestionnaireCertificationInformation from './QuestionnaireCertificationInformation'
import QuestionnaireGeneralInformation from './QuestionnaireGeneralInformation'
import QuestionnaireMandaysCalculation from './QuestionnaireMandaysCalculation'
import QuestionnaireSummary2 from './QuestionnaireSummary2'
import QuestionnaireAssignment from './QuestionnaireAssignment'
import QuestionnaireReview2 from './QuestionnaireReview2'

import { fetchQuestionnaire, updateQuestionnaire } from '../../actions/questionnaires'
import { fetchOptions } from '../../actions/options'

export function QuestionnaireView2 (props) {
  const {
    accessToken,
    fetchQuestionnaire,
    fetchOptions,
    match,
    questionnaire
  } = props

  useEffect(() => {
    fetchQuestionnaire(match.params.id, accessToken)
  }, []) // eslint-disable-line

  if (!questionnaire) {
    return <p>loading</p>
  }

  return (
    <div className='content'>
      <Switch>
        <Route path='/questionnaires/:id' exact render={() => <QuestionnaireSummary2 {...props} />} />
        <Route path='/questionnaires/:id/general-information' exact render={() => <QuestionnaireGeneralInformation {...props} />} />
        <Route path='/questionnaires/:id/certification-information' exact render={() => <QuestionnaireCertificationInformation {...props} />} />
        <Route path='/questionnaires/:id/audit-location' exact render={() => <QuestionnaireAuditLocation {...props} />} />
        <Route path='/questionnaires/:id/additional-information' exact render={() => <QuestionnaireAdditionalInformation {...props} />} />
        <Route path='/questionnaires/:id/annex' exact render={() => <QuestionnaireAnnex {...props} />} />
        <Route path='/questionnaires/:id/mandays-calculation' exact render={() => <QuestionnaireMandaysCalculation {...props} />} />
        <Route path='/questionnaires/:id/assignment' exact render={() => <QuestionnaireAssignment {...props} />} />
        <Route path='/questionnaires/:id/review' exact render={() => <QuestionnaireReview2 {...props} />} />
      </Switch>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    questionnaire: state.questionnaires.questionnaire.data,
    accessToken: state.auth.user.access.access_token,
    options: state.options.optionList.data,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchQuestionnaire: (id, key) => dispatch(fetchQuestionnaire(id, key)),
    updateQuestionnaire: (data, key, target) => dispatch(updateQuestionnaire(data, key, target)),
    push: (route) => dispatch(push(route))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireView2)
