import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import intersection from 'lodash/intersection'
import kebabCase from 'lodash/kebabCase'

import { getNcLabel } from '../../const/orders'
import { getPhaseInitial } from '../../selectors/order'

export default class OrderNc extends React.Component {
  render () {
    const { order, ncs, user } = this.props

    const orderId = order ? `OD.${order.quotation.sales.code}.${getPhaseInitial(order.phase)}.${order.quotation.counter}` : ''

    return (
      <div className='widget order-nc'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Non Comformity</h3>
          <div className='widget-header-toolbar'>
            {
              order.users &&
              order.users.filter((x) =>
                x.user.id === user.id &&
                intersection(x.role, ['Auditor', 'Lead Auditor']).length > 0
              ).length > 0 &&
              <Link to={`/orders/${order.id}/ncs/new`} className='btn btn-link btn-xs'><i className='fa fa-plus' />New</Link>
            }
            {' '}
            <Link to={`/orders/${order.id}/ncs`} className='btn btn-link btn-xs'><i className='fa fa-list' />View All</Link>
          </div>
        </div>
        <div className=''>
          <table className='table' style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <th>NC No.</th>
                <th>NC Category</th>
                <th>Stage</th>
                <th>NC Description</th>
                <th>Related Clause</th>
                <th>Follow-up Due Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { ncs.length === 0 &&
                <tr>
                  <td colSpan={8}>
                    {
                      order.users &&
                      order.users.filter((x) =>
                        x.user.id === user.id &&
                        intersection(x.role, ['Auditor', 'Lead Auditor']).length > 0
                      ).length > 0 &&
                      ['On Audit', 'Audited', 'Report Draft'].indexOf(order.status) > -1
                        ? <Link to={`/orders/${order.id}/ncs/new`} className='btn btn-default'><i className='fa fa-plus' />Create New NC</Link>
                        : <p>Not available</p>
                    }
                  </td>
                </tr>
              }
              { ncs
                .sort((a, b) => a.id < b.id)
                .map((x, y) => {
                  return (
                    <tr key={y} className={kebabCase(x.status)}>
                      <td><Link to={`/orders/${x.order.id}/ncs/${x.id}`}>NC.{x.auditor.data.auditor_code}.{orderId}.{x.stage}.{x.counter}</Link></td>
                      <td>{x.kind}</td>
                      <td>{x.stage}</td>
                      <td>{x.description}</td>
                      <td>{x.clausul}</td>
                      <td>{x.due_date ? moment.unix(x.due_date).format('DD MMMM YYYY') : '-'}</td>
                      <td>{getNcLabel(x.status)}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
