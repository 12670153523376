import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "react-router-redux";
import * as Sentry from "@sentry/browser";

import About from "./containers/About";
import AuditPlans from "./containers/AuditPlans";
import AuditReports from "./containers/AuditReports";
import Certificates from "./containers/Certificates";
import Customers from "./containers/Customers";
import DaPayments from "./containers/DaPayments";
import Das from "./containers/Das";
import Dashboard from "./containers/Dashboard";
import Events from "./containers/Events";
import Invoices from "./containers/Invoices";
import Login from "./containers/Login";
import NoMatch from "./containers/NoMatch";
import NonComplies from "./containers/NonComplies";
import Options from "./containers/Options";
import Orders from "./containers/Orders";
import PrivateRoute from "./containers/PrivateRoute";
import Questionnaires from "./containers/Questionnaires";
import Quotations from "./containers/Quotations";
import QuotationSchedules from "./containers/QuotationSchedules";
import registerServiceWorker from "./registerServiceWorker";
import ScheduleMatrix from "./containers/ScheduleMatrix";
import Standards from "./containers/Standards";
import store, { history } from "./store/store";
import Users from "./containers/Users";
import ValidityCheck from "./containers/ValidityCheck";

import "./css/index.css";
import "animate.css/animate.min.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://23525bf9d918438698d387d1a0eaf212@o104928.ingest.sentry.io/5193251",
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/about" component={About} />
        <Route path="/login" component={Login} />
        <Route path="/validity-check" component={ValidityCheck} />
        <Route path="/surveys" component={About} />

        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute path="/questionnaires" component={Questionnaires} />
        <PrivateRoute path="/quotations" component={Quotations} />
        <PrivateRoute
          path="/quotation-schedules"
          component={QuotationSchedules}
        />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/audit-plans" component={AuditPlans} />
        <PrivateRoute path="/audit-reports" component={AuditReports} />
        <PrivateRoute path="/ncs" component={NonComplies} />
        <PrivateRoute path="/certificates" component={Certificates} />
        <PrivateRoute path="/invoices" component={Invoices} />
        <PrivateRoute path="/daily-allowances" component={Das} />
        <PrivateRoute path="/daily-allowance-payments" component={DaPayments} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/standards" component={Standards} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/events" component={Events} />
        <PrivateRoute path="/options" component={Options} />
        <PrivateRoute path="/schedule-matrix" component={ScheduleMatrix} />

        <Route component={NoMatch} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
