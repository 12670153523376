import React, { useEffect } from 'react'
import get from 'lodash/get'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import intersection from 'lodash/intersection'

import QuestionnaireReviewForm from '../../components/Questionnaires/QuestionnaireReviewForm'
import CustomerWidget from '../../components/Customers/CustomerWidget'
import LocationWidget from '../../components/Customers/LocationWidget'
import ContactWidget from '../../components/Customers/ContactWidget'

import CertificationInformationWidget from '../../components/Questionnaires/CertificationInformationWidget'
import AdditionalInformationWidget from '../../components/Questionnaires/AdditionalInformationWidget'
import MandaysCalculationWidget from '../../components/Questionnaires/MandaysCalculationWidget'
import AnnexWidget from '../../components/Questionnaires/AnnexWidget'
import ReviewWidget from '../../components/Questionnaires/ReviewWidget'

import { annexWithValueSelector } from '../../selectors/questionnaires'

import { fetchSpecificationByUser } from '../../actions/users'

export function QuestionnaireReview2 (props) {
  const {
    accessToken,
    changeForm,
    fetchQuestionnaire,
    questionnaire,
    updateQuestionnaire,
    questionnaireAnnex,
    me
  } = props

  useEffect(() => {
    if (me) {
      props.fetchSpecificationByUser(me.id, accessToken)
    }
  }, [me]) // eslint-disable-line

  function handleSubmit (e) {
    let target
    const request = {
      id: questionnaire.id,
      reviewer_id: e?.reviewer?.value?.id || null,
      review_detail: { ...e }
    }

    if (e?.reviewer?.value && questionnaire?.status === 'Revision Created') {
      request.status = 'Revision Assigned'
    }
    if (e?.reviewer?.value && questionnaire?.status === 'Created') {
      request.status = 'Assigned'
    }

    if (e.advance) {
      let statusChange = [ 'Approved', 'Need to Revise', 'Not Approved' ]
      if (['Revision Created', 'Revision Assigned'].includes(questionnaire.status)) {
        if (questionnaire.quotation.status === 'Temporary') {
          statusChange = [ 'Revision Approved', 'Revision Need to Revise', 'Revision Not Approved' ]
        } else if (questionnaire.quotation.status === 'Approved by Customer') {
          statusChange = [ 'Ordered', 'Revision Need to Revise', 'Revision Not Approved' ]
        }
      }
      // eslint-disable-next-line
      switch (e.review_result) {
        case 'Approved':
          request.status = statusChange[0]
          break
        case 'Need to Revise':
          request.status = statusChange[1]
          break
        case 'Not Approved':
          request.status = statusChange[2]
          break
      }
      target = `/questionnaires/${questionnaire.id}`
    }


    return updateQuestionnaire(request, accessToken, target).then((x) => {
      fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  const customer = get(questionnaire, 'detail.copy.customer')
  const mainContact = get(questionnaire, 'detail.main_contact')

  const myScopes = props.specifications.map(x => x.scope.id)
  const orderScopesWithParent = questionnaire.scopes.reduce((sum, x) => {
    const result =  [...sum, x.scope.id]
    if (result.indexOf(x.scope.detail.parent) < 0) {
      result.push(x.scope.detail.parent)
    }
    return result
  }, [])
  
  const specScope = orderScopesWithParent.join(',')

  return (
    <div className='tab-pane active'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-6'>
              <CustomerWidget customer={customer} accessToken={accessToken} />
            </div>
            <div className='col-md-6'>
              <ContactWidget contact={mainContact} accessToken={accessToken} title='Main Contact' />
            </div>
          </div>
          <CertificationInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
          {questionnaire.locations.map((x, i) => (
            <LocationWidget
              key={i}
              location={x.location}
              detail={x.detail}
              accessToken={accessToken}
              title={`Audit Location : ${x.location.name}`}
            />
          ))}
          <AdditionalInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
          <AnnexWidget annex={questionnaireAnnex} questionnaire={questionnaire} accessToken={accessToken} />
          <MandaysCalculationWidget questionnaire={questionnaire} accessToken={accessToken} />
          { questionnaire.review_detail &&
            <ReviewWidget questionnaire={questionnaire} accessToken={accessToken} />
          }
        </div>
        <div className='col-md-4'>
          <h1>Review Questionnaire</h1>
          <QuestionnaireReviewForm
            onSubmit={handleSubmit.bind(this)}
            changeForm={changeForm}
            initialValues={{
              number_of_site: questionnaire.locations.length,
              company_detail_complete: '',
              document_complete: '',
              implementation_complete: '',
              scope_mandays_appropriate: '',
              competence_personal: '',
              review_result: '',
              advance: false,
              reviewer: {
                label: questionnaire.reviewer && questionnaire.reviewer.data && questionnaire.reviewer.data.display_name,
                value: questionnaire.reviewer && questionnaire.reviewer
              },
              ...questionnaire.review_detail
            }}
            fileAPI={{
              url: process.env.REACT_APP_BASE_API,
              accessToken: accessToken
            }}
            userAPI={{
              url: process.env.REACT_APP_BASE_API + '/users?capacity="Application Reviewer"&scope_id=' + specScope,
              accessToken: accessToken,
              label: 'data.display_name'
            }}
            showReviewer={intersection(['Application Reviewer Coordinator', 'Super Admin'], me.data.role).length > 0}
            allowApprove={intersection(myScopes, orderScopesWithParent).length > 0}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    questionnaireAnnex: annexWithValueSelector(state),
    specifications: state.users.specificationList.data || [],
    me: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchSpecificationByUser: (id, key) => dispatch(fetchSpecificationByUser(id, key)),
    changeForm: (field, value) => dispatch(change('QuestionnaireReviewForm', field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireReview2)
