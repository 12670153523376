import axios from 'axios'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchChanges = (id, accessToken) => {
  return (dispatch) => {
    console.log(`${baseAPI}/orders/${id}/changes`);
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${id}/changes`
    }).then(x => {
      dispatch({
        type: 'FETCH_CHANGES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
    })
  }
}

export const fetchChange = (orderId, id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${orderId}/changes/${id}`
    }).then(x => {
      dispatch({
        type: 'FETCH_CHANGE_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
    })
  }
}

export const createChange = (data, accessToken, target) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/changes`
    }).then(x => {
      dispatch({
        type: 'CREATE_CHANGE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Change created with the id ${x.data.id}`
      }))
      return x.data
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateChange = (data, accessToken) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/changes/${data.id}`
    }).then(x => {
      dispatch({
        type: 'UPDATE_CHANGE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Change with the id ${x.data.id} updated`
      }))
      return x.data
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}
