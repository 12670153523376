import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function DaTable({ das, showUser, role, onSelect, selectedId }) {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Auditor Name</th>
            <th>Payment Create Date</th>
            <th>Order No.</th>
            <th>Payment Date</th>
            <th className="text-right">Total DA</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {das.map((da, id) => {
            return (
              <tr key={id}>
                <td>
                  <Link to={`/daily-allowance-payments/${da.id}`}>{da.number}</Link>
                </td>
                <td>
                  <Link to={`/daily-allowance-payments/${da.id}`}>
                    {da.user.name}
                  </Link>
                </td>
                <td>{moment(da.date).format('DD MMMM YYYY')}</td>
                <td>{da?.das?.filter((x) => (x?.order?.number)).map((x) => (x.order.number)).join(' - ')}</td>
                <td>{da?.detail?.payment_date}</td>
                <td className="text-right">
                  {da?.detail?.total?.usd
                    ? `USD ${da.detail.total.usd.toLocaleString()}`
                    : ''}{' '}
                  {da?.detail?.total?.idr
                    ? `IDR ${da.detail.total.idr.toLocaleString()}`
                    : ''}
                </td>
                <td>
                  <span className={`label label-default`}>
                    {da.status.toUpperCase()}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
