import React, { Component } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import additionalServices from '../../const/additionalServices'

import {
  TextareaField,
  OptionsField
} from '../common/FormFields'

import FileField from '../common/FileField'

import ConfirmButton from '../common/ConfirmButton'

import {
  // FactorPicker,
  AuditCycleField,
  FeesField
} from './QuotationFields'

// const selector = formValueSelector('quotationForm')

function validate (values) {
  if (
    values === 'error'
  ) {
    throw new SubmissionError({})
  } else {
    return this.props.onSubmit({ ...values, advance: true })
  }
}

export class QuotationForm extends Component {
  render () {
    const { handleSubmit, submitting, phases: cycles } = this.props
    // const additionalServices = []
    // if (questionnaire) {
    //   if (questionnaire.services.audit) {
    //     additionalServices.push('Gap Analysis / Pre Audit')
    //   }
    //   if (questionnaire.services.training) {
    //     additionalServices.push('Training')
    //   }
    // }

    return (
      <form className='form-horizontal quotation-form' onSubmit={handleSubmit}>
        <fieldset>
          <legend>Certification Process</legend>
          <Field
            name='detail.audit_cycle'
            component={AuditCycleField}
            label='Audit Cycle'
            phases={cycles.map(x => x.phase)}
            scheme={'all'}
            // scheme={this.props.all ? 'all' : 'financial'}
            newTitle='Surveillance Audit'
          />
          <Field
            name='detail.additional_service'
            component={AuditCycleField}
            label='Additional Service'
            phases={additionalServices}
            scheme={'all'}
            // scheme={this.props.all ? 'all' : 'financial'}
            newTitle='On site verification'
          />
          <Field name='detail.remarks' type='text' component={TextareaField} label='Remarks (special terms & conditions)' />
        </fieldset>

        <fieldset>
          <legend>Other Fees</legend>
          <Field
            name='detail.fees'
            component={FeesField}
            label='Other Fees'
            options={[
              'Issued Certificate',
              'Document Review',
              'Monitoring'
              // 'Transport',
              // 'Accomodation'
            ]}
          />
        </fieldset>

        <fieldset>
          <Field
            name='detail.transport_accomodation'
            component={OptionsField}
            label='Transport, accomodation & rapid test'
            type='multiCheckbox'
            options={[
              'Exclude transportation fees',
              'Exclude accomodation fees',
              'Exclude rapid test'
            ]}
          />
        </fieldset>

        <fieldset>
          <Field name='detail.annex' component={FileField} label='Annex' api={{...this.props.fileAPI, path: 'quotation'}} />
        </fieldset>

        <fieldset>
          <legend>Payment</legend>
          <Field
            name='detail.payment_schedule'
            component={OptionsField}
            type='select'
            label='Payment Schedule'
            options={[
              {
                value: 'payment-in-advance',
                label: 'Full Payment in Advance'
              },
              {
                value: 'down-payment-30',
                label: 'Down Payment 30%'
              },
              {
                value: 'down-payment-50',
                label: 'Down Payment 50%'
              },
              {
                value: 'payment-after-audit',
                label: 'Full Payment after Audit'
              }
            ]}
          />
          <legend>Payment</legend>
          <Field
            name='detail.tax'
            component={OptionsField}
            type='select'
            label='Tax Overrider'
            options={[
              {
                value: '',
                label: 'None'
              },
              {
                value: '0.1',
                label: '10%'
              },
              {
                value: '0.11',
                label: '11%'
              }
            ]}
          />
        </fieldset>

        <fieldset>
          <legend>Other</legend>
          <Field
            name='detail.show_manday_calculation'
            component={OptionsField}
            type='select'
            label='Show Manday'
            options={[
              {
                value: 'show',
                label: 'Show'
              },
              {
                value: 'hide',
                label: 'Hide'
              }
            ]}
          />
          <Field
            name='detail.attachment_template'
            component={OptionsField}
            type='select'
            label='Attached Quotation Template'
            options={[
              {
                value: null,
                label: 'Full Quotation Template'
              },
              {
                value: 'Certification Audit',
                label: 'Certification Audit Template'
              },
              {
                value: 'Recertification Audit',
                label: 'Recertification Audit Template'
              },
              {
                value: 'Surveillance Audit',
                label: 'Surveillance Audit Template'
              }
            ]}
          />
        </fieldset>

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={handleSubmit} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton disabled={submitting} text={'Submit Quotation'}>
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={handleSubmit(validate.bind(this))} >Continue</button>
            </ConfirmButton>
          </div>
        </div>
      </form>
    )
  }
}

export default QuotationForm

const BaseQuotationCreateForm = props => <QuotationForm {...props} mode='create' />

export const QuotationCreateForm = reduxForm({
  form: 'quotationCreateForm'
})(BaseQuotationCreateForm)

const BaseQuotationUpdateForm = props => <QuotationForm {...props} mode='update' />

export const QuotationUpdateForm = reduxForm({
  form: 'quotationUpdateForm'
})(BaseQuotationUpdateForm)
