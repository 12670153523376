import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, TextareaField, OptionsField } from '../common/FormFields'
import FileField from '../common/FileField'
import RepeatTextField from '../common/RepeatTextField'
import MatrixFileField from '../common/MatrixFileField'

class StandardForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>

          <Field name='name' type='text' component={TextField} label='Standard Name' />

          <Field name='detail.description' type='text' component={TextareaField} label='Description' />

          <Field name='detail.allowance_a' type='number' component={TextField} label='Daily allowance (Jabodetabek, Serang, Karawang)' />

          <Field name='detail.allowance_b' type='number' component={TextField} label='Daily allowance (other)' />

          <Field name='detail.attachment' component={FileField} label='Certification & Acreditation Marks documents' api={{...this.props.fileAPI, path: 'standard'}} />

          <Field name='detail.forbid_multisite' type='checkbox' component={OptionsField} label='Forbid for multisite certificaiton' />

          <Field name='detail.audit_report_due' type='number' component={TextField} label='Audit report due days' />
          <Field name='detail.major_car_due' type='number' component={TextField} label='Corrective action report (CAR) major due days' />
          <Field name='detail.minor_car_due' type='number' component={TextField} label='Corrective action report (CAR) minor due days' />

          <Field name='detail.department' type='text' component={TextField} label='Department' />
          <Field name='detail.accreditation_body' type='text' component={RepeatTextField} label='Accreditation Body (AB)' />
          <Field name='detail.code' type='text' component={TextField} label='Standard Code/Certificate Code' />

          <Field name='detail.manday' template='manday-matrix.csv' component={MatrixFileField} label='Manday Matrix Document' />

          <Field name='detail.list_order' type='number' component={TextField} label='Order' />

          <Field name='detail.certificate_first_line_wording' component={TextField} label='Certificate first line wording' />
          <Field name='detail.certificate_first_line_wording_id' component={TextField} label='Certificate first line wording (Bahasa Indonesia)' />
          <Field name='detail.certificate_second_line_wording' component={TextField} label='Certificate second line wording' />
          <Field name='detail.certificate_scope_intro_wording' component={TextField} label='Certificate scope intro wording' />
          <Field name='detail.certificate_scope_intro_wording_id' component={TextField} label='Certificate scope intro wording (Bahasa Indonesia)' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

export default StandardForm

const BaseStandardCreateForm = props => <StandardForm {...props} mode='create' />

export const StandardCreateForm = reduxForm({
  form: 'standardCreate' // validate, warn
})(BaseStandardCreateForm)

const BaseStandardUpdateForm = props => <StandardForm {...props} mode='update' />

export const StandardUpdateForm = reduxForm({
  form: 'standardUpdate' // validate, warn
})(BaseStandardUpdateForm)
