import React from 'react'

import File from '../common/File'

export default function AdditionalInformationWidget ({ questionnaire: q, accessToken }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-newspaper-o' /> Additional Information</h3>
      </div>
      <div className='widget-content'>
        <table className='table table-hover table-striped'>
          <tbody>
            {q?.detail?.requested_information?.quality_managament_manual && (
              <tr>
                <th>Quality Management Manual</th>
                <td>
                  <File file={q.detail.requested_information.quality_managament_manual} accessToken={accessToken} />
                </td>
              </tr>
            )}
            {q?.detail?.requested_information?.legal_documents && (
              <tr>
                <th>Legal Documents</th>
                <td>
                  <File file={q.detail.requested_information.legal_documents} accessToken={accessToken} />
                </td>
              </tr>
            )}
            {q?.detail?.requested_information?.organisation_chart && (
              <tr>
                <th>Organization Chart</th>
                <td>
                  <File file={q.detail.requested_information.organisation_chart} accessToken={accessToken} />
                </td>
              </tr>
            )}
            {q?.detail?.requested_information?.business_process_map && (
              <tr>
                <th>Business Process Map</th>
                <td>
                  <File file={q.detail.requested_information.business_process_map} accessToken={accessToken} />
                </td>
              </tr>
            )}
            
            {q?.detail?.requested_information?.internal_audit && (
              <tr>
                <th>Results or summary of internal audits within the last 12 months</th>
                <td>
                  <File file={q.detail.requested_information.internal_audit} accessToken={accessToken} />
                </td>
              </tr>
            )}

            {q?.detail?.requested_information?.external_audit && (
              <tr>
                <th>Results of the management review since the last external audit</th>
                <td>
                  <File file={q.detail.requested_information.external_audit} accessToken={accessToken} />
                </td>
              </tr>
            )}

            {q?.detail?.requested_information?.last_audit_reports && (
              <tr>
                <th>Audit report and nonconformity management of the last certification body (additionally for transfer audits only)</th>
                <td>
                  <File file={q.detail.requested_information.last_audit_reports} accessToken={accessToken} />
                </td>
              </tr>
            )}

            {q?.detail?.requested_information?.last_audit_closing && (
              <tr>
                <th>Evidence of the last certification body that nonconformities are “closed” (additionally for transfer audits only)</th>
                <td>
                  <File file={q.detail.requested_information.last_audit_closing} accessToken={accessToken} />
                </td>
              </tr>
            )}

            {q?.detail?.requested_information?.last_certification && (
              <tr>
                <th>Certificates of the last certification body (additionally for transfer audits only)</th>
                <td>
                  <File file={q.detail.requested_information.last_certification} accessToken={accessToken} />
                </td>
              </tr>
            )}

            {q?.detail?.annex_multisite && (
              <tr>
                <th>Multi Site Eligibility Check.</th>
                <td>
                  <File file={q.detail.annex_multisite} accessToken={accessToken} />
                </td>
              </tr>
            )}

            {q?.detail?.annex_transfer && (
              <tr>
                <th>Transfer Audit Eligibility Check</th>
                <td>
                  <File file={q.detail.annex_transfer} accessToken={accessToken} />
                </td>
              </tr>
            )}

            <tr>
              <th> Ongoing project </th>
              <td> 
                {q.detail.ongoing_project
                  ? q.detail.ongoing_project.map((x, i) => (
                    <p key={i}>{`Project name: ${x[0]} - Location: ${x[1]}`}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <th> Project done within last 1 year </th>
              <td>
                {q.detail.within_last_year
                  ? q.detail.within_last_year.map((x, i) => (
                    <p key={i}>{`Project name: ${x[0]} - Location: ${x[1]}`}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <th> Multi Site Eligibility Check </th>
              <td>
                <File file={q.detail.annex_multisite} accessToken={accessToken} />
              </td>
            </tr>

            <tr>
              <th> Transfer Audit Eligibility Check </th>
              <td>
                <File file={q.detail.annex_multisite} accessToken={accessToken} />
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  )
}
