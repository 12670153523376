import React, { useState, useEffect } from 'react'
import { change, arrayPush, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

import BasicModal from '../../components/common/BasicModal'
import QuestionnaireAuditLocationForm from '../../components/Questionnaires/QuestionnaireAuditLocationForm'
import { CustomerLocationCreateForm as CustomerLocationForm } from '../../components/Customers/CustomerLocationForm'
import { CustomerContactCreateForm as CustomerContactForm } from '../../components/Customers/CustomerContactForm'

import {
  _fetchCustomerLocation,
  fetchCustomerLocation,
  createLocation,
  createContact
} from '../../actions/customers'

export function QuestionnaireAuditLocation (props) {
  const {
    accessToken,
    changeForm,
    fetchQuestionnaire,
    questionnaire,
    updateQuestionnaire,
    arrayPush,
    createLocation,
    createContact,
    // _fetchCustomerLocation,
    formValue,
    user
  } = props
  const [openNewLocation, setOpenNewLocation] = useState(false)
  const [openNewContact, setOpenNewContact] = useState(false)
  // const multiLocation = get(questionnaire, 'detail.multi_location')
  const { locations } = formValue

  // automatically set location data from main location
  useEffect(() => {
    // eslint-disable-next-line
    async function fetchData() {
      const mainLocation = await _fetchCustomerLocation(
        questionnaire.customer.id,
        questionnaire.detail.main_location.id,
        accessToken
      )
      changeForm('locations', [
        {
          location: {
            label: mainLocation.data.name,
            value: mainLocation.data
          }
        }
      ])
    }
    if (locations && locations.length === 0) {
      fetchData()
    }
  }, [locations]); // eslint-disable-line

  function prepareInitialValues (e) {
    let data = {
      locations: e.locations
        ? e.locations.map(x => {
          return {
            location: {
              value: x.location,
              label: x.location.name
            },
            detail: x.detail
          }
        })
        : [],
      detail: { ...e.detail },
      advance: e.detail.audit_location_complete
    }

    return data
  }

  function handleSubmit (e) {
    let target
    const request = {
      id: questionnaire.id,
      locations: e.locations
        ? e.locations.map(x => ({
          location_id: x.location.value.id,
          detail: {
            ...x.detail,
            number_of_employees:
                parseInt(x.detail.number_of_employees_temporary) +
                parseInt(x.detail.number_of_employees_fulltime) +
                parseInt(x.detail.number_of_employees_sub_contract)
          }
        }))
        : null,
      detail: {}
    }
    if (e.advance) {
      request.detail.audit_location_complete = true
      target = `/questionnaires/${questionnaire.id}/additional-information`
    }
    if (user.data.role.includes('Customer')) {
      request.detail.input_by_customer = true
    }
    return updateQuestionnaire(request, accessToken, target).then(x => {
      fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  function handleSubmitLocation (e) {
    return createLocation(
      { ...e, customer_id: questionnaire.customer.id },
      accessToken
    ).then(locationData => {
      setOpenNewLocation(false)
      arrayPush('locations', {
        location: {
          label: locationData.name,
          value: locationData
        }
      })
    })
  }

  function handleSubmitContact (e) {
    return createContact(
      { ...e, customer_id: questionnaire.customer.id },
      accessToken
    ).then(contactData => {
      changeForm(openNewContact, {
        label: contactData.name,
        value: contactData
      })
      setOpenNewContact(false)
    })
  }

  return (
    <div className='tab-pane active'>
      <h1>Audit Location</h1>
      <div className='row'>
        <div className='col-md-8'>
          <QuestionnaireAuditLocationForm
            changeForm={changeForm}
            onSubmit={handleSubmit.bind(this)}
            initialValues={prepareInitialValues(questionnaire)}
            locationAPI={{
              url:
                process.env.REACT_APP_BASE_API +
                '/customers/' +
                questionnaire.customer.id +
                '/locations',
              accessToken: accessToken
            }}
            contactAPI={{
              url:
                process.env.REACT_APP_BASE_API +
                '/customers/' +
                questionnaire.customer.id +
                '/contacts',
              accessToken: accessToken
            }}
            openNewLocation={() => setOpenNewLocation(true)}
            openNewContact={setOpenNewContact}
          />
        </div>
      </div>
      <BasicModal
        open={openNewLocation}
        onClose={() => setOpenNewLocation(false)}
      >
        <CustomerLocationForm
          onSubmit={handleSubmitLocation.bind(this)}
          basic
        />
      </BasicModal>
      <BasicModal
        open={openNewContact}
        onClose={() => setOpenNewContact(false)}
      >
        <CustomerContactForm
          onSubmit={handleSubmitContact.bind(this)}
          initialValues={{
            customer_location_id: props.locations[0] ? props.locations[0].id : ''
          }}
          locations={props.locations}

        />
      </BasicModal>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    formValue: getFormValues('QuestionnaireAuditLocationForm')(state) || {},
    locations: state.customers.customerLocationList.data,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createLocation: (data, token) => dispatch(createLocation(data, token)),
    createContact: (data, token) => dispatch(createContact(data, token)),
    fetchCustomerLocation: (customerId, id, token) => dispatch(fetchCustomerLocation(customerId, id, token)),
    changeForm: (field, value) =>
      dispatch(change('QuestionnaireAuditLocationForm', field, value)),
    arrayPush: (field, value) =>
      dispatch(arrayPush('QuestionnaireAuditLocationForm', field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireAuditLocation)
