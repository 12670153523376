export const initialState = {
  changeList: { data: null, pagination: null },
  change: { data: null },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_CHANGE_FULFILLED":
      return { ...state, change: { data: { ...action.payload } } };

    case "FETCH_CHANGES_FULFILLED":
      return {
        ...state,
        changeList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
        },
      };

    case "FETCH_CHANGE_FULFILLED":
      return { ...state, change: { data: { ...action.payload } } };

    case "UPDATE_CHANGE_FULFILLED":
      return { ...state, change: { data: { ...action.payload } } };

    case "DELETE_CHANGE_FULFILLED":
      return { ...state, change: { data: null } };

    case "LOGOUT_FULFILLED":
      return { ...initialState };

    default:
      return state;
  }
};
