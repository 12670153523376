import React, { Component } from 'react';
import AutoCompleteFilterField from '../common/AutoCompleteFilterField';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import auditPlanStatuses from '../../const/auditPlanStatuses';

class BaseEventFilter extends Component {
  render() {
    const { handleSubmit, submitting, isCustomer } = this.props;
    return (
      <div>
        <form className="form-inline" onSubmit={handleSubmit}>
          {!isCustomer && (
            <Field
              name="customer"
              component={AutoCompleteFilterField}
              label="Customer"
              api={this.props.customerAPI}
            />
          )}
           &nbsp;
          <div className="form-group">
            <Field name="status" component="select" className="form-control">
              <option value="">All Status &nbsp;</option>
              {auditPlanStatuses.map((x, i) => (
                <option key={i} value={x.label}>
                  {x.label} &nbsp;
                </option>
              ))}
            </Field>
          </div>
           &nbsp;
          <div className="form-group">
            <label className="sr-only" htmlFor="exampleInputEmail2">
              Order Number
            </label>
            <Field
              name="order_number"
              component="input"
              type="text"
              placeholder="Search for order number..."
              className="form-control"
            />
          </div>
          &nbsp;
          {
            // <div className='form-group'>
            //   <label className='sr-only' htmlFor='exampleInputEmail2'>Date</label>
            //   <Field
            //     name='date'
            //     component='input'
            //     type='text'
            //     placeholder='On Date'
            //     className='form-control'
            //   />
            // </div>
          }
          &nbsp;
          <button
            disabled={submitting}
            type="submit"
            className="btn btn-default"
          >
            Filter
          </button>
          <Link to="/audit-plans" className="btn btn-link pull-right">
            Reset
          </Link>
        </form>
        <hr />
      </div>
    );
  }
}

let EventFilter = reduxForm({
  form: 'auditPlanFilter', //, validate, warn
})(BaseEventFilter);

export { BaseEventFilter };
export default EventFilter;
