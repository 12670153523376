const flasherInitialState = [];
export default (state = flasherInitialState, action) => {
  switch (action.type) {
    case "LOGOUT_FULFILLED":
      return flasherInitialState;
    case "LOGIN_FULFILLED":
      return state.filter((x) => {
        return x.id !== "login-notice";
      });
    case "REGISTER_FULFILLED":
      return state.filter((x) => {
        return x.id !== "login-notice";
      });
    case "CREATE_FLASH":
      const { id, type, message } = action.payload;
      if (!state.find((x) => x.id === id)) {
        return [...state, { id, type, message }];
      } else {
        return state;
      }
    case "DELETE_FLASH":
      return state.filter((x) => {
        return x.id !== action.payload;
      });
    default:
      return state;
  }
};
