import React from 'react'
import File from '../common/File'

export default class DetailWidget extends React.Component {
  render () {
    const { accessToken } = this.props
    const r1 = this.props?.order?.questionnaire?.detail?.requested_information;
    const r = this.props?.order?.detail;

    if (!r && !r1) {
      return ''
    }

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Order Prerequisite</h3>
        </div>
        <div className='widget-content'>
          <div className='row'>
            <div className='col-md-6 key-val'><p>Quality Management Manual with Revision including scope, details of justification for any exclusions, policy, targets</p></div>
            <div className='col-md-6'><File file={(r1 && r1.quality_managament_manual) || (r && r.quality_managament_manual)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Legal documents</p></div>
            <div className='col-md-6'><File file={(r1 && r1.legal_documents) || (r && r.legal_documents)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Current organisation chart of the company</p></div>
            <div className='col-md-6'><File file={(r1 && r1.organisation_chart) || (r && r.organisation_chart)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Business Process Map</p></div>
            <div className='col-md-6'><File file={(r1 && r1.business_process_map) || (r && r.business_process_map)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Results or summary of internal audits within the last 12 months</p></div>
            <div className='col-md-6'><File file={(r1 && r1.internal_audit) || (r && r.internal_audit)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Results of the management review since the last external audit</p></div>
            <div className='col-md-6'><File file={(r1 && r1.external_audit) || (r && r.external_audit)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Audit report and nonconformity management of the last certification body (additionally for transfer audits only)</p></div>
            <div className='col-md-6'><File file={(r1 && r1.last_audit_reports) || (r && r.last_audit_reports)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Evidence of the last certification body that nonconformities are “closed” (additionally for transfer audits only)</p></div>
            <div className='col-md-6'><File file={(r1 && r1.last_audit_closing) || (r && r.last_audit_closing)} accessToken={accessToken} /></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-6 key-val'><p>Certificates of the last certification body (additionally for transfer audits only)</p></div>
            <div className='col-md-6'><File file={(r1 && r1.last_certification) || (r && r.last_certification)} accessToken={accessToken} /></div>
          </div>
          <hr />
        </div>
      </div>
    )
  }
}
