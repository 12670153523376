import orderStatuses from "../const/orderStatuses";

export function isVeto(user, order) {
  return (
    order.users.filter((x) => x.role === "Veto Person" && x.user.id === user.id)
      .length > 0
  );
}

export function isAuditor(user, order) {
  return (
    order.users.filter((x) => x.role === "Auditor" && x.user.id === user.id)
      .length > 0
  );
}

export function isLeadAuditor(user, order) {
  return (
    order.users.filter(
      (x) => x.role === "Lead Auditor" && x.user.id === user.id
    ).length > 0
  );
}

export function isCustomer(user, order) {
  return (
    order.users.filter((x) => x.role === "Customer" && x.user.id === user.id)
      .length > 0
  );
}

export function isAfter(a, b) {
  return orderStatuses.reduce((sum, x, i) => {
    if (sum !== null) {
      return sum;
    }
    if (x.label === a) {
      return false;
    }
    if (x.label === b) {
      return true;
    }
    return sum;
  }, null);
}

export function getStage(order) {
  if (order.phase === "Recertification Audit") {
    return "Recertification";
  }
  if (order.phase === "Transfer Audit") {
    return "Transfer";
  }
  if (order.phase.indexOf("Surveillance") === 0) {
    return "Surveillance";
  }
  if (["Audit Stage 1 On Process"].indexOf(order.status) >= 0) {
    return "Stage 1";
  }
  if (["Audit Stage 2 On Process"].indexOf(order.status) >= 0) {
    return "Stage 2";
  }
  if (
    ["VA Stage 1 On Process", "VA Stage 2 On Process", "VA On Process"].indexOf(
      order.status
    ) >= 0
  ) {
    return "Verification";
  }
  return null;
}

export function getPhaseInitial(phase) {
  if (phase === "Certification Audit") {
    return "CA";
  } else if (phase === "Recertification Audit") {
    return "RC";
  } else {
    return phase.replace("Surveillance Audit ", "SA");
  }
}
