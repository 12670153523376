import React from 'react'
import { Link } from 'react-router-dom'

import { getReportLabel } from '../../const/orders'

export default class AuditReportTable extends React.Component {
  render () {
    const { reports } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Customer</th>
              <th>Order</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((x, i) => {
              return (
                <tr key={i}>
                  <td><Link to={`/orders/${x.order.id}/reports/${x.id}`}>AR.{x.order.number}.{x.type}</Link></td>
                  <td>{x?.order?.customer?.name}</td>
                  <td><Link to={`/orders/${x.order.id}`}>{x.order.number}</Link></td>
                  <td>{getReportLabel(x.status)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
