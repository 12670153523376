import React, { Fragment, useState, useEffect } from 'react'
import get from 'lodash/get'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import intersection from 'lodash/intersection'

import ItemLog from '../../components/common/ItemLog'
import { QuotationUpdateForm as QuotationForm } from '../../components/Quotations/QuotationForm'
import QuotationDetail from '../../components/Quotations/QuotationDetail'
import ScheduleTable from '../../components/Quotations/ScheduleTable'
import AmendmentWidget from '../../components/Quotations/AmendmentWidget'

import CertificationInformationWidget from '../../components/Questionnaires/CertificationInformationWidget'
import AdditionalInformationWidget from '../../components/Questionnaires/AdditionalInformationWidget'
import MandaysCalculationWidget from '../../components/Questionnaires/MandaysCalculationWidget'
import AnnexWidget from '../../components/Questionnaires/AnnexWidget'
import ReviewWidget from '../../components/Questionnaires/ReviewWidget'

import CustomerWidget from '../../components/Customers/CustomerWidget'
import LocationWidget from '../../components/Customers/LocationWidget'
import ContactWidget from '../../components/Customers/ContactWidget'

import BasicModal from '../../components/common/BasicModal'

// import Affix from '../../components/common/Affix'
import ReviewForm from '../../components/common/ReviewForm'

import additionalServices from '../../const/additionalServices'

import { fetchQuestionnaire } from '../../actions/questionnaires'
import { fetchQuotation, updateQuotation } from '../../actions/quotations'
import { fetchOrders } from '../../actions/orders'

import { totalFeesUpdateSelector, filterAuditCycle, getFeesByYearUpdate } from '../../selectors/quotations'
import { annexWithValueSelector } from '../../selectors/questionnaires'

export function QuotationView (props) {
  const {
    fetchQuotation,
    fetchQuestionnaire,
    fetchOrders,
    updateQuotation,
    questionnaire,
    quotation,
    user,
    accessToken,
    getFees,
    match,
    orders,
    questionnaireAnnex,
    feesByYear
  } = props
  const { role } = user.data

  const status = get(quotation, 'status')
  const customer = get(questionnaire, 'detail.copy.customer')
  const mainContact = get(questionnaire, 'detail.main_contact')

  const [submitting, setSubmitting] = useState(false)
  const [temporaryUpdate, setTemporaryUpdate] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [stagedSchedule, setStagedSchedule] = useState(false)
  const [action, setAction] = useState(false)

  useEffect(() => {
    fetchQuotation(match.params.id, accessToken)
  }, [match.params.id]) // eslint-disable-line

  useEffect(() => {
    if (quotation && quotation.questionnaire) {
      fetchQuestionnaire(quotation.questionnaire.id, accessToken)
      fetchOrders(`?quotation_id=${quotation.id}`, accessToken)
    }
  }, [quotation]) // eslint-disable-line

  function handleSubmit (e) {
    setSubmitting(true)
    const data = {
      id: match.params.id,
      sub_total: getFees,
      total: getFees,
      detail: { ...e.detail },
      remarks: e.detail.remarks,
      log: {
        remarks: e.detail.remarks
      }
    }

    if (
      e.advance &&
      ['Approved', 'Quoted', 'Ordered'].includes(questionnaire.status)
    ) {
      data.status = 'Created'
    }
    if (
      e.advance &&
      ['Amendment Draft'].includes(quotation.status)
    ) {
      data.status = 'Amendment Created'
    }

    // console.log(questionnaire.status, e.advance)
    // console.log(data)

    return updateQuotation(data, accessToken, `/quotations`).then(() => {
      setSubmitting(false)
    })
  }

  function handleSubmitReview (e) {
    setSubmitting(true)
    let request = {
      id: quotation.id,
      log: e.log ? e.log.detail : '',
      status: e.result
    }

    return updateQuotation(request, accessToken, '/quotations').then(() => {
      setSubmitting(false)
    })
  }

  function handleProcedure (action, payload = null) {
    setSubmitting(true)
    const data = {
      id: quotation.id,
      procedure: action,
      payload
    }
    updateQuotation(data, accessToken, `/orders`)
  }

  function openAction (action, scheduleId) {
    setOpenModal(true)
    setAction(action)
    setStagedSchedule(scheduleId)
  }

  function getSide () {
    let result
    let message = false
    let showForm = false
    let requireFile = null

    switch (status) {
      case 'Draft':
        message = `Current status is <strong>Draft</strong>. Please wait for quotation from <strong>Sales Officer</strong>`
        showForm = false
        break
      case 'Created':
        message = `Current status is <strong>Created</strong>. Please wait for review from <strong>Sales Manager</strong>`
        showForm = intersection(['Sales Manager', 'Super Admin'], role).length > 0
        result = ['Reviewed by SM', 'Need to Revise']
        break
      case 'Reviewed by SM':
        message = `Current status is <strong>Reviewed by Sales Manager</strong>. Please wait for review from <strong>Board of Director</strong>`
        showForm = intersection(['Board of Director', 'Super Admin'], role).length > 0
        result = ['Published to Customer', 'Need to Revise']
        break
      case 'Published to Customer':
        message = `Current status is <strong>Published to Customer</strong>. Please wait for review from <strong>Customer</strong> or manual approval by <strong>Sales Officer</strong>`
        if (role.includes('Customer')) {
          result = ['Approved by Customer', 'Need to Revise', 'Failed/Rejected']
          showForm = true
          // requireFile = true
        } else if (quotation.sales.id === user.id || role.includes['Super Admin']) {
          result = ['Manual Approval', 'Need to Revise', 'Failed/Rejected']
          showForm = true
          requireFile = true
        }
        break
      case 'Manual Approval':
        message = `Current status is <strong>Manual Approval</strong>. Please wait for review from <strong>Operational Manager</strong>.`
        result = ['Approved by Customer', 'Need to Revise']
        showForm = intersection(['Operational Manager', 'Super Admin'], role).length > 0
        break

      case 'Amendment Created':
        message = `Current status is <strong>Amendment Created</strong>. Please wait for review from <strong>Sales Manager</strong>`
        showForm = intersection(['Sales Manager', 'Super Admin'], role).length > 0
        result = ['Amendment Reviewed by SM', 'Amendment Need to Revise']
        break
      case 'Amendment Reviewed by SM':
        message = `Current status is <strong>Amendment Reviewed by Sales Manager</strong>. Please wait for review from <strong>Board of Director</strong>`
        showForm = intersection(['Board of Director', 'Super Admin'], role).length > 0
        result = ['Amendment Published to Customer', 'Amendment Need to Revise']
        break
      case 'Amendment Published to Customer':
        message = `Current status is <strong>Amendment Published to Customer</strong>. Please wait for review from <strong>Customer</strong> or manual approval by <strong>Sales Officer</strong>`
        if (role.includes('Customer')) {
          result = ['Amendment Approved by Customer', 'Amendment Need to Revise', 'Failed/Rejected']
          showForm = true
          requireFile = true
        } else if (quotation.sales.id === user.id || role.includes['Super Admin']) {
          result = ['Amendment Manual Approval', 'Amendment Need to Revise', 'Failed/Rejected']
          showForm = true
          requireFile = true
        }
        break
      case 'Amendment Manual Approval':
        message = `Current status is <strong>Amendment Manual Approval</strong>. Please wait for review from <strong>Operational Manager</strong>.`
        result = ['Amendment Approved by Customer', 'Amendment Need to Revise']
        showForm = intersection(['Operational Manager', 'Super Admin'], role).length > 0
        break
      default:
        break
    }

    return (
      <div className='form-summary'>
        { message &&
          <div className='alert alert-info' dangerouslySetInnerHTML={{ __html: message }} />
        }
        { showForm &&
          <div className='widget'>
            <div className='widget-header'>
              <h3><i className='fa fa-tasks' /> Review</h3>
            </div>
            <div className='widget-content'>
              {props.quotation &&
                <ReviewForm
                  quotation={props.quotation}
                  onSubmit={handleSubmitReview.bind(this)}
                  fileAPI={{
                    url: process.env.REACT_APP_BASE_API,
                    accessToken: accessToken
                  }}
                  requireFile={requireFile}
                  submitting={submitting}
                  result={result}
                />
              }
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <div>
      <div className='content'>
        {quotation &&
          <div className='main-header'>
            {['Draft', 'Temporary'].indexOf(quotation.status) >= 0 && quotation.sales.id === user.id &&
              <h2>Create Quotation </h2>
            }
            {['Draft', 'Temporary'].indexOf(quotation.status) < 0 &&
              <h2>Review Quotation {quotation.number} <span className='small'>{quotation.id}</span></h2>
            }
            {/* <em>normal sidebar with minified mode by default</em> */}
          </div>
        }
        <div className='row'>
          <div className='col-md-8'>
            {quotation && questionnaire
              ? <div>
                { (
                  (quotation.status === 'Draft' && user.data.role.includes('Sales')) ||
                  (quotation.status === 'Need to Revise' && user.data.role.includes('Sales')) ||
                  (quotation.status === 'Amendment Draft') ||
                  (quotation.status === 'Amendment Need to Revise') ||
                  temporaryUpdate
                )
                  ? <QuotationForm
                    onSubmit={handleSubmit.bind(this)}
                    initialValues={quotation}
                    questionnaire={questionnaire}
                    all={quotation.status.indexOf('Amendment') >= 0}
                    additionalServices={additionalServices}
                    mandays={questionnaire.detail.mandays_roundup}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: accessToken
                    }}
                    phases={filterAuditCycle(questionnaire.detail.audit_cycle || [])}
                    submitting={submitting}
                  />
                  : <div className='widget'>
                    <div className='widget-content'>
                      <QuotationDetail
                        quotation={quotation}
                        allowEdit={intersection(['Sales'], role).length > 0}
                        questionnaire={questionnaire}
                        accessToken={accessToken}
                        onEditTemporary={() => setTemporaryUpdate(true)}
                      />
                    </div>
                  </div>
                }
                { orders && ['Approved by Customer', 'Amendment Approved by Customer', 'Replaced'].includes(quotation.status) &&
                  <div className='widget'>
                    <div className='widget-header'>
                      <h3><i className='fa fa-tasks' /> Quotation Schedules</h3>
                    </div>
                    <div className='widget-content'>
                      <ScheduleTable
                        orders={orders}
                        schedules={quotation.quotation_schedules}
                        onInitiate={id => openAction('initiate-schedule', id)}
                        onRecertification={id => openAction('initiate-recertification', id)}
                        onSendConfirmationRequest={id => openAction('request-initiation-confirmation', id)}
                        onConfirm={id => openAction('confirmation', id)}
                        role={user.data.role}
                      />
                    </div>
                  </div>
                }
              </div>
              : <div>loading</div>
            }
          </div>
          <div className='col-md-4'>
            { quotation && <div>{getSide()}</div> }
            {
              (
                (quotation && quotation.status === 'Draft') ||
                (quotation && quotation.status === 'Need to Revise')
              ) &&
              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-tasks' /> Basic Data</h3> <em>- Summary of Tasks</em>
                </div>
                { questionnaire &&
                <div className='widget-content'>
                  <dl>
                    <dt><strong>Mandays by Formula</strong></dt>
                    { questionnaire.detail && questionnaire.detail.mandays
                      ? <dd>{questionnaire.detail.mandays} days</dd>
                      : <dd>n/a</dd>
                    }
                    <dt><strong>Mandays by A Team</strong></dt>
                    { questionnaire.detail && questionnaire.detail.mandays_by_a_team
                      ? <dd>{questionnaire.detail.mandays_by_a_team} days</dd>
                      : <dd>n/a</dd>
                    }
                    <dt><strong>Mandays Round Up</strong></dt>
                    { questionnaire.detail && questionnaire.detail.mandays_roundup
                      ? <dd>{questionnaire.detail.mandays_roundup} days ({questionnaire.detail.mandays_adjustment} %)</dd>
                      : <dd>n/a</dd>
                    }
                    { feesByYear.map((x, i) => (
                      <Fragment key={i}>
                        <dt>{x.phase} {x.description && <span>({x.description})</span>}</dt>
                        <dd>
                          <span>Rp</span>&nbsp;
                          <NumberFormat
                            displayType='text'
                            value={x.amount}
                            thousandSeparator
                          />
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                </div>
                }
              </div>
            }
            { quotation && !role.includes('Customer') &&
            <div>
              <ItemLog
                log={quotation.log}
                entity='Quotation'
                accessToken={accessToken}
              />
            </div>
            }

          </div>
        </div>
        <div className='row'>
          <div className='col-md-8'>
            { questionnaire &&
              <Fragment>
                <div className='row'>
                  <div className='col-md-6'>
                    <CustomerWidget customer={customer} accessToken={accessToken} />
                  </div>
                  <div className='col-md-6'>
                    <ContactWidget contact={mainContact} accessToken={accessToken} title='Main Contact' />
                  </div>
                </div>
                <CertificationInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
                {questionnaire.locations.map((x, i) => (
                  <LocationWidget
                    key={i}
                    location={x.location}
                    detail={x.detail}
                    accessToken={accessToken}
                    title={`Audit Location : ${x.location.name}`}
                  />
                ))}
                <AdditionalInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
                <AnnexWidget annex={questionnaireAnnex} questionnaire={questionnaire} accessToken={accessToken} />
                <MandaysCalculationWidget questionnaire={questionnaire} accessToken={accessToken} />
                <ReviewWidget questionnaire={questionnaire} accessToken={accessToken} />
                { quotation && quotation.amendment &&
                  <AmendmentWidget amendment={quotation.amendment} />
                }
              </Fragment>
            }
          </div>
        </div>
      </div>
      {
        <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
          {action === 'initiate-schedule' &&
            <Fragment>
              <p>
                <button
                  className='btn btn-primary btn-block btn-amendment'
                  disabled={submitting}
                  onClick={() => handleProcedure('amendment')}
                >
                  Amend quotation prior to initiate phase
                </button>
              </p>
              <p><button className='btn btn-primary btn-block btn-revision' disabled={submitting} onClick={() => handleProcedure('revision')}>Revise questionnaires prior to initiate phase </button></p>
              <p><button className='btn btn-primary btn-block btn-revision-amendment' disabled={submitting} onClick={() => handleProcedure('revision-amendment')}>Revise questionnaire and amend quotation prior to initiate phase </button></p>
              <p>
                <button
                  className='btn btn-primary btn-block btn-initiate'
                  disabled={submitting}
                  onClick={() => handleProcedure('initiate-schedule', {
                    scheduleId: stagedSchedule
                  })}
                >
                  Iniate phase without questionnaire revision or quotation amendment
                </button>
              </p>
            </Fragment>
          }
          {action === 'request-initiation-confirmation' &&
            <Fragment>
              <p>
                <button
                  className='btn btn-primary btn-block'
                  disabled={submitting}
                  onClick={() => handleProcedure('request-initiation-confirmation', {
                    scheduleId: stagedSchedule
                  })}
                >
                  Send confirmation to initiate phase request
                </button>
              </p>
            </Fragment>
          }
          {action === 'confirmation' &&
            <Fragment>
              <p>
                <button
                  className='btn btn-primary btn-block'
                  disabled={submitting}
                  onClick={() => handleProcedure('initiation-confirmation', {
                    scheduleId: stagedSchedule
                  })}
                >
                  Confirm to initiate next phase
                </button>
              </p>
            </Fragment>
          }
          {action === 'initiate-recertification' &&
            <Fragment>
              <p>
                <button
                  className='btn btn-primary btn-block'
                  disabled={submitting}
                  onClick={() => handleProcedure('initiate-recertification', {
                    scheduleId: stagedSchedule
                  })}
                >
                  Confirm to initiate recertification
                </button>
              </p>
            </Fragment>
          }
        </BasicModal>
      }
    </div>
  )
}

function mapStateToProps (state) {
  return {
    quotation: state.quotations.quotation.data,
    questionnaire: state.questionnaires.questionnaire.data,
    orders: state.orders.orderList.data,
    // needBOD: needBODSelector(state),
    quotationFormValue: getFormValues('quotationForm')(state),
    getFees: totalFeesUpdateSelector(state),
    feesByYear: getFeesByYearUpdate(state),
    questionnaireAnnex: annexWithValueSelector(state),
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchQuestionnaire: (id, key) => dispatch(fetchQuestionnaire(id, key)),
    fetchQuotation: (id, key) => dispatch(fetchQuotation(id, key)),
    fetchOrders: (params, key) => dispatch(fetchOrders(params, key)),
    updateQuotation: (data, key, target) => dispatch(updateQuotation(data, key, target))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(QuotationView)
