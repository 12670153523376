import React, { Component } from 'react';
import RichTextEditor from 'react-rte';

import Required from './Required'

export default class RichTextField extends Component {
  state = {
    value: this.props?.input?.value ? RichTextEditor.createValueFromString(this.props.input.value, 'html') : RichTextEditor.createEmptyValue(),
  };

  onChange = value => {
    this.setState({ value });
    if (this.props.input.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.input.onChange(value.toString('html'));
    }
  };

  render() {
    const {
      disabled,
      help,
      input,
      label,
      meta,
      required,
      stack
    } = this.props;
    const { touched, error, warning } = meta;

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
      ]
    };
    return (
      <div className={`form-group ${input.name.split('.').join('_')}`}>
        <label
          htmlFor={label}
          className={`${!stack ? 'col-sm-3' : ''} control-label`}
        >
          {label} {required && <Required />}
        </label>
        <div className={!stack ? 'col-sm-9' : ''}>
          <RichTextEditor value={this.state.value} onChange={this.onChange} toolbarConfig={toolbarConfig} disabled={disabled}/>
          {help && <p className="help-text">{help}</p>}
          {touched &&
            ((error && <div className="alert alert-danger">{error}</div>) ||
              (warning && (
                <div className="alert alert-warning">{warning}</div>
              )))}
        </div>
      </div>
    );
  }
}
