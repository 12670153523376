import React from 'react'

export default function CertificationInformationWidget ({ questionnaire: q, props }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-id-card-o' /> Certification Information</h3>
      </div>
      <div className='widget-content'>

        <table className='table table-hover table-striped'>
          <tbody>
            <tr>
              <td>
                <p><strong>Standard and Scope</strong></p>
                {q.scopes
                  ? q.scopes.map((x, i) => (
                    <p key={i}>{x.standard.name} : {x?.scope?.detail?.number || '-'}. {x.scope.name}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            {
              // <tr>
              //   <td>
              //     <p><strong>Scope wording of operation to be certified</strong></p>
              //     <p>{q.detail.scope ? <span>{q.detail.scope}</span> : <span>n/a</span>}</p>
              //   </td>
              // </tr>
            }


            <tr>
              <td>
                <p><strong>Is an integrated certification audit (IMS) required?</strong></p>
                <p>
                  {q.detail.integrated ? <span>{q.detail.integrated}</span> : <span>n/a</span>}
                  {q.detail.integrated_detail ? <span>, {q.detail.integrated_detail}</span> : <span>n/a</span>}
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Is combined certification audit required?</strong></p>
                <p>{q.detail.combined ? <span>{q.detail.combined}</span> : <span>n/a</span>}, {q.detail.combined_detail ? <span>{q.detail.combined_detail}</span> : <span>n/a</span>}</p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Multi location audit</strong></p>
                <p>{q.locations.length >= 2 ? 'Multi location' : 'Single location'}</p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Transfer certification audit</strong></p>
                <div>
                {q.detail.transfer_audit
                  ? q.detail.transfer_audit.map((x, i) => (
                   
                <table className='table table-striped'>
                  <tr>
                    <th>Certificate No</th>
                    <th>Standard</th>
                    <th>Certification body</th>
                    <th>Cycle</th>
                  </tr>
                  <tr>
                    <td key={i}>{`${x[0]}`}</td>
                    <td key={i}>{`${x[1]}`}</td>
                    <td key={i}>{`${x[2]}`}</td>
                    <td key={i}>{`${x[3]}`}</td>
                  </tr>
                </table>

                 ))
                  : <span>n/a</span>
                }

                </div>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Existing certifications</strong></p>
                {q.detail.existing_certification
                  ? q.detail.existing_certification.map((x, i) => (
                    <p key={i}>{`Certificate no: ${x[0]} - Standard: ${x[1]} - Certification body: ${x[2]} - Audit date: ${x[3]} - Audit expiration: ${x[4]}`}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Integrated Management System (IMS) implemntation</strong></p>
                <p>{q.detail.existing_ims === 'Yes' ? <span>{q.detail.existing_ims_detail}</span> : <span>n/a</span>}</p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Member of an industry, profesional or trade association/federation</strong></p>
                <p>{q.detail.association_member === 'Yes' ? <span>{q.detail.association_member_detail}</span> : <span>n/a</span>}</p>

              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Further information</strong></p>
                <p>{q.detail.other_information ? <span>{q.detail.other_information}</span> : <span>n/a</span>}</p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Other factors which can influence to the audit time and the cost</strong></p>
                {q.detail.influencing_factor && q.detail.influencing_factor.length > 0
                  ? q.detail.influencing_factor.map((x, i) => (
                    <p key={i}>{x}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  )
}
