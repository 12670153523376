const quotationScheduleStatuses = [
  {
    label: 'Transfer',
    color: 'warning'
  },{
    label: 'Active',
    color: 'warning'
  },{
    label: 'Ordered',
    color: 'warning'
  },{
    label: 'Next',
    color: 'warning'
  },{
    label: 'Moved',
    color: 'warning'
  },{
    label: 'Fulfilled',
    color: 'warning'
  },{
    label: 'Finish',
    color: 'warning'
  },{
    label: 'Draft',
    color: 'warning'
  },
]

export default quotationScheduleStatuses
