export const phases = [
  'Certification Audit Stage 1',
  'Certification Audit Stage 2',
  'Recertification Audit',
  'Transfer Audit',
  'Follow-up Audit / Special Audit',
  'Surveillance Audit 1',
  'Surveillance Audit 2',
  'Surveillance Audit 3',
  'Surveillance Audit 4',
  'Surveillance Audit 5'
]

export const caPhases = [
  'Certification Audit Stage 1',
  'Certification Audit Stage 2',
  'Follow-up Audit / Special Audit'
]

export const saPhases = [
  'Surveillance Audit 1',
  'Surveillance Audit 2',
  'Surveillance Audit 3',
  'Surveillance Audit 4',
  'Surveillance Audit 5'
]

export default phases
