import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import get from 'lodash/get'
import { getFormValues } from 'redux-form'

import { QuotationCreateForm as QuotationForm } from '../../components/Quotations/QuotationForm'
import CertificationInformationWidget from '../../components/Questionnaires/CertificationInformationWidget'
import AdditionalInformationWidget from '../../components/Questionnaires/AdditionalInformationWidget'
import MandaysCalculationWidget from '../../components/Questionnaires/MandaysCalculationWidget'
import AnnexWidget from '../../components/Questionnaires/AnnexWidget'
import ReviewWidget from '../../components/Questionnaires/ReviewWidget'

import CustomerWidget from '../../components/Customers/CustomerWidget'
import LocationWidget from '../../components/Customers/LocationWidget'
import ContactWidget from '../../components/Customers/ContactWidget'

import Affix from '../../components/common/Affix'

import { fetchQuestionnaire } from '../../actions/questionnaires'
import { createQuotation } from '../../actions/quotations'

import { mandDayCreateSelector, totalFirstYearFeesCreateSelector, filterAuditCycle, getFeesByYear } from '../../selectors/quotations'
import { annexWithValueSelector } from '../../selectors/questionnaires'

export function QuotationNew (props) {
  const {
    accessToken,
    fetchQuestionnaire,
    createQuotation,
    match,
    user,
    questionnaire,
    questionnaireAnnex,
    year1Fees,
    feesByYear
  } = props
  const [submitting, setSubmitting] = useState(false)
  const customer = get(questionnaire, 'detail.copy.customer')
  const mainContact = get(questionnaire, 'detail.main_contact')

  useEffect(() => {
    fetchQuestionnaire(match.params.questionnaire_id, accessToken)
  }, []) // eslint-disable-line

  function handleSubmit (e) {
    setSubmitting(true)
    const data = {
      questionnaire_id: match.params.questionnaire_id,
      sales_id: user.id,
      sub_total: year1Fees,
      total: year1Fees,
      detail: { ...e.detail },
      remarks: e.remarks,
      log: {
        remarks: e.remarks
      }
    }
    if (e.advance) {
      data.detail.audit_cycle = data.detail.audit_cycle.filter((x) => x.include)
      data.status = 'Created'
    }
    return createQuotation(data, accessToken).then((x) => {
      setSubmitting(false)
    })
  }

  // function getAuditTime (questionnaire) {
  //   const numberOfEmployees = questionnaire.detail.number_of_employees
  //   const scopeLevel = questionnaire.scopes.map((x, i) => x.scope.detail.level)
  //   return getAuditTime(numberOfEmployees, scopeLevel)
  // }

  return (
    <div>
      <div className='content'>
        <div className='main-header'>
          { questionnaire &&
            <h2>Create {questionnaire.status === 'Created' && <span>Temporary</span>} Quotation </h2>
          }
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='row'>
          <div className='col-md-8'>
            {questionnaire
              ? <QuotationForm
                onSubmit={handleSubmit}
                questionnaire={questionnaire}
                initialValues={{
                  detail: {
                    audit_cycle: filterAuditCycle(questionnaire.detail.audit_cycle || []),
                    additional_service: questionnaire.detail.additional_service,
                    fees: questionnaire.detail.fees,
                    payment_schedule: 'payment-after-audit',
                    show_manday_calculation: 'show',
                  }
                }}
                phases={filterAuditCycle(questionnaire.detail.audit_cycle || [])}
                mandays={questionnaire.detail.mandays_roundup}
                fileAPI={{
                  url: process.env.REACT_APP_BASE_API,
                  accessToken: accessToken
                }}
                submitting={submitting}
              />
              : <div>loading</div>
            }
            <hr />
          </div>
          {
            <div className='col-md-4'>
              <Affix>
                <div className='widget'>
                  <div className='widget-header'>
                    <h3><i className='fa fa-tasks' /> Summary</h3>
                  </div>
                  { questionnaire &&
                    <div className='widget-content'>
                      <dl>
                        <dt><strong>Mandays by Formula</strong></dt>
                        { questionnaire.detail && questionnaire.detail.mandays
                          ? <dd>{questionnaire.detail.mandays} days</dd>
                          : <dd>n/a</dd>
                        }
                        <dt><strong>Mandays by A Team</strong></dt>
                        { questionnaire.detail && questionnaire.detail.mandays_by_a_team
                          ? <dd>{questionnaire.detail.mandays_by_a_team} days</dd>
                          : <dd>n/a</dd>
                        }
                        <dt><strong>Mandays Round Up</strong></dt>
                        { questionnaire.detail && questionnaire.detail.mandays_roundup
                          ? <dd>{questionnaire.detail.mandays_roundup} days ({questionnaire.detail.mandays_adjustment} %)</dd>
                          : <dd>n/a</dd>
                        }
                        { feesByYear.map((x, i) => (
                          <Fragment key={i}>
                            <dt>{x.phase} {x.description && <span>({x.description})</span>}</dt>
                            <dd>
                              <span>Rp</span>&nbsp;
                              <NumberFormat
                                displayType='text'
                                value={x.amount}
                                thousandSeparator
                              />
                            </dd>
                          </Fragment>
                        ))}
                      </dl>
                    </div>
                  }
                </div>
              </Affix>
            </div>
          }
        </div>
        {
          // <div className='main-header'>
          //   <h2>Questionnaire Data </h2>
          //   <em>normal sidebar with minified mode by default</em>
          // </div>
        }
        <div className='row'>
          <div className='col-md-8'>
            { questionnaire &&
              <Fragment>
                <div className='row'>
                  <div className='col-md-6'>
                    <CustomerWidget customer={customer} accessToken={accessToken} />
                  </div>
                  <div className='col-md-6'>
                    <ContactWidget contact={mainContact} accessToken={accessToken} title='Main Contact' />
                  </div>
                </div>
                <CertificationInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
                {questionnaire.locations.map((x, i) => (
                  <LocationWidget
                    key={i}
                    location={x.location}
                    detail={x.detail}
                    accessToken={accessToken}
                    title={`Audit Location : ${x.location.name}`}
                  />
                ))}
                <AdditionalInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
                <AnnexWidget annex={questionnaireAnnex} questionnaire={questionnaire} accessToken={accessToken} />
                <MandaysCalculationWidget questionnaire={questionnaire} accessToken={accessToken} />
                <ReviewWidget questionnaire={questionnaire} accessToken={accessToken} />
              </Fragment>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    questionnaire: state.questionnaires.questionnaire.data,
    quotationFormValue: getFormValues('quotationForm')(state),
    getManDayAdjustment: mandDayCreateSelector(state),
    year1Fees: totalFirstYearFeesCreateSelector(state),
    feesByYear: getFeesByYear(state),
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user,
    questionnaireAnnex: annexWithValueSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchQuestionnaire: (id, key) => dispatch(fetchQuestionnaire(id, key)),
    createQuotation: (data, key) => dispatch(createQuotation(data, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(QuotationNew)
