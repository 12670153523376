const statusString = [
  'Created',
  'Prerequisite Not Complete',
  'Prerequisite Submitted',
  'Prerequisite Completed',
  'Audit Schedule Stage 1 Created',
  'Audit Schedule Stage 2 Created',
  'Audit Schedule Created',
  'A Team Stage 1 Need to Revise',
  'A Team Stage 2 Need to Revise',
  'A Team Need to Revise',
  'A Team Stage 1 Proposed',
  'A Team Stage 2 Proposed',
  'A Team Proposed',
  'A Team Stage 1 Approved',
  'A Team Stage 2 Approved',
  'A Team Approved',
  'Audit Plan Stage 1 Drafted',
  'Audit Plan Stage 2 Drafted',
  'Audit Plan Drafted',
  'Audit Plan Stage 1 Created',
  'Audit Plan Stage 2 Created',
  'Audit Plan Created',
  'Audit Plan Stage 1 Need to Revise',
  'Audit Plan Stage 2 Need to Revise',
  'Audit Plan Need to Revise',
  'Audit Plan Stage 1 Approved',
  'Audit Plan Stage 2 Approved',
  'Audit Plan Approved',
  'Audit Stage 1 On Process',
  'Audit Stage 2 On Process',
  'Audit On Process',
  'VA Stage 1 Required',
  'VA Stage 2 Required',
  'VA Required',
  'VA Stage 1 Approved',
  'VA Stage 2 Approved',
  'VA Approved',
  'VA Stage 1 Schedule Created',
  'VA Stage 2 Schedule Created',
  'VA Schedule Created',
  'VA Stage 1 On Process',
  'VA Stage 2 On Process',
  'VA On Process',
  'A Team VA Stage 1 Need to Revise',
  'A Team VA Stage 2 Need to Revise',
  'A Team VA Need to Revise',
  'A Team VA Stage 1 Proposed',
  'A Team VA Stage 2 Proposed',
  'A Team VA Proposed',
  'A Team VA Stage 1 Approved',
  'A Team VA Stage 2 Approved',
  'A Team VA Approved',
  'Audit Plan VA Stage 1 Drafted',
  'Audit Plan VA Stage 2 Drafted',
  'Audit Plan VA Drafted',
  'Audit Plan VA Stage 1 Created',
  'Audit Plan VA Stage 2 Created',
  'Audit Plan VA Created',
  'Audit Plan VA Stage 1 Need to Revise',
  'Audit Plan VA Stage 2 Need to Revise',
  'Audit Plan VA Need to Revise',
  'Audit Plan VA Stage 1 Approved',
  'Audit Plan VA Stage 2 Approved',
  'Audit Plan VA Approved',
  'Audit Stage 1 Done',
  'Audit Stage 2 Done',
  'Audit Done',
  'Audit Documents Need to Revise',
  'Audit Documents Completed',
  'Veto Person Assigned',
  'Veto Process Done',
  'Certificate Granted',
  'Certificate Maintained',
  'Certificate Published',
  'Canceled',
  'Terminated',
  'Temporary Migration'
]

const orderStatuses = statusString.map(x => ({
  label: x,
  color: 'info'
}))

export default orderStatuses
