import set from "lodash/fp/set";

export const initialState = {
  userList: {
    data: null,
    pagination: null,
    error: null,
    loading: false,
  },
  user: {
    data: null,
    error: null,
    loading: false,
  },
  specificationList: {
    data: [],
    error: null,
    loading: false,
  },
  auditorList: {
    data: [],
    error: null,
    loading: false,
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USERS_PENDING":
      return { ...state, userList: { data: null, error: null, loading: true } };
    case "FETCH_USERS_REJECTED":
      return {
        ...state,
        userList: { data: null, error: action.payload, loading: false },
      };
    case "FETCH_USERS_FULFILLED":
      return {
        ...state,
        userList: {
          data: action.payload,
          pagination: action.pagination,
          error: null,
          loading: false,
        },
      };

    case "FETCH_AUDITORS_PENDING":
      return {
        ...state,
        auditorList: { data: null, error: null, loading: true },
      };
    case "FETCH_AUDITORS_REJECTED":
      return {
        ...state,
        auditorList: { data: null, error: action.payload, loading: false },
      };
    case "FETCH_AUDITORS_FULFILLED":
      return {
        ...state,
        auditorList: {
          data: action.payload,
          pagination: action.pagination,
          error: null,
          loading: false,
        },
      };

    case "FETCH_USER_PENDING":
      return { ...state, user: { data: null, error: null, loading: true } };
    case "FETCH_USER_REJECTED":
      return {
        ...state,
        user: { data: null, error: action.payload, loading: false },
      };
    case "FETCH_USER_FULFILLED":
      return {
        ...state,
        user: { data: { ...action.payload }, error: null, loading: false },
      };

    case "UPDATE_USER_PENDING":
      return { ...state, user: { ...state.user, error: null, loading: true } };
    case "UPDATE_USER_REJECTED":
      return {
        ...state,
        user: { ...state.user, error: action.payload, loading: false },
      };
    case "UPDATE_USER_FULFILLED":
      return {
        ...state,
        user: { data: { ...action.payload }, error: null, loading: false },
      };

    case "DELETE_USER_PENDING":
      return { ...state, user: { ...state.user, error: null, loading: true } };
    case "DELETE_USER_REJECTED":
      return {
        ...state,
        user: { ...state.user, error: action.payload, loading: false },
      };
    case "DELETE_USER_FULFILLED":
      return { ...state, user: { data: null, error: null, loading: false } };

    case "FETCH_SPECIFICATIONS_PENDING":
      return set(`specificationList.loading`, true)(state);
    case "FETCH_SPECIFICATIONS_REJECTED":
      return {
        ...state,
        specificationList: { data: [], error: action.payload, loading: false },
      };
    case "FETCH_SPECIFICATIONS_FULFILLED":
      return {
        ...state,
        specificationList: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };

    case "CREATE_SPECIFICATION_PENDING":
      return set(`specificationList.loading`, true)(state);
    case "CREATE_SPECIFICATION_REJECTED":
      return {
        ...state,
        specificationList: {
          data: [...state.specificationList.data],
          error: action.payload,
          loading: false,
        },
      };
    case "CREATE_SPECIFICATION_FULFILLED":
      return {
        ...state,
        specificationList: {
          data: [...state.specificationList.data, action.payload],
          error: null,
          loading: false,
        },
      };

    case "UPDATE_SPECIFICATION_PENDING":
      return set(`specificationList.loading`, true)(state);
    case "UPDATE_SPECIFICATION_REJECTED":
      return {
        ...state,
        specificationList: {
          data: [...state.specificationList.data],
          error: action.payload,
          loading: false,
        },
      };
    case "UPDATE_SPECIFICATION_FULFILLED":
      return {
        ...state,
        specificationList: {
          data: state.specificationList.data.map((x) => {
            if (x.id === action.payload.id) {
              return action.payload;
            } else {
              return x;
            }
          }),
          error: null,
          loading: false,
        },
      };

    case "DELETE_SPECIFICATION_PENDING":
      return set(`specificationList.loading`, true)(state);
    case "DELETE_SPECIFICATION_REJECTED":
      return {
        ...state,
        specificationList: {
          data: [...state.specificationList.data],
          error: action.payload,
          loading: false,
        },
      };
    case "DELETE_SPECIFICATION_FULFILLED":
      return {
        ...state,
        specificationList: {
          data: state.specificationList.data.filter((x) => {
            return x.id !== action.payload.id;
          }),
          error: null,
          loading: false,
        },
      };

    case "LOGOUT_FULFILLED":
      return { ...initialState };

    default:
      return state;
  }
};
