import React from "react";
import { getCurrentMainSchedule, getScheduleSummary } from "../../selectors/schedules";

export default function ScheduleCounter({ schedules, quotation, order }) {
    if (!schedules || !quotation || !order) {
        return "";
    }

    const currentMainSchedule = getCurrentMainSchedule(order);
    const scheduleTypeSummary =  getScheduleSummary(schedules, order);

    const auditCycle = quotation?.detail?.audit_cycle || [];
    const auditCycleItem = auditCycle.find(x => x.phase === currentMainSchedule);

    if (!auditCycleItem) {
        return ''
    }

    return (
        <div className="alert alert-info">
            <div className="row">
                <div className="col-md-6">
                    <p><strong>Requirement : </strong></p>
                    <p>{auditCycleItem?.phase} requires : {auditCycleItem?.day} mandays</p>
                </div>
                <div className="col-md-6">
                    <p>
                        <strong>Current Schedule : </strong>
                    </p>
                    {scheduleTypeSummary.map(([key, value]) => (
                        <p key={key}>
                            {key} : {value.dayCount} {value.dayCount > 1 ? 'mandays' : 'manday'}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
}
