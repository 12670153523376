import React from 'react'
import { Route, Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

export class PrivateRoute extends React.Component {
  render () {
    const Component = this.props.component
    const rest = { ...this.props }
    delete rest.component

    return (
      <Route {...rest} render={props => (
        this.props?.auth?.user ? (
          <Component {...props} />
        ) : (
          <span>
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location }
            }} />
          </span>

        )
      )} />
    )
  }
}

function mapStateToProps (state) {
  return {
    auth: state.auth
  }
}

function mapDispatchToProps (dispatch) {
  return {}
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute))
