import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import TopBar from '../components/common/TopBar'
import LeftSideBar from '../components/common/LeftSideBar'
import Flasher from '../components/common/Flasher'

import { toggleSidebar } from '../actions/layout'
import { logout } from '../actions/auth.js'
import './Inside.css'

function mapStateToProps (state) {
  return {
    openSidebar: state.layout.openSidebar,
    me: state.auth.user
  }
}
function mapDispatchToProps (dispatch) {
  return {
    logout: () => dispatch(logout()),
    toggleSidebar: () => dispatch(toggleSidebar()),
  }
}

export class Inside extends React.Component {
  render () {
    return (
      <div className='topnav-fixed'>
        <div id='wrapper' className='wrapper'>

          <TopBar
            onLogout={this.props.logout.bind(this)}
            onToggle={this.props.toggleSidebar}
            me={this.props.me}
          />

          <LeftSideBar />

          <div id='main-content-wrapper' className={this.props.openSidebar ? 'tuv content-wrapper' : 'tuv content-wrapper expanded'}>

            <div className='row'>
              <div className='col-lg-4 '>
                {/* TODO : get data from store state */}
                <ul className='breadcrumb'>
                  <li><i className='fa fa-home' /><Link to='/'>Home</Link></li>
                  {
                    // <li><a>Navigations</a></li>
                    // <li className='active'>Minified Sidebar</li>
                  }
                </ul>

              </div>

            </div>

            {this.props.children}

            <footer className='footer'>
              &copy; 2017 TÜV NORD Indonesia
            </footer>
          </div>
        </div>
        <Flasher />
      </div>
    )
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Inside))
