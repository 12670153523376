import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'

import Required from './Required'
import File from './File'

export default function FileField ({ help, disabled, api, input, label, required, meta: { touched, error, warning }, stack }) {
  let files = input.value || []
  if (typeof files === 'string' && files !== '') {
    files = JSON.parse(files)
  }

  const [ isSubmitting, setSubmitting ] = useState(false)
  const { url, accessToken, path } = api

  async function uploadFile (files) {
    setSubmitting(true)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let data = new window.FormData()
    for (var i = 0; i < files.length; i++) {
      data.append('files[]', files[i], files[i].name)
    }
    if(path) {
      data.append('path', path)
    }
    const result = await axios.post(`${url}/files`, data, config)
    setSubmitting(false)
    return result.data
  }

  function removeFile (id) {
    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }
    axios.delete(`${url}/files/${id}`, config).then((result) => {
      input.onChange(files.filter(x => x.id !== id))
      return result.data
    }).catch((err) => {
      input.onChange(files.filter(x => x.id !== id))
      return false;
    })

  }

  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label htmlFor={label} className={`${!stack && 'col-sm-3'} control-label`}>{label} {required && <Required />}</label>
      <div className={`${!stack && 'col-sm-9'}`}>
        <File file={files} accessToken={api.accessToken} onDelete={!disabled && removeFile} />
        { isSubmitting &&
          <div className='alert alert-info'>... uploading ...</div>
        }
        {!disabled &&
          <Dropzone
            name={label}
            style={{ marginBottom: 0 }}
            className='well'
            data-cy='dropzone'
            onDrop={(filesToUpload, e) => {
              uploadFile(filesToUpload)
                .then(r => {
                  input.onChange([...files, ...r])
                })
                .catch((err) => {
                  console.error(err)
                })
            }}
          >
            <div>Try dropping some files here, or click to select files to upload.</div>
          </Dropzone>
        }
        {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        { help && <p className='help-text'>{help}</p> }
      </div>
    </div>
  )
}
