import React, { Fragment } from 'react'

export default class ChangeDetail extends React.Component {
  render () {
    const { change } = this.props
    if (!change || !change.detail) {
      return ''
    }
    const reasonList = change.detail.reason_list || []
    const detail = change.detail

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3>
            <i className='fa fa-table' /> Change Detail
          </h3>
        </div>
        <div className='widget-content'>
          <h4>Detail of changes</h4>
          {reasonList.map((x, i) => (
            <p key={i}>{x}</p>
          ))}
          {reasonList.length === 0 && <p>Not available</p>}
          {detail.reason_other && (
            <Fragment>
              <hr />
              <h4>Other specified change</h4>
              <p>{detail.reason_other}</p>
            </Fragment>
          )}
          {detail.separated_audit_md && (
            <Fragment>
              <hr />
              <h4>Special audit needed (Separated from the regular audit)</h4>
              <p>{detail.separated_audit_md} manday(s)</p>
            </Fragment>
          )}
          {detail.additional_audit_md && (
            <Fragment>
              <hr />
              <h4>
                Special audit addition to regular audit (Special audit MD added
                to MD audit)
              </h4>
              <p>{detail.additional_audit_md} manday(s)</p>
            </Fragment>
          )}
          {detail.terminated && (
            <Fragment>
              <hr />
              <h4>The audit process is terminated</h4>
              <p>{detail.terminated ? 'Yes' : 'No'}</p>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}
