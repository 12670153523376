import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import quotationScheduleStatuses from '../../const/quotationScheduleStatuses'

class BaseQuestionnaireFilter extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          <div className='form-group'>
            <Field name='status' component='select' className='form-control'>
              <option value=''>All Status &nbsp;</option>
              {quotationScheduleStatuses.map((x, i) => <option key={i} value={x.label}>{x.label} &nbsp;</option>)}
            </Field>
          </div>
          {
            // &nbsp;
            // <div className='form-group' style={{width: 150}}>
            //   <Field
            //     name='standard'
            //     component={AutoCompleteField}
            //     label='Standard'
            //     api={this.props.standardAPI}
            //   />
            // </div>
            // &nbsp;
            // <div className='form-group'>
            //   <label className='sr-only' htmlFor='exampleInputEmail2'>Search</label>
            //   <Field
            //     name='q'
            //     component='input'
            //     type='text'
            //     placeholder='Search for ...'
            //     className='form-control'
            //   />
            // </div>
          }
          &nbsp;
          <div className='form-group'>
            <label className='sr-only' htmlFor='exampleInputEmail2'>Year</label>
            <Field
              name='year'
              component='input'
              type='text'
              placeholder='Year'
              className='form-control'
            />
          </div>
          &nbsp;
          <div className="form-group">
            <label className="sr-only" htmlFor="exampleInputEmail2">
              Quotatiom Number
            </label>
            <Field
              name="number"
              component="input"
              type="text"
              placeholder="Search for quotation number..."
              className="form-control"
            />
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/quotation-schedules' className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let QuestionnaireFilter = reduxForm({
  form: 'questionnaireFilter' //, validate, warn
})(BaseQuestionnaireFilter)

export { BaseQuestionnaireFilter }
export default QuestionnaireFilter
