import React from 'react'
import { Link } from 'react-router-dom'

export function LocalRow ({ customer, ...x }) {
  return (
    <tr>
      <td><Link to={`/customers/${customer.id}/locations/${x.id}`}>{x.name}</Link></td>
      <td>{x.address}</td>
      <td>{x.city}</td>
      <td>{x.phone}</td>
      <td>
        {x.billing && <span>Billing </span>}
        {x.default && <span>Default </span>}
        {x.delivery && <span>Delivery </span>}
      </td>
      <td className='text-right'><Link to={`/customers/${customer.id}/locations/${x.id}`} className='btn btn-xs btn-default'><i className='fa fa-gear' /></Link></td>
    </tr>
  )
}

export function BaseRow ({ customer, ...x }) {
  return (
    <tr>
      {x.local
        ? <td><Link to={`/customers/${customer.id}/locations/${x.local.id}`}>{x.address_type}</Link></td>
        : <td><button onClick={() => x.onSync(x.address_id)} className='btn-link'>{x.address_type}</button></td>
      }
      <td>{x.address_value}</td>
      <td>{x.region && x.region.kota_name}</td>
      <td>{x.address_phone}</td>
      <td />
      <td className='text-right'><button onClick={() => x.onSync(x.address_id)} className='btn btn-xs btn-default'><i className='fa fa-gear' /></button></td>
    </tr>
  )
}

export default function LocationTable ({ locations, customer, onSync }) {
  if (!locations || !customer) {
    return ''
  }

  return (
    <table className='table table-condensed'>
      <thead>
        <tr>
          <th>Address Name</th>
          <th>Street Address</th>
          <th>City</th>
          <th>Phone</th>
          <th>Note</th>
          <th className='text-center' width={80}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((x, i) => {
          if (!x.address_id) {
            return <LocalRow key={x.id} {...x} customer={customer} />
          } else {
            return <BaseRow key={x.address_id} {...x} customer={customer} onSync={onSync} />
          }
        })}
        <tr className=' bg-grey'>
          <td className='customer-scope-container' colSpan={6}>
            <Link className='btn btn-block btn-default' to={`/customers/${customer.id}/locations/new`}>Add Address</Link>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
