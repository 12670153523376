import React from 'react'
import moment from 'moment'

import AllowanceTableForm from './AllowanceTableForm'
import { getDateArrayStartEnd } from '../../selectors/schedules'

export default class ScheduleDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openDetail: []
    }
  }

  toggleDetail (i) {
    const openDetail = [...this.state.openDetail]
    if (openDetail[i]) {
      openDetail[i] = false
    } else {
      openDetail[i] = true
    }

    this.setState({
      openDetail
    })
  }

  render () {
    const { userId, schedules } = this.props
    const { openDetail } = this.state
    let keyDate

    const schedulesByStage = schedules.reduce((sum, x, i) => {
      if (x.type === 'Certification Audit Stage 1') {
        sum[0].push(x)
      }
      if (x.type === 'Certification Audit Stage 2') {
        sum[1].push(x)
      }
      if (x.type === 'Surveillance Audit') {
        sum[2].push(x)
      }
      if (x.type === 'Recertification Audit') {
        sum[3].push(x)
      }
      return sum
    }, [[], [], [], []])

    return (
      <div>
        { schedulesByStage.map((x, i) => {
          if (x.length === 0) {
            return ''
          }
          keyDate = getDateArrayStartEnd(x)
          x.sort((a, b) => (a.start_date > b.start_date))
          return (
            <div key={i}>
              <div className='widget widget-hide-header widget-reminder'>
                <div className='widget-content'>
                  <div className='today-reminder'>
                    <h4 className='reminder-title'>{x[0].type}</h4>
                    <p className='reminder-time'><i className='fa fa-clock-o' /> {moment(keyDate.start).format('dddd, DD MMMM YYYY')}</p>
                    <p className='reminder-place'>until {moment(keyDate.end).format('dddd, DD MMMM YYYY')}</p>
                    { /* <em className="reminder-notes">until {moment(keyDate.end).format('dddd, DD MMMM YYYY')}</em> */ }
                  </div>
                </div>
              </div>

              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-users' /> Auditors</h3>
                </div>
                <div className='widget-content'>
                  <ul className='list-unstyled quick-inbox-list'>
                    { x.filter(xx => (userId && xx.user.id === parseInt(userId, 10)) || !userId)
                      .map((xx, i) => {
                        return (
                          <li className='unread' key={i}>
                            <img alt={xx.user.data.display_name} src='https://api.fnkr.net/testimg/100x100/00CED1/FFF/?text=img+placeholder' className='img-circle pull-left' />
                            <div className='text'>
                              <div className='header'>
                                <span className='sender'>{xx.user.data.display_name} </span>
                                <span className='label label-default'>{xx.role}</span>{' '}
                                <span className='label label-info'>{xx.type}</span>
                                <span className='timestamp pull-right'><i className='fa fa-clock-o' /> {moment(xx.start_date).format('dddd, DD MMMM YYYY')} - {moment(xx.end_date).format('dddd, DD MMMM YYYY')}</span>
                              </div>
                              <hr />
                              { openDetail && openDetail[i]
                                ? <span className='brief'>
                                  <p className='text-center'>
                                    <button className='btn btn-sm btn-link' onClick={this.toggleDetail.bind(this, i)}>Hide specifications</button>
                                  </p>
                                  <ul className='list-unstyled'>
                                    {xx.user.specifications.map((y, j) => {
                                      return (
                                        <li key={j}><strong>{y.standard.name} {y.scope && y.scope.name}</strong> as : {y.capacity.join('/ ')}</li>
                                      )
                                    })}
                                  </ul>
                                </span>
                                : <p className='brief text-center'>
                                  <button className='btn btn-sm btn-link' onClick={this.toggleDetail.bind(this, i)}>Show specifications</button>
                                </p>
                              }
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                { userId && schedules &&
                  <div className='widget-content'>
                    <AllowanceTableForm schedules={schedules} userId={userId} />
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
