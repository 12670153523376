import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { TextareaField, OptionsField } from '../common/FormFields';
import ConfirmButton from '../common/ConfirmButton';
import FileField from '../common/FileField';

import { hasAttachment } from '../../const/helper';

const validate = (values, props) => {
  const errors = {};
  if (!values.result) {
    errors.result = 'Please select result';
  }
  if (
    props.requireFile &&
    values?.result?.indexOf('Need to Revise') === -1 &&
    !hasAttachment(values, 'log.detail.legal_document')
  ) {
    errors.log = {
      detail: {
        legal_document: 'Please include Approval Document',
      },
    };
  }
  return errors;
};

export class ReviewForm extends Component {
  render() {
    const { handleSubmit, submitting, result } = this.props;
    let defaultResult;

    if (result) {
      defaultResult = ['', ...result];
    } else {
      defaultResult = ['', 'Approved', 'Not Approved'];
    }
    return (
      <div>
        <form className="form-horizontal review-form" onSubmit={handleSubmit}>
          <Field
            name="result"
            type="select"
            component={OptionsField}
            label="Set as"
            options={defaultResult}
          />

          <Field
            name="log.detail.legal_document"
            component={FileField}
            label="Approval Document"
            api={{...this.props.fileAPI, path: 'review'}}
          />

          <Field
            name="log.detail.remarks"
            type="text"
            component={TextareaField}
            label="Remarks"
          />

          <div className="form-group">
            <div className="col-sm-offset-3 col-sm-9">
              <ConfirmButton disabled={submitting} text={'Submit'}>
                <button
                  disabled={submitting}
                  type="submit"
                  className="btn-advance btn btn-primary btn-block btn-submit"
                >
                  Submit Review
                </button>
              </ConfirmButton>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ReviewForm = reduxForm({
  // eslint-disable-line
  form: 'reviewForm',
  validate,
})(ReviewForm);

export default ReviewForm;
