import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

export default function DateField (props) {
  const { input, disabled } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (input.value) {
      const value = typeof input.value === 'number' ? moment.unix(input.value) : moment(input.value);
      setValue(value)
      input.onChange(value.format('YYYY-MM-DD'))
    }
  }, [input])

  function handleChange (data) {
    setValue(data)
    input.onChange(data.format('YYYY-MM-DD'))
  }

  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
        <DatePicker
          dateFormat='YYYY-MM-DD'
          selected={value}
          onChange={handleChange}
          className='form-control'
          disabled={disabled}
        />
    </div>
  )
}
