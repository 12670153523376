import React from 'react'
import Flasher from '../components/common/Flasher'

export default function Outside ({children}) {
  return (
    <>
      {children}
      <Flasher />
    </>
  )
}
