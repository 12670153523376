import React from 'react'
import Select from 'react-select'
import axios from 'axios'
import { asOptions } from '../../const/helper'

export default class AutoCompleteField extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.props.input.value || null,
      showInput: false
    }
  }

  onChange (value) {
    this.setState({
      value: value
    })
    this.props.input.onChange(value)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props) {
      this.setState({
        value: nextProps.input.value
      })
    }
  }

  async getCurrent (input) {
    const { api } = this.props
    const result = await axios({
      method: 'get',
      url: api.url.indexOf('?') > -1 ? api.url + '/' + input : api.url + '/' + input,
      headers: {
        'Authorization': `Bearer ${api.accessToken}`
      }
    }).then((x) => {
      return {
        options: asOptions(x.data, api.label)
      }
    })
    return result
  }

  getOptions (input, callback) {
    const { api } = this.props
    return axios({
      method: 'get',
      url: api.url.indexOf('?') > -1 ? api.url + '&q=' + input : api.url + '?q=' + input,
      headers: {
        'Authorization': `Bearer ${api.accessToken}`
      }
    }).then((x) => {
      return {
        options: asOptions(x.data, api.label)
      }
    })
  }

  updateValue () {
    const input = this.state.value
    const { api } = this.props
    const result = axios({
      method: 'get',
      url: api.url.indexOf('?') > -1 ? api.url + '/' + input : api.url + '/' + input,
      headers: {
        'Authorization': `Bearer ${api.accessToken}`
      }
    }).then((x) => {
      this.setState({
        value: {
          value: x.data,
          label: x.data.name
        }
      })
    })
    return result
  }

  render () {
    const { label, input, meta: {touched, error, warning} } = this.props

    if (typeof this.state.value === 'string' && this.state.value !== '') {
      this.updateValue()
    }

    return (
      <div>
        { this.state.showInput === false
          ? <Select.Async
            value={this.state.value}
            onChange={this.onChange.bind(this)}
            loadOptions={this.getOptions.bind(this)}
            style={{zIndex: 11, position: 'relative'}}
          />
          : <input {...input} type='text' className='form-control' id={label} placeholder={label} />
        }
        {touched && ((error && <div><br /><span className='text-danger'>{error}</span></div>) || (warning && <div><br /><span className='text-warning'>{warning}</span></div>))}
      </div>
    )
  }
}
