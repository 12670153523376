import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment';
import Logo from '../img/tuv-logo1.png'

import { getValidity } from '../actions/certificates'
import { asQueryObject } from '../const/helper'

import Outside from './Outside'
import './Login.css'

export class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      certificate: null,
      error: null,
      loading: false,
      uuid: '',
      number: '',
    }
  }

  componentDidMount () {
    const search = asQueryObject(this.props.location.search)
    if (search.uuid && search.number) {
      this.setState({ loading: true })
      getValidity(search).then((res) => {
        this.setState({certificate: res.data, error: null, loading: false})
      }).catch((err) => {
        this.setState({certificate: null, error: 'Not found', loading: false})
      })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search) {
      const search = asQueryObject(nextProps.location.search)
      if (search.uuid && search.number) {
        this.setState({ loading: true })
        getValidity(search).then((res) => {
          this.setState({certificate: res.data, error: null, loading: false})
        }).catch((err) => {
          this.setState({certificate: null, error: 'Not found', loading: false})
        })
      }
    }
  }

  handleSubmit (ev) {
    ev.preventDefault();
    const uuid = this.state.uuid.split(' ').join('')
    const number = this.state.number.split(' ').join('')
    this.props.history.push(`/validity-check?uuid=${uuid}&number=${number}`);
  }

  render () {
    const { certificate, loading } = this.state
    const endDate = certificate ? moment(certificate.end_date) : null;
    const startDate = certificate ? moment(certificate.release_date) : null;
    const mainLocationId = certificate?.questionnaire_location.location.id;
    const orderLocations = certificate?.order.locations;

    const subLocations = certificate ? orderLocations.filter((x) => x.id !== mainLocationId) : null;
    const subLocation = certificate?.sub_location ? subLocations[parseInt(certificate.sub_location) - 1] : null;

    const baseAPI = process.env.REACT_APP_BASE_API;
    const signedCopy = certificate?.detail?.signed_file?.length > 0 ? certificate.detail.signed_file[0] : undefined;
    const signedCopyId = signedCopy ? signedCopy.id : undefined;

    const hasPreview =
      certificate && 
      certificate.detail && 
      certificate.detail.signed_file && 
      certificate.detail.signed_file.length > 0 && !loading;

    const previewUrl = hasPreview ? `${baseAPI}/validity/preview?certificate_id=${certificate.id}&file_id=${signedCopyId}` : '';

    return (
      <Outside>
        <Helmet>
          <title>Welcome to SIM Cert</title>
        </Helmet>
        <div className='wrapper full-page-wrapper page-auth page-login text-center'>
          <div className={`unsplash-image ${hasPreview ? 'has-preview' : ''}`}>
          { hasPreview && <iframe title="Certificate preview" src={previewUrl} /> }
          </div>
          <div className='inner-page'>
            <div className='logo'>
              <h3><Link to="/login"><img alt='TÜV NORD INDONESIA' src={Logo} /></Link></h3>
            </div>
            <div className='login-box center-block'>
              { loading && <p>Please wait</p>}

              { certificate && !loading && (
                <>
                  <dl>
                    <dt>Certificate Number</dt>
                    <dd>{certificate.number}</dd>

                    <dt>Customer Name</dt>
                    <dd>{certificate.customer.name}</dd>

                    <dt>Customer Location</dt>
                    {!!certificate?.detail?.customer_address ? (
                      <dd dangerouslySetInnerHTML={{__html: certificate.detail.customer_address}}/>
                    ) : (
                      <>
                        <dd>{certificate.detail.customer_address}</dd>
                        <dd>{certificate.questionnaire_location.location.name}</dd>
                        <dd>{certificate.questionnaire_location.location.address} {certificate.questionnaire_location.location.city}  {certificate.questionnaire_location.location.state}  {certificate.questionnaire_location.location.country} {certificate.questionnaire_location.location.postal_code} </dd>
                      </>
                    )}

                    { !certificate?.detail?.customer_address && subLocation &&
                      <>
                        <dt>Site Location</dt>
                        <dd>{subLocation.name}</dd>
                        <dd>{subLocation.address} {subLocation.city}  {subLocation.state}  {subLocation.country} {subLocation.postal_code} </dd>
                      </>
                    }

                    <dt>Standard</dt>
                    <dd>{certificate.questionnaire_scope.standard.name}</dd>
                    <dd
                      dangerouslySetInnerHTML={{__html: certificate?.detail?.scope_wording || certificate?.questionnaire_scope?.scope?.name}}  
                    />

                    <dt>Certificate Status</dt>
                    <dd>
                      {certificate.status === "Published" ? 'Active' : certificate.status}
                    </dd>

                    <dt>Valid period</dt>
                    <dd>{startDate.format('DD-MMM-YYYY')} - {endDate.format('DD-MMM-YYYY')}</dd>
                  </dl>
                </>
              )}
              { !certificate && !loading && (
                <>
                  <form className="form" onSubmit={this.handleSubmit.bind(this)}>
                   <div className="form-group">
                     <label>Certificate Number</label>
                     <input type="text" className="form-control" onChange={ev => this.setState({number: ev.target.value})}/>
                   </div>
                   <div className="form-group">
                     <label>Certificate UUID</label>
                     <input type="text" className="form-control" onChange={ev => this.setState({uuid: ev.target.value})}/>
                   </div>
                   <button type="submit" className="btn btn-block btn-default">Submit</button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <footer className='footer'>&copy; 2014-2015 The Develovers</footer> */}
      </Outside>
    )
  }
}

export default withRouter(Login)
