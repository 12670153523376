import React from 'react'
import { connect } from 'react-redux'

import QuestionnaireAssignmentForm from '../../components/Questionnaires/QuestionnaireAssignmentForm'

export function QuestionnaireAssignment (props) {
  const {
    questionnaire,
    accessToken,
    updateQuestionnaire,
    fetchQuestionnaire
  } = props

  function handleSubmit (e) {
    let target = `/questionnaires/${questionnaire.id}`
    const request = {
      status: ['Created', 'Need to Revise'].includes(questionnaire.status) ? 'Assigned' : 'Revision Assigned',
      id: questionnaire.id,
      reviewer_id: e.reviewer.value.id
    }

    return updateQuestionnaire(request, accessToken, target).then((x) => {
      fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  return (
    <div className='content'>
      <div className='main-header'>
        <h2>Assign Questionnaire </h2>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <QuestionnaireAssignmentForm
            onSubmit={handleSubmit}
            userAPI={{
              url: process.env.REACT_APP_BASE_API + '/users?specification=reviewer',
              accessToken: accessToken,
              label: 'data.display_name'
            }}
            initialValues={{
              reviewer: {
                label: questionnaire.reviewer && questionnaire.reviewer.data && questionnaire.reviewer.data.display_name,
                value: questionnaire.reviewer && questionnaire.reviewer
              }
            }}
          />
        </div>
        <div className='col-md-4' />
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
  }
}

export default connect(
  mapStateToProps
)(QuestionnaireAssignment)
