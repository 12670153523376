import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import intersection from "lodash/intersection";

export default function ScheduleTable(props) {
  const orders = props.orders || [];
  const schedules = props.schedules.map((x, i) => {
    const xorder = orders.find((y) => {
      return y.phase === x.phase;
    });
    if (xorder) {
      x.order = xorder;
    }
    return x;
  });

  return (
    <div>
      <div className="table-responsive order-table">
        <table className="table colored-header datatable project-list">
          <thead>
            <tr>
              <th>Phase</th>
              <th>Due Date</th>
              <th>Order</th>
              <th>Audit Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {schedules.map((x, i) => {
              return (
                <tr key={i}>
                  <td>
                    {x.phase}{" "}
                    <small>
                      ({x.id} - {x.status})
                    </small>
                  </td>
                  <td>
                    {x.status !== "Moved" ? (
                      moment(x.schedule).format("D MMMM YYYY")
                    ) : (
                      <span>Amended</span>
                    )}
                  </td>
                  <td>
                    {x.order ? (
                      <Link to={`/orders/${x.order.id}`}>{x.order.number}</Link>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {x.order && (
                      <span>
                        {moment.unix(x.order.created_at).format("D MMMM YYYY")}
                      </span>
                    )}
                  </td>
                  <td>
                    {!x.order &&
                      intersection(
                        ["Super Admin", "Scheduler", "Surveillance Admin"],
                        props.role
                      ).length > 0 &&
                      ["Next", "Confirmed"].includes(x.status) &&
                      x.phase.indexOf("Surveillance") === 0 && (
                        <Fragment>
                          <button
                            onClick={() => props.onInitiate(x.id)}
                            className="btn btn-xs btn-primary btn-create-order"
                          >
                            Create Surveillance Order
                          </button>
                          <button
                            onClick={() =>
                              props.onSendConfirmationRequest(x.id)
                            }
                            className="btn btn-xs btn-primary"
                          >
                            Send Confirmation Request
                          </button>
                        </Fragment>
                      )}
                    {!x.order &&
                      intersection(
                        [
                          "Sales",
                          "Super Admin",
                          "Scheduler",
                          "Surveillance Admin",
                        ],
                        props.role
                      ).length > 0 &&
                      ["Next", "Confirmed"].includes(x.status) &&
                      x.phase === "Recertification" && (
                        <Fragment>
                          <button
                            onClick={() => props.onRecertification(x.id)}
                            className="btn btn-xs btn-primary btn-create-order"
                          >
                            Create Questionnaire for Recertification
                          </button>
                          <button
                            onClick={() =>
                              props.onSendConfirmationRequest(x.id)
                            }
                            className="btn btn-xs btn-primary"
                          >
                            Send Confirmation Request
                          </button>
                        </Fragment>
                      )}
                    {!x.order &&
                      intersection(["Customer"], props.role).length > 0 &&
                      x.status === "Next" && (
                        <Fragment>
                          <button
                            onClick={() => props.onConfirm(x.id)}
                            className="btn btn-xs btn-primary"
                          >
                            Confirm Initiation
                          </button>
                        </Fragment>
                      )}
                    {x.order && (
                      <Link
                        to={`/orders/${x.order.id}`}
                        className="btn btn-xs btn-info"
                      >
                        View Order
                      </Link>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
