import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import orderBy from 'lodash/fp/orderBy'
import get from 'lodash/get'
import intersection from 'lodash/intersection'
import { Helmet } from 'react-helmet'

import ItemLog from '../../components/common/ItemLog'
import ReviewForm from '../../components/common/ReviewForm'
import File from '../../components/common/File'
import Affix from '../../components/common/Affix'

import PlanTable from '../../components/Orders/PlanTable'

import { fetchOrder } from '../../actions/orders'
import { fetchPlan, updatePlan } from '../../actions/plans'

import { auditDaysSelector } from '../../selectors/plans'

export function OrderPlanReview (props) {
  const {
    accessToken,
    fetchOrder,
    match,
    plan,
    auditDays,
    order,
    updatePlan,
    fetchPlan,
    user
  } = props
  const orderId = match.params.orderId
  const id = match.params.id

  useEffect(() => {
    fetchOrder(orderId, accessToken)
    // eslint-disable-next-line 
  }, [orderId])

  useEffect(() => {
    fetchPlan(orderId, id, accessToken)
    // eslint-disable-next-line 
  }, [orderId, id])

  const events = get(plan, 'detail.events') || []

  function formatEvents (events, auditDays) {
    const test = events.reduce((sum, x, i) => {
      const dayNumber = auditDays.reduce((sum2, y, j) => x.day === y.label ? j : sum2, null)

      if (sum[dayNumber]) {
        sum[dayNumber].event = orderBy(['time'])(['asc'])([ ...sum[dayNumber].event, { ...x, key: i } ])
      } else {
        sum[dayNumber] = {
          day: dayNumber,
          title: 'Day ' + (parseInt(dayNumber, 10) + 1) + ' : ' + auditDays[dayNumber].label,
          event: [{ ...x, key: i }]
        }
      }
      return sum
    }, [])
    return test
  }

  function handleSubmit (e) {
    let request = {
      id: id,
      order_id: orderId,
      log: e.log ? e.log.detail : ''
    }

    request.status = e.result === 'Not Approved' ? 'Draft' : 'Approved by Customer'
    return updatePlan(request, accessToken, `/orders/${orderId}`)
      .then((x) => {
        push(`/orders/${orderId}`)
      })
  }

  console.log(112, events, auditDays)

  if (!plan || !order) {
    return <p>loading</p>
  }

  return (
    <div className='content'>
      <div>
        <Helmet>
          <title>{order ? `Order ${order.id} - Review plan` : `Loading`}</title>
        </Helmet>
        <div className='main-header'>
          <h2>AP.{order.number}</h2>
          <em>For Order <Link to={`/orders/${order.id}`}>{order.number}</Link></em>
        </div>

        <div className='row'>
          <div className='col-md-8'>
            { plan.detail.plan_document &&
              <div><strong>Attached Document :</strong> <File file={plan.detail.plan_document} accessToken={accessToken} /></div>
            }
            { events.length > 0 && 
              <PlanTable events={formatEvents(events, auditDays)} />
            }
            <ItemLog log={plan.log} entity='Order Plan' />
          </div>
          <div className='col-md-4'>
            { // review by super admin or customer
              ['Published'].includes(plan.status) &&
              (intersection(['Super Admin', 'Customer'], user.data.role).length > 0) &&
            (
              <Affix>
                <div className='widget'>
                  <div className='widget-header'>
                    <h3><i className='fa fa-tasks' /> Review</h3>
                  </div>
                  <div className='widget-content'>
                    <ReviewForm
                      onSubmit={handleSubmit}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken
                      }}
                    />
                  </div>
                </div>
              </Affix>
            )}
          </div>
        </div>

      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    plan: state.plans.plan.data,
    auditDays: auditDaysSelector(state),
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    fetchPlan: (orderId, id, accessToken) => dispatch(fetchPlan(orderId, id, accessToken)),
    updatePlan: (data, accessToken, target) => dispatch(updatePlan(data, accessToken, target)),
    push: to => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPlanReview)
