export const initialState = {
  quotationList: { data: null, paginaton: null, error: null, loading: false },
  quotationScheduleList: {
    data: null,
    paginaton: null,
    error: null,
    loading: false,
  },
  quotation: { data: null, error: null, loading: false },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_QUOTATIONS_PENDING":
      return {
        ...state,
        quotationList: { data: null, error: null, loading: true },
      };
    case "FETCH_QUOTATIONS_REJECTED":
      return {
        ...state,
        quotationList: { data: null, error: action.payload, loading: false },
      };
    case "FETCH_QUOTATIONS_FULFILLED":
      return {
        ...state,
        quotationList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false,
        },
      };

    case "FETCH_QUOTATION_SCHEDULES_PENDING":
      return {
        ...state,
        quotationScheduleList: { data: null, error: null, loading: true },
      };
    case "FETCH_QUOTATION_SCHEDULES_REJECTED":
      return {
        ...state,
        quotationScheduleList: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case "FETCH_QUOTATION_SCHEDULES_FULFILLED":
      return {
        ...state,
        quotationScheduleList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false,
        },
      };

    case "FETCH_QUOTATION_PENDING":
      return {
        ...state,
        quotation: { data: null, error: null, loading: true },
      };
    case "FETCH_QUOTATION_REJECTED":
      return {
        ...state,
        quotation: { data: null, error: action.payload, loading: false },
      };
    case "FETCH_QUOTATION_FULFILLED":
      return {
        ...state,
        quotation: { data: { ...action.payload }, error: null, loading: false },
      };

    case "UPDATE_QUOTATION_PENDING":
      return {
        ...state,
        quotation: { ...state.quotation, error: null, loading: true },
      };
    case "UPDATE_QUOTATION_REJECTED":
      return {
        ...state,
        quotation: {
          ...state.quotation,
          error: action.payload,
          loading: false,
        },
      };
    case "UPDATE_QUOTATION_FULFILLED":
      return {
        ...state,
        quotation: { data: { ...action.payload }, error: null, loading: false },
      };

    case "DELETE_QUOTATION_PENDING":
      return {
        ...state,
        quotation: { ...state.quotation, error: null, loading: true },
      };
    case "DELETE_QUOTATION_REJECTED":
      return {
        ...state,
        quotation: {
          ...state.quotation,
          error: action.payload,
          loading: false,
        },
      };
    case "DELETE_QUOTATION_FULFILLED":
      return {
        ...state,
        quotation: { data: null, error: null, loading: false },
      };

    case "LOGOUT_FULFILLED":
      return { ...initialState };

    default:
      return state;
  }
};
