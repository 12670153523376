import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import intersection from 'lodash/intersection'

import { getCertificateLabel } from '../../const/orders'

export default class OrderCertificate extends React.Component {
  render () {
    const { order, certificates, user } = this.props
    return (
      <div className='widget order-certificate'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Certificates</h3>
          <div className='widget-header-toolbar'>
            { intersection(['Super Admin', 'Certificate Admin'], user.data.role).length > 0 &&
              certificates.length < order.questionnaire.scopes.length &&
              <Link to={`/orders/${order.id}/certificates/new`} className='btn btn-link btn-xs'><i className='fa fa-plus' />New</Link>
            }{' '}
          </div>
        </div>
        <table className='table' style={{ marginBottom: 0 }}>
          <thead>
            <tr>
              <th>Certificate Number</th>
              <th>Standard</th>
              <th>Relase Date</th>
              <th>End Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { certificates.length === 0 &&
              <tr>
                <td colSpan={5}>
                  {
                    ['Super Admin', 'Certificate Admin'].indexOf(user.data.role) > -1 &&
                    ['Release Certificate'].indexOf(order.status) > -1
                      ? <Link to={`/orders/${order.id}/certificates/new`} className='btn btn-default'><i className='fa fa-plus' />Create New Certificate</Link>
                      : <p>Not available</p>
                  }
                </td>
              </tr>
            }
            {certificates.map((x, y) => {
              return (
                <tr key={y}>
                  <td><Link to={`/orders/${x.order.id}/certificates/${x.id}`}>{x.number}</Link></td>
                  <td>{x.detail ? x.detail.standard : 'n/a'}</td>
                  <td>{moment(x.release_date).format('D MMMM YYYY')}</td>
                  <td>{moment(x.end_date).format('D MMMM YYYY')}</td>
                  <td>{getCertificateLabel(x.status)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
