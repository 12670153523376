import React from 'react'
import { Link } from 'react-router-dom'
import intersection from 'lodash/intersection'

import { getCertificateLabel } from '../../const/orders'

export default class OrderChange extends React.Component {
  render () {
    const { order, changes, user } = this.props
    return (
      <div className='widget order-change'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Changes</h3>
          <div className='widget-header-toolbar'>
            { intersection(['Super Admin','Auditor', 'Operational Manager', 'Sales', 'Customer'], user.data.role).length > 0 &&
              <Link to={`/orders/${order.id}/changes/new`} className='btn btn-link btn-xs btn-new-change'><i className='fa fa-plus' />New</Link>
            }{' '}
          </div>
        </div>
        <table className='table' style={{ marginBottom: 0 }}>
          <thead>
            <tr>
              <th>Code</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { changes.length === 0 &&
              <tr>
                <td colSpan={5}>
                  {
                    ['Super Admin'].indexOf(user.data.role) > -1
                    // ['Release Certificate'].indexOf(order.status) > -1
                      ? <Link to={`/orders/${order.id}/changes/new`} className='btn btn-default'><i className='fa fa-plus' />Create New Notified Change</Link>
                      : <p>Not available</p>
                  }
                </td>
              </tr>
            }
            {changes.map((x, y) => {
              return (
                <tr key={y}>
                  <td><Link to={`/orders/${x.order.id}/changes/${x.id}`}>{x.id}</Link></td>
                  <td>{getCertificateLabel(x.status)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
