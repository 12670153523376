import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import { push } from 'react-router-redux'
import { getFormValues, change } from 'redux-form'
import moment from 'moment'
import get from 'lodash/get'
import intersection from 'lodash/intersection'
import { Helmet } from 'react-helmet'

import ReviewForm from '../../components/common/ReviewForm'
import Affix from '../../components/common/Affix'
import ScheduleCalendar from '../../components/Orders/ScheduleCalendar'
import ScheduleDetail from '../../components/Orders/ScheduleDetail'
import ScheduleForm from '../../components/Orders/ScheduleForm'
import ScheduleCounter from '../../components/Orders/ScheduleCounter'
import BasicModal from '../../components/common/BasicModal'

import { fetchOrder, updateOrder } from '../../actions/orders'
import { fetchSchedules, fetchGlobalSchedules, createSchedule, updateSchedule, deleteSchedule } from '../../actions/schedules'
import { fetchAuditors } from '../../actions/users'
import { fetchEvents } from '../../actions/events'

import { getScheduleError, otherAuditorsScheduleSelector } from '../../selectors/schedules'
import { getScheduleByType } from '../../const/scheduleTypes'

export function OrderScheduleView (props) {
  const {
    accessToken,
    fetchAuditors,
    fetchGlobalSchedules,
    fetchOrder,
    fetchEvents,
    fetchSchedules,
    match,
    events,
    createSchedule,
    updateSchedule,
    deleteSchedule,
    schedules,
    changeSchedule,
    updateOrder,
    push,
    order,
    auditorsSchedule,
    formValue
  } = props

  const day1 = moment()
  const [start] = useState(day1.format('YYYY-MM-DD'))
  const [end] = useState(day1.add(59, 'days').endOf('month').format('YYYY-MM-DD'))
  const [openDate, setOpenDate] = useState(null)
  const [openAuditor, setOpenAuditor] = useState(null)
  const [openSchedule, setOpenSchedule] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [view, setView] = useState('calendar')
  const [openModal, setOpenModal] = useState(null)

  useEffect(() => {
    fetchOrder(match.params.orderId, accessToken)
    fetchSchedules(match.params.orderId, accessToken, {
      expand: 'user'
    })

    if (match.params.id === 'manage') {
      fetchAuditors(accessToken)
      fetchEvents(`?start=${start}&end=${end}`, accessToken)
      fetchGlobalSchedules(accessToken, {
        start: start,
        end: end,
        expand: 'user'
      })
    }
  }, [accessToken, end, fetchAuditors, fetchEvents, fetchGlobalSchedules, fetchOrder, fetchSchedules, match.params.id, match.params.orderId, start])

  function handleSubmit (data) {
    const request = {
      ...data,
      order_id: order.id,
      user_id: data.user_id.value,
      location_id: data.location.value,
      status: 'Draft'
    }
    if (openSchedule) {
      request.id = openSchedule
      updateSchedule(request, accessToken)
        .then(x => {
        })
    } else {
      createSchedule(request, accessToken)
        .then(x => {
          setOpenSchedule(x.id)
        })
    }
  }

  function handleSubmitOrder () {
    let nextStatus
    if (order.phase === 'Certification Audit') {
      if (['Prerequisite Completed', 'A Team Stage 1 Need to Revise'].includes(order.status)) {
        nextStatus = 'Audit Schedule Stage 1 Created'
      }
      if (['Audit Stage 1 Done', 'A Team Stage 2 Need to Revise'].includes(order.status)) {
        nextStatus = 'Audit Schedule Stage 2 Created'
      }
      if (['VA Stage 1 Approved', 'A Team VA Stage 1 Need to Revise'].includes(order.status)) {
        nextStatus = 'VA Stage 1 Schedule Created'
      }
      if (['VA Stage 2 Approved', 'A Team VA Stage 2 Need to Revise'].includes(order.status)) {
        nextStatus = 'VA Stage 2 Schedule Created'
      }
    } else if (order.phase === 'Recertification Audit') {
      if (['Prerequisite Completed', 'A Team Need to Revise'].includes(order.status)) {
        nextStatus = 'Audit Schedule Created'
      }
      if (['VA Approved', 'A Team VA Need to Revise'].includes(order.status)) {
        nextStatus = 'VA Schedule Created'
      }
    } else {
      if (['Prerequisite Completed', 'A Team Need to Revise'].includes(order.status)) {
        nextStatus = 'Audit Schedule Created'
      }
      if (['VA Approved', 'A Team VA Need to Revise'].includes(order.status)) {
        nextStatus = 'VA Schedule Created'
      }
    }
    setSubmitting(true)
    const request = {
      id: order.id,
      status: nextStatus
    }

    updateOrder(request, accessToken)
      .then(() => {
        setSubmitting(false)
        push(`/orders/${order.id}`)
      })
  }

  function handleDelete (id) {
    const request = {
      id,
      order_id: order.id
    }
    setOpenSchedule(false)
    setOpenAuditor(false)
    setOpenDate(false)
    deleteSchedule(request, accessToken)
      .then(x => {
      })
  }

  function handleSubmitReview (e) {
    let orderStatus
    switch (order.status) {
      case 'A Team Stage 1 Proposed':
        orderStatus = ['A Team Stage 1 Need to Revise', 'A Team Stage 1 Approved']
        break
      // case 'Schedule Approved by HCB':
      //   orderStatus = ['Schedule Draft', 'Schedule Approved by Customer']
      //   break
      // case 'Reschedule - Schedule Published':
      //   orderStatus = ['Reschedule - Schedule Draft', 'Reschedule - Schedule Approved by HCB']
      //   break
      // case 'Reschedule - Schedule Approved by HCB':
      //   orderStatus = ['Reschedule - Schedule Draft', 'Reschedule - Schedule Approved by Customer']
      //   break
      // case 'Follow up - Schedule Published':
      //   orderStatus = ['Follow up - Schedule Draft', 'Follow up - Schedule Approved by HCB']
      //   break
      // case 'Follow up - Schedule Approved by HCB':
      //   orderStatus = ['Follow up - Schedule Draft', 'Follow up - Schedule Approved by Customer']
      //   break
      default :
        break
    }

    let request = {
      id: order.id,
      status: (e.result === 'Not Approved' ? orderStatus[0] : orderStatus[1]),
      log: e.log ? e.log.detail : ''
    }
    return updateOrder(request, accessToken)
      .then(() => {
        push(`/orders/${order.id}`)
      })
  }

  function closeWidget () {
    setOpenDate(null)
    setOpenAuditor(null)
    setOpenSchedule(null)
  }

  const questionnaire = get(props, 'order.questionnaire', null)
  const quotation = get(props, 'order.quotation', null)
  let staged = null

  if (questionnaire) {
    staged = {
      user_id: openAuditor ? {
        label: auditorsSchedule.find(x => x.id === openAuditor).data.display_name,
        value: auditorsSchedule.find(x => x.id === openAuditor).id
      } : null,
      type: getScheduleByType(order.phase, order.status)[0].label,
      role: 'Lead Auditor',
      location: {
        label: questionnaire.locations[0].location.name,
        value: questionnaire.locations[0].location.id
      },
      start_date: openDate || moment().format('YYYY-MM-DD'),
      end_date: openDate || moment().format('YYYY-MM-DD'),
      detail: {
        include_manday: true
      }
    }
  }

  if (!order || !questionnaire || !auditorsSchedule) {
    return <p>loading</p>
  }

  const scopes = questionnaire.scopes.map(x => x.scope.id)
  const standards = questionnaire.scopes.map(x => x.standard.id)
  const auditorWithScopes = auditorsSchedule.map(x => {
    let weight = 0

    const specScopeId = x.specifications
      .filter(xx => xx.scope)
      .map(xx => xx.scope.id)
    weight += intersection(specScopeId, scopes).length * 10

    const specStandardId = x.specifications
      .filter(xx => xx.scope)
      .map(xx => xx.standard.id)
    weight += intersection(specStandardId, standards).length

    const isExpert = x.specifications
      .filter(xx => xx.scope)
      .filter(xx => xx.capacity.includes('Expert'))
      .map(xx => xx.scope.id)

    const expertIntersection =  intersection(isExpert, scopes)

    return { ...x, weight, isExpert: expertIntersection.length > 0 }
  })
    .sort((a, b) => a.weight < b.weight)

  if (openSchedule) {
    const xxx = schedules.find(x => x.id === openSchedule)
    staged = {
      id: openSchedule,
      user_id: openAuditor ? {
        label: xxx.user.data.display_name,
        value: xxx.user.id
      } : null,
      type: xxx.type,
      role: xxx.role,
      location: {
        label: xxx.location.name,
        value: xxx.location.id
      },
      start_date: xxx.start_date,
      end_date: xxx.end_date,
      detail: { ...xxx.detail }
    }
  }

  const scheduleError = getScheduleError(schedules, order, quotation);

  return (
    <div>
      <div className='content'>
        <Switch>
          <Route path='/orders/:orderId/schedules/manage'>
            <div>
              <Helmet>
                <title>{order ? `Order ${order.id} - Manage schedule` : `Loading`}</title>
              </Helmet>
              <div className='main-header'>
                <h2>Create Audit Schedules</h2>
                <em>For Order { order && <Link to={`/orders/${order.id}`}>{order.id}</Link> }</em>
              </div>
              <ScheduleCounter schedules={schedules} quotation={quotation} order={order} />
              <div className='row'>
                <div className='col-md-8'>
                  { view === 'calendar' &&
                    <Fragment>
                      { order && auditorsSchedule
                        ? <Fragment>
                          { schedules && schedules.length > 0 && scheduleError.length === 0
                            ? <p>
                              <button className='btn btn-to-review btn-primary btn-block' onClick={() => setView('list')}>Review and submit</button>
                              </p>
                            : <div>
                              { scheduleError.map(x => (<div className="alert alert-danger" key={x}>{x}</div>))}
                              </div>
                          }
                          <ScheduleCalendar
                            onOpenModal={setOpenModal}
                            start={start}
                            end={end}
                            events={events}
                            auditorsSchedule={auditorWithScopes}
                            orderSchedule={schedules}
                            active={openAuditor ? formValue : null}
                            order={order}
                            onOpenDate={(auditor, date, schedule) => {
                              setOpenAuditor(auditor)
                              setOpenDate(date)
                              setOpenSchedule(schedule)
                            }}
                          />
                        </Fragment>
                        : <div>loading</div>
                      }
                    </Fragment>
                  }
                  { view === 'list' &&
                    <Fragment>
                      { order
                        ? <Fragment>
                          <ScheduleDetail order={order} schedules={schedules} />
                        </Fragment>
                        : <div>loading order</div>
                      }
                    </Fragment>
                  }
                </div>
                <div className='col-md-4'>
                  {view === 'calendar' && ((openDate && openAuditor) || openSchedule) &&
                    <div className='widget'>
                      <div className='widget-header'>
                        <h3>Schedule for {openDate} at {openAuditor}</h3>
                        <div className='btn-group widget-header-toolbar'>
                          <a href='#delete' onClick={closeWidget} title='Remove' className='btn-borderless btn-remove'><i className='fa fa-times' /></a>
                        </div>
                      </div>
                      <div className='widget-content'>
                        <ScheduleForm
                          initialValues={staged}
                          userAPI={{
                            url: process.env.REACT_APP_BASE_API + '/users?role=auditor',
                            accessToken: accessToken,
                            label: 'data.display_name'
                          }}
                          order={order}
                          change={changeSchedule}
                          locations={questionnaire.locations.map(x => ({ label: x.location.name, value: x.location.id }))}
                          onSubmit={handleSubmit}
                          onDelete={openSchedule && handleDelete}
                        />
                      </div>
                    </div>
                  }
                  {view === 'list' &&
                    <Fragment>
                      <p>
                        <button type='button' disabled={isSubmitting} className='btn btn-default btn-block' onClick={() => setView('calendar')}>Back to calendar</button>
                      </p>
                      <p>
                        <button type='button' disabled={isSubmitting} className='btn btn-submit-schedule btn-block btn-primary' onClick={handleSubmitOrder} >
                          Submit Schedules
                        </button>
                      </p>
                    </Fragment>
                  }
                </div>
              </div>
            </div>
          </Route>
          <Route path='/orders/:orderId/schedules/review'>
            <div>
              <Helmet>
                <title>{order ? `Order ${order.id} - Review schedule` : `Loading`}</title>
              </Helmet>
              <div className='main-header'>
                <h2>Review Audit Schedules</h2>
                <em>For Order { order && <Link to={`/orders/${order.id}`}>{order.id}</Link> }</em>
              </div>
              <div className='row'>
                <div className='col-md-8'>
                  { order
                    ? <ScheduleDetail order={order} />
                    : <div>loading order</div>
                  }
                </div>
                <div className='col-md-4'>
                  { order && [
                    'A Team Stage 1 Proposed'
                  ].indexOf(order.status) >= 0 &&

                  <Affix>
                    <div className='widget'>
                      <div className='widget-header'>
                        <h3><i className='fa fa-tasks' /> Review</h3>
                      </div>
                      <div className='widget-content'>
                        <ReviewForm
                          onSubmit={handleSubmitReview}
                          fileAPI={{
                            url: process.env.REACT_APP_BASE_API,
                            accessToken: accessToken
                          }}
                        />
                      </div>
                    </div>
                  </Affix>
                  }
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
      <BasicModal open={openModal} onClose={() => setOpenModal(null)}>
        {openModal?.email && (
          <>
            <dl className="dl-horizontal">
              <dt>Auditor</dt>
              <dd>{openModal?.data?.display_name}</dd>
              <dt>Email</dt>
              <dd>{openModal?.email}</dd>
            </dl>
            <table className="table">
              <thead>
                <tr>
                  <th>Standard</th>
                  { ['Lead Auditor', 'Auditor', 'Senior Auditor', 'Expert', 'Trainee'].map(x => <th key={x}>{x}</th>)}
                </tr>
              </thead>
              <tbody>
              {openModal.specifications.map((x, i) => (
                <tr key={i}>
                  <td>{x?.standard?.name} : {x?.scope?.name}</td>
                  { ['Lead Auditor', 'Auditor', 'Senior Auditor', 'Expert', 'Trainee'].map(xx => (
                    <td key={xx}>{x?.capacity?.includes(xx) && 'x'}</td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </>
        )}
        {openModal?.start_date && (
          <>
            <dl className="dl-horizontal">
              <dt>Order</dt>
              <dd>{openModal.order.number}</dd>
              <dt>Customer</dt>
              <dd>{openModal.customer.name}</dd>
              <dt>Standard</dt>
              <dd>{openModal.order.scopes.map((x) => x.standard.name).join(', ')}</dd>
            </dl>
          </>
        )}
      </BasicModal>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    formValue: getFormValues('ScheduleForm')(state),
    order: state.orders.order.data,
    schedules: state.schedules.scheduleList.data,
    auditors: state.users.auditorList.data,
    auditorsSchedule: otherAuditorsScheduleSelector(state),
    accessToken: state.auth.user.access.access_token,
    events: state.events.eventList.data
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    updateOrder: (data, key, target) => dispatch(updateOrder(data, key, target)),
    fetchSchedules: (id, key, params) => dispatch(fetchSchedules(id, key, params)),
    fetchGlobalSchedules: (key, params) => dispatch(fetchGlobalSchedules(key, params)),
    fetchAuditors: (key) => dispatch(fetchAuditors(key)),
    changeSchedule: (field, value) => dispatch(change('ScheduleForm', field, value)),
    createSchedule: (data, accessToken, target) => dispatch(createSchedule(data, accessToken, target)),
    deleteSchedule: (data, accessToken, target) => dispatch(deleteSchedule(data, accessToken, target)),
    updateSchedule: (data, accessToken, target) => dispatch(updateSchedule(data, accessToken, target)),
    fetchEvents: (params, key) => dispatch(fetchEvents(params, key)),
    push: to => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderScheduleView)
