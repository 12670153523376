import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getOrderLabel } from '../../const/orders'

export default class QuotationTable extends React.Component {
  render () {
    return (
      <div className='table-responsive quotation-table'>
        <table className='table colored-header datatable project-list'>
          <thead>
            <tr>
              <th>Quotation</th>
              <th>Customer</th>
              <th>Questionnaire</th>
              <th>Standard</th>
              <th>Phase</th>
              <th>Due Date</th>
              <th>CA Order Number</th>
              <th>Order Status</th>

            </tr>
          </thead>
          <tbody>
            {
              this.props.quotationSchedules.map((x, i) => {
                return (
                  <tr className={`quotation-schedule-${x.id}`} key={i}>
                    <td><Link to={`/quotations/${x.quotation.id}`}>{x.quotation.number}</Link></td>
                    <td><Link to={`/customers/${x.customer.id}`}>{x.customer.name}</Link></td>
                    <td><Link to={`/questionnaires/${x.questionnaire.id}`}>{x.questionnaire.number}</Link></td>
                    <td>{x.questionnaire.scopes.map(x => x.standard.name).join(' - ')}</td>
                    <td>{x.phase}</td>
                    <td>{moment(x.schedule).format('D MMMM YYYY')}</td>
                    <td>{x.initialOrder ? <Link to={`/orders/${x.initialOrder.id}`}>{x.initialOrder.number}</Link> : '-'}</td>
                    <td>{x.order ? getOrderLabel(x.order.status) : ''}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}
