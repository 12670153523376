import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextareaField, OptionsField } from '../common/FormFields'
import FileField from '../common/FileField'
import ConfirmButton from '../common/ConfirmButton'

class ReportForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openConfirm: false
    }
  }

  render () {
    const { handleSubmit, submitting, order } = this.props
    const { openConfirm } = this.state
    let actions

    switch (order.status) {
      case 'Audit Stage 1 On Process':
        actions = 'proceed-stage-1'
        break
      case 'Audit Stage 1 Done':
        actions = 'proceed-stage-1'
        break
      case 'VA Stage 1 On Process':
        actions = 'proceed-stage-1'
        break
      case 'VA Stage 1 Done':
        actions = 'proceed-stage-1'
        break
      case 'Audit Stage 2 On Process':
        actions = 'proceed-stage-2'
        break
      case 'Audit Stage 2 Done':
        actions = 'proceed-stage-2'
        break
      case 'VA Stage 2 On Process':
        actions = 'proceed-stage-2'
        break
      case 'VA Stage 2 Done':
        actions = 'proceed-stage-2'
        break
      case 'Audit On Process':
        actions = 'proceed'
        break
      case 'Audit Done':
        actions = 'proceed'
        break
      case 'VA On Process':
        actions = 'proceed'
        break
      case 'VA Done':
        actions = 'proceed'
        break
      // case 'Stage 2 - Audited':
      //   actions = 'proceed-stage-2'
      //   break
      // case 'Follow up - On Audit':
      //   actions = 'proceed-follow-up'
      //   break
      // case 'Follow up - Audited':
      //   actions = 'proceed-follow-up'
      //   break
      default:
        actions = null
        break
    }

    return (

      <div>

        <form className='form-horizontal report-form' onSubmit={handleSubmit}>
          <Field name='detail.audit_report' component={FileField} label='Audit Report' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.document_review' component={FileField} label='Document Review' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.environmental_aspect' component={FileField} label='Environmental Aspect' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.handwritten_notes' component={FileField} label='Handwritten Notes' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.declaration_of_independence' component={FileField} label='Declaration of Independence' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.attendance_list' component={FileField} label='Attendance List' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.draft_ncr' component={FileField} label='Draft NCR' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.management_conformity' component={FileField} label='Management of Conformity' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.evidence_ncr' component={FileField} label='Evidence of NCR' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.audit_checklist' component={FileField} label='Audit Checklist' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='detail.draft_certification' component={FileField} label='Draft of Certification' api={{...this.props.fileAPI, path: 'report'}} />
          <Field name='remarks' component={TextareaField} label='Remarks' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              { actions === 'proceed-stage-1' &&
                <div>
                  <p><button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button></p>
                  <ConfirmButton
                    onClick={(x) => this.setState({ openConfirm: !openConfirm })}
                    open={openConfirm}
                    text='Publish report'
                    confirmMessage='Please confirm the report and decision before proceeding.'
                  >
                    <Field
                      name='decision'
                      component={OptionsField}
                      type='radio'
                      label='Decision'
                      options={[
                        { value: 'Audit Stage 1 Done',
                          label: 'The audit team is convinced that the Stage 2 audit can be performed as planned without limitations.'
                        },
                        // { value: 'Reschedule',
                        //   label: 'The audit team is convinced that the Stage 2 audit can be performed as planned - the organisation must ensure, however, that the nonconformities which have been identified have been effectively corrected at the planned date. (Reschedule Stage 2)'
                        // },
                        { value: 'VA Stage 1 Required',
                          label: 'The audit team is convinced that effective correction of the nonconformities which have been identified must be verified before the stage 2 audit. (Verification Audit Stage 1 Required)'
                        }
                      ]}
                    />
                    <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({ ...values, advance: true }))} >Publish report</button>
                  </ConfirmButton>
                </div>
              }
              { actions === 'proceed-stage-2' &&
                <div>
                  <p><button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button></p>
                  <ConfirmButton disabled={submitting} text={'Publish report'} confirmMessage='Please confirm the report and decision before proceeding.'>
                    <Field
                      name='decision'
                      component={OptionsField}
                      type='radio'
                      label='Decision'
                      options={[
                        // { value: 'Audit Documents Verification',
                        //   label: 'The audit team is of the opinion that the comments made to date on the Stage 2 audit are not correct and proposes the following changes : Audit Documents Verification'
                        // },
                        { value: 'VA Stage 2 Required',
                          label: 'The audit team is of the opinion that the comments made to date on the Stage 2 audit are not correct and proposes the following changes : Verification Audit Stage 2 Required'
                        },
                        { value: 'Audit Stage 2 Done',
                          label: 'The audit team is convinced that the comments on the Stage 2 audit are correct'
                        }
                      ]}
                    />
                    <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({ ...values, advance: true }))} >Publish report</button>
                  </ConfirmButton>
                </div>
              }
              { actions === 'proceed' &&
                <div>
                  <p><button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button></p>
                  <ConfirmButton disabled={submitting} text={'Publish report'} confirmMessage='Please confirm the report and decision before proceeding.'>
                    <Field
                      name='decision'
                      component={OptionsField}
                      type='radio'
                      label='Decision'
                      options={[
                        // { value: 'Audit Documents Verification',
                        //   label: 'The audit team is of the opinion that the comments made to date on the Stage 2 audit are not correct and proposes the following changes : Audit Documents Verification'
                        // },
                        { value: 'VA Required',
                          label: 'The audit team is of the opinion that the comments made to date on the audit are not correct and proposes the following changes : Verification Audit Required'
                        },
                        { value: 'Audit Done',
                          label: 'The audit team is convinced that the comments on the audit are correct'
                        }
                      ]}
                    />
                    <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({ ...values, advance: true }))} >Publish report</button>
                  </ConfirmButton>
                </div>
              }
              { actions === 'proceed-follow-up' &&
                <div>
                  <p><button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button></p>
                  <ConfirmButton disabled={submitting} text={'Publish report'} confirmMessage='Please confirm the report and decision before proceeding.'>
                    <Field
                      name='decision'
                      component={OptionsField}
                      type='radio'
                      label='Decision'
                      options={[
                        // { value: 'Audit Documents Verification',
                        //   label: 'The audit team is of the opinion that the comments made to date on the Stage 2 audit are not correct and proposes the following changes : Audit Documents Verification'
                        // },
                        // { value: 'Follow up',
                        //   label: 'The audit team is of the opinion that the comments made to date on the Stage 2 audit are not correct and proposes the following changes : Schedule Follow up audit'
                        // },
                        { value: 'Stage 2 - Report Published',
                          label: 'The audit team is convinced that the comments on the Stage 2 audit are correct'
                        }
                      ]}
                    />
                    <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({ ...values, advance: true }))} >Publish report</button>
                  </ConfirmButton>
                </div>
              }
              { !actions &&
                <div>
                  <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ReportForm

const BaseReportCreateForm = props => <ReportForm {...props} mode='create' />

export const ReportCreateForm = reduxForm({
  form: 'reportCreate',
  enableReinitialize: true
})(BaseReportCreateForm)

const BaseReportUpdateForm = props => <ReportForm {...props} mode='update' />

export const ReportUpdateForm = reduxForm({
  form: 'reportUpdate',
  enableReinitialize: true
})(BaseReportUpdateForm)
