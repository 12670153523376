import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextareaField } from '../common/FormFields'
import FileField from '../common/FileField'

class PlanForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <form className='form-horizontal plan-form' onSubmit={handleSubmit}>
        <Field name='detail.plan_document' component={FileField} label='Audit Plan Document' api={{...this.props.fileAPI, path: 'plan'}} />
        <Field name='remarks' component={TextareaField} label='Remarks' />
        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
          </div>
          <div className='col-sm-3'>
            <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values}))} >Submit</button>
          </div>
        </div>
      </form>
    )
  }
}

export default PlanForm

const BasePlanCreateForm = props => <PlanForm {...props} mode='create' />

export const PlanCreateForm = reduxForm({
  form: 'planCreate',
  enableReinitialize: true
})(BasePlanCreateForm)

const BasePlanUpdateForm = props => <PlanForm {...props} mode='create' />

export const PlanUpdateForm = reduxForm({
  form: 'planUpdate',
  enableReinitialize: true
})(BasePlanUpdateForm)
