// TODO : annex should be loaded separately so it doesnt have to be included in every standard call

import React from 'react'
import { change } from 'redux-form'
import { connect } from 'react-redux'

import QuestionnaireAnnexForm from '../../components/Questionnaires/QuestionnaireAnnexForm'
import { annexWithValueSelector } from '../../selectors/questionnaires'

export function QuestionnaireAnnex (props) {
  const {
    questionnaire,
    accessToken,
    user
  } = props
  const questionnaireAnnex = { ...props.questionnaireAnnex }

  function handleSubmit (e) {
    let target
    const request = {
      id: questionnaire.id,
      scopes: e.scopes,
      detail: {}
    }
    if (e.advance) {
      request.detail.annex_complete = true
      target = `/questionnaires/${questionnaire.id}/mandays-calculation`
    }
    if (user.data.role.includes('Customer')) {
      request.detail.input_by_customer = true
    }
    return props.updateQuestionnaire(request, accessToken, target).then((x) => {
      props.fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  return (
    <div className='tab-pane active'>
      <h1>Certification Annex</h1>
      <div className='row'>
        <div className='col-md-8'>
          <p>Dear Sales Personnel, please fill in the customer detail form.</p>

          <QuestionnaireAnnexForm
            onSubmit={handleSubmit.bind(this)}
            initialValues={{ scopes: questionnaireAnnex }}
            questionnaire={questionnaire}
            changeForm={props.changeForm}
            fileAPI={{
              url: process.env.REACT_APP_BASE_API,
              accessToken: accessToken
            }}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    questionnaireAnnex: annexWithValueSelector(state),
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeForm: (field, value) => dispatch(change('QuestionnaireAnnexForm', field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireAnnex)
