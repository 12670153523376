const statuses = [
  {
    label: 'Approved by Customer',
    'color': 'info'
  },
  {
    label: 'Published',
    'color': 'info'
  },
  {
    label: 'Draft',
    'color': 'info'
  },
]

export default statuses
