import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import CustomerNew from './Customers/CustomerNew'
import CustomerList from './Customers/CustomerList'
import CustomerUpdate from './Customers/CustomerUpdate'
import CustomerContactUpdate from './Customers/CustomerContactUpdate'
import CustomerLocationUpdate from './Customers/CustomerLocationUpdate'
import CustomerContactNew from './Customers/CustomerContactNew'
import CustomerLocationNew from './Customers/CustomerLocationNew'

function mapStateToProps (state) {
  return {

  }
}

export class Customers extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/customers/:cust_id/contacts/new' component={CustomerContactNew} />
          <Route path='/customers/:cust_id/locations/new' component={CustomerLocationNew} />
          <Route path='/customers/:cust_id/contacts/:id' component={CustomerContactUpdate} />
          <Route path='/customers/:cust_id/locations/:id' component={CustomerLocationUpdate} />
          <Route exact path='/customers/new' component={CustomerNew} />
          <Route path='/customers/:id/:tab?' component={CustomerUpdate} />
          <Route exact path='/customers' component={CustomerList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Customers)
