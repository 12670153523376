import React from 'react'
import { Link } from 'react-router-dom'

export default class StandardTable extends React.Component {
  render () {
    const { standards } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Standard Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              standards
                .sort((a, b) => parseInt(a.detail.list_order, 10) - parseInt(b.detail.list_order, 10))
                .map((standard, id) => (
                  <tr key={id}>
                    <td><Link to={`/standards/${standard.id}`}>{standard.detail.list_order}</Link></td>
                    <td><Link to={`/standards/${standard.id}`}>{standard.name}</Link></td>
                    <td>{standard.description}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}
