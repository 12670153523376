import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function AuditorTable ({
  auditors,
  standards,
  onChangeStandard
}) {
  const [standard, setStandard] = useState(0)
  const [loading, setLoading] = useState(false)
  const [scope, setScope] = useState(0)
  const [scopes, setScopes] = useState(0)
  let auditorList = auditors

  useEffect(() => {
    if (standard !== 0) {
      onChangeStandard(standard).then((x) => {
        setScopes(x)
        setLoading(false)
      })
    }
    // eslint-disable-next-line 
  }, [standard])

  if (standard !== 0) {
    auditorList = auditors.filter((x) => {
      if (scope !== 0) {
        return x.specifications.find((xx) => xx.scope && xx.scope.id === scope)
      } else {
        return x.specifications.find((xx) => xx.standard && xx.standard.id === standard)
      }
    })
  }

  return (
    <div>
      <form className='form-inline'>
        <div className='form-group'>
          <select
            className='form-control'
            onChange={(ev) => {
              setStandard(parseInt(ev.target.value, 10))
              setLoading(true)
            }}
          >
            <option value={0}>All standards</option>
            {standards.map((x) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </select>
        </div>{' '}
        {scopes !== 0 && !loading &&
          <div className='form-group'>
            <select
              className='form-control'
              onChange={(ev) => setScope(parseInt(ev.target.value))}
            >
              <option value={0}>All scopes</option>
              {scopes.map((x) => (
                <option value={x.id} key={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
        }
      </form>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Auditor Code</th>
              <th>Name</th>
              <th>Email</th>
              {standards
                .sort(
                  (a, b) =>
                    parseInt(a.detail.list_order, 10) -
                    parseInt(b.detail.list_order, 10)
                )
                .map((x) => (
                  <th key={x.id}>{x.name}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {auditorList.length === 0 && (
              <tr colSpan={5}>
                <td>User not found</td>
              </tr>
            )}
            {auditorList
              .sort((a, b) => a.data.display_name.localeCompare(b.data.display_name))
              .map((user) => (
              <tr key={user.id}>
                <td>
                  <Link to={`/users/${user.id}`}>{user.data.auditor_code}</Link>
                </td>
                <td>
                  <Link to={`/users/${user.id}`}>
                    {user.data && user.data.display_name}
                  </Link>
                </td>
                <td>{user.email}</td>
                {standards
                  .sort(
                    (a, b) =>
                      parseInt(a.detail.list_order, 10) -
                      parseInt(b.detail.list_order, 10)
                  )
                  .map((x) => {
                    const specs = user.specifications.filter(
                      (xx) => xx.standard.id === x.id
                    )
                    return (
                      <td key={x.id} className='text-center'>
                        {specs
                          .map((xx, ii) => (
                            <span key={ii} >
                              {xx?.scope?.detail?.number}{xx?.capacity?.includes('Expert') ? '(E)' : ''}&nbsp;
                            </span>
                          ))
                        }
                      </td>
                    )
                  })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
