import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Switch, Route } from 'react-router-dom';
import { change } from 'redux-form';

import ItemLog from '../../components/common/ItemLog';
import Pagination from '../../components/common/Pagination';

import CertificateTable from '../../components/Orders/CertificateTable';
import {
  CertificateCreateForm,
  CertificateUpdateForm,
} from '../../components/Orders/CertificateForm';

import { fetchOrder } from '../../actions/orders';
import {
  fetchCertificates,
  fetchCertificate,
  createCertificate,
  updateCertificate,
} from '../../actions/certificates';
import { fetchCustomerLocations } from '../../actions/customers';

import { getPhaseInitial } from '../../selectors/order';

function DownloadButton(props) {
  return (
    <a
      className="btn btn-primary btn-block"
      href={`${process.env.REACT_APP_BASE_API}/pdf/certificate?template=${props.template}&id=${props.id}&token=${props.accessToken}`}
    >
      <i className="fa fa-download" /> {props.label}
    </a>
    )
}

export function OrderCertificateView(props) {
  const {
    fetchOrder,
    fetchCertificate,
    fetchCertificates,
    createCertificate,
    updateCertificate,
    match,
    accessToken,
    location,
    order,
    certificate,
    certificates,
    changeForm,
    user,
    locations,
  } = props;

  const { params } = match;

  useEffect(() => {
    fetchOrder(params.orderId, accessToken);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (certificate?.customer?.id) {
      fetchCustomerLocations(certificate.customer.id, accessToken);
    }
  }, [certificate]); // eslint-disable-line

  useEffect(() => {
    if (params.id && params.id !== 'new') {
      fetchCertificate(match.params.orderId, match.params.id, accessToken);
    }
  }, [params.id]); // eslint-disable-line

  useEffect(() => {
    if (!params.id) {
      fetchCertificates(params.orderId, accessToken, location.search);
    }
  }, [location, params]); // eslint-disable-line

  function handleSubmitNew(e) {
    return createCertificate(
      {
        ...e,
        release_date: e.release_date,
        end_date: e.end_date,
        order_id: params.orderId,
        log: e.log ? e.log.detail : '',
      },
      accessToken
    );
  }

  function handleSwitchMultisite() {
    return updateCertificate({
      ...certificate,
      order_id: certificate.order.id,
      multisite: certificate.multisite ? null : 'subcertificate'
    }, accessToken)
  }

  function handleSubmitUpdate(e) {
    if (e?.detail?.additional_location) {
      const additionalLocation = locations.find(
        x => x.id === parseInt(e.detail.additional_location, 10)
      );
      e.detail.additional_location = additionalLocation;
    } else {
      e.detail.additional_location = false;
    }
    return updateCertificate(
      {
        ...e,
        release_date: e.release_date,
        end_date: e.end_date,
        order_id: e.order.id,
        log: e.log ? e.log.detail : '',
      },
      accessToken
    );
  }

  const orderId = order
    ? `OD.${order.quotation.sales.code}.${getPhaseInitial(order.phase)}.${
        order.quotation.counter
      }`
    : '';

  if (
    certificate?.detail?.additional_location &&
    typeof certificate.detail.additional_location === 'object'
  ) {
    certificate.detail.additional_location =
      certificate.detail.additional_location.id;
  }

  const canEdit = user.data.role.includes('Certificate Admin') || user.data.role.includes('Super Admin')

  return (
    <div>
      <div className="content">
        <Switch>
          <Route path="/orders/:orderId/certificates/new">
            <div>
              <div className="main-header">
                <h2>New Certificate</h2>
                {order && (
                  <em>
                    For Order <Link to={`/orders/${order.id}`}>{orderId}</Link>
                  </em>
                )}
              </div>
              <div className="row">
                <div className="col-md-8">
                  {order && order.questionnaire ? (
                    <CertificateCreateForm
                      questionnaire={order.questionnaire}
                      onSubmit={handleSubmitNew.bind(this)}
                      changeForm={changeForm}
                      initialValues={{
                        status: 'Draft',
                        detail: {
                          standard: order.questionnaire.scopes[0].standard.name,
                          location: order.questionnaire.locations[0].location,
                        },
                      }}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken,
                      }}
                      locations={locations}
                      locationAPI={{
                        url:
                          process.env.REACT_APP_BASE_API +
                          '/customers/' +
                          order?.customer?.id +
                          '/locations',
                        accessToken: accessToken,
                      }}
                    />
                  ) : (
                    <p>loading</p>
                  )}
                </div>
              </div>
            </div>
          </Route>
          <Route path="/orders/:orderId/certificates/:id">
            <div>
              <div className="main-header">
                {certificate && (
                  <h2>Update Certificate {certificate.number}</h2>
                )}
                {order && (
                  <em>
                    For Order <Link to={`/orders/${order.id}`}>{orderId}</Link>
                  </em>
                )}
              </div>
              <div className="row">
                <div className="col-md-8">
                  {order && order.questionnaire ? (
                    <Fragment>

                        <CertificateUpdateForm
                          questionnaire={order.questionnaire}
                          onSubmit={handleSubmitUpdate.bind(this)}
                          changeForm={changeForm}
                          initialValues={certificate}
                          fileAPI={{
                            url: process.env.REACT_APP_BASE_API,
                            accessToken: accessToken,
                          }}
                          locations={locations}
                          disabled={!canEdit}
                        />
                    </Fragment>
                  ) : (
                    <p>loading</p>
                  )}
                </div>
                <div className="col-md-4">
                  {certificate && (
                    <div>                      
                        <div>
                          {order?.questionnaire?.locations?.length > 1 && certificate.status === 'Draft' && user.data.role.includes('Certificate Admin') && (
                            <>
                              <button className="btn btn-primary btn-block" type="button" onClick={handleSwitchMultisite}>
                                {certificate.multisite ? 'Switch to annex' : 'Switch to sub certificate'}
                              </button>
                              <hr/>
                            </>
                          )}
                          {order?.questionnaire?.locations?.length === 1 && (
                            <>
                              <DownloadButton template="certificate-2023-preview" id={certificate.id} accessToken={accessToken} label="Certificate Preview" />
                              {certificate?.status === 'Published' && user.data.role.includes('Certificate Admin') &&  (
                                <DownloadButton template="certificate-2023" id={certificate.id} accessToken={accessToken} label="Print Certificate" />
                              )}
                              <hr/>
                            </>
                          )}
                          {order?.questionnaire?.locations?.length > 1 && certificate.multisite === 'subcertificate' && (
                            <>
                              <DownloadButton template="certificate-multi-preview" id={certificate.id} accessToken={accessToken} label={`Certificate Preview (${order.questionnaire.locations.length} site) `} />
                              {certificate?.status === 'Published' && user.data.role.includes('Certificate Admin') &&  (
                                <DownloadButton template="certificate-multi" id={certificate.id} accessToken={accessToken} label={`Print Certificate (${order.questionnaire.locations.length} site) `} />
                              )}
                              <hr/>
                            </>
                          )}
                          {order?.questionnaire?.locations?.length > 1 && !certificate.multisite && (
                            <>
                              <DownloadButton template="certificate-2023-preview" id={certificate.id} accessToken={accessToken} label={`Main Certificate Preview `} />
                              {certificate?.status === 'Published' && user.data.role.includes('Certificate Admin') &&  (
                                <DownloadButton template="certificate-2023" id={certificate.id} accessToken={accessToken} label={`Print Main Certificate `} />
                              )}
                              <hr/>
                              <DownloadButton template="certificate-2023-annex-preview" id={certificate.id} accessToken={accessToken} label={`Certificate Annex Preview (${order.questionnaire.locations.length} site) `} />
                              {certificate?.status === 'Published' && user.data.role.includes('Certificate Admin') &&  (
                                <DownloadButton template="certificate-2023-annex" id={certificate.id} accessToken={accessToken} label={`Print Certificate Annex (${order.questionnaire.locations.length} site) `} />
                              )}
                              <hr/>
                            </>
                          )}
                        </div>
                    </div>
                  )}
                  {certificate ? (
                    <ItemLog log={certificate.log} entity="Certificate" />
                  ) : (
                    <div>loading</div>
                  )}
                </div>
              </div>
            </div>
          </Route>
          <Route path="/orders/:orderId/certificates">
            <div>
              <div className="main-header">
                <h2>Certificate List</h2>
                {order && (
                  <em>
                    For Order <Link to={`/orders/${order.id}`}>{order.id}</Link>
                  </em>
                )}
              </div>
              <div className="row">
                <div className="col-md-8">
                  {certificates && (
                    <CertificateTable certificates={certificates} />
                  )}
                </div>
                <Pagination />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    order: state.orders.order.data,
    certificates: state.certificates.certificateList.data,
    certificate: state.certificates.certificate.data,
    accessToken: state.auth.user.access.access_token,
    locations: state.customers.customerLocationList.data,
    user: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchCertificates: (id, accessToken) =>
      dispatch(fetchCertificates(id, accessToken)),
    fetchCertificate: (orderId, id, accessToken) =>
      dispatch(fetchCertificate(orderId, id, accessToken)),
    createCertificate: (data, accessToken) =>
      dispatch(createCertificate(data, accessToken)),
    updateCertificate: (data, accessToken) =>
      dispatch(updateCertificate(data, accessToken)),
    fetchCustomerLocations: (id, key) =>
      dispatch(fetchCustomerLocations(id, key)),
    changeForm: (field, data) =>
      dispatch(change('certificateUpdate', field, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCertificateView);
