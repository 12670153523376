import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField } from '../common/FormFields'
import FileField from '../common/FileField'
import ConfirmButton from '../common/ConfirmButton'

const validate = values => {
  const errors = {
    detail: {}
  }
  if (values.detail && !values.detail.quality_managament_manual) {
    errors.detail.quality_managament_manual = 'Required'
  }
  if (values.detail && !values.detail.legal_documents) {
    errors.detail.legal_documents = 'Required'
  }
  if (values.detail && !values.detail.organisation_chart) {
    errors.detail.organisation_chart = 'Required'
  }
  if (values.detail && !values.detail.business_process_map) {
    errors.detail.business_process_map = 'Required'
  }
  return errors
}

class OrderPrerequisite extends Component {
  render () {
    const { handleSubmit, submitting, changeForm, fileAPI } = this.props
    return (
      <form className='form-horizontal order-prerequisite-form' onSubmit={handleSubmit}>
        <fieldset>
          <legend>Customer Detail</legend>
          <Field name='detail.quality_managament_manual' component={FileField} label='Quality Management Manual with Revision including scope, details of justification for any exclusions, policy, targets' api={{...fileAPI, path: 'order'}} required />
          <Field name='detail.legal_documents' component={FileField} label='Legal documents' api={{...fileAPI, path: 'order'}} required />
          <Field name='detail.organisation_chart' component={FileField} label='Current organisation chart of the company' api={{...fileAPI, path: 'order'}} required />
          <Field name='detail.business_process_map' component={FileField} label='Business Process Map' api={{...fileAPI, path: 'order'}} required />
          <Field name='detail.internal_audit' component={FileField} label='Results or summary of internal audits within the last 12 months' api={{...fileAPI, path: 'order'}} />
          <Field name='detail.external_audit' component={FileField} label='Results of the management review since the last external audit' api={{...fileAPI, path: 'order'}} />
          <Field name='detail.last_audit_reports' component={FileField} label='Audit report and nonconformity management of the last certification body (additionally for transfer audits only)' api={{...fileAPI, path: 'order'}} />
          <Field name='detail.last_audit_closing' component={FileField} label='Evidence of the last certification body that nonconformities are “closed” (additionally for transfer audits only)' api={{...fileAPI, path: 'order'}} />
          <Field name='detail.last_certification' component={FileField} label='Certificates of the last certification body (additionally for transfer audits only)' api={{...fileAPI, path: 'order'}} />
        </fieldset>

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
              ev.preventDefault()
              changeForm('advance', false)
              setTimeout(handleSubmit, 100)
            }} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton
              disabled={submitting}
              text={'Submit Prerequisite Docs'}
            >
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', true)
                setTimeout(handleSubmit, 100)
              }} >Continue</button>
            </ConfirmButton>
          </div>
        </div>

      </form>
    )
  }
}

export default OrderPrerequisite

const BaseOrderPrerequisiteForm = props => <OrderPrerequisite {...props} mode='update' />

export const OrderPrerequisiteForm = reduxForm({
  form: 'OrderPrerequisiteForm',
  validate
})(BaseOrderPrerequisiteForm)
