import max from "lodash/max";
import get from "lodash/get";
import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

const scopesSelector = (state) =>
  state.questionnaires.questionnaire.data &&
  state.questionnaires.questionnaire.data
    ? state.questionnaires.questionnaire.data.scopes
    : [];

export const annexWithValueSelector = createSelector(scopesSelector, (items) =>
  items.map((x) => {
    return {
      standard_id: x.standard.id,
      scope_id: x.scope.id,
      detail: {
        annex: x.standard.annex
          ? x.standard.annex.map((y, i) => {
              let defaultValue;
              switch (y.type) {
                case "boolean":
                  defaultValue = "";
                  break;
                case "select":
                  const options = y.options.split(";");
                  defaultValue = options[0];
                  break;
                default:
                  defaultValue = null;
                  break;
              }

              const answer = {
                answer:
                  x.detail && x.detail.annex && x.detail.annex[i]
                    ? x.detail.annex[i].answer
                    : defaultValue,
              };
              if (
                x.detail &&
                x.detail.annex &&
                x.detail.annex[i] &&
                x.detail.annex[i].file
              ) {
                answer.file = x.detail.annex[i].file;
              }
              if (
                x.detail &&
                x.detail.annex &&
                x.detail.annex[i] &&
                x.detail.annex[i].explanation
              ) {
                answer.explanation = x.detail.annex[i].explanation;
              }
              return answer;
            })
          : [],
      },
    };
  })
);

const reductionCreate = (state) => {
  const formValue = getFormValues("questionnaireAdjustmentForm")(state);
  if (formValue && formValue.reduction_factor) {
    return formValue.reduction_factor;
  } else {
    return [];
  }
};

const enhancementCreate = (state) => {
  const formValue = getFormValues("questionnaireAdjustmentForm")(state);
  if (formValue && formValue.enhancement_factor) {
    return formValue.enhancement_factor;
  } else {
    return [];
  }
};

const additionCreate = (state) => {
  const formValue = getFormValues("questionnaireAdjustmentForm")(state);
  if (formValue && formValue.addition_factor) {
    return formValue.addition_factor;
  } else {
    return [];
  }
};

export const mandDayCreateSelector = createSelector(
  reductionCreate,
  enhancementCreate,
  additionCreate,
  (reduction, enhancement, addition) => {
    const allReduction = reduction.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const allEnhancement = enhancement.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const allAddition = addition.reduce((x, y) => {
      return x + y.value;
    }, 0);

    const baseValue = allEnhancement + allAddition - allReduction;
    return baseValue;
  }
);

export function getMandays(questionnaire) {
  const numberOfEmployees = questionnaire.locations.reduce((sum, x, i) => {
    return sum + parseInt(get(x, "detail.number_of_employees"), 10) || 0;
  }, 0);
  let uniqueStandards = [];
  for (let s of questionnaire.scopes) {
    const sid = s.standard.id;
    if (uniqueStandards.map((x) => x.standard.id).indexOf(sid) < 0) {
      uniqueStandards.push(s);
    }
  }
  uniqueStandards = uniqueStandards
    .filter((x) => x.standard.detail.manday)
    .map((x) => {
      if (x.scope.detail.level === "med") {
        x.scope.detail.level = "medium";
      }
      return {
        level: x.scope.detail.level || "default",
        manday: x.standard.detail.manday,
      };
    })
    .map((x) => {
      const days = x.manday.reduce((sum, xx, i) => {
        if (numberOfEmployees >= xx.employee) {
          return xx[x.level];
        }
        return sum;
      }, 0);
      return days;
    });
  return max(uniqueStandards);
}
