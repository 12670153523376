export const initialState = {
  scheduleList: { data: null, pagination: null, error: null, loading: false },
  globalScheduleList: {
    data: null,
    pagination: null,
    error: null,
    loading: false,
  },
  schedule: { data: null, error: null, loading: false },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SCHEDULES_FULFILLED":
      return {
        ...state,
        scheduleList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false,
        },
      };

    case "FETCH_GLOBAL_SCHEDULES_FULFILLED":
      return {
        ...state,
        globalScheduleList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false,
        },
      };

    case "CREATE_SCHEDULE_FULFILLED":
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          data: [...state.scheduleList.data, { ...action.payload }],
        },
      };

    case "UPDATE_SCHEDULE_FULFILLED":
      return {
        ...state,
        scheduleList: {
          data: state.scheduleList.data.map((x) => {
            if (x.id === action.payload.id) {
              return action.payload;
            }
            return x;
          }),
        },
      };

    case "DELETE_SCHEDULE_FULFILLED":
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          data: state.scheduleList.data.filter((x) => x.id !== action.payload),
        },
      };

    case "UPDATE_ORDER_FULFILLED":
      return {
        ...state,
        scheduleList: {
          data: [...action.payload.schedules],
          error: null,
          loading: false,
        },
      };

    case "LOGOUT_FULFILLED":
      return { ...initialState };

    default:
      return state;
  }
};
