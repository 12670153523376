import React from 'react'

export function getQuotationLabel (status) {
  let label
  switch (status) {
    case 'Draft':
      label = 'warning'
      break
    case 'Created':
      label = 'info'
      break
    case 'Reviewed by SM':
      label = 'primary'
      break
    case 'Published to Customer':
      label = 'primary'
      break
    case 'Manual Approval':
      label = 'primary'
      break
    case 'Approved by Customer':
      label = 'success'
      break
    case 'Need to Revise':
      label = 'warning'
      break
    case 'Amendment Created':
      label = 'warning'
      break
    case 'Amendment Reviewed by SM':
      label = 'warning'
      break
    case 'Amendment Published to Customer':
      label = 'warning'
      break
    case 'Amendment Need to Revise':
      label = 'warning'
      break
    case 'Amendment Approved by Customer':
      label = 'success'
      break
    default:
      label = 'danger'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}
