import React from 'react'

export default function LoadingModal ({ open, message }) {
  if (!open) {
    return ''
  }
  return (
    <div>
      <div className='modal fade in' id='myModal' tabIndex='-1' role='dialog' style={{ display: 'block', paddingRight: '15px' }} >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body text-center'>
              {message || '...loading...'}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade in' />
    </div>
  )
}
