import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { AutoCompleteField, TextField, OptionsField } from '../common/FormFields'
import FileField from '../common/FileField'
import RichTextField from '../common/RichTextField'
import ConfirmButton from '../common/ConfirmButton'

function validate (values) {
  return {}
  // if (!values.advance) {
  //   return {}
  // }
  // const errors = { detail_detail: {} }
  // return errors
}

export class QuestionnaireReviewForm extends Component {
  render () {
    const { handleSubmit, submitting, changeForm } = this.props
    return (

      <div>
        <form className='form questionnaire-review-form' onSubmit={handleSubmit}>
          <Field
            stack
            name='company_detail_complete'
            type='selectOrInput'
            component={OptionsField}
            label='1. Company Detail Complete?'
            options={[
              '', 'Yes', 'No'
            ]}
          />

          <Field name='company_detail_note' component={RichTextField} label='Company detail remarks' stack />
          <Field name='company_detail_file' component={FileField} label='Attachment' stack api={{...this.props.fileAPI, path: 'questionnaire'}} />

          <hr />

          <Field
            stack
            name='implementation_complete'
            type='selectOrInput'
            component={OptionsField}
            label='2. Implementation and application of the management system sufficient?'
            help='Based on result of the details given in the annex'
            options={[
              '', 'Yes', 'No'
            ]}
          />

          <Field name='implementation_note' component={RichTextField} label='Implementation remarks' stack />
          <Field name='implementation_file' component={FileField} label='Attachment' stack api={{...this.props.fileAPI, path: 'questionnaire'}} />

          <hr />

          <Field
            stack
            name='number_of_site'
            component={TextField}
            label='3. Number of sample site need to be audited? (In case of multiline certification)'
            help='In case of multisite certification'
          />

          <Field
            stack
            name='scope_mandays_appropriate'
            type='selectOrInput'
            component={OptionsField}
            label='4. Is the scope of certificate and mandays audit appropriate?'
            help='(please check the atea preliminary done by the sales)'
            options={[
              '', 'Yes', 'No'
            ]}
          />

          <Field name='scope_mandays_appropriate_note' component={RichTextField} label='Scope and mandays remarks' stack />
          <Field name='scope_mandays_appropriate_file' component={FileField} label='Attachment' stack api={{...this.props.fileAPI, path: 'questionnaire'}} />

          <hr />

          <Field
            stack
            name='competence_personal'
            type='selectOrInput'
            component={OptionsField}
            label='5. CB has the competence personal (auditor or expert) to perform the audit activity?'
            options={[
              '', 'Yes', 'No'
            ]}
          />

          <Field name='competence_personal_note' component={RichTextField} label='Competence personal remarks' stack />
          <Field name='competence_personal_file' component={FileField} label='Attachment' stack api={{...this.props.fileAPI, path: 'questionnaire'}} />

          <hr />

          <Field
            stack
            name='other_influence'
            type='selectOrInput'
            component={OptionsField}
            label='6. Any other points influencing the certification activity are taken into account? (language, safety conditions, threats to impartiality, etc.)'
            options={[
              '', 'Yes', 'No'
            ]}
          />

          <Field name='other_influence_note' component={RichTextField} label='Other points remarks' stack />

          { this.props.showReviewer &&
            <Field
              stack
              name='reviewer'
              component={AutoCompleteField}
              label='Select Reviewer'
              api={this.props.userAPI}
            />
          }

          <Field
            stack
            name='review_result'
            type='select'
            component={OptionsField}
            label='7. Application Review Decision'
            options={this.props.allowApprove
              ? [ '', 'Approved', 'Need to Revise' ]
              : [ '', 'Need to Revise' ]
            }
          />

          <Field name='review_result_note' component={RichTextField} label='General comments' stack />

          <Field hidden name='advance' component={TextField} label='Yes please' stack />

          <div className='row'>
            <div className='col-sm-6'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', false)
                setTimeout(handleSubmit, 100)
              }} >Save Draft</button>
            </div>
            <div className='col-sm-6'>
              <ConfirmButton disabled={submitting} text={'Submit Review'}>
                <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                  ev.preventDefault()
                  changeForm('advance', true)
                  setTimeout(handleSubmit, 100)
                }} >Continue</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

QuestionnaireReviewForm = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireReviewForm',
  enableReinitialize: true,
  validate: validate
})(QuestionnaireReviewForm)

export default QuestionnaireReviewForm
