export const initialState = {
  optionList: {
    data: null,
    pagination: null,
    error: null,
    loading: false,
  },
  option: {
    data: null,
    error: null,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_OPTIONS_FULFILLED":
      return {
        ...state,
        optionList: {
          data: action.payload,
          pagination: action.pagination,
          error: null,
          loading: false,
        },
      };

    case "FETCH_OPTION_FULFILLED":
      return {
        ...state,
        option: { data: action.payload, error: null, loading: false },
      };

    case "UPDATE_OPTION_FULFILLED":
      return {
        ...state,
        option: { data: action.payload, error: null, loading: false },
      };

    case "DELETE_OPTION_FULFILLED":
      return { ...state, option: { data: null, error: null, loading: false } };

    case "LOGOUT_FULFILLED":
      return { ...initialState };

    default:
      return state;
  }
};
