import moment from 'moment';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { AllowanceField } from '../Das/DaFields';
import { TextareaField, TextField } from '../common/FormFields';

class DaForm extends Component {
  render() {
    const { handleSubmit, submitting, schedules, role } = this.props;

    return (
      <div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <Field
            name="auditor"
            type="text"
            component={TextField}
            label="Auditor Name"
            disabled
          />

          <Field
            name="date"
            type="text"
            component={TextField}
            label="Date"
            disabled
          />

          {
          //   this.props.status === 'Approved' && role.includes('Finance') && (
          //   <Field
          //     name="detail.payment_receipt"
          //     component={FileField}
          //     label="Payment receipt"
          //     api={this.props.fileAPI}
          //   />
          // )
          }

          {schedules.map((x, i) => (
            <div key={i}>
              <hr />
              <h4>
                {x.customer.name} - {x.order.scopes[0].standard.name}{' '}
                <small>
                  {x.order.phase} {x.type} - as {x.role}
                </small>
              </h4>
              <p>
                <strong>Start : </strong>
                {moment(x.start_date).format('DD MMM YYYY')} -{' '}
                <strong>End : </strong>
                {moment(x.end_date).format('DD MMM YYYY')} -{' '}
                <strong>Duration : </strong> {x.duration} day(s)
              </p>
              <p>
                <strong>Location : </strong>
                {x.location.name} {x.location.city} {x.location.country}
              </p>

              <Field
                disabled={!['Draft', 'Need to Revise'].includes(this.props.status)}
                name={`detail.fees.fees-${x.id}`}
                component={AllowanceField}
                label="Allowances"
                options={[
                  'Audit(weekday)',
                  'Audit(weekend)',
                  'Traveling',
                  'Overtime',
                  // 'Reporting',
                  // 'Desk Audit'
                ]}
              />
            </div>
          ))}

            <Field
              name="detail.note"
              component={TextareaField}
              label="Note"
              disabled={this.props.status !== 'Proposed'}
            />

          <div className="form-group">
            <div className="col-sm-offset-3 col-sm-9">
             {['Draft', 'Need to Revise'].includes(this.props.status) && (
               <>
                  <button
                    disabled={submitting}
                    type="submit"
                    className="btn btn-default btn-block"
                  >
                    Draft
                  </button>
                  <button
                    disabled={submitting}
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={ev => {
                      ev.preventDefault();
                      this.props.change('status', 'Proposed');
                      setTimeout(handleSubmit, 100);
                    }}
                  >
                    Submit
                  </button>
               </>
             )}
             {role.includes('Operational Manager') && this.props.status === 'Proposed' && (
               <>
                  <button
                    disabled={submitting}
                    type="submit"
                    className="btn btn-default btn-block"
                    onClick={ev => {
                      ev.preventDefault();
                      this.props.change('status', 'Need to Revise');
                      setTimeout(handleSubmit, 100);
                    }}
                  >
                    Need to Revise
                  </button>
                  <button
                    disabled={submitting}
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={ev => {
                      ev.preventDefault();
                      this.props.change('status', 'Approved');
                      setTimeout(handleSubmit, 100);
                    }}
                  >
                    Approve
                  </button>
               </>
             )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default DaForm;

const BaseDaUpdateForm = props => <DaForm {...props} mode="update" />;

export const DaUpdateForm = reduxForm({
  form: 'daUpdate', // validate, warn
})(BaseDaUpdateForm);
