import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  TextField,
  OptionsField,
  TextareaField,
  AutoCompleteField
} from '../common/FormFields'
import FileField from '../common/FileField'
import DateField from '../common/DateField'

const statuses = ['Scheduled', 'Draft', 'Created', 'Paid']
const banks = ['Bank HSBC Indonesia', 'Bank Mandiri']

class InvoiceForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal invoice-form' onSubmit={handleSubmit}>
          <Field name='detail.billing_to' component={AutoCompleteField} label='Billing to' api={this.props.customerAPI} />
          <Field name='invoice_no' type='text' component={TextField} label='Invoice Number' />
          <Field name='detail.tax' component={TextField} label='Tax Faktur no' />
          <Field name='detail.faktur_pajak' component={FileField} label='Faktur Pajak' api={{...this.props.fileAPI, path: 'invoice'}} />
          <Field name='detail.description' type='text' component={TextareaField} label='Custom description' />
          <Field name='detail.terms' component={TextField} label='Terms of Payment' disabled />
          <Field name='total' type='number' component={TextField} label='Value' disabled />
          <Field name='detail.date' component={DateField} label='Date' />
          <Field name='detail.bank' type='select' component={OptionsField} label='Selected Bank Account' options={banks} />
          <Field name='detail.payment_detail' component={FileField} label='Bukti Pembayaran' api={{...this.props.fileAPI, path: 'invoice'}} />
          <Field name='detail.payment_date' component={DateField} label='Payment Date' />
          <Field name='status' type='select' component={OptionsField} label='Status' options={statuses} />
          <Field name='remarks' type='text' component={TextareaField} label='Remarks' />
          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Save</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default InvoiceForm

const BaseInvoiceCreateForm = props => <InvoiceForm {...props} mode='create' />

export const InvoiceCreateForm = reduxForm({
  form: 'invoiceCreate',
  enableReinitialize: true
})(BaseInvoiceCreateForm)

const BaseInvoiceUpdateForm = props => <InvoiceForm {...props} mode='create' />

export const InvoiceUpdateForm = reduxForm({
  form: 'invoiceUpdate',
  enableReinitialize: true
})(BaseInvoiceUpdateForm)
