import React from 'react';
import moment from 'moment';
import { countBy, forEach, findIndex, cloneDeep } from 'lodash';

import ScheduleRow from './ScheduleRow';

import './AuditorScheduleTable.css';

export default class AuditorScheduleTable extends React.Component {
  constructor(props) {
    super(props);
    const start = moment().subtract(1, 'year');
    const months = [
      {
        value: start.format('YYYY-MM'),
        label: start.format('MMMM YYYY'),
      },
    ];
    for (var i = 0; i < 35; i++) {
      start.add(1, 'months');
      months.push({
        value: start.format('YYYY-MM'),
        label: start.format('MMMM YYYY'),
      });
    }

    this.state = {
      schedules: props.auditorsSchedule,
      orderSchedules: props.initialValues,
      standards: 0,
      scope: 0,
      months,
      isOpen: null,
      nextDate: null,
      selectedDate: null,
      selectedUser: null,
    };
  }

  handleOpenSchedule(user, schedule) {
    this.setState(prevState => ({
      selectedUser: user,
      selectedSchedule: schedule,
      selectedDate: null,
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialValues &&
      this.state.orderSchedules !== nextProps.initialValues
    ) {
      this.setState({
        orderSchedules: nextProps.initialValues,
      });
    }
  }

  updateFilter(e) {
    e.preventDefault();
    const { standardFilter, scopeFilter, monthFilter } = this.refs;
    let newSchedule = this.props.auditorsSchedule;
    this.props.onUpdateFilter(monthFilter.value);
    if (standardFilter && standardFilter.value !== '0') {
      newSchedule = newSchedule.filter(x =>
        x.specifications.find(
          y => y.standard.id === parseInt(standardFilter.value, 10)
        )
      );
    }
    if (scopeFilter && scopeFilter.value !== '0') {
      newSchedule = newSchedule.filter(x =>
        x.specifications.find(
          y => y.scope.id === parseInt(scopeFilter.value, 10)
        )
      );
    }
    this.setState({ schedules: newSchedule });
  }

  getDatesWithSchedule(days, schedules) {
    return days.map((x, i) => {
      let schedule = false;
      if (schedules.length > 0) {
        schedule = schedules.find(
          y =>
            y.start_date <= x.date.format('YYYY-MM-DD') &&
            y.end_date >= x.date.format('YYYY-MM-DD')
        );
      }
      return (
        <td
          key={i}
          className={
            ['Sunday', 'Saturday'].indexOf(x.date.format('dddd')) > -1
              ? 'bg-info'
              : ''
          }
        >
          {!schedule ? x.date.format('DD') : ''}
        </td>
      );
    });
  }

  pad(x) {
    return x < 10 ? '0' + x : x;
  }

  selectDate(user, date) {
    const { dates, schedule, ...userBase } = user;
    let newSchedule = this.state.orderSchedules;
    let selectedSchedule;

    if (
      newSchedule.length === 0 || // empty schedule
      !newSchedule.find(x => x.user.id === user.id) || // new user selected
      !newSchedule.find(
        x => x.user.id === user.id && x.start_date === x.end_date
      ) // existing user selected with no unset date
    ) {
      newSchedule.push({
        user: userBase,
        role: 'Auditor',
        start_date: date.fullDate,
        end_date: date.fullDate,
        xid: `${userBase.id}-${date.fullDate}`,
        type: 'Stage 1',
        detail: {
          includeManday: false,
        },
      });
    } else if (
      newSchedule.find(
        x => x.user.id === user.id && x.start_date === x.end_date
      )
    ) {
      // existing user selected with unset date
      selectedSchedule = newSchedule.find(
        (x, i) => x.user.id === user.id && x.start_date === x.end_date
      );
      if (selectedSchedule.start_date < date.fullDate) {
        selectedSchedule.end_date = date.fullDate;
      } else if (selectedSchedule.start_date > date.fullDate) {
        selectedSchedule.start_date = date.fullDate;
      }

      newSchedule = newSchedule.map((x, i) => {
        if (
          x.user.id === selectedSchedule.user.id &&
          x.start_date === x.end_date
        ) {
          return selectedSchedule;
        } else {
          return x;
        }
      });
    }

    this.setState({
      orderSchedules: newSchedule,
    });

    return this.props.onUpdateSchedule(newSchedule);
  }

  removeDate(schedule) {
    const orderSchedules = this.state.orderSchedules.filter(
      x => x.xid !== schedule.xid
    );
    this.setState({
      orderSchedules,
    });
    return this.props.onUpdateSchedule(orderSchedules);
  }

  handleSubmit(data) {
    let newSchedule = this.state.orderSchedules;
    // let selectedSchedule
    const newData = {
      ...data,
      type: data.stage,
      detail: { includeManday: data.includeManday },
    };

    newSchedule.push(newData);
    this.setState({
      orderSchedules: newSchedule,
    });

    return this.props.onUpdateSchedule(newSchedule);
  }

  handleRemove(user, date) {
    const orderSchedules = this.state.orderSchedules
      .filter(x => x.xid !== `${user}-${date}`)
      .filter(x => !(x.user.id === user && x.start_date === date));

    this.setState({
      orderSchedules,
    });

    return this.props.onUpdateSchedule(orderSchedules);
  }

  render() {
    const {
      schedules,
      months,
      selectedUser,
      selectedSchedule,
      orderSchedules,
    } = this.state;
    const { serviceDetail, order } = this.props;
    const start = moment(this.props.start);
    const end = moment(this.props.end);
    const numberOfDays = end.diff(start, 'days');
    const locations = order
      ? order.questionnaire.locations.map((x, i) => {
          return {
            key: x.location.id,
            label: x.location.name + ' - ' + x.location.city,
          };
        })
      : [
          {
            key: null,
            label: 'n/a',
          },
        ];

    const dates = [
      {
        order: 0,
        fullDate: start.format('YYYY-MM-DD'),
        day: start.format('dddd'),
        date: start.format('DD'),
        month: start.format('MMMM'),
        monthYear:`${start.format('MMMM')} ${start.format('YYYY')}`,
        year: start.format('YYYY'),
      },
    ];

    for (var i = 0; i < numberOfDays; i++) {
      start.add(1, 'days');
      dates.push({
        order: i + 1,
        fullDate: start.format('YYYY-MM-DD'),
        day: start.format('dddd'),
        date: start.format('DD'),
        month: start.format('MMMM'),
        monthYear:`${start.format('MMMM')} ${start.format('YYYY')}`,
        year: start.format('YYYY'),
      });
    }

    const monthHeader = [];
    forEach(countBy(dates, 'monthYear'), function (val, key) {
      monthHeader.push({
        name: key,
        count: val,
      });
    });

    const standards = serviceDetail
      ? serviceDetail.scopes.map(x => x.standard)
      : [];
    const scopes = serviceDetail ? serviceDetail.scopes.map(x => x.scope) : [];
    const schedulesWithDates = schedules.map((x) => {
      x.dates = cloneDeep(dates);
      forEach(x.schedule, (y) => {
        const dayIndex = findIndex(x.dates, function (o) {
          return o.fullDate === y.start_date;
        });
        if (x.dates[dayIndex]) {
          x.dates[dayIndex].schedule = y;
          x.dates.splice(dayIndex + 1, y.duration - 1);
        }
      });
      return x;
    });

    return (
      <div className="auditor-schedule-table">
        <form className="form-inline" onSubmit={this.updateFilter.bind(this)}>
          {serviceDetail && (
            <div className="form-group">
              <select
                name=""
                id=""
                className="form-control"
                ref="standardFilter"
              >
                <option key={null} value={0}>
                  All Standards
                </option>
                {standards.map((x, y) => {
                  return (
                    <option key={y} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}{' '}
          {serviceDetail && (
            <div className="form-group">
              <select name="" id="" className="form-control" ref="scopeFilter">
                <option key={null} value={0}>
                  All Scopes
                </option>
                {scopes.map((x, y) => {
                  return (
                    <option key={y} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}{' '}
          <div className="form-group">
            <select name="" id="" className="form-control" ref="monthFilter">
              <option>Select month</option>
              {months.map((x, i) => (
                <option key={i} value={x.value}>
                  {x.label}
                </option>
              ))}
            </select>
          </div>{' '}
          <button type="submit" className="btn btn-default">
            Filter Auditors
          </button>
        </form>
        <br />
        {schedules.length ? (
          <div className="table-responsive">
            <table className="table table-schedule table-condensed">
              <thead>
                <tr>
                  <th className="fixed-left" width={400}>
                    Auditor
                  </th>
                  {monthHeader.map((x, i) => (
                    <th key={i} colSpan={x.count}>
                      {x.name}
                    </th>
                  ))}
                </tr>
                <tr className="date-row">
                  <th className="fixed-left">&nbsp;</th>
                  {dates.map((x, i) => {
                    let className = '';

                    className =
                      ['Sunday', 'Saturday'].indexOf(x.day) > -1
                        ? 'bg-info'
                        : '';

                    const event =
                      this.props.events &&
                      this.props.events.find(yy => yy.date === x.fullDate);
                    if (event) {
                      className = 'bg-success';
                    }
                    return (
                      <td key={i} className={className}>
                        {x.date}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {schedulesWithDates.map((x, i) => (
                  <ScheduleRow
                    onOpenModal={this.props.onOpenModal}
                    key={i}
                    schedule={x}
                    events={this.props.events}
                    orderSchedules={orderSchedules}
                    locations={locations}
                    order={order}
                    onOpenSchedule={this.handleOpenSchedule.bind(this)}
                    openSchedule={
                      x.id === selectedUser ? selectedSchedule : null
                    }
                    onSubmit={this.handleSubmit.bind(this)}
                    onRemove={this.handleRemove.bind(this)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>not found</div>
        )}
      </div>
    );
  }
}
