import React from 'react';
import { Link } from 'react-router-dom';

import { getInvoiceLabel } from '../../const/orders';

export default class InvoiceTable extends React.Component {
  render() {
    const { invoices } = this.props;
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Invoice Number</th>
              <th>Order</th>
              <th>Quotation</th>
              <th>Questionnaire</th>
              <th>Customer</th>
              <th>Standard</th>
              <th>Cycle</th>
              <th>Payment Terms</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((x, i) => (
              <tr key={i}>
                <td>
                  <Link to={`/orders/${x.order.id}/invoices/${x.id}`}>
                    {x.id}
                  </Link>
                </td>
                <td>{x.invoice_no || 'n/a'}</td>
                <td>
                  <Link to={`/orders/${x.order.id}`}>{x.order.number}</Link>
                </td>
                <td>
                  <Link to={`/quotations/${x.order.quotation.id}`}>{x.order.quotation.number}</Link>
                </td>
                <td>
                  <Link to={`/questionnaires/${x.order.questionnaire.id}`}>{x.order.questionnaire.number}</Link>
                </td>
                <td>
                  <Link to={`/orders/${x.order.id}`}>
                    {x.order.customer.name}
                  </Link>
                </td>
                <td>{x.order.questionnaire.scopes.map(x => x.standard.name).join('-')}</td>
                <td>
                  {x.order.phase}
                </td>
                <td>{x?.detail?.terms}</td>
                <td>{getInvoiceLabel(x.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
