import React, { Fragment } from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import get from 'lodash/get'

import ConfirmButton from '../common/ConfirmButton'
import TableField from '../common/TableField'
import { TextField } from '../common/FormFields'
import FileField from '../common/FileField'

function validate (values) {
  return {}
}

export function QuestionnaireAdditionalInformation (props) {
  const { handleSubmit, submitting, changeForm, fileAPI, questionnaire } = props

  const isTransfer = get(questionnaire, 'detail.transfer_audit.length')
  const isMultisite = get(questionnaire, 'locations.length')

  return (
    <div>
      <form className='form-horizontal questionnaire-additional-information-form' onSubmit={handleSubmit}>

        <h3>Prerequisite Documents</h3>
        <p>The following documents must be sent to TUV NORD Indonesia after the contract is approved. <br /> If available you can upload the documents by now!</p>

        <Field name='detail.requested_information.quality_managament_manual' component={FileField} label='Quality Management Manual with Revision including scope, details of justification for any exclusions, policy, targets' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.legal_documents' component={FileField} label='Legal documents' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.organisation_chart' component={FileField} label='Current organisation chart of the company' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.business_process_map' component={FileField} label='Business Process Map' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.internal_audit' component={FileField} label='Results or summary of internal audits within the last 12 months' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.external_audit' component={FileField} label='Results of the management review since the last external audit' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.last_audit_reports' component={FileField} label='Audit report and nonconformity management of the last certification body (additionally for transfer audits only)' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.last_audit_closing' component={FileField} label='Evidence of the last certification body that nonconformities are “closed” (additionally for transfer audits only)' api={{...fileAPI, path: 'questionnaire'}} />
        <Field name='detail.requested_information.last_certification' component={FileField} label='Certificates of the last certification body (additionally for transfer audits only)' api={{...fileAPI, path: 'questionnaire'}} />

        { isMultisite > 1 &&
          <Fragment>
            <p>Please use <a href='https://trello-attachments.s3.amazonaws.com/5920cfaedcdb726f151950d5/5e683c2189514516dbc5908b/32be39ae526acca47d06b74e3a2b76b1/FMLF-TNI-082_Annex_7_Rev.00_Multi_Site_Eligibility_Check.docx'>this template</a> for Multi Site Eligibility Check.</p>
            <Field name='detail.annex_multisite' component={FileField} label='Multi Site Eligibility Check.' api={{...fileAPI, path: 'questionnaire'}} />
          </Fragment>
        }
        { isTransfer &&
          <Fragment>
            <p>Please use <a href='https://trello-attachments.s3.amazonaws.com/5920cfaedcdb726f151950d5/5e683c2189514516dbc5908b/4e52cea5c45a4804166838b9caf591aa/FMLF-TNI-082_Annex_6_Rev.01_Transfer_Audit.docx'>this template</a> for Transfer Audit Eligibility Check.</p>
            <Field name='detail.annex_transfer' component={FileField} label='Transfer Audit Eligibility Check' api={{...fileAPI, path: 'questionnaire'}} />
          </Fragment>
        }

        <h3>Project History</h3>

        <FieldArray
          name='detail.ongoing_project'
          component={TableField}
          label='Ongoing project'
          heads={['Project name', 'Location']}
        />

        <FieldArray
          name='detail.within_last_year'
          component={TableField}
          label='Project done within last 1 year'
          heads={['Project name', 'Location']}
        />

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
              ev.preventDefault()
              changeForm('advance', false)
              setTimeout(handleSubmit, 100)
            }} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton
              disabled={submitting}
              text={'Save Questionnaire'}
            >
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', true)
                setTimeout(handleSubmit, 100)
              }} >Continue</button>
            </ConfirmButton>
          </div>
        </div>
      </form>
    </div>
  )
}

QuestionnaireAdditionalInformation = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireAdditionalInformationForm',
  enableReinitialize: true,
  validate: validate
})(QuestionnaireAdditionalInformation)

export default QuestionnaireAdditionalInformation
