import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class RevisionWidget extends React.Component {
  render () {
    // const q = this.props.quotqtion
    // const r = q.review_detail

    // if (!r) {
    //   return ''
    // }

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Revision History</h3>
        </div>
        <div className='widget-content'>
          <table className='table'>
            <thead>
              <tr>
                <th>Questionnaire Number</th>
                <th>Revision Date</th>
              </tr>
            </thead>
            <tbody>
              {this.props.revision.map((x, i) => (
                <tr key={i}>
                  <td><Link to={`/questionnaires/${x.id}`}>{x.number}</Link></td>
                  <td>{moment.unix(x.created_at).format('DD MMMM YYYY')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
