import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Pagination from '../../components/common/Pagination';
import AuditPlanTable from '../../components/AuditPlans/AuditPlanTable';
import AuditPlanFilter from '../../components/AuditPlans/AuditPlanFilter';

import { fetchGlobalPlans } from '../../actions/plans';
import { asQueryObject, asQueryString } from '../../const/helper';

class AuditPlanList extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchGlobalPlans(
        nextProps.location.search,
        this.props.accessToken
      );
    }
  }

  componentWillMount() {
    this.props.fetchGlobalPlans(
      this.props.location.search,
      this.props.accessToken
    );
  }

  handleFilter(filter) {
    if (filter.customer) {
      filter.customer = filter.customer.value.id
    }
    delete filter.page;
    return this.props.push(`/audit-plans?${asQueryString(filter)}`);
  }

  render() {
    const { plans, pagination, location, isCustomer } = this.props;

    return (
      <div className="content">
        <div className="main-header">
          <h2>Audit Plan List</h2>
          <em className="hide">normal sidebar with minified mode by default</em>
        </div>
        <div className="main-content">
          <div className="row">
            <div className="col-md-8">
              {plans ? (
                <div>
                  <AuditPlanFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                    isCustomer={isCustomer}
                    customerAPI={{
                      url: process.env.REACT_APP_BASE_API + '/customers',
                      accessToken: this.props.accessToken,
                    }}
                  />
                  <p>Total count : {pagination.totalCount} {pagination.totalCount > 1 ? 'items' : 'item'}</p>
                  <AuditPlanTable plans={plans} />
                </div>
              ) : (
                <div>loading</div>
              )}
              <Pagination
                pagination={pagination}
                base="/audit-plans"
                params={asQueryObject(location.search)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plans: state.plans.globalPlanList.data,
    pagination: state.plans.globalPlanList.pagination,
    accessToken: state.auth.user.access.access_token,
    isCustomer: state.auth.user.data.role.includes('Customer'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchGlobalPlans: (params, key) => dispatch(fetchGlobalPlans(params, key)),
    push: to => dispatch(push(to)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditPlanList);
