import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import AutoCompleteField from '../common/AutoCompleteField'
import quotationStatuses from '../../const/quotationStatuses'

class BaseQuestionnaireFilter extends Component {
  render () {
    const { handleSubmit, submitting, isShortVersion } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          {!isShortVersion && (
            <>
              <div className='form-group'>
                <Field name='status' component='select' className='form-control'>
                  <option value=''>All Status &nbsp;</option>
                  {quotationStatuses.map((x, i) => <option key={i} value={x.label}>{x.label} &nbsp;</option>)}
                </Field>
              </div>
              &nbsp;
            </>
          )}
          <div className='form-group' style={{width: 150}}>
            <Field
              name='standard'
              component={AutoCompleteField}
              label='Standard'
              api={this.props.standardAPI}
            />
          </div>
          &nbsp;
          {!isShortVersion && (
            <>
              <div className='form-group' style={{width: 150}}>
                <Field
                  name='customer_id'
                  component={AutoCompleteField}
                  label='Customer'
                  api={this.props.customerAPI}
                />
              </div>
              &nbsp;
              <div className='form-group' style={{width: 150}}>
                <Field
                  name='sales_id'
                  component={AutoCompleteField}
                  label='Sales'
                  api={this.props.salesAPI}
                />
              </div>
              &nbsp;
            </>  
          )}
          <div className="form-group">
            <label className="sr-only" htmlFor="exampleInputEmail2">
              Quotation Number
            </label>
            <Field
              name="number"
              component="input"
              type="text"
              placeholder="Search for quotation number..."
              className="form-control"
            />
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/quotations' className='btn btn-link pull-right'>Reset</Link>
        </form>
        <hr />
      </div>
    )
  }
}

let QuestionnaireFilter = reduxForm({
  form: 'questionnaireFilter' //, validate, warn
})(BaseQuestionnaireFilter)

export { BaseQuestionnaireFilter }
export default QuestionnaireFilter
