import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function LocalRow ({ id, name, business }) {
  return (
    <tr>
      <td><Link to={`/customers/${id}`}>{id}</Link></td>
      <td><Link to={`/customers/${id}`}>{name}</Link></td>
      <td>{business}</td>
    </tr>
  )
}

function BaseRow (x) {
  return (
    <tr>
      <td>
        {x.local
          ? <Fragment>
            <Link to={`/customers/${x.local.id}`}>{x.customer_id}</Link>
            <span>*</span>
          </Fragment>
          : <Fragment>
            <button type='button' className='btn-link' onClick={xx => x.onSync(x.customer_id)}>
              {x.customer_id}
            </button>
          </Fragment>
        }
      </td>
      <td>
        {x.local
          ? <Fragment>
            <Link to={`/customers/${x.local.id}`}>{x.customer_name}</Link>
            <span>*</span>
          </Fragment>
          : <Fragment>
            <button type='button' className='btn-link' onClick={xx => x.onSync(x.customer_id)}>
              {x.customer_name}
            </button>
          </Fragment>
        }
      </td>
      <td>{x.customer_bisnis}</td>
    </tr>
  )
}

export default function CustomerTable ({ customers, onSync }) {
  if (!customers) {
    return ''
  }
  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Customer Name</th>
            <th>Business</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((x) => {
            if (x.id) { return <LocalRow key={x.id} {...x} /> }
            if (x.customer_id) { return <BaseRow key={x.id} {...x} onSync={onSync}/> }
            return ''
          })}
        </tbody>
      </table>
    </div>
  )
}
