import React from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { CustomerCreateForm as CustomerForm } from '../../components/Customers/CustomerForm'
import { createCustomer } from '../../actions/customers'
import businessType from '../../const/businessType'

export function CustomerNew (props) {
  const step = 0

  function handleSubmit (e) {
    return props.submitCustomer(e, props.accessToken)
  }

  return (
    <div className='content'>
      <div className='main-header'>
        <h2>Create New Customer</h2>
      </div>
      <div className='main-content'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='widget'>
              <div className='widget-content'>

                <div className='wizard' style={{ marginBottom: 20 }}>
                  <ul className='steps'>
                    <li className={step === 0 ? 'active' : ''}><span className={step === 0 ? 'badge badge-info' : 'badge'}>1</span>Basic Information<span className='chevron' /></li>
                    <li className={step === 1 ? 'active' : ''}><span className={step === 1 ? 'badge badge-info' : 'badge'}>2</span>Locations<span className='chevron' /></li>
                    <li className={step === 2 ? 'active' : ''}><span className={step === 2 ? 'badge badge-info' : 'badge'}>3</span>Contacts<span className='chevron' /></li>
                    <li className={step === 3 ? 'active last' : ' last'}><span className={step === 3 ? 'badge badge-info' : 'badge'}>4</span>Other Information</li>
                  </ul>
                </div>

                <CustomerForm
                  onSubmit={handleSubmit}
                  fileAPI={{
                    url: process.env.REACT_APP_BASE_API,
                    accessToken: props.accessToken
                  }}
                  basic
                />
              </div>
            </div>
          </div>
          <div className='col-md-4' />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    formValue: getFormValues('customerCreateExtended')(state),
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    submitCustomer: (data, key) => dispatch(createCustomer(data, key))
  }
}

export function XCustomerNew (props) {
  const step = 0

  function submitCustomer (e) {
    return props.submitCustomer(e, props.accessToken)
  }

  return (
    <div className='content'>
      <div className='main-header'>
        <h2>Create New Customer</h2>
      </div>
      <div className='main-content'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='widget'>
              <div className='widget-content'>

                <div className='wizard' style={{ marginBottom: 20 }}>
                  <ul className='steps'>
                    <li className={step === 0 ? 'active' : ''}><span className={step === 0 ? 'badge badge-info' : 'badge'}>1</span>Basic Information<span className='chevron' /></li>
                    <li className={step === 1 ? 'active' : ''}><span className={step === 1 ? 'badge badge-info' : 'badge'}>2</span>Locations<span className='chevron' /></li>
                    <li className={step === 2 ? 'active' : ''}><span className={step === 2 ? 'badge badge-info' : 'badge'}>3</span>Contacts<span className='chevron' /></li>
                    <li className={step === 3 ? 'active last' : ' last'}><span className={step === 3 ? 'badge badge-info' : 'badge'}>4</span>Other Information</li>
                  </ul>
                </div>

                <CustomerForm
                  onSubmit={submitCustomer.bind(this)}
                  initialValues={{
                    business: businessType[0]
                  }}
                  fileAPI={{
                    url: process.env.REACT_APP_BASE_API,
                    accessToken: props.accessToken
                  }}
                  basic
                />
              </div>
            </div>
          </div>
          <div className='col-md-4' />
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerNew)
