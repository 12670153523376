import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, OptionsField } from '../common/FormFields'
// import SelectOrInputField from '../common/SelectOrInputField'
// import FileField from '../common/FileField'
import ConfirmButton from '../common/ConfirmButton'

// import entityType from '../../const/entityType'
// import businessType from '../../const/businessType'

const validate = values => {
  const errors = {}
  // if (!values.name) {
  //   errors.name = 'Required'
  // } else if (values.name.length > 50) {
  //   errors.name = 'Must be 50 characters or less'
  // }
  // if (values.detail && values.detail.type === '') {
  //   errors.detail = {
  //     type: 'Required'
  //   }
  // }
  return errors
}

const requiredDocuments = [
  'Audit programme ATEA',
  'Review of certification period',
  'Review of system documents',
  'Declaration of independence',
  'Audit plan stage 1 and list of participants',
  'Audit report stage 1',
  'Records of stage 1 audit (e.g audit protocol, HWN,NCR)',
  'Draft of certificates ((Re-)Certification, Modification)',
  'Audit plan abd kist of participants',
  'Audit report',
  'Records of (stage 2) audit (e.g Audit Protocol, HWN,NCR)',
  'Management of nonconformities',
  'Release protocol',
  'Questionnaire, contract with client'
]

class OrderReleaseProtocol extends Component {
  render () {
    const { handleSubmit, submitting, changeForm } = this.props
    return (
      <form className='form-horizontal order-prerequisite-form' onSubmit={handleSubmit}>
        <fieldset>
          <legend>Audit Data</legend>
          <Field name='detail.release_protocol.audit_language' component={TextField} label='Audit Langauge' />
          <Field name='detail.release_protocol.audit_duration' component={TextField} label='Audit Duration' />
        </fieldset>
        <fieldset>
          <legend>Result</legend>
          <Field name='detail.release_protocol.correction_received_on' component={TextField} label='Correction from the organization received on' />
          <Field name='detail.release_protocol.calculation_confirmed' component={TextField} label='Calculation / audit program confirmed' />
        </fieldset>
        <fieldset>
          <legend>Documents</legend>
          <Field name='detail.release_protocol.required_ca' component={OptionsField} label='Title of Document / Record required by CA' options={requiredDocuments} type='multiCheckbox' />
          <Field name='detail.release_protocol.required_sa' component={OptionsField} label='Title of Document / Record required by SA' options={requiredDocuments} type='multiCheckbox' />
          <Field name='detail.release_protocol.required_ra' component={OptionsField} label='Title of Document / Record required by RA' options={requiredDocuments} type='multiCheckbox' />
          <Field name='detail.release_protocol.document_provided' component={OptionsField} label='Title of Document / Record Provided by Auditor' options={requiredDocuments} type='multiCheckbox' />
          <Field name='detail.release_protocol.document_checked' component={OptionsField} label='Title of Document / Checked by Veto Person' options={requiredDocuments} type='multiCheckbox' />
        </fieldset>
        <fieldset>
          <legend>Evaluation / Decision</legend>
          <Field name='detail.release_protocol.need_correction' component={TextField} label='Need correction' />
          <Field name='detail.release_protocol.granted' component={TextField} label='The certificate / the proposed modification can be granted (again)' />
          <Field name='detail.release_protocol.suspended' component={TextField} label='The certificate is suspended' />
          <Field name='detail.release_protocol.withdrawn' component={TextField} label='The certificate is withdrawn' />
        </fieldset>

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
              ev.preventDefault()
              changeForm('advance', false)
              setTimeout(handleSubmit, 100)
            }} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton
              disabled={submitting}
              text={'Submit'}
            >
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', true)
                setTimeout(handleSubmit, 100)
              }} >Continue</button>
            </ConfirmButton>
          </div>
        </div>

      </form>
    )
  }
}

export default OrderReleaseProtocol

const BaseOrderReleaseProtocolForm = props => <OrderReleaseProtocol {...props} mode='update' />

export const OrderReleaseProtocolForm = reduxForm({
  form: 'OrderReleaseProtocolForm',
  validate
})(BaseOrderReleaseProtocolForm)
