import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { change } from 'redux-form'
import get from 'lodash/get'

import { OrderPrerequisiteForm } from '../../components/Customers/OrderPrerequisiteForm'
import { fetchOrder, updateOrder } from '../../actions/orders'

export function OrderPrerequisiteView ({ order, match, accessToken, changeForm, ...props }) {
  const { fetchOrder } = props
  useEffect(() => {
    fetchOrder(match.params.orderId, accessToken)
  }, [match.params.orderId, accessToken, fetchOrder])

  function handleSubmit (e) {
    let target
    const request = {
      id: order.id,
      detail: {
        ...e.detail
      }
    }
    if (e.advance) {
      request.detail.prerequisite_complete = true
      request.status = 'Prerequisite Submitted'
      target = `/orders/${order.id}`
    }
    return props.updateOrder(request, accessToken, target).then((x) => {
      fetchOrder(order.id, accessToken)
    })
  }

  const requestedInformation = get(order, 'questionnaire.detail.requested_information')

  return (
    <div>
      <div className='main-header'>
        <h2>Order Prerequisite</h2>
        { order &&
          <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
        }
      </div>
      { order &&
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              <OrderPrerequisiteForm
                changeForm={changeForm}
                initialValues={{
                  detail: {
                    quality_managament_manual: (order.detail && order.detail.quality_managament_manual) || (requestedInformation && requestedInformation.quality_managament_manual),
                    legal_documents: (order.detail && order.detail.legal_documents) || (requestedInformation && requestedInformation.legal_documents),
                    organisation_chart: (order.detail && order.detail.organisation_chart) || (requestedInformation && requestedInformation.organisation_chart),
                    business_process_map: (order.detail && order.detail.business_process_map) || (requestedInformation && requestedInformation.business_process_map),
                    internal_audit: (order.detail && order.detail.internal_audit) || (requestedInformation && requestedInformation.internal_audit),
                    external_audit: (order.detail && order.detail.external_audit) || (requestedInformation && requestedInformation.external_audit),
                    last_audit_reports: (order.detail && order.detail.last_audit_reports) || (requestedInformation && requestedInformation.last_audit_reports),
                    last_audit_closing: (order.detail && order.detail.last_audit_closing) || (requestedInformation && requestedInformation.last_audit_closing),
                    last_certification: (order.detail && order.detail.last_certification) || (requestedInformation && requestedInformation.last_certification)
                  }
                }}
                onSubmit={handleSubmit.bind(this)}
                fileAPI={{
                  url: process.env.REACT_APP_BASE_API,
                  accessToken: accessToken
                }}
              />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    updateOrder: (data, key, target) => dispatch(updateOrder(data, key, target)),
    changeForm: (field, value) => dispatch(change('OrderPrerequisiteForm', field, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPrerequisiteView)
